"use strict";

angular.module('backstage.services').factory('essenceRegistry', /* @ngInject */["$q", "$injector", "blueprintService", function essenceRegistryFactory($q, $injector, blueprintService) {
  var appModule = blueprintService.lazyAppModule;
  function getEssence(essenceName) {
    var essenceFunc = appModule.metadataEssences[essenceName];
    return essenceFunc ? $q.resolve($injector.instantiate(essenceFunc)) : {};
  }
  return {
    getEssence: getEssence
  };
}]);