"use strict";

module.exports = /* @ngInject */["$routeParams", "$scope", "$q", "eventService", "eventCopyService", "userService", function EventCopyHistory($routeParams, $scope, $q, eventService, eventCopyService, userService) {
  var eventId = $scope.eventId = $routeParams.eventId;
  var event = eventService.decorateScope(eventId);
  var userEvents = [];
  $scope.loading = true;
  $scope.getEventName = function (eid) {
    var event = userEvents.find(function (_ref) {
      var _id = _ref._id;
      return _id === eid;
    });
    return (event || {}).eventname || eid;
  };
  $scope.navigateToEventReport = function (eid, reportId) {
    var event = userEvents.find(function (_ref2) {
      var _id = _ref2._id;
      return _id === eid;
    });
    if (!event || !reportId) return;
    window.location.href = eventService.generateGeoOptimalEventUrl(event, "/copy/history/".concat(reportId));
  };

  // load report and events
  $q.all([userService.getUserEvents().then(function (events) {
    return userEvents = events;
  }),
  // exports
  eventCopyService.getReports(event).then(function (reports) {
    return $scope.exportReports = reports;
  }),
  // ... and imports
  eventCopyService.getReports(event, true).then(function (reports) {
    return $scope.importReports = reports;
  })]).then($scope.loading = false);
}];