"use strict";

module.exports = function (options) {
  var resolver = /* @ngInject */function resolver($route, $q, eventService, metadataService) {
    var currentRouteDescriptor = $route.current ? $route.current.$$route : {};
    var fpType = options.fpType;
    var crudOpts = currentRouteDescriptor.crudOpts || {};
    var eventId = eventService.getCurrentEvent()._id;
    var _fpType = fpType || crudOpts.queryFpType;
    if (!_fpType) {
      throw Error('an fpType must be provided for this strategy');
    }
    return $q.when(metadataService.getCachedMetadataForTypeAsArray(eventId, _fpType));
  };
  resolver.$inject = ["$route", "$q", "eventService", "metadataService"];
  return resolver;
};