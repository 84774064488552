"use strict";

// bs-navigate-to-pax-profile-cell

module.exports = /* @ngInject */["$sanitize", "$filter", "eventService", "urlService", function ($sanitize, $filter, eventService, urlService) {
  return {
    restrict: 'AC',
    template: '<a></a>',
    link: function link(scope, elem, attrs) {
      var event = eventService.getCurrentEvent();
      scope.text = scope.dataRow[attrs.scopeAttr] || '(untitled)';
      var modelId = scope.dataRow._id;
      var linkHref = urlService.substituteParamsInRoutePathWithBasePath('/event/:eventId/participants/:modelId', event ? event.id : undefined, modelId);
      return elem.attr('href', linkHref).html($sanitize($filter('abbreviateFullUserName')(scope.text, 21)));
    }
  };
}];