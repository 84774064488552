"use strict";

module.exports = /* @ngInject */["$routeParams", "$scope", "$q", "eventService", "eventCopyService", "userService", function EventCopyHistoryDetails($routeParams, $scope, $q, eventService, eventCopyService, userService) {
  var eventId = $routeParams.eventId;
  var reportId = $routeParams.reportId;
  var event = eventService.decorateScope(eventId);
  var userEvents = [];
  $scope.getEventName = function (eid) {
    var event = userEvents.find(function (_ref) {
      var _id = _ref._id;
      return _id === eid;
    });
    return (event || {}).eventname || eid;
  };
  $scope.loading = true;

  // load report and events
  $q.all([userService.getUserEvents().then(function (events) {
    return userEvents = events;
  }), eventCopyService.getReport(event, reportId).then(function (report) {
    return $scope.report = report;
  })]).then($scope.loading = false);
}];