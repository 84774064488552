module.exports = [
    '$timeout',
    '$location',
    'localStorageService',
    ($timeout, $location, localStorageService) => ({
        restrict: 'A',
        require: '^smartTable',
        link(scope, elem, attr, ctrl = {}) {
            let storedPage;
            let nameSpace = `tablePaginationPersistence:${$location.path()}`;

            // safeguard against older version of smart-table
            if (!_.isFunction(ctrl.getCurrentPage)) {
                return;
            }

            // save the table state when it changes
            scope.$watch(() => ctrl.getCurrentPage()

                ,
                function(newValue, oldValue) {
                    if (newValue === oldValue) {
                        return;
                    }
                    localStorageService.set(nameSpace, newValue);
                });

            // fetch and load the table state when the directive is loaded
            elem.css({
                visibility: 'hidden'
            });
            if ((storedPage = localStorageService.get(nameSpace))) {
                $timeout(function() {
                    ctrl.changePage({
                        page: storedPage
                    });
                    return elem.css({
                        visibility: 'visible'
                    });
                }, 0);
            } else {
                elem.css({
                    visibility: 'visible'
                });
            }

        }
    })
];
