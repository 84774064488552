import BaseService from './base-service';

// Constants
import { API_BASE_PATH } from 'libs/utils/constants';
/**
 * API url for active plans. Interpolation: `{{orgName}}`
 * @private
 */
const ACTIVE_PLANS_ENDPOINT = `${API_BASE_PATH}/entitlements/{{orgName}}/active-plans`;

/**
 * Provides utils for entitlements management.
 *
 * @example
 * import EntitlementsService from 'libs/services/entitlements';
 * ...
 * const Entitlements = new EntitlementsService();
 */
export default class EntitlementsService extends BaseService {

    /**
     * Given an organization name this method returns
     * its entitlement plans.
     *
     * @param {String} orgName the organization's name
     *
     * @returns {Promise<Object[]>} the organization's plans
     */
    async getPlans(orgName) {
        const url = ACTIVE_PLANS_ENDPOINT.replace('{{orgName}}', orgName);
        const { data } = await this.get(url);

        return data;
    }
}
