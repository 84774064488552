"use strict";

var _url = require("libs/utils/url");
var _require = require('underscore'),
  findWhere = _require.findWhere,
  isString = _require.isString,
  extend = _require.extend;

/* @ngInject */
module.exports = function ModernOrgView($route, $routeParams, $scope, userService, orgService) {
  var orgName = $routeParams && $routeParams.orgName;
  var currentUser = userService.getCurrentUser();
  var goBackToOrgList = function goBackToOrgList() {
    return $scope.navigate('/orgs');
  };
  if (!isString(orgName)) return goBackToOrgList();
  if (!currentUser) return goBackToOrgList();
  var orgId = orgService.getOrgIdFromOrgName(orgName);
  var org = findWhere((currentUser || {}).orgs || [], {
    name: orgName
  });
  var userIsOrgAdmin = userService.matchesRoles({
    orgRole: 'admin',
    orgId: orgId
  });
  var userIsSuperAdmin = userService.matchesRoles({
    globalRole: 'superadmin'
  });
  var userCanViewEntitlements = org && userService.matchesRoles({
    orgRole: 'entitlement_manager',
    orgId: orgId
  }, {
    isSpotme: true
  });
  var userCanListWorkspaces = org && userService.hasPermission('org_realms.list_org_workspaces', {
    org_id: orgId
  });
  var userCanListContentHubs = org && org.content_hubs_enabled && userService.hasPermission('org_realms.list_org_content_hubs', {
    org_id: orgId
  });
  var userCanUseAPI = org && userService.hasPermission('org_realms.public_api', {
    org_id: orgId
  });
  var userCanViewPackages = org && userService.hasPermission('pkg_repo_realms.read_write_package_description', {
    org_id: orgId
  });
  var userCanViewWebhooks = userIsOrgAdmin;
  var userCanEditDefaults = userIsOrgAdmin;
  var userCanReadOrgDoc = userService.canReadOrgDoc(orgId);
  if (!org && !userCanReadOrgDoc) return goBackToOrgList();
  if (userCanReadOrgDoc && !org) {
    orgService.getOrgByName(orgName).then(function (_ref) {
      var data = _ref.data;
      $scope.organisation = org = data;

      // update entitlement section
      $scope.sections.forEach(function (section) {
        if (section.id === 'entitlements') {
          section.hide = !org.entitlements_enabled;
        }
      });
    });
  }
  $scope.sections = [{
    id: 'details',
    label: 'Details'
  }, {
    id: 'members',
    label: 'Members'
  }, {
    id: 'users',
    label: 'Users',
    hide: !userIsOrgAdmin && !userCanReadOrgDoc
  }, {
    id: 'workspaces',
    label: 'Workspaces',
    hide: !userCanListWorkspaces
  }, {
    id: 'content-hubs',
    label: 'Content hubs',
    hide: !userCanListContentHubs
  }, {
    id: 'legal',
    label: 'Legal documents'
  }, {
    id: 'entitlements',
    label: 'Entitlements',
    hide: !org || !org.entitlements_enabled || !userCanViewEntitlements
  }, {
    id: 'defaults',
    label: 'Default workspace settings',
    hide: !org || !userCanEditDefaults
  }, {
    id: 'webhooks',
    label: 'Webhooks',
    hide: !org || !userCanViewWebhooks
  }, {
    id: 'api',
    label: 'Developer API',
    hide: !org || !userCanUseAPI
  }, {
    id: 'packages',
    label: 'Package manager',
    hide: !org || !userCanViewPackages
  }];
  if (userIsSuperAdmin) {
    $scope.sections.forEach(function (section) {
      return section.hide = false;
    });
  }
  if ($routeParams.section) {
    var activeTabIndex = $scope.sections.findIndex(function (section) {
      return section.id === $routeParams.section;
    });
    $scope.sections[activeTabIndex].active = true;
    $scope.activeSection = $scope.sections[activeTabIndex].id;
    $scope.activeSectionTitle = $scope.sections[activeTabIndex].label;
  } else {
    var defaultTabId = $scope.sections[0].id;
    $route.updateParams({
      section: defaultTabId
    });
  }
  $scope.activateSection = function (id) {
    if (id === 'api') {
      var url = (0, _url.getDeveloperDocURL)({
        orgName: orgName
      });
      window.open(url, '_blank');
      return;
    }
    $route.updateParams({
      section: id,
      tab: id !== 'entitlements' ? null : $routeParams.tab
    });
  };
  $scope.organisation = org;
  $scope.save = function (model) {
    orgService.updateOrg(orgName, model).then(function (response) {
      extend($scope.organisation, response.data);
      $scope.setFlashMessage("".concat($scope.organisation.display_name, " was updated."));
      $scope.setNavigationWarningMessage(null);
    }, function () {
      return alert('An error occurred.');
    });
  };
};
module.exports.$inject = ["$route", "$routeParams", "$scope", "userService", "orgService"];