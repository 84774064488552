"use strict";

module.exports = /* @ngInject */["$rootScope", "userService", function ($rootScope, userService) {
  return {
    restrict: 'AC',
    link: function link(scope) {
      scope.userCanManageApps = userService.matchesRoles({
        isSpotme: true
      });
    }
  };
}];