class BrandedAppSidebarComponent {
    $onInit() {
        return this.select(this.selectedItem);
    }

    select(item) {
        return this.onSelect({ item });
    }
}

module.exports = angular.module('backstage.components.branded-app.branded-app-sidebar', [])
    .component('brandedAppSidebar', {
        templateUrl: '/src/angular/components/branded-app/branded-app-sidebar/branded-app-sidebar.html',
        bindings: {
            brandingName: '<',
            sections: '<',
            selectedItem: '<',
            onSelect: '&'
        },
        controller: BrandedAppSidebarComponent
    }).name;
