module.exports = ['$rootScope', $rootScope => ({
    restrict: 'E',
    link(scope, elem) {
        return $rootScope.$watch('routeChangeInProgress', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            if (newValue) {
                elem.addClass('working');
            }
            if (!newValue) {
                return elem.removeClass('working');
            }
        });
    }
})];
