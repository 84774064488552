"use strict";

module.exports = /* @ngInject */["$window", "$document", function ($window, $document) {
  var $body = angular.element($document.get(0).body);
  return {
    restrict: 'A',
    scope: {
      themeEditorHighlightSelector: '@'
    },
    link: function link(scope, elem) {
      var highlight = angular.element('<div class="theme-editor-highlight"></div>');
      highlight.hide();
      elem.on('mouseover', function () {
        var target = angular.element(".theme-editor ".concat(scope.themeEditorHighlightSelector)).first();
        if (!target) return;
        var rect = target.get(0).getBoundingClientRect();
        highlight.css({
          left: rect.left - 4,
          top: $window.scrollY + rect.top - 4,
          width: rect.width,
          height: rect.height
        });
        highlight.show();
      });
      elem.on('mouseout', function () {
        return highlight.hide();
      });
      $body.append(highlight);
    }
  };
}];