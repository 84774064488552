"use strict";

var _ = require('underscore');
module.exports = /* @ngInject */["$scope", function ($scope) {
  var DEFAULT_ITEMS_PER_PAGE_OPTIONS = [5, 10, 20, 50, 100];
  var controller = ($scope.globalConfig || {}).ctrl;
  var initialItemsByPage = controller ? controller.getItemsByPage() : 0;
  $scope.makePageJumpRange = function (num) {
    if (isNaN(num)) num = 0;
    return _.range(1, num + 1);
  };
  $scope.makeItemsByPageRange = function () {
    return _.chain([initialItemsByPage]) // always keep initial setting as an option
    .union(DEFAULT_ITEMS_PER_PAGE_OPTIONS).compact().uniq().sortBy().value();
  };

  // set the current selected page #
  $scope.$watch('globalConfig.ctrl.getCurrentPage()', function (currentPage) {
    if (!_.isNumber(currentPage)) {
      return;
    }
    $scope._jumpToPage = currentPage;
  });

  // set "items by page" # (smart-table's words not mine)
  var _first = true;
  return $scope.$watch('globalConfig.ctrl.getItemsByPage()', function (currentItemsByPage) {
    if (_first) {
      initialItemsByPage = currentItemsByPage;
    }
    $scope._itemsByPage = currentItemsByPage;
    _first = false;
  });
}];