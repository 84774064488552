import BaseService from './base-service';
import { getNodeName, getBackstageURL } from 'libs/utils/url';

import {
    USER_SESSIONS_ALL_PATH,
    USER_SESSIONS_CANCEL_ALL_PATH,
    USER_SESSIONS_INVALIDATE_ALL_PATH,
    USER_SESSIONS_GET_IN_EVENT_PATH,
    USER_SESSIONS_CANCEL_IN_EVENT_PATH,
    USER_SESSIONS_INVALIDATE_IN_EVENT_PATH
} from 'libs/utils/constants';

/**
 * Provides utils for managing user sessions
 *
 * @example
 * import UserSessionService from 'libs/services/user-session';
 * ...
 * const userSessionService = new UserSessionService(user, eventId);
 */
export default class UserSessionService extends BaseService {
    /**
     * Get all users session for events belonging to organisation
     *
     * @param {String} orgId organisation ID
     * @param {String} email user's email
     * @returns {Promise<Object<String, String>>} array of user's sessions (key: eventId, value: pax ID)
     */
    async getAll(orgId, email) {
        const postData = { email };
        const url = USER_SESSIONS_ALL_PATH
            .replace('{{orgId}}', orgId);

        const { data } = await this.post(url, postData);

        return data;
    }

    /**
     * Cancel all users session for events belonging to organisation
     *
     * @param {String} orgId organisation ID
     * @param {String} email user's email
     * @returns {Promise}
     */
    async cancelAll(orgId, email) {
        const postData = { email };
        const url = USER_SESSIONS_CANCEL_ALL_PATH
            .replace('{{orgId}}', orgId);

        const { data } = await this.post(url, postData);

        return data;
    }

    /**
     * Invalidate all users session for events belonging to organisation
     *
     * @param {String} orgId organisation ID
     * @param {String} email user's email
     * @returns {Promise}
     */
    async invalidateAll(orgId, email) {
        const postData = { email };
        const url = USER_SESSIONS_INVALIDATE_ALL_PATH
            .replace('{{orgId}}', orgId);

        const { data } = await this.post(url, postData);

        return data;
    }

    /**
     * Get all user's sessions in the event
     *
     * @param {String} eventId event ID
     * @param {String} userId user ID
     * @param {String} rootNodeUrl event root node
     * @returns {Promise<Array<Object>>}
     */
    async getInEvent(eventId, userId, rootNodeUrl) {
        const url = getBackstageURL(
            getNodeName(rootNodeUrl),
            USER_SESSIONS_GET_IN_EVENT_PATH
                .replace('{{eventId}}', eventId)
                .replace('{{userId}}', userId)
        );

        const { data } = await this.get(url, { withCredentials: true });

        return data;
    }

    /**
     * Cancel user and revoke all her sessions in the event
     *
     * @param {String} eventId event ID
     * @param {String} userId user ID
     * @param {String} rootNodeUrl event root node
     * @returns {Promise}
     */
    async cancelInEvent(eventId, userId, rootNodeUrl) {
        const url = getBackstageURL(
            getNodeName(rootNodeUrl),
            USER_SESSIONS_CANCEL_IN_EVENT_PATH
                .replace('{{eventId}}', eventId)
                .replace('{{userId}}', userId)
        );

        const { data } = await this.post(url, {}, { withCredentials: true });

        return data;
    }

    /**
     * Invalidate all user's sessions in the event
     *
     * @param {String} eventId event ID
     * @param {String} userId user ID
     * @param {String} rootNodeUrl event root node
     * @returns {Promise}
     */
    async invalidateInEvent(eventId, userId, rootNodeUrl) {
        const url = getBackstageURL(
            getNodeName(rootNodeUrl),
            USER_SESSIONS_INVALIDATE_IN_EVENT_PATH
                .replace('{{eventId}}', eventId)
                .replace('{{userId}}', userId)
        );

        const { data } = await this.post(url, {}, { withCredentials: true });

        return data;
    }
}
