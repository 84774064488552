"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var TimeUtils = _interopRequireWildcard(require("libs/utils/time"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var DEFAULT_FORMATTING_FORMAT = 'YYYY/MM/DD HH:mm';
var TimeService = /* @ngInject */function TimeService(DEFAULT_TIMEZONE_IDENTIFIER) {
  var timeService = {
    getAllZones: TimeUtils.getAllZones,
    /**
     * Return the representation of provide unix timestamp in targeted timezone
     * @param {Number} timestamp
     * @param {String} tzIdentifier optional
     * @param {String} formatStr optional
     * @return {String}
     */
    convertTimestampToHumanForm: function convertTimestampToHumanForm(timestamp) {
      var tzIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_TIMEZONE_IDENTIFIER;
      var formatStr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_FORMATTING_FORMAT;
      return TimeUtils.convertTimestampToHumanForm(timestamp, tzIdentifier, formatStr);
    },
    /**
     * In object format of timestamp fields as specificed in the metadata
     * @param {Object} obj
     * @param {Object} metadataFields
     * @param {String} tzIdentifier optional
     */
    convertTimestampsToHumanFormInObject: function convertTimestampsToHumanFormInObject(obj, metadataFields) {
      var tzIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_TIMEZONE_IDENTIFIER;
      return TimeUtils.convertTimestampsToHumanFormInObject(obj, metadataFields, tzIdentifier);
    },
    /**
     * Try to convert a date as string in given timezone to unix timestamp
     * @param {String} dateStr
     * @param {String} tzIdentifier optional
     * @param {String} format optional
     * @return {Number}
     */
    convertDateStringToUTCTimestamp: function convertDateStringToUTCTimestamp(dateStr) {
      var tzIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_TIMEZONE_IDENTIFIER;
      var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_FORMATTING_FORMAT;
      return TimeUtils.convertDateStringToUTCTimestamp(dateStr, tzIdentifier, format);
    },
    /**
     * From with given format to a long version of the date
     * @param {String} dateStr
     * @param {String} format optional
     * @return {String}
     */
    convertDateStringToFullLocaleString: function convertDateStringToFullLocaleString(dateStr) {
      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_FORMATTING_FORMAT;
      return TimeUtils.convertDateStringToFullLocaleString(dateStr, format);
    }
  };
  return timeService;
};
TimeService.$inject = ["DEFAULT_TIMEZONE_IDENTIFIER"];
TimeService.$inject = ["DEFAULT_TIMEZONE_IDENTIFIER"];
angular.module('backstage.services').factory('timeService', TimeService);