"use strict";

module.exports = /* @ngInject */function () {
  return {
    restrict: 'E',
    templateUrl: '/api/v1/bstg-template/bt-modal.html',
    replace: false,
    transclude: true,
    scope: {
      title: '@',
      okIcon: '@',
      okLabel: '@',
      onOkClick: '&',
      onCancelClick: '&',
      isVisible: '=',
      isCancelVisible: '='
    }
  };
};