// Utils
import BaseService from './base-service';

import { API_BASE_PATH } from 'libs/utils/constants';

/**
 * API endpoint for getting the event theme bit
 * @const THEME_BIT_ENDPOINT
 * @private
 */
const THEME_BIT_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/docbyid/{{bit}}`;

/**
 * API endpoint to compile the theme
 * @const THEME_ENDPOINT
 * @private
 */
const COMPILE_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/compile/theme`;


const THEME_BIT_TYPE = 'theme-bit';
const THEME_BIT_ID = 'bstg-theme-bit:base';
const THEME_BIT_PRIORITY = 9999;

const V3_THEMES = {
    dark: {
        foregroundColor1: '#E4E6EB',
        foregroundColor2: '#B1B3B8',
        backgroundColor1: '#3F4041',
        backgroundColor2: '#303336',
        backgroundColor3: '#252627',
        backgroundColor4: '#18191A',
        backgroundColor5: 'rgba(255, 255, 255, 0.05)',
        activeLinkStyle: 'underline'
    },
    light: {
        foregroundColor1: '#18191A',
        foregroundColor2: '#7D8082',
        backgroundColor1: '#F3F3F3',
        backgroundColor2: '#DDDDDD',
        backgroundColor3: '#FFFFFF',
        backgroundColor4: '#F7F7F7',
        backgroundColor5: 'rgba(0, 0, 0, 0.05)',
        activeLinkStyle: 'background'
    }
};

const V2_THEME_MAPPING = {
    appearance: { paths: ['appearance'] },
    color1: { paths: [
        'primary_color',
        'layouts.navigation_bar.background_color',
        'layouts.tool_bar.background_color',
    ] },
    color2: { paths: ['layouts.navigation_bar.title.font_color'] },
    color3: { paths: ['navs.nav_spotman.list_row.title.font_color'], defaultValue: '#000000' },
    color4: { paths: ['navs.nav_spotman.list_row.image_view.icons_color.color'] },
    color5: { paths: ['layouts.navigation_tab_bar.item_active'] },
    color6: { paths: ['navs.nav_spotman.profilerow_nav.title.font_color'] },
    color7: { paths: ['navs.nav_spotman.profilerow_nav.subtitle.font_color'] },
    color8: { paths: [
        'navs.nav_home.view.background_color',
        'navs.nav_home1.view._tablet.background_color',
        'navs.nav_home1.view.background_color'
    ], defaultValue: '#E6E6E6' },
    color9: { paths: [
        'navs.nav_spotman.list_row.background_color',
        'navs.nav_spotman.view.background_color'
    ], defaultValue: '#F7F7F7' },
    color10: { paths: ['navs.nav_spotman.spotmansectionheaderrow_nav.background_color'] },
    color11: { paths: ['navs.nav_spotman.spotmansectionheaderrow_nav.title.font_color'] },
    color12: { paths: ['layouts.list_row.checkmark_color'] },
    color13: { paths: ['contrastColor'], defaultValue: '#FF9500' },
    tintIcons: { paths: ['navs.nav_spotman.list_row.image_view.icons_color.enabled'] }
};

/**
 * Provides utils for getting or saving information of the theme.
 *
 * @example
 * import ThemeService from 'libs/services/template';
 * ...
 * const tmpl = new ThemeService();
 */
export default class ThemeService extends BaseService {

    get THEME_BIT_TYPE() {
        return THEME_BIT_TYPE;
    }

    get V2_THEME_MAPPING() {
        return V2_THEME_MAPPING;
    }

    get V3_THEMES() {
        return V3_THEMES;
    }

    /**
     * Loads the theme configuration
     *
     * @param {string} eventId the ID of the event
     *
     * @returns {Promise<object>} the theme configuration
     */
    getTheme(eventId) {
        return this.getThemeBit(eventId, 'theme');
    }

    /**
     * Loads the specified theme bit configuration
     *
     * @param {string} eventId the ID of the event
     * @param {string} bit the bit of the theme to load
     *
     * @returns {Promise<object>} the theme bit configuration
     */
    async getThemeBit(eventId, bit = THEME_BIT_ID) {
        const url = this.buildUrl(THEME_BIT_ENDPOINT, { eventId, bit });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Prepares the given theme bit for saving
     *
     * @param {string} eventId the ID of the event
     * @param {object} newBit the content of the bit to save into the theme
     *
     * @returns {Promise<object>} an object containing the theme bit ready for saving
     */
    async buildBitForSave(eventId, newBit) {
        let currentBit = { _id: THEME_BIT_ID };
        try {
            currentBit = await this.getThemeBit(eventId);
        } catch (error) {
            console.log('[ThemeService] No base bit found');
        }

        const isV3 = ['light', 'dark'].includes(newBit.appearance);
        const extraProps = V3_THEMES[newBit.appearance] || V3_THEMES['light'];

        if (isV3) {
            for (const key of Object.keys(extraProps)) {
                newBit[key] = extraProps[key];
            }

        } else {
            for (const key of Object.keys(extraProps)) {
                if (newBit.hasOwnProperty(key)) {
                    newBit[key] = { _to_prune: true };
                }
            }
            newBit.contrastColor = { _to_prune: true };
        }

        return Object.assign(newBit, {
            _id: currentBit._id,
            _rev: currentBit._rev,
            fp_owner: 'private',
            fp_type: THEME_BIT_TYPE,
            fp_bit_priority: THEME_BIT_PRIORITY,
            fp_ext_id: currentBit.fp_ext_id || currentBit._id || THEME_BIT_ID,
            theming_version: isV3 ? 3 : 2
        });
    }

    /**
     * Compiles the theme bits
     *
     * @param {string} eventId the ID of the event
     *
     * @returns {Promise<import("axios").AxiosResponse} the server response
     */
    compile(eventId) {
        const url = this.buildUrl(COMPILE_ENDPOINT, { eventId });
        return this.post(url);
    }

    /**
     * Refreshes the theme document and load it
     *
     * @param {string} eventId the ID of the event
     *
     * @returns {Promise<object>} a fresh version of the theme document
     */
    async refresh(eventId) {
        await this.compile(eventId);
        return this.getTheme(eventId);
    }
}
