// Based on https://github.com/meikidd/iso-639-1/blob/master/src/index.js
// but without babel runtime and langmap as data source

const { keys, values, memoize, map, find } = require('lodash');

const DATA = require('langmap');
const ALL_CODES = keys(DATA);
const ALL_VALUES = values(DATA);

const validate = code => DATA[code] !== undefined;

const getName = memoize(code =>
    validate(code) ? DATA[code].englishName : '');

const getAllNames = memoize(() =>
    map(ALL_VALUES, l => l.englishName));

const getNativeName = memoize(code =>
    validate(code) ? DATA[code].nativeName : '');

const getAllNativeNames = memoize(() =>
    map(ALL_VALUES, l => l.nativeName));

const getCode = memoize(name => {
    if (!name) return '';

    name = name.toLowerCase();

    const code = find(ALL_CODES, code => {
        const language = DATA[code];

        return (
            language.englishName.toLowerCase() === name ||
            language.nativeName.toLowerCase() === name
        );
    });

    return code || '';
});

const getAllLanguages = () => DATA;
const getAllCodes = () => ALL_CODES;

module.exports = {
    getAllLanguages,
    getName,
    getAllNames,
    getNativeName,
    getAllNativeNames,
    getCode,
    getAllCodes
};
