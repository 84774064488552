module.exports = ['$window', $window => ({
    scope: {},
    replace: true,
    transclude: 'element',
    template: `\
<div>
    <div class="clearfix">
        <div class="btn-group pull-right">
            <button class="btn btn-glow btn-mini" type="button" ng-click="enterBrowserFullscreenMode()">
                Enter fullscreen mode
            </button>
            <button class="btn btn-glow btn-mini" type="button" ng-click="openTranscludedFrameInNewWindow()">
                Open in new window
            </button>
        </div>
    </div>
    <hr>
    <div ng-transclude class="ph-transcluded-frame"></div>
</div>\
`,
    controller: 'BlueprintCurrentModuleNavCtrl',
    link(scope, elem) {
        return scope.openTranscludedFrameInNewWindow = function() {
            let transcludedFrameEl = angular.element('.ph-transcluded-frame', elem);

            let opts = 'width=980,height=720,resizable=yes,scrollbars=yes,status=no,toolbar=no,location=no';
            let win = $window.open('', 'bstg-transcluded-frame', opts);

            win.document.body.appendChild(transcludedFrameEl[0]);

            let style = document.createElement('style');

            style.appendChild(document.createTextNode('')); // because webkit
            win.document.head.appendChild(style);
            if (style.sheet.insertRule) {
                style.sheet.insertRule('body   { margin: 0 }');
                return style.sheet.insertRule('iframe { width: 100%; height: 100%; }');
            } else {
                style.sheet.addRule('body', 'margin: 0;');
                return style.sheet.addRule('iframe', 'width: 100%; height: 100%;');
            }
        };
    }
})];
