"use strict";

// bsFormGenerator
//   generates a form for record creation

module.exports = /* @ngInject */["metadataService", "userService", "$timeout", function (metadataService, userService, $timeout) {
  return {
    scope: {
      form: '=',
      model: '=',
      fields: '=',
      omitButtonBar: '=',
      omitFieldLabel: '=',
      showAddButtonInHeaderOfExternalFieldWidgets: '=',
      saveFn: '&',
      removeFieldFn: '&',
      isBulkEdit: '=',
      checkedFields: '=',
      bulkFieldOptions: '='
    },
    templateUrl: '/static/partials/components/form-generator.html',
    transclude: true,
    link: function link(scope, elem, attrs) {
      var otherCategoryName = metadataService.FIELD_CATEGORY_CATCHALL;
      var isGeneralCategory = function isGeneralCategory(category) {
        return category.toLowerCase() === metadataService.FIELD_CATEGORY_GENERAL;
      };
      scope.categories = [];
      scope.showRemoveFieldLink = attrs.showRemoveFieldLink !== null && attrs.showRemoveFieldLink !== undefined;
      scope.withRevealingEditInputs = attrs.withRevealingEditInputs === 'true';
      scope.shouldShowCategoryHeading = function (category) {
        return !isGeneralCategory(category) && (scope.categories.length > 1 || category !== otherCategoryName);
      };
      var refreshCategories = function refreshCategories() {
        scope.categories = [];
        // delayed generate categories to do proper cleanup of the existing
        $timeout(function () {
          scope.categories = metadataService.getCategoryGroupedMetadataFields(scope.fields);
          var hasGeneralCategory = scope.categories.some(function (desc) {
            return isGeneralCategory(desc.category);
          });
          scope.categories.forEach(function (desc) {
            return desc._isCollapsed = !desc.hasRequiredFields && hasGeneralCategory && !isGeneralCategory(desc.category);
          });
        });
      };
      scope.$watchCollection('fields', refreshCategories);
      // also refresh on model change to recreate custom controllers
      scope.$watch('model', function () {
        refreshCategories();
      });
      if (_.isEmpty(scope.model)) {
        scope.model = _.extend(
        // if an empty object is provided, use it as it can be reused later one
        scope.model || {}, metadataService.extractDefaults(scope.fields));
      }
    }
  };
}];