import BaseService from './base-service';

// constants
import {
    API_BASE_PATH,
    USER_SESSION_ENDPOINT,
    STUDIO_EXPRESS_BASE_PATH,
    LIVE_DISPLAY_BASE_PATH
} from 'libs/utils/constants';

/**
 * API path for managing the token. Interpolation: `{{eventId}}, {{token}}`.
 * @const {string} TOKEN_ENDPOINT
 * @private
 */
const TOKEN_ENDPOINT = `${USER_SESSION_ENDPOINT}/{{eventId}}/{{token}}`;

/**
 * API path segment for the event. Interpolation: `{{eventId}}`.
 * @const {string} URL_EVENT_PATH
 * @private
 */
const URL_EVENT_PATH = `${API_BASE_PATH}/events/{{eventId}}`;

/**
 * API path for getting accredited link defined by routes. Interpolation: `{{eventId}}`.
 * @const {string} GET_LINK_BY_ROUTES_ENDPOINT
 * @private
 */
const GET_LINK_BY_ROUTES_ENDPOINT = `${URL_EVENT_PATH}/accredited-link-by-routes`;

/**
 * Endpoint to get all accredited links
 * @constant {string} GET_ALL_ENDPOINT
 * @private
 */
const GET_ALL_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/accredited-links`;

/**
 * Endpoint to revoke accredited links
 * @constant {string} REVOKE_ENDPOINT
 * @private
 */
const REVOKE_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/revoke-accredited-links`;

/**
 * Builds the live session base URL
 *
 * @param {String} eventId
 * @param {String} liveStreamId
 * @returns {String}
 */
function getLiveSessionPath(eventId, liveStreamId) {
    return `${STUDIO_EXPRESS_BASE_PATH}/${eventId}/${liveStreamId}`;
}

/**
 * Provides methods necessary for managing deep links
 *
 * @example
 *
 * import DeepLinkService from 'libs/services/deep-link';
 * ...
 * const deepLink = new DeepLinkService();
 */
export default class DeepLinkService extends BaseService {

    /**
     * Returns accredited link with access to given set of routes
     *
     * @param {String} eventId event ID
     * @param {String} path path for the link
     * @param {Object} routes route definitions to grant access to
     * @param {String} [parentDocId] ID of parent document
     * @param {String} [name] name for the link
     * @param {String[]} [roles] roles to attribute to the user of the link
     * @param {Object} [params] key-value pairs to add as query params
     * @returns {Promise<String>}
     */
    // /!\ routes must be kept here for backwards compatibility with old pkg-sponsors
    async getLink(eventId, path, routes, parentDocId, name, roles, params) {
        const getLinkUrl = GET_LINK_BY_ROUTES_ENDPOINT.replace('{{eventId}}', eventId);
        const { data: { url } } = await this.post(getLinkUrl, { path, parentDocId, name, routes, roles, params }, { withCredentials: true });
        return url;
    }

    /**
     * Returns the path to access live sessions
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream id
     * @returns {String} path
     */
    getLiveSessionPath(eventId, liveStreamId) {
        return getLiveSessionPath(eventId, liveStreamId);
    }

    /**
     * Returns the path to access the live display app in standalone mode
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream id
     * @returns {String} path
     */
    getLiveDisplayPath(eventId, liveStreamId) {
        return `${LIVE_DISPLAY_BASE_PATH}/${eventId}/${liveStreamId}`;
    }

    /**
     * Extends the session with an accredited link token
     *
     * @param {String} eventId The event ID
     * @param {String} token The token to extend the session with and consume afterwards
     * @returns {Promise<Object>}
     */
    async consumeLinkToken(eventId, token) {
        const url = TOKEN_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{token}}', token);

        const { data } = await this.get(url);

        return data;
    }

    /**
     * @param {string} eventId
     * @param {object} liveStream
     * @returns {Promise<String>}
     */
    getLiveStreamSpeakerLink(eventId, liveStream) {
        const liveStreamId = liveStream._id;
        const path = this.getLiveSessionPath(eventId, liveStreamId);

        return this.getLink(
            eventId,
            path,
            undefined,
            liveStreamId,
            `${liveStream.title} speaker live stream`,
            ['speaker']
        );
    }

    /**
     * @param {string} eventId
     * @param {object} liveStream
     * @returns {Promise<String>}
     */
    getLiveStreamModeratorLink(eventId, liveStream) {
        const liveStreamId = liveStream._id;
        const path = this.getLiveSessionPath(eventId, liveStreamId);

        return this.getLink(
            eventId,
            path,
            undefined,
            liveStreamId,
            `${liveStream.title} moderator live stream`,
            ['moderator']
        );
    }

    /**
     * @param {string} eventId
     * @param {object} liveStream
     * @returns {Promise<String>}
     */
    getLiveStreamLiveDisplayLink(eventId, liveStream) {
        const liveStreamId = liveStream._id;

        return this.getLink(
            eventId,
            this.getLiveDisplayPath(eventId, liveStreamId),
            undefined,
            liveStreamId,
            `${liveStream.title} live display for in person live stream`,
            ['live_display']
        );
    }

    /**
     * Gets all the accredited links for the given event
     *
     * @param {string} eventId the id of the event
     * @returns {Promise<AccreditedLink[]>} list of accredited links
     */
    async getAll(eventId) {
        const url = this.buildUrl(GET_ALL_ENDPOINT, { eventId });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Revokes all given accredited links
     *
     * @param {string} eventId the id of the event
     * @param {AccreditedLink[]} links the links to revoke
     * @returns {Promise<import('axios').AxiosResponse>} the result of revoking links
     */
    revoke(eventId, links) {
        const ids = links.map(link => link.id);
        const url = this.buildUrl(REVOKE_ENDPOINT, { eventId });
        return this.post(url, { ids });
    }
}
