"use strict";

// bs-external-item-label-display-cell
// displays the label of an external link target

var externalItemLabelDisplayCellCtrl = /* @ngInject */function externalItemLabelDisplayCellCtrl($scope, $q, eventService, metadataService) {
  var event = $scope.column.event;
  var doc = $scope.dataRow;
  var type = $scope.dataRow.fp_type;
  var map = $scope.column.map;
  var allFields = metadataService.getCachedMetadataForTypeAsArray(event.id, type, true);
  var applicableField = _.findWhere(allFields, {
    field: map
  });
  if (!applicableField || !(doc[map] ? doc[map].length : undefined)) {
    return;
  }

  // array or string permitted!
  var targetIds = _.flatten([doc[map]]);
  var kindOptions = applicableField.kind_options || {};
  var idField = kindOptions.id_field || 'fp_ext_id';
  var promises = targetIds.map(function (targetId) {
    switch (idField) {
      case '_id':
        return eventService.getEventDocById(event, targetId);
      case 'fp_ext_id':
        return eventService.getEventDocByFpExtId(event, targetId, kindOptions.type);
    }
  });
  return $q.all(promises).then(function (resps) {
    var displayValues = $scope.displayValues = _.compact(resps).map(function (resp) {
      var targetDoc = resp.data || resp;
      return metadataService.getReprLabelForItemOrUseTitle(event.id, targetDoc) || doc[map];
    });
    if (!(displayValues.length > 3)) {
      return;
    }
    var first3WithEllip = _.first(displayValues, 3).concat('…');
    return angular.copy(first3WithEllip, displayValues);
  });
};
externalItemLabelDisplayCellCtrl.$inject = ["$scope", "$q", "eventService", "metadataService"];
module.exports = function () {
  return {
    restrict: 'C',
    // styled in css
    template: "\n<ul>\n    <li ng-repeat=\"displayValue in displayValues\"\n        ng-bind=\"displayValue\">\n    </li>\n</ul>\n",
    replace: true,
    scope: true,
    controller: externalItemLabelDisplayCellCtrl
  };
};