/* eslint-disable
    eqeqeq
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
import { getEventAssetPath } from 'libs/utils/assets';

let _ = require('underscore');

angular.module('backstage.services')

// keepin it Blobby
    .factory('assetsService', [

        'BASE_API_ENDPOINT',
        '$http',
        '$rootScope',
        'urlService',

        function(BASE_API_ENDPOINT, $http, $rootScope, urlService) {

            let assetsService;
            let { prependRouterRootPathIfOnCloud } = urlService;

            // # # # #
            // public
            // # # # #

            return _.extend((assetsService = {}), {

                baseAssetUrl(eventId) {
                    return getEventAssetPath(eventId);
                },

                assetUrl(eventId, asset) {
                    return `${this.baseAssetUrl(eventId)}/${asset}`;
                },

                generateRawAssetsPostUrl(eventId, eventNode, fpType, apiUploadType) {
                    if (apiUploadType == null) { apiUploadType = 'autodetect'; }
                    return prependRouterRootPathIfOnCloud(eventNode,
                        `${BASE_API_ENDPOINT}/eid/${eventId}/assets/${fpType}/${apiUploadType}`);
                },

                postRawAssetsDataBlob(url, blob, dataFileName, dataMimeType, event = $rootScope.event) {
                    if (dataMimeType == null) { dataMimeType = blob.type; }
                    if (!dataFileName) {
                        // automatically try to figure out what the upload filename should be from mime
                        dataFileName = `file.${dataMimeType.substr(dataMimeType.lastIndexOf('/') + 1)}`;
                    }

                    const csrfTokenUrl = prependRouterRootPathIfOnCloud(
                        event.node, `${BASE_API_ENDPOINT}/eid/${event._id}/csrf-token`);

                    return $http.get(csrfTokenUrl, { withCredentials: true }).then(result => {
                        const csrfToken = result.data.token;
                        const fd = new FormData();

                        fd.append('file', blob, dataFileName);
                        fd.append('_csrf', csrfToken);

                        return $http.post(url, fd, {
                            transformRequest: angular.identity,
                            // angular’s default content-type header for POST and PUT requests is application/json
                            // by setting this to undefined the browser sets the content-type to multipart/form-data
                            // for us and fills in the correct boundary
                            // the boundary will NOT be set if this header is sent as multipart/form-data
                            headers: { 'content-type': undefined },
                            withCredentials: true
                        });
                    });
                },

                // ⚑
                // TODO: used anywhere?
                postRawAssetsData(eventId, eventNode, dataToUpload, fpType, dataMimeType, dataFileName) {
                    if (dataMimeType == null) { dataMimeType = 'application/zip'; }
                    let url = assetsService.generateRawAssetsPostUrl(eventId, eventNode, fpType);

                    if (!_.isString(dataToUpload)) { dataToUpload = JSON.stringify(dataToUpload); }
                    let blob = new Blob([dataToUpload], { type: dataMimeType });

                    return assetsService.postRawAssetsDataBlob(
                        url, blob, dataFileName, dataMimeType, { _id: eventId, node: eventNode });
                },

                downloadAssetsToBlob(eventId, eventNode, assetFpType) {
                    let url = prependRouterRootPathIfOnCloud(eventNode,
                        `${BASE_API_ENDPOINT}/events/${eventId}/assets/${assetFpType}_all.zip`);

                    return assetsService.downloadUrlToBlob(url, { withCredentials: true });
                },

                downloadUrlToBlob(url, config) {
                    // this requires support for the arraybuffer output type. ie no
                    if (config == null) { config = {}; }
                    return $http.get(url, _.extend({ responseType: 'arraybuffer' }, config))
                        .then(function(resp) {
                            let contentType = resp.headers('content-type') || 'application/octet-stream';

                            return new Blob([resp.data], { type: contentType });
                        });
                },

                convertDataToBlob(data, dataMimeType) {
                    if (!_.isString(data)) { data = JSON.stringify(data); }
                    return new Blob([data], { type: dataMimeType });
                }
            }
            );
        }
    ]);
