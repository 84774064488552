// ⚑
// TODO: DEPRECATED: Uses OLD assets API for old events
module.exports = ['BASE_API_ENDPOINT', BASE_API_ENDPOINT => ({
    scope: {
        event: '=',
        assetFilenameSrc: '=',
        modalClosedCallback: '&'
    },
    transclude: true,
    templateUrl: '/static/partials/components/asset-attachment-replacement-modal.html',
    controller: [
        '$rootScope', '$scope',
        function($rootScope, $scope) {
            $scope.shouldShowImagePreviewSection = () => true;
            $scope.shouldSendFilenameParam = () => true;
            $scope.getAssetFpExtId = () => undefined;

            $scope.results = function(content, completed) {
                // HACK: makes legacy mode work with the new assets api
                if ((!_.isString(content) && !content.ok) || completed) {
                    return $scope.uploadStatus = null;
                }
                $rootScope.setFlashMessage('Your upload was successful.');
                return $scope.uploadStatus = content;
            };

            $scope.closeModal = function() {
                $scope.editingAsset = false;
                return (typeof $scope.modalClosedCallback === 'function' ? $scope.modalClosedCallback() : undefined);
            };

            const getAssetSrc = ($scope.getAssetSrc = function() {
                const currentAssetSrc = $scope.assetFilenameSrc;

                if (!_.isString(currentAssetSrc) || !currentAssetSrc.match(/^\.?\//) || !$scope.event) {
                    return currentAssetSrc;
                }
                return currentAssetSrc.replace(/^\.?\//, `${$scope.event._id}/`);
            });

            $scope.getUploadActionUrl = () => `/api/v1/events/${$scope.event._id}/assets`;

            $scope.getAssetFileUrl = function() {
                const event = $scope.event;

                return `${BASE_API_ENDPOINT}/events/${event._id}/assets/${getAssetSrc()}${$scope.cacheBusterSuffix}`;
            };

            $scope.getAssetStyleUrl = prefix => `url("${$scope.getAssetFileUrl(prefix)}")`;

            const setCacheBusterSuffixFn = () => $scope.cacheBusterSuffix = `?_=${new Date().getTime()}`;

            $scope.$watch('uploadStatus', () => setCacheBusterSuffixFn());
            return setCacheBusterSuffixFn();
        }
    ]
})];
