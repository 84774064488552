"use strict";

var moment = require('moment');
var _require = require('src/branded-apps-metadata'),
  FORMS = _require.FORMS,
  TABLES = _require.TABLES;
angular.module('backstage.controllers.brandedApps').controller('PublishingRequestsListCtrl', /* @ngInject */["$route", "$scope", "$sanitize", "userService", "appBrandingService", "taskSeriesService", "taskExecutorService", "modalService", function ($route, $scope, $sanitize, userService, appBrandingService, taskSeriesService, taskExecutorService, modalService) {
  if (!userService.matchesRoles({
    globalRole: 'app_publisher'
  }, {
    globalRole: 'superadmin'
  })) {
    return;
  }
  var currentUserId = userService.getCurrentUser()._id;
  $scope.$watch('rowCollection', function (collection) {
    if (!collection) {
      return;
    }
    $scope.selectedRequests = _.where(collection, {
      isSelected: true
    });
  }, true);
  $scope.tableConfig = {
    maxSize: 9,
    itemsByPage: 10,
    displaySelectionCheckbox: true,
    isGlobalSearchActivated: true,
    selectionMode: 'multiple'
  };
  _.each(TABLES.publishingRequestsList, function (column) {
    return column.formatFunction = $sanitize;
  });
  $scope.columnCollection = TABLES.publishingRequestsList;
  var startBulkTask = function startBulkTask(_ref) {
    var title = _ref.title,
      task = _ref.task;
    var requests = $scope.selectedRequests;
    var count = requests.length;
    if (!count) {
      return;
    }
    var tasks = [];
    var taskNames = [];
    _.each(requests, function (request, index) {
      taskNames.push("".concat(index + 1, "/").concat(count));
      tasks.push(function () {
        return task(request);
      });
    });
    var taskSeries = taskSeriesService.createTaskSeries(tasks, taskNames);
    var executor = taskExecutorService.executeTaskSeries(taskSeries);
    return taskExecutorService.openModalWithTaskSeriesProgress(executor, {
      title: title
    }).then(function () {
      return $route.reload();
    });
  };
  $scope.bulkAssign = function () {
    var count = $scope.selectedRequests.length;
    if (!count || !confirm("Are you sure you want to assign ".concat(count, " requests to yourself?"))) {
      return;
    }
    return startBulkTask({
      title: 'Assigning requests...',
      task: function task(_ref2) {
        var _id = _ref2._id;
        return appBrandingService.assignPublishingRequest(_id, currentUserId);
      }
    });
  };
  $scope.bulkUpdateStatus = function () {
    var count = $scope.selectedRequests.length;
    if (!count) {
      return;
    }
    return modalService.openModalWithForm({
      title: 'Update publishing request',
      fields: FORMS.statusUpdate,
      saveLabel: "Update ".concat(count, " requests"),
      formCanBeSaved: function formCanBeSaved(model) {
        return model.status;
      }
    }).then(function (updates) {
      updates.type = 'store-status';
      return startBulkTask({
        title: 'Updating requests...',
        task: function task(_ref3) {
          var branding_id = _ref3.branding_id,
            fp_owner = _ref3.fp_owner;
          updates = _.extend({}, updates);
          if (fp_owner !== currentUserId) {
            updates.notify = fp_owner;
          }
          return appBrandingService.logChange({
            fp_ext_id: branding_id
          }, updates);
        }
      });
    });
  };
  $scope.bulkClose = function () {
    var count = $scope.selectedRequests.length;
    if (!count || !confirm("Are you sure you want to close ".concat(count, " publishing requests?"))) {
      return;
    }
    return startBulkTask({
      title: 'Closing requests...',
      task: function task(_ref4) {
        var _id = _ref4._id;
        return appBrandingService.closePublishingRequest(_id);
      }
    });
  };
  $scope.loading = true;
  appBrandingService.getAllPublishingRequests().then(function (resp) {
    var extractEmail = function extractEmail(str) {
      return _.last((str || 'N/A').split(':'));
    };
    return $scope.rowCollection = _.map(resp.data, function (row) {
      row.fp_owner = extractEmail(row.fp_owner);
      row.created_at = moment.unix(row.created_at).format('MMM d YYYY, HH:mm');
      if (row.assignee === currentUserId) {
        row.assignee = 'You';
      } else {
        row.assignee = extractEmail(row.assignee);
      }
      return row;
    });
  }, function (err) {
    return console.error(err);
  }).finally(function () {
    return $scope.loading = false;
  });
}]);