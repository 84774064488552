import * as messages from 'locales/index';
import { getFirstEligibleLocale } from 'libs/utils/locales';
import { get } from 'lodash';

angular.module('backstage.services')
    .factory('vueI18n', () => {

        const locale = getFirstEligibleLocale(Object.keys(messages));

        /**
         * Translates the requested key to a message.
         *
         * @param {String} key the translation key
         * @param {String[]} [params] optional translation's parameters
         *
         * @return {String} the tranlsated key
         */
        const t = (key, params = []) => {
            if (!key) {
                return '';
            }

            let message = get(messages[locale], key);

            if (!message) {
                console.warn('[i18n]: unable to find valid key for ', key);
                message = key;
            }

            for (let i = 0; i < params.length; i++) {
                const subst = `{${i}}`;

                message = message.replace(subst, params[i]);
            }

            return message;
        };

        return { t };
    });
