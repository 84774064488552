module.exports = [() => ({
    transclude: true,
    scope: {
        taskExecutor: '=',
        additionalMessage: '@',
        taskEpicName: '<'
    },
    templateUrl: '/static/partials/components/task-execution-progress-modal.html',
    controller: 'TaskExecutionProgressModalCtrl'
})];
