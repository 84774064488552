"use strict";

var _ = require('underscore');
module.exports = /* @ngInject */["$scope", "model", "metadataService", "$routeParams", "fieldDescriptor", function ($scope, model, metadataService, $routeParams, fieldDescriptor) {
  var eventId = $routeParams.eventId;
  var kindOptions = fieldDescriptor.kind_options;
  var fpType = kindOptions.fp_type;
  var includeOnlyKind = kindOptions.include_only_kind;
  includeOnlyKind = _.isArray(includeOnlyKind) ? includeOnlyKind : [includeOnlyKind];
  $scope.error = null;
  $scope.model = model;
  $scope.singleDoc = kindOptions.single_doc !== false;
  $scope.selectId = fieldDescriptor.field;
  var fieldValue = $scope.model[$scope.selectId];
  var fieldIsArray = _.isArray(fieldValue);
  if ($scope.singleDoc && fieldIsArray) {
    $scope.model[$scope.selectId] = fieldValue[0];
  }
  if (!$scope.singleDoc && !fieldIsArray) {
    $scope.model[$scope.selectId] = [fieldValue];
  }
  if (!$scope.singleDoc) {
    // no duplicates/false/empty values allowed in the list
    $scope.model[$scope.selectId] = _.chain($scope.model[$scope.selectId]).compact().uniq().value();
  }
  if (!fpType) {
    $scope.error = 'Error: fp_type not defined';
    return;
  }
  var allMetadata = metadataService.getCachedMetadataForTypeAsArray(eventId, fpType);
  if (allMetadata && _.isArray(allMetadata) && allMetadata.length === 0) {
    $scope.error = 'Error: Could not retrieve metadata for type ' + fpType;
    return;
  }
  if (includeOnlyKind.length) {
    allMetadata = _.filter(allMetadata, function (field) {
      return includeOnlyKind.indexOf(field.kind || '') !== -1 && !field.hidden;
    });
  }
  $scope.filteredMetadata = _.map(allMetadata, function (metadata) {
    return [metadata.field, metadata.label || metadata.field];
  });
  $scope.updateFieldsList = function (field) {
    var fieldIndex = $scope.model[$scope.selectId].indexOf(field);
    if (fieldIndex !== -1) {
      // removes the field if it's already present
      $scope.model[$scope.selectId].splice(fieldIndex, 1);
    } else {
      // adds the field if it's not present
      $scope.model[$scope.selectId].push(field);
    }
  };
}];