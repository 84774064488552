"use strict";

module.exports = /* @ngInject */function () {
  return {
    restrict: 'EA',
    scope: {
      theme: '='
    },
    link: function link(scope, el) {
      scope.$watch('theme', function (theme) {
        return theme && el.css(theme);
      });
    }
  };
};