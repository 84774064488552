const GenericMetadataEditorCtrl = require('./GenericMetadataEditorCtrl');
const GenericModelListTableCtrl = require('./GenericModelListTableCtrl');
const GenericNewItemCtrl = require('./GenericNewItemCtrl');
const GenericPreferencesCtrl = require('./GenericPreferencesCtrl');
const GenericViewItemCtrl = require('./GenericViewItemCtrl');
const AssetEssencePreviewCtrl = require('./AssetEssencePreviewCtrl');

angular.module('backstage.controllers.crud', [])
    .controller('GenericViewItemCtrl', GenericViewItemCtrl)
    .controller('GenericModelListTableCtrl', GenericModelListTableCtrl)
    .controller('GenericMetadataEditorCtrl', GenericMetadataEditorCtrl)
    .controller('GenericPreferencesCtrl', GenericPreferencesCtrl)
    .controller('GenericNewItemCtrl', GenericNewItemCtrl)
    .controller('NewPaxCtrl', GenericNewItemCtrl)
    .controller('AssetEssencePreviewCtrl', AssetEssencePreviewCtrl);
