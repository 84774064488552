module.exports = () =>
    ({
        restrict: 'A',
        link(scope, elem) {
            let { form } = elem[0];
            let $form = angular.element(form);

            return elem.bind('change', function() {
                $form.triggerHandler('submit');
                return form.submit();
            });
        }
    });
