"use strict";

var _require = require('src/table-utils'),
  searchMetadataFieldsFilter = _require.searchMetadataFieldsFilter;
angular.module('backstage.controllers.agenda', []).controller('AgendaTableCtrl', /* @ngInject */["$route", "$routeParams", "$scope", "$filter", "uiService", "urlService", "eventService", "eventAgendaItems", "eventAgendaItemsFields", function ($route, $routeParams, $scope, $filter, uiService, urlService, eventService, eventAgendaItems, eventAgendaItemsFields) {
  var eventId = $routeParams.eventId;
  var event = eventService.decorateScope(eventId);
  var currentRouteDescriptor = $route.current ? $route.current.$$route : undefined;
  var crudOpts = currentRouteDescriptor ? currentRouteDescriptor.crudOpts : undefined;
  var itemsPerPage = (crudOpts ? crudOpts.itemsPerPage : undefined) || 6;
  $scope.globalConfig = {
    maxSize: 9,
    itemsByPage: itemsPerPage,
    selectionMode: 'multiple',
    displaySelectionCheckbox: true,
    isGlobalSearchActivated: true,
    filterAlgorithm: searchMetadataFieldsFilter.bind(this, _.pluck(eventAgendaItemsFields, 'field').concat(['fp_ext_id']))
  };

  // populate the rows with pax data
  var sessionsList = _.chain((eventAgendaItems ? eventAgendaItems.data : undefined) || []).compact().sortBy('start').value();
  var rows = $scope.rowCollection = [];
  angular.extend(rows, sessionsList);

  // populate the cols with metadata data
  var fields = {
    name: 'Title',
    location: 'Location',
    _seats_template: 'Seats',
    can_register: 'Registration'
  };
  if (_.compact(_.pluck(rows, 'presenters')).length) {
    fields.presenters = 'Speakers';
  }
  var cols = $scope.columnCollection = [];
  var _cols = [];
  _.keys(fields).forEach(function (key) {
    return _cols.push({
      isEditable: true,
      map: key,
      label: fields[key],
      formatFunction: key === 'location' ? function () {
        return $filter('curtailWithEllipsis')(arguments[0], 32);
      } : undefined,
      cellTemplateUrl: function () {
        switch (key) {
          case 'name':
            return '/static/partials/table-cells/agenda-item-blurb-cell.html';
          case 'can_register':
            return '/static/partials/table-cells/agenda-item-registration-cell.html';
          case 'presenters':
            return '/static/partials/table-cells/external-item-label-display-cell.html';
          case '_seats_template':
            return '/static/partials/table-cells/agenda-item-capacity-stats-cell.html';
          default:
            return '/static/partials/table-cells/basic-formatted-value-cell.html';
        }
      }(),
      eventId: eventId,
      event: event
    });
  });
  var customColumns = crudOpts ? crudOpts.customColumns : undefined;
  if (customColumns) {
    _cols.push.apply(_cols, uiService.postProcessCustomColumns(_.flatten([customColumns]), event));
  }
  angular.extend(cols, _cols);

  // bulk import/export button render logic
  $scope.hasCustomExport = crudOpts ? !!crudOpts.customExportLinks : false;
  $scope.customExportRouteLinks = _.chain(crudOpts ? crudOpts.customExportLinks : undefined).map(function (pathOrDescriptor, label) {
    var url = urlService.getImportExportOverridePath(pathOrDescriptor, event);
    return [label, url];
  }).object().value();
}]);