"use strict";

var _require = require('src/branded-apps-metadata'),
  FORMS = _require.FORMS,
  TABLES = _require.TABLES;
angular.module('backstage.controllers.brandedApps').controller('PublishingAccountsListCtrl', /* @ngInject */["$scope", "$sanitize", "userService", "appBrandingService", "modalService", function ($scope, $sanitize, userService, appBrandingService, modalService) {
  $scope.tableConfig = {
    maxSize: 9,
    itemsByPage: 10,
    displaySelectionCheckbox: false,
    isGlobalSearchActivated: true
  };
  _.each(TABLES.publishingAccountsList, function (column) {
    return column.formatFunction = $sanitize;
  });
  $scope.columnCollection = TABLES.publishingAccountsList;
  $scope.showAccountCreationModal = function () {
    return modalService.openModalWithForm({
      title: 'Create a publishing account',
      saveLabel: 'Create',
      fields: FORMS.publishingAccount
    }).then(function (model) {
      return appBrandingService.createPublishingAccount(model).then(function (resp) {
        $scope.setFlashMessage('Account was created.');
        return $scope.navigate("/apps/admin/account/a".concat(resp.data.id));
      }, function () {
        return alert('There was an error creating a publishing account. Please try again in a moment.');
      });
    });
  };
  return appBrandingService.getAllPublishingAccounts().then(function (resp) {
    return $scope.rowCollection = resp.data;
  }, function (err) {
    return console.error(err);
  });
}]);