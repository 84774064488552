module.exports = () => ({
    require: 'ngModel',
    link: ($scope, element, attrs, ctrl) => {
        const regex = /^#([0-9a-f]{3}){1,2}$/i;

        ctrl.$validators.colour = (modelValue, viewValue) => {
            const value = modelValue || viewValue;

            return ctrl.$isEmpty(value) || regex.test(value);
        };
    }
});
