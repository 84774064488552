"use strict";

NavEditorFormComponentCtrl.$inject = ["$log", "$scope", "$rootScope", "$injector", "$q", "navsService", "eventService", "metadataService", "crashReporter"];
MultiNavEditorFormComponentCtrl.$inject = ["$scope", "$q", "$injector", "navsService", "eventService", "metadataService"];
var Mustache = require('mustache');
var _require = require('lodash'),
  get = _require.get,
  set = _require.set;
var _require2 = require('libs/utils/objects'),
  deepExtend = _require2.deepExtend;

/* @ngInject */
function MultiNavEditorFormComponentCtrl($scope, $q, $injector, navsService, eventService, metadataService) {
  var event = eventService.getCurrentEvent();
  var builderOptions = null;
  var service = null;

  // this is the editor config we keep in the blueprint
  var navEditorConfig = $scope.navEditorConfig;
  $scope.model = {};

  // step 1: route config parsing and nav retrieval
  // get a sequentially ordered list of configs. this way we can match by index a bit later
  // ⚑ TODO: Bit pruning is disabled here. is that what we want?!
  var ignoredNavIdxs = [];
  var navIdsAndPaths = _.values(navEditorConfig);
  var navIds = _.pluck(navIdsAndPaths, 'navId');
  var promise = navsService.fetchMultipleNavs(event.id, navIds);
  var getSerializedNavBit = function getSerializedNavBit(model) {
    return service[getBuilderMethodName('serializer')](model);
  };
  var buildModel = function buildModel(navs) {
    // run em all through the parsers
    $scope.navBits = navs;
    var navPartials = navs.map(function (nav, idx) {
      var path = navIdsAndPaths[idx].path;
      if (!path) {
        return nav;
      }
      var partial = get(nav, path);
      if (partial) {
        return partial;
      }
      // exception fallback: pretend this entry doesn't exist
      console.log('[MultiNavEditorFormComponentCtrl] The given path does not exist in the nav', nav, path);
      return ignoredNavIdxs.push(idx);
    });
    // run all the partials through the nav parsers
    var parsedPartials = navPartials.map(service[getBuilderMethodName('parser')]);
    // if there's just one nav partial we shall skip the manual merge stage altogether
    var firstParsedPartial = _.first(parsedPartials);
    if (_.rest(parsedPartials).every(_.isEqual.bind(_, firstParsedPartial))) {
      // all partials match the first. skip the merge.
      $scope.isNavDiffCompleted = true;
      angular.extend($scope.model, firstParsedPartial);
    } else {
      populateNavPartialsDiffs(parsedPartials);
    }
  };
  promise.then(buildModel, function (err) {
    console.error('[MultiNavEditorFormComponentCtrl] Unable to fetch the prerequisite navs', navIds, err.message);
    window.BSTG.noty.$notify({
      group: 'flashes',
      title: 'An error occurred',
      text: 'Unable to fetch one or more navs required to render the editor form.',
      type: 'error'
    });
  });
  $scope.$watch('formFields', function () {
    if (_.isArray($scope.navBits)) {
      $scope.model = {};
      $scope.$applyAsync(function () {
        return buildModel($scope.navBits);
      });
    }
  });

  // step 2: the diff of all the values takes place here - these are used to populate the merge form
  var populateNavPartialsDiffs = function populateNavPartialsDiffs(partials) {
    // all of the scope state here must be cleared each time we run the "diff"
    // this one will keep track of which fields we have in the metadata
    $scope.formFieldsInMetadata = [];
    // this array will keep track of which fields have been assigned in the merge view
    $scope.formFieldsWhereDiffsSelected = [];
    // for each field compile the list of differences
    var diffs = $scope.formFieldPartialsDiffs = {};
    _.each($scope.formFields, function (descriptor) {
      // could be an object, hence _.each
      var field = descriptor.field;
      if (!diffs[field]) {
        diffs[field] = [];
      }
      var _diffs = partials.map(function (partial, idx) {
        return {
          index: idx,
          value: partial[field] || null
        };
      });
      _diffs = _.uniq(_diffs, function (diff) {
        // ensure uniqueness of the values listed as options in the manual merge form
        // must stringify (_.uniq tests equality by reference :( )
        return JSON.stringify(diff.value);
      });
      diffs[field].push.apply(diffs[field], _diffs);
      return $scope.formFieldsInMetadata.push(field);
    });
    $scope.formFieldsInMetadata = _.uniq($scope.formFieldsInMetadata);
  };

  // step 1.5: the user selects the differences to use in the merged model
  $scope.markFieldValueSelectionChange = function (field) {
    $scope.formFieldsWhereDiffsSelected.push(field);
    $scope.formFieldsWhereDiffsSelected = _.uniq($scope.formFieldsWhereDiffsSelected);
    if ($scope.formFieldsWhereDiffsSelected.length === $scope.formFieldsInMetadata.length) {
      delete $scope.formFieldPartialsDiffs;
      return $scope.isNavDiffCompleted = true;
    }
  };

  // step 3: merge the model into each nav bit and save them all
  $scope.saveIntermediateModel = function (model) {
    if (($scope.navEditorComponentForm ? $scope.navEditorComponentForm.$invalid : false) || _.isEmpty(model)) {
      return;
    }

    // for each of our nav bits extend move our modified partial into its destination
    var mergePartialIntoNavBit = function mergePartialIntoNavBit(navBit, path) {
      var setter = function setter(ctx, obj) {
        return angular.extend(navBit, obj);
      };
      if (path && path.length) {
        setter = function setter(ctx, obj) {
          return set(ctx, path, obj);
        };
      }
      try {
        return setter(navBit, getSerializedNavBit(model));
      } catch (e) {
        console.error('[MultiNavEditorFormComponentCtrl] An error occurred while merging the nav bits', e.message);
        window.BSTG.noty.$notify({
          group: 'flashes',
          title: 'An error occurred',
          text: 'Could not merge nav bit.',
          type: 'error'
        });
        throw e;
      }
    };
    var promises = navIdsAndPaths.map(function (navIdAndPath) {
      var deferred = $q.defer();
      var navId = navIdAndPath.navId;
      var path = navIdAndPath.path;
      var updateAndSaveOverrideBitFn = function updateAndSaveOverrideBitFn(bit) {
        mergePartialIntoNavBit(bit, path);
        // commit the override navs to the bit
        return navsService.updateOverrideNavBit(event.id, event.node, bit, navId, "nav-bit:".concat(navId)).then(function () {
          return navsService.compileSingleNav(event.id, navId).then(function () {
            return deferred.resolve(bit);
          }, deferred.reject);
        }, deferred.reject);
      };
      navsService.fetchOverrideNavBit(event, navId).then(updateAndSaveOverrideBitFn, function () {
        return updateAndSaveOverrideBitFn({});
      });
      return deferred.promise;
    });

    // save all the bits!
    return $q.all(promises).then(function (bits) {
      return $scope.onBitsSavedCallbackFn({
        bits: bits
      });
    }, function (err) {
      console.error('[MultiNavEditorFormComponentCtrl] Could not save the nav bits', err);
      window.BSTG.noty.$notify({
        group: 'flashes',
        title: 'An error occurred',
        text: 'The nav bits could not be saved.',
        type: 'error'
      });
    });
  };
  $scope.getEditorFormTemplateUrl = function () {
    return $scope.templateUrl || '/static/partials/nav-editors/generic-nav-editor-form.html';
  };
  $scope.deepEquals = _.isEqual;

  // ⚑
  // TODO: WHAT DO WE DO WITH DEFAULTS??!?!?!?

  // watch out for changes to the form config
  $scope.$watch('navEditorKey', function () {
    builderOptions = $scope.builderOptions;
    if (!builderOptions) {
      console.error('[MultiNavEditorFormComponentCtrl] Missing builder options.');
      return;
    }
    try {
      service = $injector.get(builderOptions.service);
    } catch (e) {
      console.error("[MultiNavEditorFormComponentCtrl] The service `".concat(this.builderOptions.service, "` is not loaded."), e.message);
      return;
    }
    if (_.isArray($scope.formFields)) {
      return;
    }

    // transform formFields into array form
    return $scope.formFields = metadataService.convertMetadataHashToOrderedList($scope.formFields);
  });
  var getBuilderMethodName = function getBuilderMethodName(method) {
    if (!builderOptions || !builderOptions.methods) return undefined;
    return builderOptions.methods[method];
  };
}

/* @ngInject */
function NavEditorFormComponentCtrl($log, $scope, $rootScope, $injector, $q, navsService, eventService, metadataService, crashReporter) {
  var event = eventService.getCurrentEvent();
  var unregisterModelWatch = null;
  var builderOptions = null;
  var service = null; // used in the $watch below

  var getBuilderMethodName = function getBuilderMethodName(method) {
    if (!builderOptions || !builderOptions.methods) return undefined;
    return builderOptions.methods[method];
  };
  var getSerializedNavBit = function getSerializedNavBit(model) {
    return service[getBuilderMethodName('serializer')](model, $scope.navBit);
  };
  $scope.getEditorFormTemplateUrl = function () {
    return $scope.templateUrl || '/static/partials/nav-editors/generic-nav-editor-form.html';
  };
  $scope.saveModel = function (model) {
    if (($scope.navEditorComponentForm ? $scope.navEditorComponentForm.$invalid : undefined) || _.isEmpty(model)) {
      return;
    }
    var serialized = {};
    var updateAndSaveOverrideBitFn = function updateAndSaveOverrideBitFn(bit) {
      if (!bit.el) {
        bit.el = {};
      }
      bit.el[serialized._id] = serialized;
      // commit the override navs to the bit
      return navsService.updateOverrideNavBit(event.id, event.node, bit, $scope.navId).then(function () {
        return navsService.compileSingleNav(event.id, $scope.navId).then(function () {
          return $scope.onBitSavedCallbackFn({
            bit: serialized
          });
        }, function () {
          return alert("Error: couldn't compile the nav!");
        });
      });
    };
    $rootScope.setNavigationWarningMessage(null);

    // ⚑
    // TODO: Implement a layer of indirection between service methods and this directive's controller ...
    // TODO: Take care of diff logic here
    // pull out the props we want to override, ignore things like events that packages might replace themselves
    return $q.when(getSerializedNavBit(model)).then(function (_serialized) {
      serialized = _serialized;
      serialized._custom_editor = $scope.navEditorKey;
      return navsService.fetchOverrideNavBit(event, $scope.navId);
    }, function (e) {
      alert(e.message || e);
      return $q.when(null);
    }).then(updateAndSaveOverrideBitFn, function () {
      return updateAndSaveOverrideBitFn({});
    });
  };

  // watch out for changes to the form config
  $scope.$watch('navEditorKey', function () {
    builderOptions = $scope.builderOptions;
    if (!builderOptions) return $log.error('Missing builder options.');
    try {
      service = $injector.get(builderOptions.service);
    } catch (error) {
      return $log.error("The service `".concat(builderOptions.service, "` is not loaded."), error);
    }
    if (_.isObject($scope.navBit)) {
      try {
        $scope.model = service[getBuilderMethodName('parser')]($scope.navBit);
      } catch (e) {
        alert("Error: service was unable to parse the nav bit: ".concat(e.message || e));
      }
    }

    // always add defaut as they may have changed
    var _defaults = metadataService.extractDefaults($scope.formFields);
    $scope.model = angular.extend({}, _defaults, $scope.model);

    // when editing an existing nav bit we should update it live
    if (_.isObject($scope.navBit)) {
      // watch in a watch ?
      if (unregisterModelWatch) {
        unregisterModelWatch();
      }
      unregisterModelWatch = $scope.$watch(function () {
        return JSON.stringify($scope.model);
      }, function (newStr, oldStr) {
        if (newStr === oldStr) {
          return;
        }
        try {
          // deep extend rather than regular extend to fix "dirty on load" bug
          // bug demo: http://s.genoud.me/aPbf
          return deepExtend($scope.navBit, getSerializedNavBit($scope.model));
        } catch (e) {
          console.error('[NavEditorFormComponentCtrl] An error occurred while serializing nav bit', e, $scope.navBit, $scope.model);
          crashReporter.captureException(e);
        }
      });
    }
    if (_.isArray($scope.formFields)) {
      return;
    }

    // transform formFields into array form
    $scope.formFields = metadataService.convertMetadataHashToOrderedList($scope.formFields);
  });
  var headingTemplate = $scope.headingTemplate;
  if (!_.isString(headingTemplate)) {
    return;
  }
  return $scope.$watch('model', function (newModel) {
    if (!_.isObject(newModel)) {
      return;
    }
    try {
      return $scope.renderedHeading = Mustache.render(headingTemplate, newModel);
    } catch (e) {
      // do nothing
    }
  },
  // non-assignable expression. don't care.
  true);
}
angular.module('backstage.controllers.navs', []).controller('MultiNavEditorFormComponentCtrl', MultiNavEditorFormComponentCtrl).controller('NavEditorFormComponentCtrl', NavEditorFormComponentCtrl);