"use strict";

var UiService = /* @ngInject */function UiService($filter) {
  return {
    shouldShowNavItem: function shouldShowNavItem(navDescriptor, isItemActive) {
      if (navDescriptor.hideNavItem) {
        return false;
      }

      // hideNavUnlessActive hides this nav item if it isn't active.
      if (navDescriptor.hideNavUnlessActive && !isItemActive) {
        return false;
      }
      return true;
    },
    // decorates column descriptors with references to the event, eventId and invokes $filter on formatFunction
    // https://github.com/Spotme/spotme3-common/wiki/Standard-%22CRUD%22-Controllers-in-Backstage#custom-columns
    postProcessCustomColumns: function postProcessCustomColumns(columns, event) {
      return columns.map(function (column) {
        return _.extend({}, column, {
          formatFunction: _.isString(column.formatFunction) ? $filter(column.formatFunction) : column.formatFunction,
          event: event,
          eventId: event.id
        });
      });
    }
  };
};
UiService.$inject = ["$filter"];
UiService.$inject = ["$filter"];
angular.module('backstage.services').factory('uiService', UiService);