"use strict";

var pad = function pad(str) {
  return str.length === 2 ? str : "0".concat(str);
};
var rgbToHex = function rgbToHex(rgb) {
  return '#' + _.chain(rgb).invoke('toString', 16).map(pad).value().join('');
};
module.exports = /* @ngInject */["$timeout", "colorThiefService", function ($timeout, colorThiefService) {
  return {
    restrict: 'C',
    scope: {
      onColoursExtracted: '&'
    },
    link: function link(scope, elem) {
      var loadPreview = function loadPreview(file) {
        var reader = new FileReader();
        reader.onload = function (e) {
          elem.addClass('file-loaded');
          var preview = elem.find('.preview');
          preview.attr('src', e.target.result);
          preview[0].onload = function () {
            return $timeout(function () {
              return scope.onColoursExtracted({
                palette: _.map(colorThiefService.getPalette(preview[0], 6), rgbToHex)
              });
            });
          };
        };
        reader.readAsDataURL(file);
      };
      elem.on('dragover', function (e) {
        elem.find('.dropzone').addClass('hover');
        e.preventDefault();
      }).on('dragend dragleave', function () {
        return elem.removeClass('hover');
      }).on('drop', function (e) {
        e.preventDefault();
        elem.find('.dropzone').removeClass('hover');
        var originalEvent = e.originalEvent;
        var files = originalEvent.files || originalEvent.dataTransfer.files;
        var file = files && files[0];
        if (file && file.type.indexOf('image/') === 0) {
          loadPreview(file);
        }
      });
    }
  };
}];