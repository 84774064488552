module.exports = ['$http', 'BASE_API_ENDPOINT', ($http, BASE_API_ENDPOINT) => ({
    scope: {
        event: '=',
        assetFilenameSrc: '=',
        useFilenameAsFileParamName: '=',
        modalClosedCallback: '&'
    },
    transclude: true,
    templateUrl: '/static/partials/components/asset-attachment-replacement-modal.html',
    controller: ['$rootScope', '$scope', ($rootScope, $scope) => {
        $scope.results = function(content) {
            const error = (content || {}).error;

            if (error) {
                return alert(`Oops! ${error}`);
            }
            if (!_.isString(content)) {
                setCacheBusterSuffixFn();
                const isOk = (content || {}).ok;

                if (isOk) {
                    $rootScope.setFlashMessage('Your upload was successful.');
                }
                return $scope.uploadStatus = null;
            }
            return $scope.uploadStatus = content;
        };

        var setCacheBusterSuffixFn = () => $scope.cacheBusterSuffix = `?_=${new Date().getTime()}`;

        $scope.$watch('uploadStatus', () => setCacheBusterSuffixFn());
        return setCacheBusterSuffixFn();
    }],
    link(scope, elem, attrs = {}) {
        scope.getUploadActionUrl = function() {
            let assetFpType;

            if (attrs.customUploadUrl) {
                return attrs.customUploadUrl;
            }
            if ((assetFpType = attrs.assetFpType) === 'person_photo') {
                return `/api/v1/events/${scope.event._id}/assets`;
            } else {
                return `/api/v1/eid/${scope.event._id}/assets/${assetFpType}`;
            }
        };

        $http.get(`${BASE_API_ENDPOINT}/eid/${scope.event._id}/csrf-token`)
            .then(result => scope.csrfToken = result.data.token);

        let getAssetSrc = (scope.getAssetSrc = function() {
            let currentAssetSrc = scope.assetFilenameSrc;

            if (!_.isString(currentAssetSrc) || !currentAssetSrc.match(/^\.?\//) || !scope.event) {
                return currentAssetSrc;
            }
            return currentAssetSrc.replace(/^\.?\//, `${scope.event._id}/`);
        });

        scope.getAssetFileUrl = function() {
            if (attrs.customAssetFileUrl) {
                return `${attrs.customAssetFileUrl}${scope.cacheBusterSuffix}`;
            }
            let {
                event
            } = scope;

            return `${BASE_API_ENDPOINT}/events/${event._id}/assets/${getAssetSrc()}${scope.cacheBusterSuffix}`;
        };

        scope.getAssetFpExtId = () => {
            const match = scope.assetFilenameSrc.match(/^[^.]+\.([^/]+)/);

            return match && match[1];
        };

        scope.getAssetStyleUrl = prefix => `url("${scope.getAssetFileUrl(prefix)}")`;

        scope.shouldShowImagePreviewSection = function() {
            let imageExts = [
                '/photo', '.jpg', '.jpeg', '.png',
                '.bmp', '.gif', '.tiff'
            ];
            let src = getAssetSrc();

            if (!src) {
                return false;
            }
            for (let ext of Array.from(imageExts)) {
                if (src.indexOf(ext) === (src.length - ext.length)) {
                    return true;
                }
            }
        };

        scope.shouldSendFilenameParam = () => attrs.assetFpType === 'person_photo';

        scope.closeModal = function() {
            scope.editingAsset = false;
            return (typeof scope.modalClosedCallback === 'function' ? scope.modalClosedCallback() : undefined);
        };

        // temp for now; test asset retrieval route with HEAD in future
        const imagesStringIndex = (attrs.assetContentType || '').indexOf('image/');

        if (imagesStringIndex === 0) {
            return elem.removeClass('placeholder');
        }
    }
})];
