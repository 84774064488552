module.exports = ['$http', 'BASE_API_ENDPOINT', ($http, BASE_API_ENDPOINT) => ({
    restrict: 'AC',
    scope: {
        event: '=',
        participantId: '='
    },
    link(scope, elem) {
        let TRANSPARENT_GIF_IMG_SRC = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

        let updateImageSourceFn = function() {
            let {
                participantId
            } = scope;

            if (!participantId) {
                return;
            }
            let eventId = scope.event._id;
            let photoUrl = `${BASE_API_ENDPOINT}/events/${eventId}/assets/${participantId}-photo/photo?_=${Date.now()}`;

            // test the route with a HEAD request
            return $http.head(photoUrl).then(() => {
                elem.one('load', () => elem.removeClass('placeholder')).attr({
                    'src': photoUrl
                });
            }, () => {
                // eliminates a funky border that browsers put around broken images
                elem.attr({
                    src: TRANSPARENT_GIF_IMG_SRC
                });
            });
        };

        // update on element init
        updateImageSourceFn();

        // ... and when we receive a "pax-photo-changed" event
        return scope.$on('event:pax-photo-changed', updateImageSourceFn);
    }
})];
