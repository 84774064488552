import BaseService from './base-service';
import { SERVERS_SERVICE_GROUPS_ENDPOINT, SERVERS_SERVICE_ROOT_ENDPOINT } from 'libs/utils/constants';

export default class ServerService extends BaseService {

    /**
     * Given an organization name this method returns all available servers.
     *
     * @param {String} orgName the organization name
     *
     * @returns {Promise<Object[]>} a list of available server for the specified organization.
     */
    async getOrgServers(orgName) {
        const url = SERVERS_SERVICE_GROUPS_ENDPOINT.replace('{{orgName}}', orgName);
        const { data } = await this.get(url, { withCredentials: true });

        return data;
    }

    /**
     * Given a server group name this method returns the root instance.
     *
     * @param {String} serverGroup the server group name
     *
     * @return {Promise<Object>} the root node name
     */
    async getRootForGroup(serverGroup) {
        const url = SERVERS_SERVICE_ROOT_ENDPOINT.replace('{{serverGroup}}', serverGroup);
        const { data } = await this.get(url, { withCredentials: true });

        return data;
    }
}
