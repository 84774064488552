// bsFormWithRevealingInputs

//   encapsulates an entire form with inputs that reveal their ability to edit upon clicking a trigger button
module.exports = function() {
    return {
        scope: {
            fields: '=',
            targetObject: '=',
            saveObjectExp: '&'
        },
        controller: [function() {}]
    };
};
