/** @type {(md: string) => string} */
var mdToHtml = require('marked');
var Turndown = require('turndown');

var converter = new Turndown({ headingStyle: 'atx' });

/** @type {(html: string) => string} */
var htmlToMd = function(html) {
    return converter.turndown(html);
};

/**
 *
 * @param {JQuery<HTMLElement>} objMd
 * @param {JQLite} rootEl
 */
function MdHtmlForm(objMd, rootEl) {
    var self = this;
    var dataGroup = 'mdhtmlform-group';

    this.selHtml = '.ng-mdhtmlform-html';
    this.selMd = '.ng-mdhtmlform-md';
    this.html = '';
    this.md = $(objMd, rootEl).val();
    this.htmlChangeBeaconEl = document.createElement('div');

    this.group = $(objMd, rootEl).data(dataGroup);
    if (this.group) {
        this.selHtml + '[data-' + dataGroup + '=' + this.group + ']';
        this.selMd = this.selMd + '[data-' + dataGroup + '=' + this.group + ']';
    }

    /** @type {() => string} */
    this.updateMdToHtml = function() {
        self.md = $(objMd, rootEl).val();
        self.convertMdToHtml();
        return self.render(false);
    };

    /** @type {(ignoreHallo?: boolean) => string} */
    this.updateHtmlToMd = function(ignoreHallo) {
        var selHtmlEl = $('div' + self.selHtml, rootEl);
        if (selHtmlEl.hallo) {
            self.html = selHtmlEl.html();
        } else {
            self.html = $('textarea' + self.selHtml, rootEl).val();
        }
        self.convertHtmlToMd();
        return self.render(ignoreHallo);
    };

    /** @type {() => string} */
    this.convertMdToHtml = function() {
        console.log('convertMdToHtml', self.md, mdToHtml(self.md));
        return self.html = mdToHtml(self.md);
    };

    /** @type {() => string} */
    this.convertHtmlToMd = function() {
        console.log('convertHtmlToMd', self.html, htmlToMd(self.html));
        return self.md = htmlToMd(self.html).trim();
    };

    /** @type {(ignoreHallo?: boolean) => string} */
    this.render = function(ignoreHallo) {
        var selHtmlEl = $('div' + self.selHtml, rootEl);
        if (selHtmlEl.hallo || !ignoreHallo) {
            selHtmlEl.html(self.html);
        }
        $(objMd, rootEl).val(self.md);
        $('textarea' + self.selHtml, rootEl).val(self.html);

        return self.htmlChangeBeaconEl.html = self.html;
    };

    var input = $(objMd, rootEl)[0];
    input.addEventListener('input', function() { return self.updateMdToHtml(); }, false);

    $('textarea' + this.selHtml, rootEl).bind('keyup', function() {
        return self.updateHtmlToMd(false);
    });
    $(this.selHtml, rootEl).bind('hallomodified', function() {
        return self.updateHtmlToMd(true);
    });
    this.updateMdToHtml();

    return self;
}

module.exports = MdHtmlForm;
