module.exports = [
    'eventService',
    'BASE_API_ENDPOINT',
    'BACKSTAGE_HELP_CONTENT_DOC_ID',
    (eventService, BASE_API_ENDPOINT, BACKSTAGE_HELP_CONTENT_DOC_ID) => ({
        link(scope, elem, attrs) {
            // issue with pre-58 version of firefox where watch is defined on Object prototype, doing crazy things
            // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Object/watch
            if (!attrs.hasOwnProperty('watch')) {
                return;
            }
            return scope.$watch(attrs.watch, function(newValue, oldValue) {
                if ((newValue === null || newValue === undefined) &&
                    (oldValue === null || oldValue === undefined)) {
                    return;
                }
                const event = eventService.getCurrentEvent();

                return elem.find('img[src]').each(function() {
                    const imgEl = $(this);
                    let originalSrc = imgEl.attr('src') || '';
                    const originalSrcFirstChar = originalSrc.charAt(0);

                    if (originalSrcFirstChar === '/') {
                        return;
                    } // break on non-relative path
                    if ((originalSrcFirstChar === '.') && (originalSrc.indexOf('./') === 0)) {
                        originalSrc = originalSrc.substr(2);
                    }
                    const selectedKeyRootPath =
                        scope.selectedKey.substr(0, scope.selectedKey.lastIndexOf('/'));
                    const newSrc =
                        `${BASE_API_ENDPOINT}/events/${event.id}/assets` +
                        `/${encodeURIComponent(BACKSTAGE_HELP_CONTENT_DOC_ID)}` +
                        `/${encodeURIComponent(selectedKeyRootPath)}%2F${originalSrc}`;

                    return imgEl.attr('src', newSrc);
                });
            });
        }
    })
];
