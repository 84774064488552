/* eslint-disable
    eqeqeq,
    no-case-declarations,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
let Mustache = require('mustache');

import * as URLUtils from 'libs/utils/url';

angular.module('backstage.services')

    .factory('urlService', [

        'IS_AWS',
        'APP_BASE_PATH',
        '$window',
        '$route',
        '$location',

        function(IS_AWS, APP_BASE_PATH, $window, $route, $location) {

            let urlService;

            const ROUTE_PATH_PARAM_REPLACEMENT_REXEXP = /\/(:[a-z0-9]*)/i;
            const VALID_RELATIVE_URL_REGEX = /^\/.+/i;
            // regex from https://gist.github.com/dperini/729294
            // could be eventually installed as npm module
            // https://www.npmjs.com/package/url-regex
            const VALID_URL_REGEX = new RegExp(
                '^' +
                    '(?:(?:(?:https?|ftp):)?\\/\\/)' +
                    '(?:\\S+(?::\\S*)?@)?' +
                    '(?:' +
                        '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
                        '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
                        '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
                        '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
                        '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
                        '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
                    '|' +
                        '(?:' +
                            '(?:' +
                                '[a-z0-9\\u00a1-\\uffff]' +
                                '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
                            ')?' +
                            '[a-z0-9\\u00a1-\\uffff]\\.' +
                        ')+' +
                        '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
                    ')' +
                    '(?::\\d{2,5})?' +
                    '(?:[/?#]\\S*)?' +
                '$', 'i'
            );

            // # # # #
            // public
            // # # # #

            return _.extend((urlService = {}), {

                replacePlaceholdersInResourceUrl(url, event) {
                    if (event == null) { event = {}; }
                    if (!event.id) { return url; }
                    return url.replace('{{eventId}}', event.id);
                },

                prependRouterRootPathIfOnCloud(cloudNode, apiRoute) {
                    if (urlService.isLocalNodeOurHost()) {
                        return apiRoute;
                    } else {
                        if (URLUtils.isCurrentNode(cloudNode)) {
                            return apiRoute;
                        }
                        return URLUtils.getBackstageURL(cloudNode, apiRoute);
                    }
                },

                getBackstageURL(host, path = '/', query = '', scheme = '') {
                    return URLUtils.getBackstageURL(host, path, query, scheme);
                },

                // TODO: potentially deprecate once not used anymore in pkg-backstage-skeleton
                getBackendURL(node = null) {
                    return URLUtils.getBackendURL(node);
                },

                getAppRootDomain(host) {
                    return URLUtils.getTopLevelDomain(host);
                },

                getBackstageCookieDomain(host) {
                    return URLUtils.getBackstageCookieDomain(host);
                },

                getTopLevelInstanceHost() {
                    return URLUtils.getBackstageDomain();
                },

                getTopLevelAppInstanceUrl(path = '/', query = '') {
                    return URLUtils.getHomeUrl(path, query);
                },

                getImportExportOverridePath(pathOrDescriptor, event, eventBasePath) {
                    if (eventBasePath == null) { eventBasePath = `/api/v1/events/${event.id}`; }
                    let getPathSuffix = function() {
                        let path = _.isString(pathOrDescriptor) ? pathOrDescriptor : pathOrDescriptor.path;

                        return urlService.replacePlaceholdersInResourceUrl(path, event);
                    };

                    if (_.isString(pathOrDescriptor) || (pathOrDescriptor.base === 'event')) {
                        return eventBasePath + getPathSuffix();
                    }
                    if ((pathOrDescriptor.base === 'app') || (pathOrDescriptor.base === 'root')) {
                        return getPathSuffix();
                    }
                },

                getInterpolatedRoutePath(route, context, eventId) {
                    if (context == null) { context = {}; }
                    let newContext = _.extend(
                        { eventId }
                        , context);

                    return Mustache.render(route, newContext);
                },

                openInterpolatedRoutePath(route, target) {
                    if (target == null) { target = 'route'; }
                    switch (target) {
                        case 'route': $location.url(route); break;
                        case 'window':
                            let opts = 'width=980,height=720,resizable=yes,scrollbars=yes,status=no,toolbar=no,location=no';
                            let win = $window.open(route, 'bstg-internal-route-frame', opts);

                            break;
                    }

                }, // avoids [$parse:isecwindow] error

                substituteParamsInRoutePath(path, ...substitutions) {
                    let matches;
                    let phRegExp = ROUTE_PATH_PARAM_REPLACEMENT_REXEXP;
                    let subIdx = 0;

                    while ((matches = path.match(phRegExp))) {
                        path = path.replace(matches[1], substitutions[subIdx] || '?');
                        subIdx++;
                    }
                    return path;
                },

                // substituteParamsInRoutePathWithBasePath: used in <a href="">'s, so base path is included
                substituteParamsInRoutePathWithBasePath(path, ...substitutions) {
                    path = urlService.substituteParamsInRoutePath.apply(this, arguments);
                    // old IE is not pushstate compatible and weird. don't bother
                    if ((APP_BASE_PATH.length !== 1)) {
                        path = (APP_BASE_PATH + path).replace('//', '/');
                    }
                    return path;
                },

                isLocalNodeOurHost(host = $window.location.host) {
                    return URLUtils.isHostLocal(host);
                },

                isDevNode(host = $window.location.host) {
                    const match = host.match(/^localhost.backstage.4pax.com$/);

                    return match ? 'localhost' : false;
                },

                isValidAppRoute(matcherRoute) {
                    let makeMatchableRoute = function(_route) {
                        if (_route == null) { _route = ''; }
                        return _route.replace(/\/:[a-z]+/gi, '/:$');
                    };
                    let matchableMatcherRoute = makeMatchableRoute(matcherRoute);

                    return _.keys($route.routes || {}).some(matchedRoute => matchableMatcherRoute === makeMatchableRoute(matchedRoute));
                },

                validateUrl(url) {
                    return VALID_URL_REGEX.test(url);
                },

                validateRelativeUrl(url) {
                    return VALID_RELATIVE_URL_REGEX.test(url);
                }
            }
            );
        }
    ])

    .factory('routerService', [
        'ROUTER_INFO_ENDPOINT',
        '$rootScope',
        '$http',
        (ROUTER_INFO_ENDPOINT, $rootScope, $http) => {
        // # # # #
        // public
        // # # # #
            return {
                getRouterInfo() {
                    return $http.get(ROUTER_INFO_ENDPOINT);
                }
            };
        }
    ]);
