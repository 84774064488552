// Utils
import BaseService from './base-service';
import { dateToFormat } from 'libs/utils/time';
import { parameterize } from 'libs/utils/string';

// Constants
import { API_BASE_PATH } from 'libs/utils/constants';

/**
 * @const {String} APPSCRIPT_RUN_ENDPOINT Appscript API endpoint. Interpolations: `{{eventId}}`, `{{path}}`.
 * @private
 */
const APPSCRIPT_RUN_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/appscripts/{{path}}`;

/**
 * Provides utils for running appscripts.
 *
 * @example
 * import AppScripts from 'libs/services/app-scripts';
 * ...
 * const appScripts = new AppScripts();
 */
export default class AppScriptsService extends BaseService {

    /**
     * Call an appscript by providing path and parameters
     *
     * @example
     * appScript
     * .callAppScript('eventId', 'questionnaire/get-questionnaires')
     * .then(function (questionnaires) {
     *     console.log('Questionnaires:', questionnaires);
     * })
     *
     * @param {String} eventId the ID of the event on which run the appscript
     * @param {string} path full path to the appscript
     * @param {object} [params={}] parameters to be passed to the appscript
     * @param {boolean} [returnData=true] of result: if returnData is true, pre-extract the data field from the result, otherwise return result as is
     *
     * @return {Promise<any>} promise for a returned value from the appscript
     */
    async callAppScript(eventId, path, params = {}, returnData = true) {
        const url = APPSCRIPT_RUN_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{path}}', path);

        const response = await this.post(url, { params });

        if (returnData) {
            return (response || {}).data;
        } else {
            return response;
        }
    }

    /**
     * Call an appscript by providing path and parameters in a cached manner
     *
     * @example
     * appScript
     * .callAppScript('questionnaire/get-questionnaires')
     * .then(function (questionnaires) {
     *     console.log('Questionnaires:', questionnaires);
     * })
     *
     * @param {String} eventId the ID of the event on which run the appscript
     * @param {string} path full path to the appscript
     * @param {object} [params={}] parameters to be passed to the appscript
     * @param {boolean} [returnData=true] of result: if returnData is true, pre-extract the data field from the result, otherwise return result as is
     * @param {number} [maxDuration=60000] the maximum duration to keep the cached data in milliseconds
     *
     * @return {Promise<any>} promise for a returned value from the appscript
     */
    async callAppScriptCached(eventId, path, params = {}, returnData = true, maxDuration = 60000) {
        const url = APPSCRIPT_RUN_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{path}}', path);

        const response = await this.postCached(url, { params }, undefined, maxDuration);

        if (returnData) {
            return (response || {}).data;
        } else {
            return response;
        }
    }

    /**
     * Creates a background job with the given parameters
     *
     * @param {String} eventId the ID of the event on which run the appscript
     * @param {string} jobAppScript the job appscript path
     * @param {object} jobParams the params of the job appscript
     * @param {object} jobDocParams the params for the job document
     *
     * @return {Promise<any>} promise for a returned value from the appscript
     */
    async createJob(eventId, jobAppScript, jobParams, jobDocParams) {
        return await this.callAppScript(eventId, 'background-jobs/create-job', {
            jobAppScript,
            jobParams,
            jobDocParams
        });
    }

    /**
     * @param {object} args
     * @param {string} args.eventId the ID of the event on which run the appscript
     * @param {string} args.jobAppScript the job appscript path
     * @param {Record<string,unknown>} [args.jobParams] the params of the job appscript
     * @param {Record<string,unknown>} [args.jobDocParams] the params for the job document
     * @param {number} [args.pollMilliseconds] milliseconds between polls
     * @param {number} [args.timeoutMinutes] minutes until timeout
     */
    async createJobAndWaitForCompletion({ eventId, jobAppScript, jobParams = {}, jobDocParams = {}, pollEvery = 1000, timeout = 10 }) {
        const start = Math.round(new Date().getTime() / 1000);
        const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
        const { _id } = await this.createJob(eventId, jobAppScript, jobParams, jobDocParams);

        const poll = async () => {
            const hasTimedOut = Math.round(new Date().getTime() / 1000) > start + timeout * 1000 * 60;
            if (hasTimedOut) {
                throw new Error(`job did not finish within ${timeout} minutes`);
            }
            const { job } = await this.callAppScript(eventId, 'background-jobs/fetch-job-by-doc-id', { id: _id });
            if (job.state === 'complete') {
                return this.callAppScript(eventId, 'background-jobs/fetch-job-result', { id: _id });
            }
            await wait(pollEvery);
            return poll();
        };

        return poll();
    }

    /**
     * Creates a background job for exporting lists
     *
     * @param {string} eventId the ID of the event
     * @param {object} params the options to create the background job
     * @param {string} params.fpType the fp type of the export
     * @param {string} params.name the base name of the export file
     * @param {string} params.exporter the path of the appscript to use as exporter
     *
     * @return {Promise<{bgJobDoc: object, job: object}>} a backround job document and a job progress document
     */
    async createExportJob(eventId, { fpType, name, exporter }) {
        const date = dateToFormat(new Date(), 'YYYY-MM-DD-HH[h]mm[m]ss[s]');
        const params = { fpType };
        const bgJobDoc = await this.createJob(eventId, 'background-jobs/bg-export', {}, {
            name,
            fileName: `${parameterize(name)}-${date}.xlsx`,
            exporter: { params, path: exporter },
            source: { params, path: 'lib/background-jobs/fp-type-exporter-source' },
            columnsFallback: { params, path: 'lib/background-jobs/fp-type-exporter-columns-fallback' }
        });

        const { job } = await this.callAppScript(eventId, 'background-jobs/fetch-job-by-doc-id', { id: bgJobDoc._id });

        return { bgJobDoc, job };
    }
}
