"use strict";

module.exports = /* @ngInject */["$http", "eventService", function ($http, eventService) {
  return {
    restrict: 'AC',
    scope: {
      event: '='
    },
    link: function link(scope, elem) {
      var updateImageSourceFn = function updateImageSourceFn(event) {
        // reinit styles
        elem.addClass('placeholder');
        elem.css({
          backgroundImage: 'initial'
        });
        if (!event) return;
        var imgSrc = eventService.getEventAppIconImageUrl(event);
        if (!imgSrc) return;

        // load and put as background
        $('<img/>').attr({
          src: imgSrc
        }).one('load', function () {
          return elem.removeClass('placeholder').css({
            backgroundImage: "url(".concat(imgSrc, ")")
          });
        });
      };

      // if the event change, change the img
      scope.$watch('event', updateImageSourceFn);
    }
  };
}];