// ⚑
// TODO: This shouldn't have to exist BUT unfortunately Bootstrap is failing to hook automatically
module.exports = () => ({
    link(scope, elem) {
        elem.attr({
            'href': 'javascript:void(0)',
            'data-toggle': 'dropdown'
        });
        return elem.dropdown();
    }
});
