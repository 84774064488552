"use strict";

var _require = require('src/branded-apps-metadata'),
  FORMS = _require.FORMS;

/* @ngInject */
var PublishingAccountEditCtrl = function PublishingAccountEditCtrl($scope, $routeParams, userService, metadataService, appBrandingService) {
  var init = function init() {
    return appBrandingService.getPublishingAccount($routeParams.id).then(function (resp) {
      return $scope.model = resp.data;
    });
  };
  $scope.fields = metadataService.convertMetadataHashToOrderedList(FORMS.publishingAccount);
  $scope.omitNameHeading = false;
  $scope.getLabelForModel = function (model) {
    return model ? model.name : undefined;
  };
  $scope.saveModel = function (model) {
    return appBrandingService.updatePublishingAccount(model).then(function () {
      $scope.setFlashMessage('Saved.');
      return init();
    }).catch(function () {
      return alert('An error occured. Please try again.');
    });
  };
  return init();
};
PublishingAccountEditCtrl.$inject = ["$scope", "$routeParams", "userService", "metadataService", "appBrandingService"];
PublishingAccountEditCtrl.$inject = ["$scope", "$routeParams", "userService", "metadataService", "appBrandingService"];
angular.module('backstage.controllers.brandedApps').controller('PublishingAccountEditCtrl', PublishingAccountEditCtrl);