"use strict";

var _ = require('underscore');

// DEFAULT VALUES

var CONDITIONS = {
  'equal': {
    label: 'equals',
    param: true
  },
  'not-equal': {
    label: 'does not equal',
    param: true
  },
  'contains': {
    label: 'contains',
    param: true
  },
  'doesnt-contain': {
    label: 'does not contain',
    param: true
  },
  'contains-some': {
    label: 'contains some of',
    param: true
  },
  'contains-none': {
    label: 'contains none of',
    param: true
  },
  'defined': {
    label: 'is defined',
    param: false
  },
  'not-defined': {
    label: 'isn\'t defined',
    param: false
  }
};
var getNewCondition = function getNewCondition(field) {
  return {
    field: field,
    condition: 'equal',
    value: null
  };
};
module.exports = /* @ngInject */["$scope", "model", "metadataService", "$routeParams", "fieldDescriptor", function BtMetadataFilterController($scope, model, metadataService, $routeParams, fieldDescriptor) {
  var eventId = $routeParams.eventId;
  var fpType = fieldDescriptor.kind_options.fp_type;
  var onlyPublicFields = fieldDescriptor.kind_options.onlyPublicFields;
  var firstMetadataField;
  $scope.ruleConditions = CONDITIONS;
  $scope.error = null;
  if (fpType || eventId) {
    if (!fpType) {
      $scope.error = 'Error: fp_type not defined';
      return;
    }

    // get metadata with dynamic extensions and custom kind filtered out
    var allMetadata = _.filter(metadataService.getCachedMetadataForTypeAsArray(eventId, fpType, true), function (metadataField) {
      return metadataField.kind !== 'custom';
    });
    if (allMetadata && _.isArray(allMetadata) && allMetadata.length === 0) {
      $scope.error = 'Error: Could not retrieve metadata for fp_type ' + fpType;
      return;
    }
    $scope.singleFieldMetadatas = _.reduce(allMetadata, function (map, metadata) {
      map[metadata.field] = {
        value: metadata
      };
      return map;
    }, {});
    $scope.filteredMetadata = allMetadata.filter(function (fieldMeta) {
      return !onlyPublicFields || !fieldMeta.crypt;
    }).map(function (fieldMeta) {
      return [fieldMeta.field, fieldMeta.label || fieldMeta.field];
    });
    firstMetadataField = $scope.filteredMetadata[0][0];
  }
  $scope.rules = model[fieldDescriptor.field];

  // CONTROLLER METHODS

  $scope.addRule = function () {
    if (!$scope.rules) {
      $scope.rules = [];
      model[fieldDescriptor.field] = $scope.rules;
    }
    var newCondition = getNewCondition(firstMetadataField);
    $scope.rules.push([newCondition]);
  };
  $scope.addCondition = function (rule) {
    var newCondition = getNewCondition(firstMetadataField);
    rule.push(newCondition);
  };
  $scope.addConditionAtIndex = function (rule, $index) {
    var newCondition = getNewCondition(firstMetadataField);
    rule.splice($index + 1, 0, newCondition);
  };
  $scope.addConditionToLastRule = function () {
    var newCondition = getNewCondition(firstMetadataField);
    $scope.rules[$scope.rules.length - 1].push(newCondition);
  };
  $scope.removeCondition = function (ruleIndex, conditionIndex) {
    var rule = $scope.rules[ruleIndex];
    rule.splice(conditionIndex, 1);
    if (rule.length === 0) $scope.rules.splice(ruleIndex, 1);
  };
}];