"use strict";

var _brandedAppsMetadata = require("src/branded-apps-metadata");
var _constants = require("libs/utils/constants");
var _lodash = require("lodash");
var _semver = _interopRequireDefault(require("semver"));
var _attachments = require("libs/utils/attachments");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var _ = require('underscore');
var moment = require('moment');
var _require = require('src/branded-apps-metadata'),
  ASSET_PRESETS = _require.ASSET_PRESETS,
  SCREENSHOT_TESTS = _require.SCREENSHOT_TESTS,
  DEFAULT_THEME = _require.DEFAULT_THEME,
  DEFAULT_ANDROID_BRANDING = _require.DEFAULT_ANDROID_BRANDING;
var APP_THEME_PATH = 'theme/container-theme.json';
var ANDROID_BRANDING_PATH = 'branding-theme.json';
var DEFAULT_BRANDINGS = [{
  _id: 'eventspace',
  fp_ext_id: 'eventspace',
  springboard_name: 'Eventspace by SpotMe'
}];
var AppBrandingService = /* @ngInject */function AppBrandingService(APP_BRANDING_SERVICE_ENDPOINT, APP_PUBLISHING_SERVICE_ENDPOINT, APP_PUBLISHING_CERTIFICATES_SERVICE_ENDPOINT, APP_PUBLISHING_ACCOUNTS_SERVICE_ENDPOINT, $http, $q) {
  var apiHostPrefix = _constants.CENTRAL_BACKSTAGE_URL;
  APP_BRANDING_SERVICE_ENDPOINT = apiHostPrefix + APP_BRANDING_SERVICE_ENDPOINT;
  APP_PUBLISHING_SERVICE_ENDPOINT = apiHostPrefix + APP_PUBLISHING_SERVICE_ENDPOINT;
  APP_PUBLISHING_CERTIFICATES_SERVICE_ENDPOINT = apiHostPrefix + APP_PUBLISHING_CERTIFICATES_SERVICE_ENDPOINT;

  // import and exports helpers

  var importTimestamps = function importTimestamps() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$binary_configura = _ref.binary_configurations,
      binary_configurations = _ref$binary_configura === void 0 ? {} : _ref$binary_configura,
      _ref$store_config = _ref.store_config,
      store_config = _ref$store_config === void 0 ? {} : _ref$store_config;
    if (_.isNumber(binary_configurations.update_until)) {
      var ts = binary_configurations.update_until;
      binary_configurations.update_until = moment.unix(ts).format('YYYY-MM-DD');
      if (store_config.recurring === false) {
        binary_configurations.update_until = moment().add(1, 'year').add(1, 'month').date(1).format('YYYY-MM-DD');
      }
    }
  };
  var exportTimestamps = function exportTimestamps() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$binary_configur = _ref2.binary_configurations,
      binary_configurations = _ref2$binary_configur === void 0 ? {} : _ref2$binary_configur;
    if (_.isString(binary_configurations.update_until)) {
      var str = binary_configurations.update_until;
      var ts = moment(str, 'YYYY-MM-DD').unix();
      binary_configurations.update_until = ts;
    }
  };
  var importKeywords = function importKeywords() {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$store_config = _ref3.store_config,
      store_config = _ref3$store_config === void 0 ? {} : _ref3$store_config;
    if (_.isString(store_config.keywords)) {
      store_config.keywords = store_config.keywords.split(',');
    }
  };
  var exportKeywords = function exportKeywords() {
    var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$store_config = _ref4.store_config,
      store_config = _ref4$store_config === void 0 ? {} : _ref4$store_config;
    if (_.isArray(store_config.keywords)) {
      store_config.keywords = _.uniq(store_config.keywords).join(',');
    }
  };
  var importInvitationsCategories = function importInvitationsCategories() {
    var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$categories = _ref5.categories,
      categories = _ref5$categories === void 0 ? [] : _ref5$categories;
    if (_.isArray(categories)) {
      _.each(categories, function () {
        var category = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        return category.name = (category.name ? category.name.en : undefined) || '';
      });
    }
  };
  var exportInvitationsCategories = function exportInvitationsCategories() {
    var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref6$categories = _ref6.categories,
      categories = _ref6$categories === void 0 ? [] : _ref6$categories;
    if (_.isArray(categories)) {
      _.each(categories, function (category, index) {
        category.order = index + 1;
        category.name = {
          en: category.name
        };
      });
    }
  };
  var initFinalBundleConfigIfMissing = function initFinalBundleConfigIfMissing() {
    var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref7$binary_configur = _ref7.binary_configurations,
      binary_configurations = _ref7$binary_configur === void 0 ? {} : _ref7$binary_configur;
    if (!binary_configurations.ios) return;
    if (binary_configurations.ios.finalBundle) return;
    binary_configurations.ios.finalBundle = {
      bundle_id: ''
    };
  };
  var initAppLockConfigIfMissing = function initAppLockConfigIfMissing() {
    var branding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (!branding.app_lock) {
      branding.app_lock = {
        enabled: false,
        lock_type: 'pin',
        expiration_time: 0,
        min_length: 4,
        max_failed_attempts: 10,
        fingerprint: true
      };
    }
  };
  var initWebAppConfigIfMissing = function initWebAppConfigIfMissing() {
    var branding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (!branding.webapp) {
      branding.webapp = {};
    }
    if (branding.webapp.install_instructions !== false) {
      branding.webapp.install_instructions = true;
    }
  };
  var initArConfigIfMissing = function initArConfigIfMissing() {
    var branding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (!branding.ar) {
      branding.ar = {
        enabled: false
      };
    }
  };
  var exportSSOOpenConnect = function exportSSOOpenConnect() {
    var branding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (!branding.sso_config) {
      return;
    }
    for (var _i = 0, _Object$keys = Object.keys(branding.sso_config); _i < _Object$keys.length; _i++) {
      var configKey = _Object$keys[_i];
      var config = branding.sso_config[configKey];
      if (config.type === 'openidconnect') {
        var conf = config.conf;
        var keys = [];
        for (var _i2 = 0, _Object$keys2 = Object.keys(_brandedAppsMetadata.FORMS.sso.openidconnect); _i2 < _Object$keys2.length; _i2++) {
          var key = _Object$keys2[_i2];
          if (_brandedAppsMetadata.FORMS.sso.openidconnect[key].kind === 'list') {
            keys.push(key);
          }
        }
        for (var _i3 = 0, _keys = keys; _i3 < _keys.length; _i3++) {
          var secondLevelKey = _keys[_i3];
          /**
           * TODO: the SSO section(when its loaded), is remapping the part of the config into an array
           * if the single sign section is not opened, it will not get remapped
           * so if you open SSO section and save, or just open and go to other section, bug is not happening.
           * it is happening, when SSO is not opened and something else got saved.
           * this is temporary, permanent "the deserialized section" has to be moved from the SSO Section
           * to the getBranding in the appBrandingService
           */
          if (Array.isArray(conf[secondLevelKey])) {
            var fields = {};
            var _iterator = _createForOfIteratorHelper(conf[secondLevelKey]),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var field = _step.value;
                fields[field.key] = field.value;
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            conf[secondLevelKey] = fields;
          }
        }
        config.conf = conf;
      }
    }
  };
  var cleanupBuilds = function cleanupBuilds(builds) {
    Object.keys(builds || {}).forEach(function (key) {
      var build = builds[key] || {};
      build.hidden = key === 'sod' || !build.version
      // Android used pre-release version notation, remove it
      || !_semver.default.satisfies(build.version.replace(/-.*/, ''), '>=2.0.0');
    });
  };
  var cleanupDeprecatedBuilds = function cleanupDeprecatedBuilds() {
    var branding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    cleanupBuilds((0, _lodash.get)(branding, 'fp_last_upload.ios'));
    cleanupBuilds((0, _lodash.get)(branding, 'fp_last_upload.android'));
  };
  return {
    /* Public */getBrandingArtworkUrl: function getBrandingArtworkUrl(id) {
      return "".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(id, "/artwork.png");
    },
    isBrandingIdValid: function isBrandingIdValid(id) {
      return /^[a-z]{1}[a-z0-9]+$/.test(id);
    },
    getAllBrandings: function getAllBrandings() {
      return $http.get(APP_BRANDING_SERVICE_ENDPOINT, {
        withCredentials: true
      });
    },
    addDefaultBrandings: function addDefaultBrandings(brandings) {
      _.each(DEFAULT_BRANDINGS, function (branding) {
        var hasBranding = _.find(brandings, function (aBranding) {
          return aBranding.fp_ext_id === branding.fp_ext_id;
        });
        if (!hasBranding) {
          brandings.unshift(branding);
        }
      });
      return brandings;
    },
    cloneBranding: function cloneBranding(sourceId) {
      var targetConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return $http.post("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(sourceId, "/clone"), targetConfig, {
        withCredentials: true
      });
    },
    createBranding: function createBranding(config) {
      return this.cloneBranding('default', config);
    },
    getBranding: function getBranding(id) {
      return $http.get("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(id), {
        withCredentials: true
      }).then(function (resp) {
        if (!resp.data) {
          return resp;
        }
        var branding = resp.data;

        // normalizations
        importTimestamps(branding);
        importKeywords(branding);
        importInvitationsCategories(branding);
        cleanupDeprecatedBuilds(branding);

        // defaults
        initFinalBundleConfigIfMissing(branding);
        initAppLockConfigIfMissing(branding);
        initWebAppConfigIfMissing(branding);
        initArConfigIfMissing(branding);
        return resp;
      });
    },
    buildBranding: function buildBranding(id) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return $http.post("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(id, "/build"), options, {
        withCredentials: true
      });
    },
    saveBranding: function saveBranding(branding) {
      branding = _.omit(branding, '_attachments') || {};
      exportTimestamps(branding);
      exportKeywords(branding);
      exportInvitationsCategories(branding);
      exportSSOOpenConnect(branding);
      return $http.post("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(branding.fp_ext_id), branding, {
        withCredentials: true
      });
    },
    versionsMatch: function versionsMatch(v1, v2) {
      v1 = (v1 || '').split('-')[0];
      v2 = (v2 || '').split('-')[0];
      return v1 === v2;
    },
    /* Asset managment */getAssetPreset: function getAssetPreset(id) {
      return ASSET_PRESETS[id];
    },
    getAssetInfo: function getAssetInfo(branding, assetId) {
      var preset = ASSET_PRESETS[assetId] ? ASSET_PRESETS[assetId].path : undefined;
      if (!preset || !(0, _attachments.hasAnyAttachments)(branding)) return;
      return (0, _attachments.getAttachmentMetadata)(branding, preset);
    },
    deleteAsset: function deleteAsset(branding, assetUrl) {
      return $http.delete("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(branding.fp_ext_id, "/assets/").concat(assetUrl), {
        withCredentials: true
      });
    },
    getScreenshotTypesForPlatform: function getScreenshotTypesForPlatform(platform) {
      return SCREENSHOT_TESTS[platform];
    },
    getScreenshotFilenamesOfType: function getScreenshotFilenamesOfType(branding, platform, type) {
      var descriptor = SCREENSHOT_TESTS[platform][type];
      var attachmentsNames = (0, _attachments.getAttachmentNames)(branding);
      return _.chain(attachmentsNames).filter(function (path) {
        return descriptor.pathTest.test(path);
      }).map(function (path) {
        return _.last(path.split('/'));
      }).sortBy(function (path) {
        return parseInt((path.match(descriptor.pathTest) || [])[1], 10);
      }).value();
    },
    getNextScreenshotsFilename: function getNextScreenshotsFilename(screenshots, platform, type) {
      var descriptor = SCREENSHOT_TESTS[platform][type];
      var lastIndex = 0;
      if (screenshots.length) {
        lastIndex = parseInt((_.last(screenshots).match(descriptor.indexMatch) || [])[1], 10);
        if (isNaN(lastIndex)) {
          lastIndex = 0;
        }
      }
      return descriptor.fileName.replace('$n', lastIndex + 1);
    },
    /* Theme */getTheme: function getTheme(branding) {
      var brandingId = branding.fp_ext_id;
      var base = "".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(brandingId, "/assets/branding");
      var pluckData = function pluckData(resp) {
        return resp.data;
      };
      var handleMissingThemeData = function handleMissingThemeData(resp) {
        if (resp.status === 404) {
          return {};
        } else {
          return $q.reject(resp);
        }
      };
      return $q.all({
        container: $http.get("".concat(base, "/").concat(APP_THEME_PATH), {
          withCredentials: true
        }).then(pluckData, handleMissingThemeData),
        android: $http.get("".concat(base, "/").concat(ANDROID_BRANDING_PATH), {
          withCredentials: true
        }).then(pluckData, handleMissingThemeData)
      }).then(function (resps) {
        return _.extend({}, angular.merge({}, DEFAULT_THEME, resps.container), angular.merge({}, DEFAULT_ANDROID_BRANDING, resps.android));
      });
    },
    saveTheme: function saveTheme(branding, theme) {
      var _this = this;
      var brandingId = branding.fp_ext_id;
      var androidBranding = _.pick(theme, 'android');
      var container = _.omit(theme, 'android');
      var base = "".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(brandingId, "/assets/branding");

      // the branding is saved as an attachment, so it needs a csrf token
      var postWithCsrf = function postWithCsrf(url, data) {
        return _this.getCSRFToken().then(function (token) {
          return $http({
            method: 'POST',
            headers: {
              'csrf-token': token
            },
            url: url,
            data: data,
            withCredentials: true
          });
        });
      };
      return postWithCsrf("".concat(base, "/").concat(APP_THEME_PATH), container)
      // failsafe
      .catch(function (err) {
        console.error('Failed to save container', err);
      }).then(function () {
        return postWithCsrf("".concat(base, "/").concat(ANDROID_BRANDING_PATH), androidBranding);
      });
    },
    /**
     * Turn theme retrieved from backend into a simplified version
     * @param {object} theme
     * @return {object}
     */
    simplifyTheme: function simplifyTheme(theme) {
      var navBar = ((theme.navs || {}).eventslist_nav || {}).navigation_bar;
      var activationScreen = theme.activation_screens || {};
      var activationScreenButton = activationScreen.button || {};
      return {
        _fullTheme: theme,
        version: theme.theming_version,
        toolbars: {
          color: (navBar.title || {}).font_color,
          backgroundColor: navBar.background_color
        },
        systemStatusBar: {
          backgroundColor: ((theme.layouts || {}).status_bar || {}).background_color
        },
        segmentedControl: {
          tintColor: (((theme.navs || {}).eventslist_nav || {}).segmented_control || {}).tint_color
        },
        accentColor: (theme.android || {}).color_accent,
        activationScreens: {
          button: {
            backgroundColor: activationScreenButton.background_color,
            title: {
              fontColor: (activationScreenButton.title || {}).font_color
            }
          }
        }
      };
    },
    /**
     * Prepare theme before saving it
     * @param {object} simpleTheme
     * @return {object}
     */
    complicateTheme: function complicateTheme(simpleTheme) {
      var fullTheme = simpleTheme._fullTheme;
      var toolbarConfig = {
        background_color: simpleTheme.toolbars.backgroundColor,
        title: {
          font_color: simpleTheme.toolbars.color
        }
      };
      return angular.merge({}, fullTheme, {
        layouts: {
          status_bar: {
            background_color: simpleTheme.systemStatusBar.backgroundColor
          }
        },
        navs: {
          eventslist_nav: {
            navigation_bar: toolbarConfig,
            segmented_control: {
              tint_color: simpleTheme.segmentedControl.tintColor
            },
            tool_bar: toolbarConfig
          }
        },
        android: {
          color_primary: simpleTheme.toolbars.backgroundColor,
          color_primary_dark: simpleTheme.systemStatusBar.backgroundColor,
          color_accent: simpleTheme.accentColor
        },
        activation_screens: {
          button: {
            background_color: simpleTheme.activationScreens.button.backgroundColor,
            title: {
              font_color: simpleTheme.activationScreens.button.title.fontColor
            }
          }
        }
      });
    },
    /* Publishing requests */getAllPublishingRequests: function getAllPublishingRequests() {
      var status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'open';
      return $http.get(APP_PUBLISHING_SERVICE_ENDPOINT, {
        params: {
          status: status
        },
        withCredentials: true
      });
    },
    getPublishingRequestsForBranding: function getPublishingRequestsForBranding(brandingId) {
      return $http.get("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(brandingId, "/publishing-requests"), {
        withCredentials: true
      });
    },
    createPublishingRequest: function createPublishingRequest(branding) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return $http.post("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(branding.fp_ext_id, "/publishing-requests"), _.extend(opts, {
        withCredentials: true
      }));
    },
    updatePublishingRequest: function updatePublishingRequest(id, updates) {
      return $http.put("".concat(APP_PUBLISHING_SERVICE_ENDPOINT, "/").concat(id), updates, {
        withCredentials: true
      });
    },
    assignPublishingRequest: function assignPublishingRequest(id, userId) {
      return this.updatePublishingRequest(id, {
        assignee: userId
      });
    },
    closePublishingRequest: function closePublishingRequest(id) {
      return this.updatePublishingRequest(id, {
        status: 'closed'
      });
    },
    /* Publishing accounts */getAllPublishingAccounts: function getAllPublishingAccounts() {
      return $http.get(APP_PUBLISHING_ACCOUNTS_SERVICE_ENDPOINT, {
        withCredentials: true
      });
    },
    getPublishingAccount: function getPublishingAccount(id) {
      return $http.get("".concat(APP_PUBLISHING_ACCOUNTS_SERVICE_ENDPOINT, "/").concat(id), {
        withCredentials: true
      });
    },
    createPublishingAccount: function createPublishingAccount(account) {
      return $http.post("".concat(APP_PUBLISHING_ACCOUNTS_SERVICE_ENDPOINT), account, {
        withCredentials: true
      });
    },
    updatePublishingAccount: function updatePublishingAccount(account) {
      return $http.put("".concat(APP_PUBLISHING_ACCOUNTS_SERVICE_ENDPOINT, "/").concat(account._id), account, {
        withCredentials: true
      });
    },
    /* Push certificates */getAllPushCertificates: function getAllPushCertificates() {
      return $http.get(APP_PUBLISHING_CERTIFICATES_SERVICE_ENDPOINT, {
        withCredentials: true
      });
    },
    getPushCertificate: function getPushCertificate(id) {
      return $http.get("".concat(APP_PUBLISHING_CERTIFICATES_SERVICE_ENDPOINT, "/").concat(id), {
        withCredentials: true
      });
    },
    /* Changelog */getChangelog: function getChangelog(brandingId) {
      return $http.get("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(brandingId, "/changelog"), {
        withCredentials: true
      });
    },
    logChange: function logChange(branding, change) {
      return $http.post("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(branding.fp_ext_id, "/changelog"), change, {
        withCredentials: true
      });
    },
    /**
     * Returns a CSRF token for asset upload
     * @returns {Promise}
     */
    getCSRFToken: function getCSRFToken() {
      return window.BSTG.services.branding.getCsrfToken();
    },
    /**
     * Returns the name of app branding
     *
     * @param {String} brandingId
     * @returns {Promise<String>} app branding name
     */
    getAppName: function getAppName(brandingId) {
      return $http.get("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(brandingId, "/name"), {
        withCredentials: true
      }).then(function (result) {
        return result.data.name;
      });
    },
    /**
     * Update emails for branding
     * @param {string} brandingId
     * @return {Promise<{jobId:string>}}
     */
    updateEmailLogs: function updateEmailLogs(brandingId) {
      return $http.post("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(brandingId, "/update-email-logs"), {
        withCredentials: true
      }).then(function (result) {
        return result.data;
      });
    },
    /**
     * Update emails for branding
     * @param {string} brandingId
     * @return {Promise<{jobId:string>}}
     */
    getEmailLogs: function getEmailLogs(brandingId) {
      var skip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 50;
      return $http.get("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(brandingId, "/email-logs"), {
        params: {
          skip: skip,
          limit: limit
        },
        withCredentials: true
      }).then(function (result) {
        return result.data;
      });
    },
    /**
     * Trigger export of failed and pending emails
     * @param {string} brandingId
     * @return {Promise<{jobId:string>}}
     */
    exportFailedAndPendingEmails: function exportFailedAndPendingEmails(brandingId) {
      return $http.post("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(brandingId, "/export-failed-pending-emails"), {
        withCredentials: true
      }).then(function (result) {
        return result.data;
      });
    },
    /**
     * Download failed and pending emails
     * @param {string} brandingId
     * @return {Promise<void>}}
     */
    downloadFailedAndPendingEmails: function downloadFailedAndPendingEmails(brandingId) {
      window.open("".concat(APP_BRANDING_SERVICE_ENDPOINT, "/").concat(brandingId, "/failed-pending-emails-export"), '_blank');
      return Promise.resolve();
    }
  };
};
AppBrandingService.$inject = ["APP_BRANDING_SERVICE_ENDPOINT", "APP_PUBLISHING_SERVICE_ENDPOINT", "APP_PUBLISHING_CERTIFICATES_SERVICE_ENDPOINT", "APP_PUBLISHING_ACCOUNTS_SERVICE_ENDPOINT", "$http", "$q"];
AppBrandingService.$inject = ["APP_BRANDING_SERVICE_ENDPOINT", "APP_PUBLISHING_SERVICE_ENDPOINT", "APP_PUBLISHING_CERTIFICATES_SERVICE_ENDPOINT", "APP_PUBLISHING_ACCOUNTS_SERVICE_ENDPOINT", "$http", "$q"];
angular.module('backstage.services').factory('appBrandingService', AppBrandingService);