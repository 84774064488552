module.exports = ['$log', $log => ({
    restrict: 'C',
    link(scope, elem) {
        // backwards compatibility - each modal had it's own .modal-backdrop
        if (!elem.hasClass('required')) {
            $log.warn('.modal-backdrop elements are no longer required', elem);
            elem.remove();
            return;
        }

        // prevent propagation of mouse drag events
        // https://github.com/Spotme/backstage-app/issues/167
        elem[0].addEventListener('mousedown', ev => ev.stopPropagation(), { passive: true });
        elem[0].addEventListener('touchstart', ev => ev.stopPropagation(), { passive: true });

        return elem;
    }
})];
