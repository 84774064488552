"use strict";

var bsOrgEntitlementsDisplayWidgetsCtrl = /* @ngInject */function bsOrgEntitlementsDisplayWidgetsCtrl($scope, entitlementsService) {
  var orgId = $scope.orgId;
  entitlementsService.getOrganizationSalesRep(orgId).then(function (contactSalesRep) {
    $scope.isEntitlementsLoaded = true;
    $scope.contactSalesRep = contactSalesRep;
  }, function () {
    return $scope.isEntitlementsLoaded = true;
  });
};
bsOrgEntitlementsDisplayWidgetsCtrl.$inject = ["$scope", "entitlementsService"];
module.exports = [function () {
  return {
    scope: {
      orgId: '='
    },
    template: "\n<i class=\"icon-spinner icon-spin\" ng-show=\"!isEntitlementsLoaded\" size=16></i>\n\n<div class=\"accordion accordion-support-entitlements sales-rep-info fade\"\n     ng-class=\"{in: contactSalesRep}\">\n\n  <div class=\"accordion-group filter-block\">\n    <div class=\"accordion-heading\">\n        <span class=\"accordion-toggle\">\n            Your Sales Representative\n        </span>\n    </div>\n    <div class=\"accordion-body collapse in\">\n      <div class=\"accordion-inner\">\n        <ul>\n            <li ng-bind=\"contactSalesRep.name | abbreviateFullUserName:25\"></li>\n            <li ng-bind=\"contactSalesRep.email\"></li>\n            <li ng-bind=\"contactSalesRep.phone\"></li>\n            <li ng-bind=\"contactSalesRep.mobilePhone\"></li>\n        </ul>\n      </div>\n    </div>\n  </div>\n</div>\n",
    controller: bsOrgEntitlementsDisplayWidgetsCtrl
  };
}];