module.exports = [
    'APP_BASE_PATH',
    (APP_BASE_PATH) =>
        ({
            priority: 101, // run before {{}} interpolate (priority 100)
            compile(elem, attrs = {}) {
                const ngHref = attrs.ngHref || '';

                if (ngHref.substr(0, 7) !== '/event/') { return; }
                attrs.ngHref = (APP_BASE_PATH + attrs.ngHref).replace('//', '/');
            }
        })

];
