// Utils
import { getHomeUrl, getTopLevelDomain } from 'libs/utils/url';

// Constants
import { SSO_DOMAIN_ENDPOINT, ROUTE_LOGIN, ROUTE_USERS } from 'libs/utils/constants';

/**
 * The cookie name where we save the attempted URL access before relogin
 * @const {string} REQUESTED_URL_COOKIE
 */
const REQUESTED_URL_COOKIE = '__redirect_to';

/**
 * The cookie name where we save the redirect attempt
 * @const {string} REDIRECT_ATTEMPTED
 */
const REDIRECT_ATTEMPTED = '__redirect_attempted';

/**
 * Provides methods necessary for browser navigation to various Backstage URLs.
 *
 * Note that some methods refer to their deprecated counterpart in utils.urls. Once
 * every reference has been fixed we can move all of them in RouterService.
 *
 * @example
 * import RouterService from 'libs/services/router';
 * ...
 * const routerService = new RouterService();
 */
export default class RouterService {
    /**
     * @param {import('../providers/window').default} windowProvider proxy to the browser's window object.
     */
    constructor(windowProvider) {
        this.window = windowProvider;
    }

    /**
     * Redirects the browser to the specified URL
     *
     * @param {string} url the URL to open
     */
    redirect(url) {
        try {
            this.window.location.assign(url);
        } catch (error) {
            console.error('[RouterService] Could not redirect user to %s due to an error', url, error);
        }
    }

    /**
     * Redirects the browser to backstage Home page, i.e. Infra server.
     *
     * @param {String} [path='/'] URL path
     * @param {String} [query=''] URL query
     */
    redirectToHome(path, query) {
        this.redirect(this.getHomeUrl(path, query));
    }

    /**
     * Initiate SSO login with the given SSO domain.
     *
     * @param {String} ssoDomain the SSO domain name
     */
    redirectToSSOUrl(ssoDomain) {
        this.redirect(this.getSSOUrl(ssoDomain));
    }

    /**
     * Returns the main backstage instance URL
     *
     * @param {String} [path='/'] URL path
     * @param {String} [query=''] URL query
     * @param {String} [scheme=''] an optional protocol
     *
     * @returns {String} main backstage instance URL
     */
    getHomeUrl(path, query, scheme) {
        return getHomeUrl(path, query, scheme);
    }

    /**
     * Build the URL used to initiate SSO login.
     *
     * @param {String} ssoDomain the SSO domain name
     */
    getSSOUrl(ssoDomain) {
        return SSO_DOMAIN_ENDPOINT.replace(/{{domain}}/, encodeURIComponent(ssoDomain));
    }

    /**
     * Open targetUrl in a new tab/window.
     *
     * @param {String} url A string indicating the URL or path of the resource to be loaded. If an empty string ("") is specified or this parameter is omitted, a blank page is opened into the targeted browsing context.
     * @param {String} target A string, without whitespace, specifying the name of the browsing context the resource is being loaded into. If the name doesn't identify an existing context, a new context is created and given the specified name. The special target keywords, _self, _blank, _parent, and _top, can also be used.
     * @param {string} windowFeatures A string containing a comma-separated list of window features in the form name=value — or for boolean features, just name. These features include options such as the window's default size and position, whether or not to open a minimal popup window, and so forth.
     *
     * @returns {Window} A reference to the newly created window.
     */
    openNewWindow(url, target = '_blank', windowFeatures = 'noreferrer,noopener') {
        return this.window.open(url, target, windowFeatures);
    }

    /**
     * Opens a route based on the specified target.
     *
     * @param {string} route - The route to open.
     * @param {string} [target] - The target where the route should be opened. Defaults to 'route'.
     *
     * @returns {Window|void} A reference to the newly created window if the target is a window, void otherwise.
     */
    openRoute(route, target) {
        const shouldRedirect = !target || target === 'route';
        if (shouldRedirect) {
            return this.redirect(route);
        } else {
            return this.openNewWindow(route, target, 'width=980,height=720,resizable=yes,scrollbars=yes,status=no,toolbar=no,location=no');
        }
    }

    /**
     * Saves the requested URL into a cookie for future processing
     */
    saveRequestedUrl() {
        const href = this.window.location.href;
        let path = '';
        try {
            path = new URL(href).pathname;
        } catch (error) {
            console.error('[RouterService] Could not compute current path name:', error.message);
        }

        if (!path.startsWith(ROUTE_LOGIN) && !path.startsWith(ROUTE_USERS)) {
            this.window.$cookies.set(REQUESTED_URL_COOKIE, href, '7d', '/', getTopLevelDomain());
        }
    }

    /**
     * Redirects the user to the saved URL
     */
    redirectToSavedUrl() {
        const attemptsCookie = this.window.$cookies.get(REDIRECT_ATTEMPTED) || false;
        const attempted = attemptsCookie === 'true'; // yep, cookies are saved in string format
        this.window.$cookies.set(REDIRECT_ATTEMPTED, true, '7d', '/', getTopLevelDomain());

        if (attempted) {
            this.deleteSavedUrl();
            return;
        }

        const redirect = this.window.$cookies.get(REQUESTED_URL_COOKIE);
        if (redirect) {
            const url = decodeURI(redirect);
            this.deleteSavedUrl();
            this.redirect(url);
        }
    }

    /**
     * Consumes the saved URL cookies
     *
     * @private
     */
    deleteSavedUrl() {
        this.window.$cookies.remove(REQUESTED_URL_COOKIE, '/', getTopLevelDomain());
        this.window.$cookies.remove(REDIRECT_ATTEMPTED, '/', getTopLevelDomain());
    }

    /**
     * Checks if the user has a saved URL to be redirected to
     *
     * @returns {boolean}
     */
    hasSavedUrl() {
        return this.window.$cookies.isKey(REQUESTED_URL_COOKIE);
    }
}
