/* eslint-disable
    eqeqeq,
    no-undef,
    indent
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
let dictree = require('dictree');

angular.module('backstage.services')

    .factory('navsService', [
        '$q',
        '$filter',
        '$http',
        'eventService',
        'bulkUpdateService',
        'BACKSTAGE_NAV_SPOTMAN_LAUNCHER_PREFIX',
        'BACKSTAGE_NAV_SPOTMAN_SEPARATOR_PREFIX',
        function($q, $filter, $http, eventService, bulkUpdateService, BACKSTAGE_NAV_SPOTMAN_LAUNCHER_PREFIX, BACKSTAGE_NAV_SPOTMAN_SEPARATOR_PREFIX) {

            let navsService;
            let OVERRIDE_NAV_BIT_ID_PREFIX = 'bstg-nav-bit:';
            let OVERRIDE_NAV_BIT_TYPE = 'nav-bit:nav_spotman';
            let OVERRIDE_NAV_BIT_PRIORITY = 'highest';
            let OVERRIDE_NAV_BIT_OWNER = 'private';

            let generateNavCompileFns = function(expectNavId) {
                let fullFn = function(eventId, navId, doDryRun, enableBitPruning) {
                    if (doDryRun == null) { doDryRun = false; }
                    if (enableBitPruning == null) { enableBitPruning = true; }
                    let method = doDryRun ? 'get' : 'post';
                    let route = expectNavId ? `/nav/${navId}` : '/navs';
                    let query = enableBitPruning ? '' : '?prune=false';

                    return $http[method](`/api/v1/eid/${eventId}/compile${route}${query}`);
                };

                if (expectNavId) { return fullFn; } else { return (eventId, doDryRun, enableBitPruning, eventNode) => fullFn(eventId, null, doDryRun, enableBitPruning, eventNode); }
            };

            let getNavOverrideBitId = navId => `${OVERRIDE_NAV_BIT_ID_PREFIX}-${navId}`;

            // # # # #
            // public
            // # # # #

            return _.extend((navsService = {}), {

                compileAllNavs: generateNavCompileFns(false),

                compileSingleNav: generateNavCompileFns(true),

                fetchAllNavs(eventId, enableBitPruning) {
                    return generateNavCompileFns(false)(eventId, true, enableBitPruning);
                },

                fetchSingleNav(eventId, navId, enableBitPruning) {
                    return generateNavCompileFns(true)(eventId, navId, true, enableBitPruning);
                },

                fetchMultipleNavs(eventId, navIds, enableBitPruning) {
                    let promises = navIds.map(navId => navsService.fetchSingleNav(eventId, navId, enableBitPruning));

                    return $q.all(promises).then(results => _.pluck(results, 'data').map(_.first));
                },

                fetchOverrideNavBit(event, navId) {
                    if (navId == null) { navId = 'nav_spotman'; }
                    return eventService.getEventDocById(event, getNavOverrideBitId(navId))
                        .then(data => data != null ? data.data : undefined);
                },

                createOverrideNavBit(navDictOrList, navId) {
                    let _id = getNavOverrideBitId(navId);
                    let baseNavDict = {
                        _id,
                        fp_ext_id: _id,
                        fp_subtype: navId,
                        fp_bit_priority: OVERRIDE_NAV_BIT_PRIORITY,
                        fp_owner: OVERRIDE_NAV_BIT_OWNER
                    };

                    let navDict = !_.isArray(navDictOrList)
                        ? _.extend(_.clone(navDictOrList), baseNavDict)
                        :
                        navDictOrList.reduce(function(dict, item, idx) {
                            if (!dict.el) { dict.el = {}; }
                            dict.el[item._id] = item;
                            dict.el[item._id].order = idx;
                            return dict;
                        }
                            , baseNavDict);

                    navDict.el._to_array = true;
                    return navDict;
                },

                updateOverrideNavBit(eventId, eventNode, navBit, navId, navBitType) {
                    // ensure the override bit contains required fields
                    if (navBitType == null) { navBitType = OVERRIDE_NAV_BIT_TYPE; }
                    navBit._id = getNavOverrideBitId(navId);
                    navBit.fp_subtype = navId;
                    navBit.fp_bit_priority = 'highest';
                    // save the override bit to the db
                    return bulkUpdateService.createDocs(eventId, eventNode, navBitType, navBit, { keepId: true });
                },

                updateOverrideNavBitWithNavItems(eventId, eventNode, navDictOrList, navId) {
                    let navBit = navsService.createOverrideNavBit(navDictOrList, navId);

                    return navsService.updateOverrideNavBit(eventId, eventNode, navBit, navId);
                },

                // legacy
                pickOverrideFieldsFromNavItem(navItem) {
                    let nav = navItem; // shorthand
                    let _nav = _.pick(navItem, '_id', 'rowHeight', 'order', '_to_prune', '_custom_editor');

                    _nav._to_prune = _nav._to_prune ? true : false; // booleanize it
                    // https://github.com/Spotme/backstage-app/issues/66
                    if ((nav.ds != null ? nav.ds.type : undefined) !== 'enum') { return _nav; }
                    if ((nav.ds != null ? nav.ds.source : undefined) && (nav.ds.source.title || nav.ds.source.img)) {
                        if (!_nav.ds) { _nav.ds = { type: 'enum' }; }
                        if (!_nav.ds.source) { _nav.ds.source = {}; }
                        _nav.ds.source.title = (nav.ds.source.title != null ? nav.ds.source.title.length : undefined) ? nav.ds.source.title : null;
                        _nav.ds.source.img = (nav.ds.source.img != null ? nav.ds.source.img.length : undefined) ? nav.ds.source.img : null;
                    }
                    if ((nav.render != null ? nav.render.mapping : undefined) && (nav.render.mapping.title || nav.render.mapping.img)) {
                        if (!_nav.render) { _nav.render = {}; }
                        _nav.render.mapping = _.pick(nav.render.mapping, 'title', 'img');
                    }
                    return _nav;
                },

                isNavItemOfEnumType(navItem) {
                    return (navItem.ds != null ? navItem.ds.type : undefined) === 'enum';
                },

                isActivatedPersonItem(navItem) {
                    let cond1 = __guard__(__guard__(navItem.render != null ? navItem.render.mapping : undefined, x1 => x1.title), x => x.indexOf('{{#activatedperson}}')) === 0;
                    let cond2 = (navItem._id != null ? navItem._id.indexOf('owner_row') : undefined) === 0;

                    return cond1 || cond2;
                },

                isFlushReplicationsRowItem(navItem) {
                    return navItem._id === 'flushreplications_row';
                },

                isEditRestrictedItem(navItem) {
                    return navsService.isActivatedPersonItem(navItem) || navsService.isFlushReplicationsRowItem(navItem);
                },

                isNavItemBackstageCreated(navItem) {
                    for (let test of [BACKSTAGE_NAV_SPOTMAN_LAUNCHER_PREFIX, BACKSTAGE_NAV_SPOTMAN_SEPARATOR_PREFIX]) {
                        if (navItem._id.indexOf(test) > -1) { return true; }
                    }
                    return false;
                },

                isNavItemBackstageIgnored(navItem) {
                    return !!navItem._bstg_ignored;
                },

                getNavItemBackstageManagedIn(navItem) {
                    return navItem._bstg_managed_in || null;
                },

                getOverrideNavItems(navs) {
                    return _.chain(navs)
                        .sortBy('order')
                        .map(function(nav) {
                            // we can leave navs that were created by backstage as they are
                            // (i.e. whole in the override bit)
                            if (navsService.isNavItemBackstageCreated(nav)) { return nav; }
                            return navsService.pickOverrideFieldsFromNavItem(nav); }).compact()
                        .value();
                },

                getOverrideNavBitFromDiff(navId, localNavsList, existingFullNav, existingNavBit) {
                    if (existingFullNav == null) { existingFullNav = {}; }
                    if (existingNavBit == null) { existingNavBit = {}; }
                    localNavsList = _.sortBy(localNavsList, 'order'); // order sort
                    // the existingNavBit might be an array or an object. handle both.
                    let existingNavDict = existingNavBit.el || existingNavBit;
                    let existingFullDict =
            (existingFullNav.el || existingFullNav)
                .reduce(function(dict, item, idx) {
                    dict[item._id] = item;
                    // figure out ordering and translate it into ints here
                    dict[item._id].order = idx;
                    return dict;
                }
                    , {});
                    // 1. find all local changes vs compiled nav
                    let newNavBit = navsService.createOverrideNavBit(localNavsList, navId);
                    let newNavDict = newNavBit.el;
                    let changesDict = dictree.diff(newNavDict, existingFullDict);
                    // 2. replicate all `order` attrs from the existing nav list into the new one

                    _.forEach(existingFullDict, function(existingNavItem, key) {
                        if (!changesDict[key]) { changesDict[key] = {}; }
                        return changesDict[key].order || (changesDict[key].order = existingNavItem.order);
                    });
                    newNavBit.el = (newNavDict = changesDict);
                    // 3. use changes as the new bit when there's no existing bit
                    if (_.isEmpty(existingNavDict)) { return newNavBit; }
                    // 4. diff and merge existing and new nav dicts
                    angular.copy(dictree.merge([existingNavDict, changesDict]), existingNavDict);
                    return existingNavBit;
                },

                getNavItemTemplateForAssetLinkType(newAssetLinkType, newAssetLinkId, navItemId, extraOpts) {
                    if (extraOpts == null) { extraOpts = {}; }
                    let isNewAssetLinkOnlineOnly = extraOpts.online;
                    let isNewUrlLinkOpenExternal = extraOpts.external;
                    let newNavItemFn = (() => { switch (newAssetLinkType) {
                        case 'page': return navsService.generatePageLinkSpotManItemTemplate;
                        case 'can': return navsService.generateCanLinkSpotManItemTemplate;
                        case 'can_asset':
                            if (isNewAssetLinkOnlineOnly) { return navsService.generateOnlineAssetLinkSpotManItemTemplate;
                            } else { return navsService.generateAssetLinkSpotManItemTemplate; }
                        case 'shelf': return navsService.generateShelfLinkSpotManItemTemplate;
                        case 'url':
                            if (isNewUrlLinkOpenExternal) { return navsService.generateUrlExternalLinkSpotManItemTemplate;
                            } else { return navsService.generateUrlInAppLinkSpotManItemTemplate; }
                        } })();

                    return newNavItemFn(newAssetLinkId, navItemId);
                },


                // ⚑
                // TODO: Remove legacy methods (replaced in new version of the spotman menu editor)

                // all following functions are templated nav item generators

                generateAutoGeneratedSpotManNavItemId() {
                    return `${BACKSTAGE_NAV_SPOTMAN_LAUNCHER_PREFIX}_${new Date().getTime()}_row`;
                },

                generateUrlInAppLinkSpotManItemTemplate(url, itemId) {
                    return {
                        _id: itemId || bulkUpdateService.generateAutoGeneratedValueString(),
                        navtype: 'list_nav',
                        decoration: 'plain',
                        rowHeight: 39,
                        ds: {
                            type: 'enum',
                            source: {
                                title: 'My New URL Link',
                                img: 'icon.spotman.cactus'
                            }
                        },
                        render: {
                            navtype: 'spotmanitemrow_nav',
                            mapping: {
                                title: '{{#spotme.tr}}{{{title}}}{{/spotme.tr}}',
                                img: '{{couchbase_url}}/{{default_db}}/{{{img}}}/file.png'
                            }
                        },
                        on: [{
                            event: 'tap',
                            target: 'spotme://shownav?{{#urlescape}}{"_id":"nav_home1", "animated":"false"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target: 'spotme://showhome?{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target: 'spotme://hidespotman?{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target: 'spotme://opennav?' +
                        '{{#urlescape}}' +
                            '{"_id":"nav_wv_external", ' +
                                `"url":"${url}", "title":"{{{title}}}", "fullscreen":true, "scalesPageToFit":true` +
                            '}' +
                        '{{/urlescape}}'
                        }
                        ]
                    };
                },

                generateUrlExternalLinkSpotManItemTemplate(url, itemId) {
                    return {
                        _id: itemId || bulkUpdateService.generateAutoGeneratedValueString(),
                        navtype: 'list_nav',
                        decoration: 'plain',
                        rowHeight: 39,
                        ds: {
                            type: 'enum',
                            source: {
                                title: 'My New External URL Link',
                                img: 'icon.spotman.cactus'
                            }
                        },
                        render: {
                            navtype: 'spotmanitemrow_nav',
                            mapping: {
                                title: '{{#spotme.tr}}{{{title}}}{{/spotme.tr}}',
                                img: '{{couchbase_url}}/{{default_db}}/{{img}}/file.png'
                            }
                        },
                        on: [{
                            event: 'tap',
                            target: url
                        }
                        ]
                    };
                },

                generateShelfLinkSpotManItemTemplate(shelfFpExtId, itemId) {
                    return {
                        _id: itemId || bulkUpdateService.generateAutoGeneratedValueString(),
                        navtype: 'list_nav',
                        decoration: 'plain',
                        rowHeight: 39,
                        ds: {
                            type: 'enum',
                            source: {
                                title: 'My New Shelf Link',
                                img: 'icon.spotman.help'
                            }
                        },
                        render: {
                            navtype: 'spotmanitemrow_nav',
                            mapping: {
                                title: '{{#spotme.tr}}{{{title}}}{{/spotme.tr}}',
                                img: '{{couchbase_url}}/{{default_db}}/{{{img}}}/file.png'
                            }
                        },
                        on: [{
                            event: 'tap',
                            target:
                    'spotme://shownav?' +
                    '{{#urlescape}}{"_id":"nav_home1","animated":"false"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://showhome?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://hidespotman?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://opennav?' +
                    '{{#urlescape}}' +
                        '{"_id":"nav_spotlet", ' +
                        `"onloadjs":"load_spotlet({spotlet_name: 'basicapps', path: 'shelf?fp_ext_id=${shelfFpExtId}&fp_type=shelf'})", ` +
                        '"title":"{{{title}}}" }' +
                    '{{/urlescape}}'
                        }
                        ]
                    };
                },

                generatePageLinkSpotManItemTemplate(pageFpExtId, itemId) {
                    return {
                        _id: itemId || bulkUpdateService.generateAutoGeneratedValueString(),
                        navtype: 'list_nav',
                        decoration: 'plain',
                        rowHeight: 39,
                        ds: {
                            type: 'enum',
                            source: {
                                title: 'My New Page Link',
                                img: 'icon.spotman.help'
                            }
                        },
                        render: {
                            navtype: 'spotmanitemrow_nav',
                            mapping: {
                                title: '{{#spotme.tr}}{{{title}}}{{/spotme.tr}}',
                                img: '{{couchbase_url}}/{{default_db}}/{{{img}}}/file.png'
                            }
                        },
                        on: [{
                            event: 'tap',
                            target:
                    'spotme://shownav?' +
                    '{{#urlescape}}{"_id":"nav_home1","animated":"false"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://showhome?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://hidespotman?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://opennav?' +
                    '{{#urlescape}}' +
                        '{"_id":"nav_spotlet", ' +
                        `"onloadjs":"load_spotlet({spotlet_name: 'basicapps', path: 'page?fp_ext_id=${pageFpExtId}&seed={{currenttimestamp}}'})", ` +
                        '"title":"{{{title}}}", ' +
                        '"topbarhidden": false, ' +
                        '"cache" : false }' +
                    '{{/urlescape}}'
                        }
                        ]
                    };
                },

                generateCanLinkSpotManItemTemplate(canFpExtId, itemId) {
                    return {
                        _id: itemId || bulkUpdateService.generateAutoGeneratedValueString(),
                        navtype: 'list_nav',
                        decoration: 'plain',
                        rowHeight: 39,
                        ds: {
                            type: 'enum',
                            source: {
                                title: 'My New Can Link',
                                img: 'icon.spotman.help'
                            }
                        },
                        render: {
                            navtype: 'spotmanitemrow_nav',
                            mapping: {
                                title: '{{#spotme.tr}}{{{title}}}{{/spotme.tr}}',
                                img: '{{couchbase_url}}/{{default_db}}/{{{img}}}/file.png'
                            }
                        },
                        on: [{
                            event: 'tap',
                            target:
                    'spotme://shownav?' +
                    '{{#urlescape}}{"_id":"nav_home1","animated":"false"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://showhome?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://hidespotman?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://opennav?' +
                    '{{#urlescape}}' +
                        '{"_id":"nav_spotlet", ' +
                        `"onloadjs":"load_spotlet({spotlet_name: 'basicapps', path: 'can?fp_ext_id=${canFpExtId}&seed={{currenttimestamp}}'})", ` +
                        '"cache" : false, ' +
                        '"title":"{{{title}}}" }' +
                    '{{/urlescape}}'
                        }
                        ]
                    };
                },

                generateOnlineAssetLinkSpotManItemTemplate(fpAssetPath, itemId) {
                    return {
                        _id: itemId || bulkUpdateService.generateAutoGeneratedValueString(),
                        navtype: 'list_nav',
                        decoration: 'plain',
                        rowHeight: 39,
                        ds: {
                            type: 'enum',
                            source: {
                                title: 'My New Online Asset Link',
                                img: 'icon.spotman.help'
                            }
                        },
                        render: {
                            navtype: 'spotmanitemrow_nav',
                            mapping: {
                                title: '{{#spotme.tr}}{{{title}}}{{/spotme.tr}}',
                                img: '{{couchbase_url}}/{{default_db}}/{{{img}}}/file.png'
                            }
                        },
                        on: [{
                            event: 'tap',
                            target:
                    'spotme://shownav?' +
                    '{{#urlescape}}{"_id":"nav_home1","animated":"false"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://showhome?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://hidespotman?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://opennav?' +
                    '{{#urlescape}}' +
                        '{"_id":"nav_document", ' +
                        `"doc_location": "{{cloudnodeurl}}/${fpAssetPath}", ` +
                        '"title":"{{title}}" }' +
                    '{{/urlescape}}'
                        }
                        ]
                    };
                },

                generateAssetLinkSpotManItemTemplate(fpAssetPath, itemId) {
                    return {
                        _id: itemId || bulkUpdateService.generateAutoGeneratedValueString(),
                        navtype: 'list_nav',
                        decoration: 'plain',
                        rowHeight: 39,
                        ds: {
                            type: 'enum',
                            source: {
                                title: 'My New Asset Link',
                                img: 'icon.spotman.notes'
                            }
                        },
                        render: {
                            navtype: 'spotmanitemrow_nav',
                            mapping: {
                                title: '{{#spotme.tr}}{{{title}}}{{/spotme.tr}}',
                                img: '{{couchbase_url}}/{{default_db}}/{{{img}}}/file.png'
                            }
                        },
                        on: [{
                            event: 'tap',
                            target:
                    'spotme://shownav?' +
                    '{{#urlescape}}{"_id":"nav_home1","animated":"false"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://showhome?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://hidespotman?' +
                    '{{#urlescape}}{"animated":"true"}{{/urlescape}}'
                        },
                             {
                            event: 'tap',
                            target:
                    'spotme://opennav?' +
                    '{{#urlescape}}' +
                        '{"_id":"nav_document", ' +
                        `"doc_location": "{{couchbase_url}}/{{default_db}}/${fpAssetPath}", ` +
                        '"title":"{{title}}" }' +
                    '{{/urlescape}}'
                        }
                        ]
                    };
                }
            });
        }
    ]);

function __guard__(value, transform) {
    return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
