"use strict";

var _constants = require("libs/utils/constants");
var _url = require("libs/utils/url");
var _workspaces = require("libs/utils/workspaces");
var EventService = /* @ngInject */function EventService(CENTRAL_BACKSTAGE_URL, USER_SERVICE_ENDPOINT, EVENT_SERVICE_ENDPOINT, BACKSTAGE_SKELETON_PACKAGE_NAME, $routeParams, $rootScope, $q, $http, urlService, timeService, handlerService, taskSeriesService, taskExecutorService, userService) {
  var EVENT_DATE_FORMAT = 'YYYY-MM-DD';
  var setTimestampsInEventDocDescriptionHash = function setTimestampsInEventDocDescriptionHash(event) {
    var tz = event ? event.timezone : undefined;
    if (!tz) {
      return;
    }
    if (!event.description) {
      event.description = {};
    }

    // use a custom date format for proper timestamp at beginning and end of day
    var dateFormat = EVENT_DATE_FORMAT + ' HH:mm';
    if (event.startdate) {
      event.description.start_time = timeService.convertDateStringToUTCTimestamp("".concat(event.startdate), tz, dateFormat);
    }
    if (event.enddate) {
      event.description.end_time = timeService.convertDateStringToUTCTimestamp("".concat(event.enddate), tz, dateFormat);
    }
  };
  var serializeEventAttributes = function serializeEventAttributes(event) {
    var serializedEvent = _.extend({},
    // omit renamed keys
    _.omit(event, 'ownerId'),
    // renamed keys
    {
      bs_owner_id: event.ownerId
    });
    setTimestampsInEventDocDescriptionHash(serializedEvent);
    return serializedEvent;
  };
  var prependRouterRootPathIfOnCloud = urlService.prependRouterRootPathIfOnCloud;

  // for backward compatibility and performance, add a in memory cache of
  // getEventDocByFpExtId, should be replaced at some stage by a proper
  // layer of access for a given fpType
  var byEventAndTypeCache = {};

  // # # # #
  // public
  // # # # #

  var eventService = {
    // scope and state
    decorateScope: function decorateScope(eventId) {
      if (!eventId) {
        eventId = $routeParams.eventId;
      }
      if (!eventId) {
        return;
      }
      return $rootScope.event;
    },
    cleanScope: function cleanScope() {
      var skipNavigation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return $rootScope.returnToHolodeck(skipNavigation);
    },
    init: function init(eventId, user) {
      if (!eventId) return $q.resolve();
      return eventService.getEvent(eventId, user).then(function (event) {
        $rootScope.event = event;
      });
    },
    // event manipulation
    createEvent: function createEvent(event, createFromBaseTemplate, supplementaryTasks) {
      // not ideal but it's what we had in the ole days
      if (!createFromBaseTemplate) {
        createFromBaseTemplate = false;
      }
      if (!event.node) {
        event.node = event.cloudnode;
      }

      // createFromBaseTemplate is appended as a query param as it's not part of the event doc
      var querySuffix = '?createFromBaseTemplate=' + createFromBaseTemplate ? 'true' : 'false';

      // https://github.com/Spotme/backstage-app/issues/42
      setTimestampsInEventDocDescriptionHash(event);
      var findEventCloudNode = function findEventCloudNode(event) {
        Object.keys(event.nodes).filter(function (node) {
          return event.nodes[node].is_root;
        })[0];
      };
      var taskNames = ['Creating the event instance', 'Adding the user access permission', 'Installing the backstage-skeleton package', 'Refreshing user session'];
      var _event = null;
      var createdEvent = null;
      var taskFns = [
      // step 1 - create the instance
      function () {
        return $http.post(EVENT_SERVICE_ENDPOINT + querySuffix, event, {
          withCredentials: true
        }).then(function (data) {
          createdEvent = data.data;
          return {
            id: data.data._id
          };
        });
      },
      // step 2 - assign access perm.
      function (resp) {
        // assign access permissions through the /users/ API
        var data = {
          id: resp.id,
          access: 'manager'
        };
        return $http.post("".concat(USER_SERVICE_ENDPOINT, "/").concat(userService.getCurrentUser().name, "/events"), data, {
          withCredentials: true
        }).then(function () {
          var node = findEventCloudNode(createdEvent);
          return $http.post((0, _url.getBackstageURL)(node, "/api/v1/node/instance/".concat(resp.id, "/replicate-event-users")), {}, {
            withCredentials: true
          });
        }).then(function () {
          return resp;
        });
      },
      // step 3 - install backstage-skeleton package
      function (resp) {
        // we branch off the event object here because we don't want to keep hold of the id if subsequent calls fail
        _event = _.clone(event);
        _event.cloudnode = findEventCloudNode(createdEvent);
        _event.id = _event._id = resp.id;
        _event.node = _event.cloudnode;
        return eventService.installBackstageSkeletonPackage(_event).then(function () {
          return _event;
        });
      }];
      var tasks = taskSeriesService.createTaskSeries(taskFns, taskNames);
      if (supplementaryTasks) {
        tasks = tasks.concat(supplementaryTasks);
      }
      return taskExecutorService.executeTaskSeries(tasks);
    },
    saveEvent: function saveEvent(event) {
      return $http.post("".concat(EVENT_SERVICE_ENDPOINT, "/").concat(event.id), serializeEventAttributes(event), {
        withCredentials: true
      });
    },
    convertToTemplate: function convertToTemplate(event) {
      return window.BSTG.services.event.convertToTemplate(event);
    },
    archiveEvent: function archiveEvent(eventId, node) {
      return window.BSTG.services.event.archiveEvent(eventId, node);
    },
    convertWorkspaceToProduction: function convertWorkspaceToProduction(event) {
      window.BSTG.services.event.convertWorkspaceToProduction(event);
    },
    setEventLock: function setEventLock(event) {
      var locked = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var actingUser = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var postData = {
        locked: locked
      };
      var lockedBy;
      if (locked) {
        lockedBy = postData.lockedBy = actingUser.name;
      }
      return $http.post(EVENT_SERVICE_ENDPOINT + "/".concat(event._id), postData, {
        withCredentials: true
      }).then(function () {
        event.locked = locked;
        event.lockedBy = lockedBy;
        return [locked, lockedBy];
      });
    },
    getEventInstanceNodeName: function getEventInstanceNodeName() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _workspaces.getRootNodeName)(event);
    },
    // Team members
    addEventTeamMember: function addEventTeamMember(eventOrEventId) {
      var username = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var role = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'viewer';
      var eventNodeName = arguments.length > 3 ? arguments[3] : undefined;
      var url = eventNodeName ? (0, _url.getBackstageURL)(eventNodeName, _constants.EVENT_ADD_TO_TEAM_ENDPOINT) : _constants.EVENT_ADD_TO_TEAM_ENDPOINT;
      url = url.replace('{{email}}', username.toLowerCase()).replace('{{nodeId}}', eventNodeName);
      var data = {
        eid: _.isString(eventOrEventId) ? eventOrEventId : eventOrEventId ? eventOrEventId._id : undefined,
        access: role
      };

      // this has to be called on the cloud node
      return $http.post(url, data, {
        withCredentials: true
      });
    },
    removeTeamMember: function removeTeamMember(event) {
      var username = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return window.BSTG.services.event.removeTeamMember(event._id, username);
    },
    getEventTeamMembers: function getEventTeamMembers() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return window.BSTG.services.event.getTeamMembers(event._id, true);
    },
    // events lookup
    /**
     * Get the adapted event document of targeted event
     * And build event roles for user
     *
     * @param {string} eventId
     * @param {Object} [user]
     * @return {Promise}
     */
    getEvent: function getEvent(eventId, user) {
      return window.BSTG.services.event.getEvent(eventId, user || userService.getCurrentUser());
    },
    getCurrentEvent: function getCurrentEvent() {
      return $rootScope.event;
    },
    /**
     * Get all the events for a user
     *
     * @param {Object} user
     * @return {Promise}
     */
    getEventInstancesForUser: function getEventInstancesForUser(user) {
      return window.BSTG.services.event.getEventInstancesForUser(user);
    },
    // the APIs below should be moved to appropriate services
    compilei18n: function compilei18n(eventId) {
      return $http.post("/api/v1/eid/".concat(eventId, "/compile/i18n"));
    },
    getEventAppIconImageUrl: function getEventAppIconImageUrl() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return window.BSTG.services.event.getAppIconUrl(event);
    },
    sendActivationEmails: function sendActivationEmails(event, participants, templateExtId) {
      var data = {
        fp_ext_ids: _.pluck(participants, 'fp_ext_id')
      };
      var url = prependRouterRootPathIfOnCloud(event.node, "/api/v1/eid/".concat(event._id, "/email/template/").concat(templateExtId));
      return $http.post(url, data, {
        withCredentials: true
      });
    },
    installBackstageSkeletonPackage: function installBackstageSkeletonPackage() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return $http.post(prependRouterRootPathIfOnCloud(event.node, "/api/v1/packages/install/".concat(BACKSTAGE_SKELETON_PACKAGE_NAME, "/event/").concat(event._id)), {
        withCredentials: true
      });
    },
    // Event specific APIS: sessions, participants. Should not be here either, but in packages.
    getEventSessions: function getEventSessions() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'get';
      var queryParamSuffix = limit ? "?limit=".concat(limit) : '';
      return $http[method](prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/sessions").concat(queryParamSuffix)), {
        withCredentials: true
      });
    },
    // TO DEPRECATE ONCE ALL EVENTS MIGRATED TO LATEST AGENDA PACKAGE VERSION
    // (notably because the registration/capacity handler is in the agenda package...)
    getEventSessionCapacityInfo: function getEventSessionCapacityInfo(event, sessionFpExtId) {
      return handlerService.callHandler(event._id, 'registration/capacity', {
        sid: sessionFpExtId
      });
    },
    getEventParticipants: function getEventParticipants() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var limit = arguments.length > 1 ? arguments[1] : undefined;
      var queryParamSuffix = limit ? "?limit=".concat(limit) : '';
      return $http.get(prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/participants").concat(queryParamSuffix)), {
        withCredentials: true
      });
    },
    getEventParticipantsInfo: function getEventParticipantsInfo() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'get';
      return $http[method](prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/paxinfo")), {
        withCredentials: true
      });
    },
    getEventParticipantInfoAndTimeline: function getEventParticipantInfoAndTimeline() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var participantId = arguments.length > 1 ? arguments[1] : undefined;
      // ⚑
      // TODO: Cleaner construction
      return $http.get(prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/paxinfo/").concat(participantId)), {
        withCredentials: true
      });
    },
    getEventParticipantActCode: function getEventParticipantActCode() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var participantId = arguments.length > 1 ? arguments[1] : undefined;
      return $http.get(prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/paxinfo/").concat(participantId, "/actcode")), {
        withCredentials: true
      });
    },
    getEventParticipantsCount: function getEventParticipantsCount(event) {
      var deferred = $q.defer();
      // this handler might or might not exist, let's give it a try
      var handler = 'bstg-xl-events/pax-counter';
      $http.get(handlerService.generateRemoteHandlerUrl(event.id, event.node, handler), {
        withCredentials: true
      }).then(function (resp) {
        return deferred.resolve(resp.data.totalParticipants);
      }, function () {
        // error !! use legacy method
        // fetch and populate total pax; use HEAD method for a "quicker" response
        return eventService.getEventParticipantsInfo(event, 'head').then(function (resp) {
          var totalPax = parseInt(resp.headers('x-total-records') || 0);
          return deferred.resolve(totalPax);
        }, deferred.reject);
      });
      return deferred.promise;
    },
    getEventSessionsCount: function getEventSessionsCount(event) {
      var deferred = $q.defer();
      eventService.getEventSessions(event, null, 'head').then(function (resp) {
        var totalSessions = parseInt(resp.headers('x-total-records') || 0);
        return deferred.resolve(totalSessions);
      }, deferred.reject);
      return deferred.promise;
    },
    // event API
    getEventMetadata: function getEventMetadata(eventOrEventId, eventNode) {
      // support for several argument forms
      var eventId = eventOrEventId;
      if (_.isObject(eventOrEventId)) {
        eventNode = event.node;
        eventId = event._id;
      }
      var url = eventNode ? (0, _url.getBackstageURL)(eventNode, "/api/v1/events/".concat(eventId, "/metadata")) : "/api/v1/events/".concat(eventId, "/metadata");
      return $http.get(url, {
        withCredentials: true
      });
    },
    getEventCogsConfigs: function getEventCogsConfigs() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return $http.get(prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/cogsconfigs")), {
        withCredentials: true
      });
    },
    getEventDocById: function getEventDocById() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var docId = arguments.length > 1 ? arguments[1] : undefined;
      if (!docId || !docId.length) {
        throw Error('Expecting a `docId` argument here');
      }
      return $http.get(prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/docbyid/").concat(docId)), {
        withCredentials: true
      });
    },
    getEventDocByFpExtId: function getEventDocByFpExtId(eventOrEventId, docFpExtId, docFpType) {
      if (!docFpExtId || !docFpExtId.length) {
        throw Error('Expecting a `docFpExtId` argument here');
      }
      var eventId = _.isObject(eventOrEventId) ? eventOrEventId._id : eventOrEventId;
      if (docFpType) {
        return handlerService.callCouchView(eventId, 'dl', 'extidstype', {
          key: [docFpType, docFpExtId]
        }).then(function (rows) {
          return ((rows || [])[0] || {}).value;
        });
      }
      console.warn('Calling getEventDocByFpExtId without a fp_type might return multiple values');
      return handlerService.callCouchView(eventId, 'dl', 'extids', {
        key: docFpExtId
      }).then(function (rows) {
        return ((rows || [])[0] || {}).value;
      });
    },
    getEventDocsByType: function getEventDocsByType() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var fpType = arguments.length > 1 ? arguments[1] : undefined;
      var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        _ref$fields = _ref.fields,
        fields = _ref$fields === void 0 ? [] : _ref$fields,
        skip = _ref.skip,
        limit = _ref.limit,
        cached = _ref.cached;
      if (!event._id || !fpType) {
        return $q.reject('Missing event id or fp type');
      }
      var cacheKey = "".concat(event._id, ":").concat(fpType, ":").concat(fields.join('-'), ":").concat(skip, ":").concat(limit);
      if (cached && byEventAndTypeCache[cacheKey]) {
        return byEventAndTypeCache[cacheKey];
      }
      var params = {};
      if (limit) {
        params['limit'] = limit;
      }
      if (skip) {
        params['skip'] = skip;
      }
      if (fields && fields.length) {
        params['field'] = fields;
      }
      var call = $http.get(prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/docsbytype/").concat(fpType)), {
        params: params,
        withCredentials: true
      });

      // save it for later if needed
      byEventAndTypeCache[cacheKey] = call;
      return call;
    },
    getEventEmailTemplates: function getEventEmailTemplates() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return $http.get(prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/emailTemplates")), {
        withCredentials: true
      });
    },
    removeDocById: function removeDocById() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var docId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return $http.delete(prependRouterRootPathIfOnCloud(event.node, "/api/v1/events/".concat(event._id, "/docbyid/").concat(docId)), {
        withCredentials: true
      });
    },
    // event urls
    generateGeoOptimalEventUrl: function generateGeoOptimalEventUrl(event) {
      var suffixPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var protocol = arguments.length > 2 ? arguments[2] : undefined;
      return window.BSTG.services.event.getEventUrl(event, suffixPath, protocol);
    },
    /**
     * Gets the correct event name
     *
     * @param {Object} event the event to get the title of
     * @param {String} [locale] the locale to use for the name
     *
     * @return {String} the event name
     */
    getName: function getName(event, locale) {
      return window.BSTG.services.event.getName(event, locale);
    }
  };
  return eventService;
};
EventService.$inject = ["CENTRAL_BACKSTAGE_URL", "USER_SERVICE_ENDPOINT", "EVENT_SERVICE_ENDPOINT", "BACKSTAGE_SKELETON_PACKAGE_NAME", "$routeParams", "$rootScope", "$q", "$http", "urlService", "timeService", "handlerService", "taskSeriesService", "taskExecutorService", "userService"];
EventService.$inject = ["CENTRAL_BACKSTAGE_URL", "USER_SERVICE_ENDPOINT", "EVENT_SERVICE_ENDPOINT", "BACKSTAGE_SKELETON_PACKAGE_NAME", "$routeParams", "$rootScope", "$q", "$http", "urlService", "timeService", "handlerService", "taskSeriesService", "taskExecutorService", "userService"];
angular.module('backstage.services').factory('eventService', EventService);