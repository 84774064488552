const moment = require('moment-timezone');

import { getBackstageURL } from 'libs/utils/url';

const createEventButtonModule = angular.module('backstage.components.events.create-event-button', []);

createEventButtonModule.factory('EventCreationJobListener', [
    '$timeout',
    '$http',
    function($timeout, $http) {
        const POLL_DELAY = 1000;

        return class EventCreationJobListener {

            constructor(onComplete) {
                this.isRunning = false;
                this.progress = 0;
                this.positionInQueue = 0;
                this.state = 'scheduled';
                this.onComplete = onComplete;
            }

            startListening(jobData) {
                this.jobId = jobData.jobId;
                this.node = jobData.node;
                this.isRunning = true;
                this.nextTick();
            }

            stopListening() {
                this.isRunning = false;
            }

            nextTick() {
                return $http.get(getBackstageURL(this.node, `/api/v1/event-instance-creation-jobs/${this.jobId}`))
                    .then(response => {
                        var data = response.data || {};

                        this.progress = data.progress;
                        this.state = data.state;
                        this.positionInQueue = data.positionInQueue;
                        this.eid = data.eid;

                        if (this.eid && typeof this.onComplete === 'function') {
                            this.onComplete();
                        }
                    })
                    .catch(err => console.error('Error getting event-creation job\'s progress', err))
                    .finally(() => {
                        return $timeout(() => {
                            if (this.isRunning && !this.eid && this.state !== 'failed') {
                                requestAnimationFrame(() => this.nextTick());
                            }
                        }, POLL_DELAY);
                    });

            }
        };
    }
]);

createEventButtonModule.controller('CreateEventButtonController', class CreateEventButtonController {

    static get $inject() { return [
        '$http',
        '$rootScope',
        'modalService',
        'metricsService',
        'serverService',
        'EventCreationJobListener'
    ]; }

    constructor(
        $http,
        $rootScope,
        modalService,
        metricsService,
        serverService,
        EventCreationJobListener
    ) {
        this.$http = $http;
        this.$rootScope = $rootScope;
        this.modalService = modalService;
        this.metricsService = metricsService;
        this.serverService = serverService;
        this.eventCreationJob = new EventCreationJobListener(this.onEventCreationComplete.bind(this));
    }

    onEventCreationComplete() {
        this.progressModal.close();
        this.stopTracking();
        if (this.eventData.stage === 'production') {
            this.licensingModal = this.modalService.openModal({
                templateUrl: '/src/angular/components/events/create-event-button/create-event-button-licensing-modal.html',
                scope: { $ctrl: this }
            });
        } else {
            this.navigateToTheEvent();
        }
    }

    navigateToTheEvent() {
        this.$rootScope.navigateHard(this.$rootScope.getGeoOptimalEventUrl({
            _id: this.eventCreationJob.eid,
            node: this.eventCreationJob.node
        }));
    }

    onClick($event) {
        $event.preventDefault();
        this.progressModal = this.modalService.openModal({
            templateUrl: '/src/angular/components/events/create-event-button/create-event-button-progress-modal.html',
            scope: { $ctrl: this }
        });
        this.progressModal.finally(() => {
            this.eventCreationJob.stopListening();
        });
        this.startTracking()
            .then(() => this.addEventCreationToJobQueue())
            .then(response => this.eventCreationJob.startListening(response.data));
    }

    startTracking() {
        return this.serverService.getRootForGroup(this.eventData.serverGroup)
            .then((rootNode) => {
                this.trackingId = this.metricsService.startTracking('event_creation', {
                    node: rootNode,
                    bundle: this.bundle
                });
            });
    }

    stopTracking() {
        return this.metricsService.endTracking(this.trackingId, {
            eid: this.eventCreationJob.eid,
            ok: true
        });
    }

    get isButtonDisabled() {
        return (this.progressModal && this.progressModal.isOpen)
            || (this.licensingModal && this.licensingModal.isOpen);
    }

    $onDestroy() {
        return this.eventCreationJob.stopListening();
    }

    addEventCreationToJobQueue() {
        const jobConfig = angular.extend({}, this.eventData, { bundle: this.bundle });

        // event dates come in following format: Thu Sep 05 2019 00:00:00 GMT+0200 (Central European Summer Time)
        // it's a localized date that does not respect event's timezone
        // we strip the timezone and recreate the same date with the correct one
        const startDateString = moment(jobConfig.startdate).format('YYYY-MM-DD');
        const endDateString = moment(jobConfig.enddate).format('YYYY-MM-DD');
        const timezone = jobConfig.timezone;

        jobConfig.startdate = moment.tz(startDateString, timezone).format();
        jobConfig.enddate = moment.tz(endDateString, timezone).format();

        return this.$http.post('/api/v1/event-creation-jobs', jobConfig);
    }

    get isProdCreatedState() {
        return this.eventCreationJob.state === 'complete' && this.eventData.stage === 'production';
    }

});

const createEventButtonComponent = createEventButtonModule.component('bsCreateEventButton', {
    template: `
              <button class="bs-create-event-button btn btn-glow primary btn-next-unbound"
                      ng-disabled="$ctrl.isButtonDisabled"
                      ng-click="$ctrl.onClick($event)">
                  <ng-transclude></ng-transclude>
              </button>
    `,
    transclude: true,
    bindings: {
        eventData: '<',
        bundle: '<'
    },
    controller: 'CreateEventButtonController'
});

module.exports = createEventButtonComponent.name;

