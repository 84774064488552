// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
angular.module('backstage.directives.naveditors', [])

    .directive('bsNavEditorComponent', [() => {
    // all scope bindings here form the directive's attribute api
        return {
            scope: {
                navId: '=', // the nav (NOT the bit)'s id (for save) : string
                navBit: '=', // the nav bit itself : object
                formFields: '=', // the form fields as metadata : object || array
                templateUrl: '=', // the custom template url (optional) : string
                navEditorKey: '=', // the name of the editor used to build the nav bit in the first place : string
                builderOptions: '=', // the options hash that defines which service to call to build the bit : object
                omitButtonBar: '=', // omit button bar? (passed to standard form generator) : boolean
                renderedHeading: '=', // the mustache-rendered form heading (optional) : string
                headingTemplate: '@', // the mustache template for the above (optional) : text
                onBitSavedCallbackFn: '&'
            },
            // we can't use templateUrl with a function here cuz the function doesn't have access to scope
            template: `\
<form
        name="navEditorComponentForm"
        ng-include="getEditorFormTemplateUrl()"
        ng-submit="saveModel(model)"
        autocomplete="off">
</form>\
`,
            controller: 'NavEditorFormComponentCtrl'
        };
    }])

    .directive('bsMultiNavEditorComponent', [() => {
    // all scope bindings here form the directive's attribute api
        return {
            scope: {
                navEditorKey: '=', // the name of the editor used to build the nav bit in the first place : string
                navEditorConfig: '=', // the nav (NOT the bit)'s ids for save : array
                formFields: '=', // the form fields as metadata : object || array
                templateUrl: '=', // the custom template url (optional) : string
                builderOptions: '=', // the options hash that defines which service to call to build the bit : object
                omitButtonBar: '=', // omit button bar? (passed to standard form generator) : boolean
                onBitsSavedCallbackFn: '&'
            },
            // we can't use templateUrl with a function here cuz the function doesn't have access to scope
            templateUrl: '/static/partials/nav-editors/multi-nav-editor-component-directive-template.html',
            controller: 'MultiNavEditorFormComponentCtrl'
        };
    }]);
