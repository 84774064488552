"use strict";

module.exports = /* @ngInject */["btDnd", function (btDnd) {
  return {
    restrict: 'A',
    link: function link(scope, element, attrs) {
      var accepts = [];
      var onDrag;
      var onDrop;
      var onStopDrag;
      attrs.$observe('btDndAccepts', function (newVal) {
        accepts = scope.$eval(newVal);
      });
      attrs.$observe('btDndOnDrag', function (newVal) {
        onDrag = newVal;
      });
      attrs.$observe('btDndOnDrop', function (newVal) {
        onDrop = newVal;
      });
      attrs.$observe('btDndOnStopDrag', function (newVal) {
        onStopDrag = newVal;
      });
      element.addClass('bt-droppable');
      btDnd.on('drag', function (event, data) {
        event.preventDefault();
        var draggedElement = data.draggedElement;
        var keys = Object.keys(draggedElement.data);
        var isAccepted = _.intersection(keys, accepts).length > 0;
        var elOffset = element.offset();
        var elSize = {
          width: element.outerWidth(),
          height: element.outerHeight()
        };
        var isOver = data.x > elOffset.left && data.x < elOffset.left + elSize.width && data.y > elOffset.top && data.y < elOffset.top + elSize.height;
        if (isOver && isAccepted) {
          element.addClass('bt-drop-accepted');
          scope.$eval(onDrag, {
            draggedElement: data.draggedElement,
            x: data.x,
            y: data.y
          });
        }
        scope.$apply();
      });
      btDnd.on('drop', function (event, data) {
        event.preventDefault();
        scope.$eval(onDrop, {
          draggedElement: data.draggedElement,
          x: data.x,
          y: data.y
        });
        scope.$apply();
      });
      btDnd.on('stopDrag', function (event, data) {
        event.preventDefault();
        scope.$eval(onStopDrag, {
          draggedElement: data.draggedElement
        });
        scope.$apply();
      });
    }
  };
}];