import BaseContentService from './base-content';

// Constants
import {
    API_BASE_PATH,
} from 'libs/utils/constants';


/**
 * Provide service related to Content Library
 *
 * @example
 * import ContentLibraryService from 'libs/services/content-libray';
 * ...
 * const ContentLibraryService = new ContentLibraryService();
 */
export default class ContentLibraryService extends BaseContentService {

    /**
     * Getter to expose CONTENT_PER_PAGE from an instantiated service.
     */
    get CONTENTS_ENDPOINT() {
        return `${API_BASE_PATH}/event/{{eventId}}/contents`;
    }

}
