"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
module.exports = /* @ngInject */["$filter", function ($filter) {
  return {
    restrict: 'E',
    templateUrl: '/static/partials/apps/components/bundle-identifier-editor.html',
    scope: {
      branding: '='
    },
    link: function link(scope) {
      scope.vm = {
        model: {}
      };
      var capitalize = $filter('capitalize');
      var buildFields = function buildFields() {
        return scope.vm.fields = _.chain(scope.branding.binary_configurations).map(function (types, platform) {
          if (!_.isObject(types)) {
            return;
          }
          return _.map(types, function (config, type) {
            var key = "".concat(platform, "_").concat(type);
            var platformLabel = platform === 'ios' ? 'iOS' : platform;
            var typeLabel = type.length === 3 ? type.toUpperCase() : capitalize(type);
            if (platform === 'android' && type === 'standard') {
              typeLabel = '';
            }
            if (platform === 'ios') {
              // note: there is a special unicode character after the quotes
              // U+200C aka &zwnj; aka zero-width non-joiner, this prevents BSTG
              // from rendering 'iOS' as 'IOS'
              platformLabel = "\u200C".concat(platformLabel);
            }
            scope.vm.model[key] = config.bundle_id;
            return {
              field: "".concat(platform, "_").concat(type),
              label: "".concat(platformLabel, " ").concat(typeLabel)
            };
          });
        }).compact().flatten().value();
      };
      scope.$watch('branding', buildFields);
      scope.$watch('vm.model', function (model) {
        if (!model) {
          return;
        }
        var config = scope.branding.binary_configurations;
        return _.each(model, function (value, id) {
          var _Array$from = Array.from(id.split('_')),
            _Array$from2 = _slicedToArray(_Array$from, 2),
            platform = _Array$from2[0],
            type = _Array$from2[1];
          if (!config[platform]) {
            config[platform] = {};
          }
          if (!config[platform][type]) {
            config[platform][type] = {};
          }
          return config[platform][type] = {
            bundle_id: value
          };
        });
      }, true);
    }
  };
}];