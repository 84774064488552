"use strict";

module.exports = function (options) {
  var resolver = /* @ngInject */function resolver($route, eventService, navsService) {
    var eventId = eventService.getCurrentEvent()._id;
    var id = options.id;
    return navsService.fetchSingleNav(eventId, id, false);
  };
  resolver.$inject = ["$route", "eventService", "navsService"];
  return resolver;
};