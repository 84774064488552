// Classes
import BaseService from './base-service';
import { EventEmitter } from 'libs/utils/event-emitter';

export default class ObservableService extends BaseService {

    constructor() {
        super();

        this._eventEmitter = new EventEmitter();
    }

    /**
     * Adds a listener for an event
     *
     * @param {String} eventType Name of the event
     * @param {Function} listener Of shape: (payload: Object) => Any
     * @return {Function} Function to unsubscribe from the event. Of shape: () => Void
     */
    addEventListener(eventType, listener) {
        return this._eventEmitter.addEventListener(eventType, listener);
    }

    /**
     * Removes a listener for an event
     *
     * @param {String} eventType Name of the event
     * @param {Function} listener Of shape: (payload: Object) => Any
     */
    removeEventListener(eventType, listener) {
        return this._eventEmitter.removeEventListener(eventType, listener);
    }

    /**
     * Calls the event unsubscribe functions
     *
     * @param  {Function[]} unsubscribers Event unsubscribe functions. Of shape: () => Void
     */
    unsubscribe(unsubscribers) {
        return this._eventEmitter.unsubscribe(unsubscribers);
    }

    /**
     * Emits an event to all its listeners
     *
     * @param {String} eventType Name of the event
     * @param {any} [payload] What to send to the listeners alongside the event
     */
    emit(eventType, payload) {
        this._eventEmitter.emit(eventType, payload);
    }
}
