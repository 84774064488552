// Utils
import BaseService from './base-service';

import { API_BASE_PATH } from 'libs/utils/constants';

/**
 * API endpoint for getting sessions exclusion groups. Interpolation: `{{eventId}}`.
 * @const EXCLUSION_GROUPS_ENDPOINT
 * @private
 */
const EXCLUSION_GROUPS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/session-relations/mutually-exclusive`;

/**
 * API endpoint for getting sessions inclusion groups. Interpolation: `{{eventId}}`.
 * @const INCLUSION_GROUPS_ENDPOINT
 * @private
 */
const INCLUSION_GROUPS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/session-relations/mutually-inclusive`;


/**
 * Provides utils for getting or saving session relations information.
 *
 * @example
 * import SessionRelationsService from 'libs/services/session-relations';
 * ...
 * const settings = new SessionRelationsService();
 */
export default class SessionRelationsService extends BaseService {

    /**
     * Retrieves the exclusion groups for a given event.
     *
     * @param {string} eventId - The ID of the event.
     *
     * @returns {Promise<Array>} - A promise that resolves to an array of exclusion groups.
     */
    async getExclusionGroups(eventId) {
        const url = this.buildUrl(EXCLUSION_GROUPS_ENDPOINT, { eventId });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Saves exclusion groups for a specific event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {Array} exclusionGroups - The exclusion groups to be saved.
     *
     * @returns {Promise} A promise that resolves when the exclusion groups are saved.
     */
    async saveExclusionGroups(eventId, exclusionGroups) {
        const url = this.buildUrl(EXCLUSION_GROUPS_ENDPOINT, { eventId });
        await this.put(url, exclusionGroups);
    }

    /**
     * Retrieves the inclusion groups for a given event.
     *
     * @param {string} eventId - The ID of the event.
     *
     * @returns {Promise<Array>} - A promise that resolves to an array of inclusion groups.
     */
    async getInclusionGroups(eventId) {
        const url = this.buildUrl(INCLUSION_GROUPS_ENDPOINT, { eventId });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Saves inclusion groups for a specific event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {Array} inclusionGroups - The inclusion groups to be saved.
     *
     * @returns {Promise} A promise that resolves when the inclusion groups are saved.
     */
    async saveInclusionGroups(eventId, inclusionGroups) {
        const url = this.buildUrl(INCLUSION_GROUPS_ENDPOINT, { eventId });
        await this.put(url, inclusionGroups);
    }
}
