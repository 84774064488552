"use strict";

module.exports = function (options) {
  var resolver = /* @ngInject */function resolver($route, eventService) {
    var currentRouteDescriptor = $route.current ? $route.current.$$route : {};
    if (currentRouteDescriptor.controller === 'VueWrapperCtrl') {
      // This route is handled by vue, so we don't need to do anything here
      return;
    }
    var crudOpts = currentRouteDescriptor.crudOpts || {};
    var fpType = options.fpType,
      fields = options.fields,
      preferCachedListResponse = options.preferCachedListResponse;

    // fall back to looking in the route's crudOpts if an fp_type was not supplied as an arg
    var _fpType = fpType || crudOpts.queryFpType;
    if (!_fpType) {
      throw Error('an fpType must be provided for this strategy');
    }
    var event = eventService.getCurrentEvent();
    return eventService.getEventDocsByType(event, _fpType, {
      fields: fields,
      cached: preferCachedListResponse
    });
  };
  resolver.$inject = ["$route", "eventService"];
  return resolver;
};