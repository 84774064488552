const { isValidEmail } = require('libs/utils/string');
const { get } = require('lodash');

module.exports = [() => ({
    require: '?ngModel',
    scope: {
        'overwriteEmailValidator': '='
    },
    link: function(scope, elm, attrs, ctrl) {
        const isRequired = get(scope.overwriteEmailValidator, 'validations.required');

        if (ctrl && ctrl.$validators.email) {
            // this will overwrite the default AngularJS email validator
            ctrl.$validators.email = function(modelValue) {
                const normalizedValue = modelValue && modelValue.trim() || '';
                if (isRequired || normalizedValue.length > 0) {
                    return isValidEmail(modelValue);
                }

                return true;
            };
        }
    }
})];
