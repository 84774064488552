import { uniqueId } from 'lodash';
import EventService from './event';
import { DEFAULT_COMPANY } from 'libs/utils/constants';

const FORCE_TRACKING_PARAM = 'track';
const BLACK_LISTED_WORDS = ['token', 'email', 'password'];

/**
 * This service is meant to be used to track every event that happens on the backstage UI.
 *
 * At the time of writing our tracker service is appcue that provides the APIs reported
 * in the link.
 *
 * @example
 * import TrackingService from 'libs/services/tracking';
 * ...
 * const trk = new TrackingService();
 *
 * @requires EventService
 *
 */
export default class TrackingService {

    constructor() {
        this.eventService = new EventService();
        this.timed = {};
        this.isDev = ['dev', 'test'].includes(window.BSTG.env) && !this._checkForTrackingParam();
        this.initialized = false;
        this.user = null;
        this.cues = null;
        this.tourId = null;

        this.init();
    }

    /**
     * Sets the user details for tracking purpose.
     *
     * @param {Object} user the backstage user to use for tracking.
     * @param {Boolean} isAdmin whether the user is admin in any organization or not.
     */
    setUser(user, isAdmin) {
        this.user = user;

        const orgRole = isAdmin ? 'Admin' : 'Member';

        if (!this.isDev) {
            this._setupAppcues(orgRole);
        }
    }

    /**
     * Tries to initialize the tracker.
     *
     * If no forbidden words are detected, the tracker is then initialized.
     *
     * @returns {Promise<Boolean>} a Promise that represents the initialization status.
     */
    async init() {
        if (this.initialized) {
            return Promise.resolve(true);
        }

        // We stop initialization here if we hit a forbidden word because otherwise
        // sensitive data are sent to the third party service.
        if (!this.canTrack()) {
            return Promise.resolve(false);
        }

        return new Promise(resolve => {
            if (!this.isDev) {
                this._setupAppcues();
            }

            this.initialized = true;
            resolve(true);
        });
    }

    /**
     * Performs a check if the current url can be tracked or not based on a dictionary of black listed tokens.
     *
     * @param {String} [location=window.location.href] the path to track.
     * @returns {Boolean} true if the current url is safe, false otherwise
     */
    canTrack(location = window.location.href) {
        const foundTokens = BLACK_LISTED_WORDS.find(w => location.indexOf(w) !== -1);
        const canTrack = !foundTokens;

        if (!canTrack) {
            console.warn('[TrackingService] Protected word detected.');
        }

        return canTrack;
    }

    /**
     * Tracks an event to analytics service.
     *
     * @param {String} event the occured event to track.
     * @param {Object} [properties] additional properties to append on the tracker.
     *
     * @returns {Promise<>} a promise
     */
    async track(event, properties = {}) {
        if (!this.canTrack()) return;

        // We run the (re)init here. Might have been skipped
        // because of a black listed word.
        await this.init();

        const eid = this.eventService.getCurrentEventIdFromUrl();

        if (eid) properties.eid = eid;

        if (this.cues) {
            await this.cues.track(event, properties);
        }

        console.log('[TrackingService] Perform tracking on %s', event, properties);

        return {
            event,
            properties
        };
    }

    /**
     * Starts the tracking of a timed event.
     *
     * @param {String} event the that started the tracking.
     * @param {Object} [properties] additional properties to append on the tracker.
     *
     * @return {String} the id of the last timed event.
     */
    startTracking(event, properties = {}) {
        const id = uniqueId('metricsService_');

        this.timed[id] = {
            start: Date.now(),
            event,
            properties
        };

        return id;
    }

    /**
     * Completes the tracking of a timed event.
     *
     * @param {String} id the id of the timed event to end.
     * @param {Object} [properties] additional properties to append on the tracker.
     */
    async endTracking(id, properties = {}) {
        const info = this.timed[id];

        if (!info) return;

        delete this.timed[id];
        properties.$duration = Math.round((Date.now() - info.start) / 1000);

        return await this.track(info.event, Object.assign({}, info.properties, properties));
    }

    /**
     * This method gets called each time the page URL changes.
     * It can be used to notify trackers.
     */
    pageChanged() {
        this.cues && this.cues.page();
        this.showTour(this.tourId, true);
    }

    /**
     * Shows specified onboarding tour.
     * This method is `null` safe: if tourId is not provided this method will do nothing.
     *
     * @param {String} tourId the ID of the onboarding process to show
     * @param {Boolean} [immediate=false] whether to show the tour now or after the page has changed
     */
    showTour(tourId, immediate = false) {
        if (!tourId || !this.cues) {
            return;
        }

        if (immediate) {
            this.cues.show(tourId);
            this.tourId = null; // Reset instance tour ID
            return;
        }

        // For some reason `Appcue.page()` will cause
        // `Appcue.show()` to fail when it's called before
        // the tour popoup is shown:
        //
        // 1. Call Appcue.show
        // 2. Call Appcue.page before the tour is shown
        // result: tour will not be displayed
        //
        // Since there's no way to await for angular change page,
        // we store the tour ID value temporarely for subsequent consumption.
        this.tourId = tourId;
    }

    /**
     * Initializes appcues tracker
     *
     * @param {String} [orgRole] the organization role
     *
     * @private
     */
    _setupAppcues(orgRole) {
        this.cues = window.Appcues;

        if (!this.cues) {
            if (window.BSTG.env !== 'test') console.warn('[TrackingService] Appcues is undefined');
            return;
        }

        if (!this.user || !this.user.tracking_key) {
            console.warn('[TrackingService] Cannot identify user');
            return;
        }

        // Appcues
        const params = {
            name: this.user.realname,
            email: this.user.email,
            currentEID: this.eventService.getCurrentEventIdFromUrl(),
            company: this._getTrackingGroups().join(','),
            org_role: orgRole,
            created_date_user: this.user.created_at
        };

        this.cues.identify(this.user.tracking_key, params);
        console.info('[TrackingService] Appcues initialized with params', params);
    }

    /**
     * Checks if the user forced the tracking
     *
     * @returns {Boolean} true if the tracking parameter is passed, false otherwise
     *
     * @private
     */
    _checkForTrackingParam() {
        return window.location.search.indexOf(FORCE_TRACKING_PARAM) !== -1;
    }

    /**
     * Gets the groups the tracked user belongs to
     *
     * @returns {String[]} a list of user's groups
     *
     * @private
     */
    _getTrackingGroups() {
        if (!this.user) {
            return [];
        }

        let orgs = this.user.orgs.map(o => o.name);

        if (orgs.includes(DEFAULT_COMPANY)) {
            orgs = [DEFAULT_COMPANY];
        }

        return orgs;
    }
}
