"use strict";

var ExceptionHandlerService = /* @ngInject */function ExceptionHandlerService(crashReporter, $log) {
  return {
    /**
     * @param {*} err
     * @param {String} message
     */
    handleException: function handleException(err) {
      var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      crashReporter.captureException(err);
      if (_.isString(message) && !_.isEmpty(message)) {
        $log.error(message, err);
      }
    }
  };
};
ExceptionHandlerService.$inject = ["crashReporter", "$log"];
ExceptionHandlerService.$inject = ["crashReporter", "$log"];
angular.module('backstage.services').service('exceptionHandlerService', ExceptionHandlerService);