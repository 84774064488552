module.exports = ['modalService', modalService => ({
    link(scope, elem, attrs) {
        // each modal has a unique token so that we know if other modals are nested within it (i.e. last to open)
        let uniqueToken = parseInt(Math.random() * 100000);

        let {
            useInstantHideMode
        } = attrs;

        let body = () => angular.element('body');

        elem.addClass('modal');
        if (useInstantHideMode) {
            elem.addClass('hide');
        }
        if (!useInstantHideMode && !elem.hasClass('fade')) {
            elem.addClass('fade');
        }
        elem.attr({
            tabindex: '-1',
            role: 'dialog'
        });

        // we need to wait for the animation to finish because
        // some browsers don't accept focus() requests while
        // there's an animation going on
        elem.on('webkitAnimationEnd animationend', function() {
            let firstInput = elem.find(':input:eq(0)');

            if (firstInput.length) {
                return firstInput[0].focus();
            }
        });

        let hideBackdrop = function() {
            // unfortunately we can't reliably use modalService.isSoleOpenModal() for this
            // until we rewrite all modals to use modalService.openModal and stop using this directive
            if (!$('.modal:visible').length) {
                return body().removeClass('modal-open');
            }
        };

        scope.$on('$destroy', function() {
            modalService.trackModalClosed();
            return hideBackdrop();
        });

        if (!attrs.showModalWhen) {
            return;
        }

        scope.$watch(() => scope.$eval(attrs.showModalWhen), function(shouldShow) {
            // if we use the "hide" class we are removing, if fade/in we are adding
            let showMethod = useInstantHideMode ? elem.removeClass : elem.addClass;
            // if we use the "hide" class we are adding, if fade/in we are removing
            let hideMethod = useInstantHideMode ? elem.addClass : elem.removeClass;
            let isHidden = useInstantHideMode ? elem.hasClass('hide') : !elem.hasClass('in');
            let classToAddOrRemove = useInstantHideMode ? 'hide' : 'in';

            if (shouldShow && isHidden) {
                // show
                modalService.trackModalOpened(uniqueToken);
                showMethod.call(elem, classToAddOrRemove);
                if (!body().hasClass('modal-open')) {
                    return body().addClass('modal-open');
                }

            } else if (!shouldShow && !isHidden) {
                // hide
                modalService.trackModalClosed();
                hideMethod.call(elem, classToAddOrRemove);
                return hideBackdrop();
            }
        });

        scope.$watch(() => modalService.getOpenModalCount(), function(newOpenModalCount) {
            if (!_.isNumber(newOpenModalCount)) {
                return;
            }
            if (modalService.isModalContainingNestedModals(uniqueToken)) {
                elem.addClass('contains-nested-modals');
            } else {
                elem.removeClass('contains-nested-modals');
                elem.css({
                    height: ''
                });
            }
        });

        // prevent propagation of mouse drag events
        // https://github.com/Spotme/backstage-app/issues/167
        elem[0].addEventListener('mousedown', ev => ev.stopPropagation(), { passive: true });
        elem[0].addEventListener('touchstart', ev => ev.stopPropagation(), { passive: true });

        return elem;
    }
})];
