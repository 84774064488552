"use strict";

// bsRevealEditFieldsLink

module.exports = /* @ngInject */["$rootScope", function ($rootScope) {
  return {
    restrict: 'AC',
    link: function link(scope, elem) {
      scope.$on('formEditModeToggled', function () {
        elem.toggleClass('in');
        // ⚑
        // TODO: Refactor this 'orrible code
        if (elem.is('.in')) {
          return $rootScope.setNavigationWarningMessage('You may have made changes here. ' + 'Discard them?');
        } else {
          return $rootScope.setNavigationWarningMessage(null);
        }
      });
      // if this element has direct descendents we'll make them capture clicks instead
      var clickTargets = elem.children();
      if (!clickTargets.length) {
        clickTargets = elem;
      }
      return clickTargets.click(function () {
        return $rootScope.$broadcast('formEditModeToggled');
      });
    }
  };
}];