// bsBrowserAutofillConsciousForm

module.exports = function() {
    // include smart table controller to use its API if needed
    return {
        priority: 10,
        link(scope, element) {
            element.on('submit', () =>
                $('input[ng-model]', element).each(() => {
                    if ((angular.element(this).attr('type') !== 'checkbox') && (angular.element(this).attr('type') !== 'radio')) {
                        const model = angular.element(this).controller('ngModel');

                        if (model) {
                            model.$setViewValue($(this).val());
                        }
                    }
                })
            );
            return scope.$on('$destroy', () => element.off('submit'));
        }
    };
};
