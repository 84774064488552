import Primus from 'primus';
import { get, isEmpty, isFunction, isString } from 'lodash';
import { getBackstageURL, objectToQueryString } from 'libs/utils/url';

const MAX_RETRIES = 6; // number of times Primus will try to reconnect after an issue
const TIMEOUT = 15000; // Timeout before Primus abandon a connection attempt
const MIN_DELAY = 500; // delay before Primus starts a new reconnection attempt, will be doubled on each retry (+ some randomness)
const approxMaxRetryDelay = MIN_DELAY * (Math.pow(2, MAX_RETRIES) - 1);

/**
 * Provides utils for managing signal messages.
 *
 * We have now 'timeout' in the reconnection strategy, without there were no retries if the initial connection failed.
 * Primus is discouraging this for ws with auth but we suspect this to have prevented some users to properly connect.
 *
 * With this in place, the following behavior is observed (not all events are reported, and onInitError is our own event emitted on Primus' 'error'):
 *
 * If BE refuses the Auth, or if the query is wrong (i.e. source: 'abc'):
 * 'onInitError' event -> x retries showing an error in the console without emitting events -> 'end' event
 *
 * If BE takes too long to authorize (can be tested by changing TIMEOUT to 1):
 * 'timeout' event -> x retries + 'reconnect timeout' event -> 'end' event -> 'reconnect fail' event -> 'onInitError' event
 *
 * If user goes offline:
 * 'offline' event -> 'close' event -> 'end' event -> no retries, waiting to be online again -> 'online' event -> 'reconnected' event -> 'open' event
 *
 * If connection is permanently broken after a successful connection (i.e. BE goes down):
 * 'close' event -> x retries showing an error in the console without emitting events -> 'end' event
 *
 * If BE takes inexplicably long to authorize but still does after a long time, this one is a bit weird but Primus recovers after emitting 'end', even though the docs
 * stipulate 'When this event is emitted you should consider your connection to be fully dead with no way of reconnecting'
 * This makes the UI a bit inconsistent and may display the signal error, but then make it disappear (can be tested with a delay on BE auth):
 * 'close' event -> x retries showing an error in the console without emitting events -> 'end' event -> 'open' event
 *
 * If connection is temporarily broken (can be tested by restarting BE):
 * 'close' event -> x retries showing an error in the console without emitting events -> 'reconnected' event -> 'open' event
 *
 * @example
 * import SignalService from 'libs/services/signal';
 * ...
 * const signal = new SignalService();
 */
export default class SignalService {

    constructor() {
        this.listeners = {}; // signal listeners
        this.eventListeners = {};
        this.url = getBackstageURL();
    }

    /**
     * Initialize the service for the given event
     *
     * @param {String} eid the event id to connect the socket to
     */
    init(eid) {
        console.log('[SignalService] init');

        this.eid = eid;

        if (this.primus) {
            try {
                this.primus.destroy();
            } catch (e) {
                console.log('[SignalService] could not destroy previous instance', e);
            }
            this.primus = null;
        }

        clearTimeout(this.restartPrimusTimeout);
        this.restartPrimusTimeout = null;

        const query = {
            source: 'bstg',
            eid
        };

        this.primus = new Primus(`${this.url}?${objectToQueryString(query)}`, {
            // Initial connection timeout
            timeout: TIMEOUT,
            // This looks like the default but it's not, since we have BE ws auth, the 'timeout' isn't part of the default strategy
            // 'timeout' will most importantly induce a reconnect if the initial connection throws an error, or times out
            strategy: 'disconnect,online,timeout',
            reconnect: {
                'reconnect timeout': TIMEOUT,
                retries: MAX_RETRIES,
                min: MIN_DELAY
            }
        });

        this.primus.on('open', () => this.onInitOpen());
        this.primus.on('error', (err) => this.onInitError(err));
        this.primus.on('timeout', (err) => this.onInitError(err));

        this.primus.on('data', (data) => this.onData(data));
        this.primus.on('end', () => this.onEnd());

        const genericEventTypes = ['close', 'disconnection', 'open', 'reconnect', 'reconnected', 'reconnect failed', 'timeout'];
        for (const type of genericEventTypes) {
            this.primus.on(type, (err) => this.onPrimusGenericEvent(type, err));
        }
    }

    /**
     * Check if the type is a primus issued type, or some Spotme issued signal data type
     * @param type
     * @return {boolean}
     */
    isSignalEventType(type) {
        const signalEventTypes = ['close', 'disconnection', 'end', 'error', 'onInitError', 'open', 'reconnect failed', 'reconnected', 'timeout'];
        return signalEventTypes.includes(type);
    }

    /**
     * Adds the specified listener to the given signal type pool
     *
     * @param {String} signalType the type of the signal to add the listener to
     * @param {Function} listener the listener to add
     */
    async addSignalListener(signalType, listener) {
        if (this.isSignalEventType(signalType)) {
            return console.error('[SignalService] wrong signal type, use addEventListener');
        }
        if (!isFunction(listener)) {
            return console.error('[SignalService] listener must be a function');
        }
        try {
            await this.primus.write({
                type: 'registerToSignal',
                signalType
            });

            if (!this.listeners[signalType]) {
                this.listeners[signalType] = [];
            }

            this.listeners[signalType].push(listener);

            return () => {
                this.removeSignalListener(signalType, listener);
            };
        } catch (error) {
            console.error('[SignalService] An error occurred while adding listener', error);
        }
    }

    /**
     * Removes the specified listener from the given signal type pool
     *
     * @param {String} signalType the type of the signal to remove the listener from
     * @param {Function} listener the listener to remove
     */
    async removeSignalListener(signalType, listener) {
        const index = (this.listeners[signalType] || []).indexOf(listener);

        if (index === -1) {
            return console.info(`[SignalService] Cannot remove listener for signal ${signalType} listener does not exist`);
        }

        this.listeners[signalType].splice(index, 1);

        if (this.listeners[signalType].length === 0) {
            try {
                await this.primus.write({
                    type: 'unregisterFromSignal',
                    signalType
                });
            } catch (error) {
                console.error('[SignalService] An error occurred while removing listener', error);
            }
        }
    }

    /**
     * Sends a signal through the bus.
     *
     * @param {Object} signal the signal payload to send
     */
    async sendSignal(signal) {
        console.log('[SignalService] Sending signal', signal);
        try {
            await this.primus.write({ type: 'sendSignal', signal });
        } catch (error) {
            console.error('[SignalService] An error occurred while sending signal', error);
        }
    }

    /**
     * Adds event listener
     *
     * @param {string} eventType type of an event (for now only 'reconnected' and 'close' events are supported)
     * @param {Function} listener function to be called
     */
    addEventListener(eventType, listener) {
        if (!this.isSignalEventType(eventType)) {
            return console.error('[SignalService] wrong signal type, use addSignalListener');
        }
        this.eventListeners[eventType] = this.eventListeners[eventType] || [];
        this.eventListeners[eventType].push(listener);

        return () => {
            this.removeEventListener(eventType, listener);
        };
    }

    /**
     * Remove event listener
     *
     * @param {string} eventType type of event (for now only 'reconnected' and 'close' events are supported)
     * @param {Function} listener
     */
    removeEventListener(eventType, listener) {
        const index = (this.eventListeners[eventType] || []).indexOf(listener);
        if (index !== -1) {
            this.eventListeners[eventType].splice(index, 1);
        }
    }

    /**
     * Calls event listeners for a specific event.type
     *
     * @param {object} event
     */
    triggerEvent(event) {
        const type = event?.type;
        const listeners = get(this, `eventListeners.${type}`, []);
        for (const listener of listeners) {
            if (isFunction) {
                setTimeout(() => {
                    listener(event);
                });
            }
        }
    }

    /* =============== */
    /* Events handlers */
    /* =============== */

    /**
     * Removes handlers from the socket
     *
     * @private
     */
    offInitListeners() {
        this.primus.off('open', this.onInitOpen);
        this.primus.off('error', this.onInitError);
        this.primus.off('timeout', this.onInitError);
    }

    /**
     * Handler called when the initialization succeeds
     *
     * @private
     */
    onInitOpen() {
        console.info('[SignalService] connected');
        this.offInitListeners();
        this.primus.on('error', (err) => this.onPrimusGenericEvent('error', err));
        this.primus.on('timeout', (err) => this.onPrimusGenericEvent('timeout', err));
    }

    /**
     * Handler called when socket initialization fails
     *
     * @param {Error} error the error to rise
     *
     * @private
     */
    onInitError(error) {
        console.error(`[SignalService] signal initialization error, we will retry ${MAX_RETRIES} times for approx. ${approxMaxRetryDelay / 1000}s`, error);
        this.triggerEvent({ type: 'onInitError', error });
    }

    /**
     * Handler called when data is received through the socket
     *
     * @param {Object} data received data
     *
     * @private
     */
    onData(data) {
        const { type, signal } = data;

        if (type === 'signal' && signal && isString(signal.type) && !isEmpty(signal.type)) {
            (this.listeners[signal.type] || []).forEach(handler => handler(signal));
        }
    }

    /**
     * if the ws is disconnected, we have to re register to all the signals on open
     */
    addBackAllExistingSignals() {
        Object.keys(this.listeners).forEach(signalType => {
            this.primus.write({
                type: 'registerToSignal',
                signalType
            });
        });
    }

    /**
     * Handler called when primus emits an event
     *
     * @param {String} type the event type
     * @param {Error} error the error to rise
     *
     * @private
     */
    onPrimusGenericEvent(type, error) {
        console.debug(`[SignalService] ${type} event`, error);
        if (type === 'open') {
            this.addBackAllExistingSignals();
        }

        this.triggerEvent({ type, error });
    }

    /**
     * Handler called when the socket dies and emits an 'end' event
     *
     * @private
     */
    onEnd() {
        console.error('[SignalService] end event');

        if (this.restartPrimusTimeout) {
            return;
        }

        this.restartPrimusTimeout = setTimeout(() => {
            this.restartPrimusTimeout = null;
            console.error('[SignalService] connection is dead, initiating it again');
            this.init(this.eid);
        }, 15 * 1000);
    }
}
