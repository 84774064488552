"use strict";

module.exports = function (options) {
  var resolver = /* @ngInject */function resolver(eventService, handlerService) {
    var eventId = eventService.getCurrentEvent()._id;
    var handler = options.handler,
      params = options.params;
    if (!handler) {
      return;
    }
    return handlerService.callHandler(eventId, handler, params);
  };
  resolver.$inject = ["eventService", "handlerService"];
  return resolver;
};