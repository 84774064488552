"use strict";

angular.module('backstage.services').factory('deeplinkService', /* @ngInject */["ACCREDITED_LINK_TOKEN_PARAM", "BLUEPRINT_FULLSCREEN_PARAM", "$location", function (ACCREDITED_LINK_TOKEN_PARAM, BLUEPRINT_FULLSCREEN_PARAM, $location) {
  var inAccreditedDeeplinkMode = false;
  return {
    /**
     * Checks if in current URL we have instruction to switch to fullscreen mode
     *
     * @returns {string|undefined}
     */
    getCurrentBlueprintFullscreenMode: function getCurrentBlueprintFullscreenMode() {
      var search = $location.search();
      return (search || {})[BLUEPRINT_FULLSCREEN_PARAM];
    },
    /**
     * Checks if current route has accredited token
     *
     * @returns {boolean}
     */
    isCurrentUrlAnAccreditedDeeplink: function isCurrentUrlAnAccreditedDeeplink() {
      if (inAccreditedDeeplinkMode) {
        return true;
      }
      var search = $location.search();
      return _.isString((search || {})[ACCREDITED_LINK_TOKEN_PARAM]);
    },
    /**
     * turns "accredited deeplink" mode on
     */
    rememberInAccreditedDeeplinkMode: function rememberInAccreditedDeeplinkMode() {
      inAccreditedDeeplinkMode = true;
    },
    /**
     * Returns accredited link with access to given set of routes
     *
     * @param {String} eventId event ID
     * @param {String} path path for the link
     * @param {Object} routes route definitions to grant access to
     * @param {String} [parentDocId] ID of parent document
     * @param {String} [name] name of the link
     * @param {String[]} [roles] array of role names
     * @returns {Promise<String>}
     */
    // /!\ routes must be kept here for backwards compatibility with old pkg-sponsors
    getLink: function getLink(eventId, path, routes, parentDocId, name, roles) {
      return window.BSTG.services.deepLink.getLink(eventId, path, routes, parentDocId, name, roles);
    },
    /**
     * Returns accredited link for moderators
     *
     * @param {String} eventId event ID
     * @param {Object} liveStream live stream
     * @returns {Promise<String>}
     */
    getLiveStreamModeratorLink: function getLiveStreamModeratorLink(eventId, liveStream) {
      return window.BSTG.services.deepLink.getLiveStreamModeratorLink(eventId, liveStream);
    },
    /**
     * Returns accredited link for speakers
     *
     * @param {String} eventId event ID
     * @param {Object} liveStream live stream
     * @returns {Promise<String>}
     */
    getLiveStreamSpeakerLink: function getLiveStreamSpeakerLink(eventId, liveStream) {
      return window.BSTG.services.deepLink.getLiveStreamSpeakerLink(eventId, liveStream);
    },
    /**
     * Returns accredited link for live displays
     *
     * @param {String} eventId event ID
     * @param {Object} liveStream live stream
     * @returns {Promise<String>}
     */
    getLiveStreamLiveDisplayLink: function getLiveStreamLiveDisplayLink(eventId, liveStream) {
      return window.BSTG.services.deepLink.getLiveStreamLiveDisplayLink(eventId, liveStream);
    }
  };
}]);