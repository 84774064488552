const Spinner = require('spinner');

module.exports = [() => ({
    restrict: 'C',
    link(scope, elem, attrs) {
        let spinner = null;
        let showSpinner = function() {
            if (spinner) {
                spinner.stop();
            }
            spinner = new Spinner;
            if (attrs.text) {
                spinner.text(attrs.text);
            }
            spinner.size(parseInt(attrs.size || 13));
            if (attrs.speed) {
                spinner.speed(parseInt(attrs.speed));
            }
            return elem.removeClass('icon-spinner icon-spin') // legacy font-awesome based stuffs
                .addClass('new-spinner')
                .empty()
                .append(spinner.el);
        };
        let hideSpinner = function() {
            if (spinner) {
                spinner.stop();
            }
            return elem.empty();
        };

        if (!attrs.ngShow) {
            return showSpinner();
        }
        // remove spinner when our scope is destroyed
        scope.$on('$destroy', hideSpinner);
        // remove spinners that use ngShow to appear conditionally
        return scope.$watch(() => scope.$eval(attrs.ngShow), function(newValue) {
            if (newValue) {
                return showSpinner();
            } else {
                return hideSpinner();
            }
        });
    }
})];
