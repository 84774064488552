import 'regenerator-runtime/runtime'; // This is still needed because @ngInject are still compiled through babel rather than esbuild.
import $ from 'jquery';

window.setUp = function() {
    window.$ = $;
    window.jQuery = $;
    window.Hammer = require('hammerjs');
    require('angular');
};

window.setUp();

window.initApp = async function() {
    window.bstgRequire = require;
    window._ = require('underscore');

    // the prototype assignment was removed from newer versions of dateFormat
    // see https://github.com/felixge/node-dateformat
    const dateFormat = require('dateformat');

    Date.prototype.format = function(mask, utc) {
        return dateFormat(this, mask, utc);
    };

    // providers
    const WindowProvider = require('libs/providers/window').default;

    // vanilla services
    const BaseService = require('libs/services/base-service').default;
    const AccountService = require('libs/services/account').default;
    const APIDocService = require('libs/services/api-doc').default;
    const AppScriptsService = require('libs/services/app-scripts').default;
    const AnalyticsService = require('libs/services/analytics').default;
    const BacklinksService = require('libs/services/backlinks').default;
    const BlueprintService = require('libs/services/blueprint').default;
    const BrandingService = require('libs/services/branding').default;
    const ContentHubService = require('libs/services/content-hub/content-hub').default;
    const ContentLibraryService = require('libs/services/content-library').default;
    const CrashReportingService = require('libs/services/crash-reporting').default;
    const DocumentsService = require('libs/services/documents').default;
    const EmailsService = require('libs/services/emails').default;
    const EntitlementsService = require('libs/services/entitlements').default;
    const EventService = require('libs/services/event').default;
    const ExcelService = require('libs/services/excel').default;
    const HandlersService = require('libs/services/handlers').default;
    const JobsService = require('libs/services/jobs').default;
    const LegalDocsService = require('libs/services/legal-docs').default;
    const LiveDisplayService = require('libs/services/live-display').default;
    const LiveStreamService = require('libs/services/live-stream').default;
    const LowerThirdService = require('libs/services/lower-third').default;
    const MarketplaceService = require('libs/services/marketplace').default;
    const MediaDevicesService = require('libs/services/media-devices').default;
    const MeetingService = require('libs/services/meetings').default;
    const MenuService = require('libs/services/menu').default;
    const MetadataService = require('libs/services/metadata').default;
    const NavsService = require('libs/services/navs').default;
    const OpenIdConnectService = require('libs/services/openid-connect').default;
    const OrganizationService = require('libs/services/organization').default;
    const PaxService = require('libs/services/pax').default;
    const PublicAssetsService = require('libs/services/public-assets').default;
    const RouterService = require('libs/services/router').default;
    const ServerService = require('libs/services/server').default;
    const SessionRelationsService = require('libs/services/session-relations').default;
    const SettingsService = require('libs/services/settings').default;
    const SignalService = require('libs/services/signal').default;
    const StorageService = require('libs/services/storage').default;
    const SupportService = require('libs/services/support').default;
    const TagService = require('libs/services/content-hub/tag').default;
    const TemplateService = require('libs/services/template').default;
    const ThemeService = require('libs/services/theme').default;
    const TrackingService = require('libs/services/tracking').default;
    const TriggerService = require('libs/services/trigger-service').default;
    const UserService = require('libs/services/user').default;
    const UserSessionService = require('libs/services/user-session').default;
    const VideoCallsService = require('libs/services/video-calls').default;
    const DeepLinkService = require('libs/services/deep-link').default;
    const WebinarsService = require('libs/services/webinar').default;

    /** @type {import('ui/plugins').Services} */
    const vanillaServices = {
        account: new AccountService(),
        analytics: new AnalyticsService(),
        apiDoc: new APIDocService(),
        appScripts: new AppScriptsService(),
        backlinks: new BacklinksService(),
        blueprint: new BlueprintService(),
        branding: new BrandingService(),
        content: new ContentLibraryService(),
        contentHub: new ContentHubService(),
        crashReporting: new CrashReportingService(),
        deepLink: new DeepLinkService(),
        documents: new DocumentsService(),
        emails: new EmailsService(),
        entitlements: new EntitlementsService(),
        event: new EventService(),
        excel: new ExcelService(),
        handlers: new HandlersService(),
        jobs: new JobsService(),
        legal: new LegalDocsService(),
        liveStream: new LiveStreamService(),
        lowerThird: new LowerThirdService(),
        liveDisplay: new LiveDisplayService(),
        market: new MarketplaceService(),
        mediaDevices: new MediaDevicesService(navigator.mediaDevices),
        meetings: new MeetingService(),
        menu: new MenuService(),
        metadata: new MetadataService(),
        navs: new NavsService(),
        oidc: new OpenIdConnectService(),
        org: new OrganizationService(),
        pax: new PaxService(),
        publicAssets: new PublicAssetsService(),
        router: new RouterService(new WindowProvider(window.location)),
        server: new ServerService(),
        sessionRelations: new SessionRelationsService(),
        settings: new SettingsService(),
        signal: new SignalService(),
        storage: new StorageService(),
        support: new SupportService({ intercom: window.BSTG.intercom }),
        tag: new TagService(),
        template: new TemplateService(),
        theme: new ThemeService(),
        track: new TrackingService(),
        trigger: new TriggerService(),
        user: new UserService(),
        userSession: new UserSessionService(),
        videoCalls: new VideoCallsService(),
        webinar: new WebinarsService()
    };

    window.BSTG.services = vanillaServices;

    /**
     * This must be used with big precaution because it violates the blackbox principle
     * and ideally one day all services will come without inter-dependencies.
     *
     * It's here because we're porting old services and this little trick will
     * allow us to ease the task in the short run.
     *
     * @type {import('ui/plugins').Services}
     */
    BaseService.prototype._services = vanillaServices;

    // requires
    require('jquery.caret'); // Needed by at.js (see below)
    require('at.js');
    require('bootstrap'); // This will be removable once we get rid of strapTooltips (generic form controllers)
    require('select2');
    require('typeahead.js');
    require('fuelux.wizard')($); // Excel imports (next step)
    require('rome');
    require('jsTag');

    require('ngUpload');
    require('select2-ng');
    require('ng-hammer');
    require('ngRepeatReorder');
    require('angular-route');
    require('angular-sanitize');
    require('angular-cookies');
    require('ngprogress-lite');
    require('textAngularSetup');
    require('textAngular');
    require('angular-typeahead');

    require('highcharts');
    require('highcharts-more');
    require('modules/exporting');
    require('modules/solid-gauge');
    require('modules/heatmap');

    require('./angular/app');
    require('./angular/filters');
    require('quill');

    // src
    require('src/branded-apps-metadata');
    // ngControllers
    require('angular/controllers/agenda');
    require('angular/controllers/crud');
    require('angular/controllers/data');
    require('angular/controllers/events');
    require('angular/controllers/features');
    require('angular/controllers/help');
    require('angular/controllers/main');
    require('angular/controllers/misc');
    require('angular/controllers/orgs');
    require('angular/controllers/users');
    require('angular/controllers/navs');
    require('angular/controllers/widgets');
    require('angular/controllers/brandedApps');
    require('angular/controllers/tools');
    require('angular/controllers/theme-editor');
    require('angular/controllers/participants');
    // ngServices
    require('angular/services/main');
    require('angular/services/metadataService');
    require('angular/services/metadata');
    require('angular/services/blueprintService');
    require('angular/services/accountService');
    require('angular/services/userService');
    require('angular/services/eventService');
    require('angular/services/eventCopyService');
    require('angular/services/orgService');
    require('angular/services/timeService');
    require('angular/services/urlService');
    require('angular/services/fieldFormatterService');
    require('angular/services/navsService');
    require('angular/services/packagesService');
    require('angular/services/settingsService');
    require('angular/services/modalService');
    require('angular/services/uiService');
    require('angular/services/i18nService');
    require('angular/services/assetsService');
    require('angular/services/deeplinkService');
    require('angular/services/clipboardService');
    require('angular/services/taskExecutorService');
    require('angular/services/dynamicExtensionsService');
    require('angular/services/customActionButtonsService');
    require('angular/services/frontstageService');
    require('angular/services/helpService');
    require('angular/services/paginationService');
    require('angular/services/scrollService');
    require('angular/services/appBrandingService');
    require('angular/services/appScriptService');
    require('angular/services/entitlementsService');
    require('angular/services/taskQueueService');
    require('angular/services/exceptionHandlerService');
    require('angular/services/vueI18nService');
    require('angular/services/tools');
    require('angular/services/themeService');
    require('angular/services/themeImporterService');
    require('angular/services/colorThiefService');
    require('angular/services/webappUrlService');
    const mappingAngularToVanillaServices = {
        backlinksService: vanillaServices.backlinks,
        bulkUpdateService: vanillaServices.documents,
        handlerService: vanillaServices.handlers,
        liveDisplayService: vanillaServices.liveDisplay,
        liveStreamService: vanillaServices.liveStream
    };

    for (const serviceName in mappingAngularToVanillaServices) {
        angular.module('backstage.services')
            .factory(serviceName, () => mappingAngularToVanillaServices[serviceName]);
    }

    // ngDirectives
    require('angular/directives/directives');
    require('angular/directives/help-tooltip');
    require('angular/directives/mdHtmlFormDirective');
    require('angular/directives/forms');
    require('angular/directives/color-picker');
    require('angular/directives/tables');
    require('angular/directives/externals');
    require('angular/directives/naveditors');
    require('angular/directives/quill');
    require('angular/directives/commonToolbar');
    require('angular/directives/brandedApps');
    require('angular/directives/validation');
    require('angular/directives/smart-table');
    require('angular/directives/tools');
    require('angular/directives/theme-editor');
    // components
    require('angular/components/main');
    // providers
    require('angular/providers/dataSourceProvider');

    angular.module('backstage.services')
        .service('packageManagerService', function() { return vanillaServices.market; })
        .service('metricsService', function() { return vanillaServices.track; })
        .service('localStorageService', function() { return vanillaServices.storage; })
        .service('localStorage', function() { return vanillaServices.storage; })
        .service('serverService', function() { return vanillaServices.server; })
        .service('signalService', function() { return vanillaServices.signal; })
        .service('legalDocumentsService', function() { return vanillaServices.legal; })
        .service('userSessionService', UserSessionService)
        .service('paxService', function() { return vanillaServices.pax; })
        .service('crashReporter', function() { return vanillaServices.crashReporting; });


    // needed for backwards compatibility
    window.kanso = {
        moduleCache: {
            dictree: { exports: require('dictree') },
            mustache: { exports: require('mustache') },
            'src/table-utils': { exports: require('src/table-utils') },
        }
    };

    angular.element(document).ready(() =>
        angular.bootstrap(document, ['backstage'], {
            strictDi: window.location.host.indexOf('backstage.4pax.com') !== -1
        })
    );
};
