/* eslint-disable
    eqeqeq,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
// @name TaskSeries
// @description
//
// A set of generator functions that provide promises resolved in series to complete a task.
//
var TaskSeries = (function() {
    let DEFAULT_TASK_NAME = undefined;
    let DEFAULTS = undefined;

    TaskSeries = class TaskSeries {
        static initClass() {

            let executeSeriesFn;

            DEFAULT_TASK_NAME = 'Working, please wait';

            DEFAULTS = {
                promiseFns: [],
                taskNames: [DEFAULT_TASK_NAME]
            };

            this.prototype.executeSeries = (executeSeriesFn = function(callback) {
                let cb, errCb, notifyCb;

                if (this.spent) { throw Error('TaskSeries may only be executed once'); }
                this.spent = true;
                this.currentTaskName = _.first(this.taskNames);
                this.completed = this.taskCount === this.completedCount;
                callback(this);
                // emulating async.series here
                return _.first(this.promiseFns)(this.lastReturnValue)
                    .then((cb = ret => {
                    // shift off first task pair if successful (moved from above, allows for resume)
                        if (this.completedCount === 0) {
                            this.taskNames.shift(); this.promiseFns.shift();
                        }
                        this.completedCount++;
                        this.lastReturnValue = ret;
                        if (this.promiseFns.length) {
                            if (this.taskNames.length) { this.currentTaskName = this.taskNames.shift(); }
                            this.currentTaskStatus = null;
                            this.promiseFns.shift()(ret)
                                .then(cb, errCb, notifyCb);
                        } else {
                            this.completed = true;
                        }
                        return callback(this);
                    })
                    , (errCb = err => {
                        if (typeof console !== 'undefined' && console !== null) {
                            console.error('Error while executing tasks', err);
                        }
                        this.errored = true;
                        this.error = err;
                        return callback(this);
                    })
                    , (notifyCb = status => {
                        this.currentTaskStatus = status;
                        return callback(this);
                    })
                    );
            });

            this.prototype.execute = executeSeriesFn;
        }

        constructor(promiseFns, taskNames) {
            this.promiseFns = promiseFns;
            this.taskNames = taskNames;
            _.defaults(this, DEFAULTS);
            this.taskCount = this.promiseFns.length;
            this.completedCount = 0;
        }

        concat(taskSeries) {
            if (!(taskSeries instanceof TaskSeries)) {
                throw Error('concat must be called with a TaskSeries argument');
            }
            return new TaskSeries(
                this.promiseFns.concat(taskSeries.promiseFns),
                this.taskNames.concat(taskSeries.taskNames));
        }

        addTask(taskName, promiseFn) {
            if (this.spent) { throw Error('TaskSeries may only be executed once'); }
            if (!(arguments.length >= 2)) { throw Error('a taskName and promiseFn must be provided'); }
            this.taskNames.push(taskName); this.promiseFns.push(promiseFn);
            return this.taskCount++;
        }

        resetErrorState() {
            this.spent = (this.errored = false);
            return this.error = null;
        }

        getStatusText(includeProgressPercentage) {
            if (this.errored) {
                let msg = `An error occurred while ${this.currentTaskName.toLowerCase()}.`;

                msg += ' You may try again or dismiss this message.';
                return msg;
            }
            let text = this.currentTaskName;

            if (this.currentTaskStatus) { text += ` (${this.currentTaskStatus})`; }
            if (includeProgressPercentage) { text += `, ${this.getProgressPercentageStatusText()}`; }
            return text;
        }

        getProgressPercentageStatusText() {
            return `${this.getProgressPercentage(0)}% done`;
        }

        getErrorText() {
            let { error } = this;

            if (error != null ? error.data : undefined) { error = error.data; }
            if (error) { return JSON.stringify(error.error || error.message || error); } else { return ''; }
        }

        getProgressPercentage(precision) {
            if (precision == null) { precision = 2; }
            if (this.errored) { return 100; }
            return parseFloat(((this.completedCount / this.taskCount) * 100).toFixed(precision));
        }

        isInProgress() {
            return this.spent && (this.completedCount < this.taskCount);
        }
    };
    TaskSeries.initClass();
    return TaskSeries;
})();


// ####

angular.module('backstage.services')

    .factory('taskSeriesService', () =>

        ({
            createTaskSeries(promiseFns, taskNames) {
                if (promiseFns == null) { promiseFns = []; }
                if (taskNames == null) { taskNames = []; }
                return new TaskSeries(promiseFns, taskNames);
            }
        })
    )

    .factory('taskExecutorService', [
        '$q',
        'ngProgressLite',
        'modalService',
        function($q, ngProgressLite, modalService) {

            let taskExecutorService;

            return _.extend((taskExecutorService = {}), {

                executeTaskSeries(taskSeries, deferred) {
                    if (!deferred) { deferred = $q.defer(); }
                    ngProgressLite.start();
                    taskSeries.executeSeries(function(taskSeries) {
                        deferred.notify(taskSeries);
                        ngProgressLite.set(taskSeries.getProgressPercentage(0) / 100);
                        if (taskSeries.completed) {
                            deferred.resolve(taskSeries.lastReturnValue);
                        } else if (taskSeries.errored) {
                            deferred.reject(taskSeries.error);
                        } else { return; } // still in progress / waiting on user decision
                        return ngProgressLite.done();
                    });
                    deferred.promise.executor = taskSeries;
                    taskSeries.deferred = deferred;
                    return deferred.promise;
                },

                resumeTaskSeriesAfterError(taskSeries) {
                    if (!(taskSeries != null ? taskSeries.errored : undefined)) { return; }
                    taskSeries.resetErrorState();
                    return taskExecutorService.executeTaskSeries(taskSeries, taskSeries.deferred);
                },

                openModalWithTaskSeriesProgress(executor, { title }) {
                    let modalScope = {
                        title,
                        progress: 0,
                        currentTask: {
                            label: 'Initializing'
                        }
                    };

                    executor.then(
                        function(lastReturnValue) {
                            modalScope.progress = 100;
                            return modalScope.label = 'Done.';
                        },
                        err => $q.reject(err),
                        function(series) {
                            modalScope.label = series.getStatusText();
                            modalScope.progress = series.getProgressPercentage();

                            if (series.errored) {
                                modalScope.error = true;
                                modalScope.progress = 100;
                                return modalScope.title = 'An error occurred';
                            }
                        });

                    return modalService.openModal({
                        templateUrl: '/static/partials/components/modal-progress-bar.html',
                        scope: { vm: modalScope
                        }
                    });
                }
            }
            );
        }
    ]);
