"use strict";

var _lodash = require("lodash");
var _require = require('src/fp-i18n-utils'),
  getAllI18nKeys = _require.getAllI18nKeys;
var BrandedAppsI81NFieldEditorCtrl = /* @ngInject */function BrandedAppsI81NFieldEditorCtrl($rootScope, $scope, fieldDescriptor) {
  if (!$rootScope.currentBranding) {
    return;
  }

  // disclamer: we expect branding to be present somewhere in parent scope
  var i18n = $rootScope.currentBranding.activation_pages.i18n;
  $scope.$watch('model', function (model) {
    return $scope.vm = {
      model: model,
      field: fieldDescriptor.field,
      allI18nKeys: getAllI18nKeys(i18n)
    };
  });
  return $scope.$watch("model.".concat(fieldDescriptor.field), function (newValue) {
    if (!newValue) {
      return;
    }
    $scope.vm.key = $scope.model[fieldDescriptor.field];
    return $scope.vm.translation = (0, _lodash.get)(i18n, $scope.vm.key);
  });
};
BrandedAppsI81NFieldEditorCtrl.$inject = ["$rootScope", "$scope", "fieldDescriptor"];
BrandedAppsI81NFieldEditorCtrl.$inject = ["$rootScope", "$scope", "fieldDescriptor"];
angular.module('backstage.controllers.brandedApps').controller('BrandedAppsI81NFieldEditorCtrl', BrandedAppsI81NFieldEditorCtrl);