const _ = require('underscore');

/**
 * Check if provided IP is valid and v4
 * @param {String} str
 * @return {Boolean}
 */
module.exports.isV4 = str => {
    str = str.split('.');
    if (str.length !== 4) return false;
    return _.every(str, part => {
        const int = parseInt(part, 10);

        // should be between 0 and 255 without leading 0
        return int > -1 && int < 256 && `${int}` === part;
    });
};

const isValidV6Fragment = str => str.match(/^[\da-f]{1,4}/i);

/**
 * Check if provided IP is valid and v6
 * @param {String} str
 * @return {Boolean}
 */
module.exports.isV6 = str => {
    str = str.split(':');
    if (str.length < 2 || str.length > 8) {
        return false;
    }
    if (str[0] !== '' || str[1] !== '') {
        // Address does not begin with a zero compression ("::")
        if (!isValidV6Fragment(str[0])) {
            // Component must contain 1-4 hex characters
            return false;
        }
    }

    let numberOfZeroCompressions = 0;

    for (let i = 1; i < str.length; i++) {
        if (str[i] === '') {
            // We're inside a zero compression ("::")
            numberOfZeroCompressions += 1;
            if (numberOfZeroCompressions > 1) {
                // Zero compression can only occur once in an address
                return false;
            }
            continue;
        }
        if (!isValidV6Fragment(str[i])) {
            // Component must contain 1-4 hex characters
            return false;
        }
    }
    return true;
};
