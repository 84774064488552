angular.module('backstage.directives.tables', [])
    .directive('bsExternalItemLabelDisplayCell', require('./bsExternalItemLabelDisplayCell'))
    .directive('bsDynamicExtensionValueDisplayCell', require('./bsDynamicExtensionValueDisplayCell'))
    .directive('bsAssetFilePreviewCell', require('./bsAssetFilePreviewCell'))
    .directive('bsNavigateToPaxProfileCell', require('./bsNavigateToPaxProfileCell'))
    .directive('bsNavigateToViewGenericItem', require('./bsNavigateToViewGenericItem'))
    .directive('bsTableCellTick', require('./bsTableCellTick'))
    .directive('bsTableItemSelectionActions', require('./bsTableItemSelectionActions'))

    // agenda, deprecated
    .directive('bsToggleAgendaRegistrationButtonsCell', require('./bsToggleAgendaRegistrationButtonsCell'))
    .directive('bsAgendaItemCapacityStatsDisplayCell', require('./bsAgendaItemCapacityStatsDisplayCell'));

// removed because not used
// .directive('bsEventInstanceStatsDisplayCell', require('./bsEventInstanceStatsDisplayCell'));
// renamed
// bsParticipantStatTableCellTick -> bsTableCellTick
