"use strict";

var _attachments = require("libs/utils/attachments");
var _require = require('src/table-utils'),
  searchMetadataFieldsFilter = _require.searchMetadataFieldsFilter;
module.exports = /* @ngInject */["$route", "$routeParams", "$scope", "uiService", "urlService", "eventService", "appScriptService", "metadataService", "signalService", "crudItems", "crudItemFields", "BASE_API_ENDPOINT", function ($route, $routeParams, $scope, uiService, urlService, eventService, appScriptService, metadataService, signalService, crudItems, crudItemFields, BASE_API_ENDPOINT) {
  var _defaultResolver$opti;
  var eventId = $routeParams.eventId;
  var event = eventService.decorateScope(eventId);
  var currentRouteDescriptor = ($route.current || {}).$$route;
  var crudOpts = currentRouteDescriptor.crudOpts || {};
  var visibleFields = crudOpts.visibleFields;
  var sortableFields = crudOpts.sortableFields || [];
  var blueprintController = (currentRouteDescriptor.blueprintMetadata || {}).controller;
  var defaultResolver = blueprintController.resolvers && blueprintController.resolvers.crudItems ? blueprintController.resolvers.crudItems : blueprintController.resolvers.default || {};
  var moduleControllers = ((currentRouteDescriptor.blueprintMetadata || {}).module || {}).controllers || {};
  $scope.useVueUploader = !!((_defaultResolver$opti = defaultResolver.options) !== null && _defaultResolver$opti !== void 0 && _defaultResolver$opti.useVueUploader);
  var modelFpType = $scope.modelFpType = defaultResolver ? (defaultResolver.options || {}).fpType : crudOpts.fpType;
  var rows = $scope.rowCollection = [];
  var isPaginated = defaultResolver ? (defaultResolver.options || {}).paginated : false;
  var pageListAppscript = (defaultResolver.options || {}).appscriptUrl;
  $scope.data = (crudItems || {}).data;
  $scope.exporter = crudOpts.exporter;
  $scope.hideAssociateWithOtherItems = crudOpts.hideAssociateWithOtherItems;
  $scope.totalNumRecords = $scope.data.rows ? $scope.data['total_rows'] : $scope.data.length;
  $scope.data = $scope.data.rows ? $scope.data.rows : $scope.data;
  var itemsPerPage = crudOpts.itemsPerPage || $scope.data.length || 25;

  // ⚑
  // TODO: HACKED IN FILTER to be removed
  var modelList = _.compact($scope.data).filter(function () {
    var m = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return m.type !== 'conversationmessage';
  });
  angular.extend(rows, modelList);
  $scope.contextSensitiveNavKey = currentRouteDescriptor.contextSensitiveNavKey || null;

  // populate the cols with metadata data

  var tableColumns;
  if (visibleFields) {
    tableColumns = visibleFields.reduce(function (memo, fieldKey) {
      memo[fieldKey] = (_.findWhere(crudItemFields, {
        field: fieldKey
      }) || {}).label;
      return memo;
    }, {});
  } else {
    tableColumns = crudItemFields.reduce(function (memo, descriptor) {
      memo[descriptor.field] = descriptor.label;
      return memo;
    }, {});
  }
  var cols = $scope.columnCollection = [];
  var _cols = [];
  _.keys(tableColumns).forEach(function (key, index) {
    return _cols.push({
      map: key,
      label: tableColumns[key] || key,
      isSortable: isPaginated ? sortableFields.indexOf(key) >= 0 : true,
      cellTemplateUrl: index === 0 ? '/static/partials/table-cells/generic-view-linked-item.html' : '/static/partials/table-cells/basic-formatted-value-cell.html'
    });
  });
  $scope.showAssetPreviewColumn = crudOpts.showAssetPreviewColumn;
  if (crudOpts.showAssetPreviewColumn) {
    _cols.unshift({
      map: '_id',
      label: 'Preview',
      isSortable: false,
      cellTemplateUrl: '/static/partials/table-cells/asset-file-preview-cell.html',
      // used in the above template
      assetUrlRoot: "".concat(BASE_API_ENDPOINT, "/events/").concat(eventId, "/assets/")
    });
  }
  $scope.showAssetFileTypeColumn = crudOpts.showAssetFileTypeColumn;
  if (crudOpts.showAssetFileTypeColumn) {
    _cols.push({
      map: '_attachments',
      label: 'File type',
      isSortable: false,
      formatFunction: function formatFunction(doc) {
        var _getAttachmentMetadat;
        var attachmentsNames = (0, _attachments.getAttachmentNames)(doc);
        return (_getAttachmentMetadat = (0, _attachments.getAttachmentMetadata)(doc, attachmentsNames[0])) === null || _getAttachmentMetadat === void 0 ? void 0 : _getAttachmentMetadat.content_type;
      }
    });
  }
  if (crudOpts.customColumns) {
    _cols.push.apply(_cols, uiService.postProcessCustomColumns(_.flatten([crudOpts.customColumns]), event));
  }
  $scope.showAssetUploadDownloadButtons = crudOpts.showAssetUploadDownloadButtons;
  angular.extend(cols, _cols);
  $scope.globalConfig = {
    maxSize: 9,
    numberOfItems: $scope.totalNumRecords,
    itemsByPage: itemsPerPage,
    selectionMode: 'multiple',
    displaySelectionCheckbox: true,
    isGlobalSearchActivated: true,
    serverSidePagination: isPaginated,
    filterAlgorithm: _.partial(searchMetadataFieldsFilter, _.pluck(crudItemFields, 'field').concat(['fp_ext_id'])),
    serverSidePageGetter: _.partial(appScriptService.callAppScript, pageListAppscript)
  };

  // bulk import/export button render logic

  $scope.isExcelImportRouteAvailable = function () {
    var cond1 = !!moduleControllers.import;
    var cond2 = !!crudOpts.importPathOverride;
    return cond1 || cond2;
  };
  $scope.getExcelImportRouteLinkHref = function () {
    var importController = moduleControllers.import;
    var importRoutePath = importController && importController.route.replace('/event/:eventId', '');
    var importPathOverride = (currentRouteDescriptor.crudOpts || {}).importPathOverride;
    return urlService.getImportExportOverridePath(importPathOverride || importRoutePath, event, "/event/".concat(eventId));
  };
  $scope.getExcelExportRouteLinkHref = function () {
    var exportRoutePath = "/docsByType/".concat(modelFpType, "?format=xlsx");
    var exportPathOverride = (currentRouteDescriptor.crudOpts || {}).exportPathOverride;
    if (exportPathOverride && !exportPathOverride.base && !exportPathOverride.path) {
      return '';
    }
    return urlService.getImportExportOverridePath(exportPathOverride || exportRoutePath, event);
  };
  $scope.onUploadComplete = function () {
    return $route.reload();
  };
  $scope.hasCustomExport = crudOpts ? !!crudOpts.customExportLinks : false;
  $scope.customExportRouteLinks = _.chain(crudOpts.customExportLinks).map(function (pathOrDescriptor, label) {
    return [label, urlService.getImportExportOverridePath(pathOrDescriptor, event)];
  }).object().value();
  var metadataForType = metadataService.getCachedMetadataForType(eventId, modelFpType);
  var limit = (metadataForType || {})._limit;
  if (limit && $scope.totalNumRecords >= limit) {
    $scope.limitReached = true;
  }
  $scope.supportedMimes = metadataForType === null || metadataForType === void 0 ? void 0 : metadataForType._allowed_content_types;
  signalService.addSignalListener("document/".concat(modelFpType, "/update"), function () {
    return $scope.onUploadComplete();
  }).then(function (unsubscribeListener) {
    $scope.$on('$destroy', function () {
      unsubscribeListener();
    });
  });
}];