"use strict";

CommonToolbarCtrl.$inject = ["$scope", "$rootScope"];
var TEMPLATE = "<div class=\"bs-common-toolbar\">\n\n    <div class=\"buttons-container\">\n        <div class=\"buttons\">\n            <i class=\"icon-spinner icon-spin padded-container\"\n            ng-style=\"{visibility: showLoader ? 'visible' : 'hidden'}\"></i>\n\n\n            <ng-transclude></ng-transclude>\n\n            <button class=\"btn primary btn-glow save-btn\"\n                    ng-click=\"save($event)\"\n                    ng-bind=\"saveButtonLabel\"\n                    ng-disabled=\"showLoader || isDisabled\"\n                    ng-if=\"showSaveButton\"></button>\n        </div>\n    </div>\n</div>";

/* @ngInject */
function CommonToolbarCtrl($scope, $rootScope) {
  $scope.save = function ($event) {
    if ($scope.preventDefault) {
      $event.preventDefault();
    }
    $rootScope.setNavigationWarningMessage(null);
    $scope.onSaveClick();
  };
  if (!angular.isDefined($scope.saveButtonLabel)) {
    return $scope.saveButtonLabel = 'Save';
  }
}
angular.module('backstage.directives').directive('bsCommonToolbar', [function () {
  return {
    restrict: 'E',
    transclude: true,
    replace: true,
    controller: 'CommonToolbarCtrl',
    template: TEMPLATE,
    scope: {
      showSaveButton: '=',
      saveButtonLabel: '=?',
      onSaveClick: '&',
      showLoader: '=',
      isDisabled: '=',
      preventDefault: '='
    }
  };
}]).controller('CommonToolbarCtrl', CommonToolbarCtrl);