module.exports = () => ({
    require: 'ngModel',
    link: ($scope, element, attrs, ctrl) => {
        let maxFileSize = null;
        const multiplier = parseInt(attrs.bsMaxFileSizeValidation);

        if (multiplier) {
            maxFileSize = multiplier * 1024 * 1024;
        }

        ctrl.$validators.maxFileSize = (modelValue, viewValue) => {
            const file = modelValue || viewValue;

            return !maxFileSize || ctrl.$isEmpty(file) || file.size < maxFileSize;
        };
    }
});
