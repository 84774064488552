"use strict";

var _lodash = require("lodash");
/**
 * This controller is used to initialize the new Vue main menu for the SpotMe CMS.
 *
 * It is linked to the `index.pug` as follow:
 *
 * ```
 * #main-navigator(ng-controller='VueMainMenu')
 * ```
 */

module.exports = /* @ngInject */["$routeParams", "$rootScope", "$location", "blueprintService", "$route", function ($routeParams, $rootScope, $location, blueprintService, $route) {
  /**
   * Sanitizes all blueprint modules before initilaizing the main menu.
   *
   * @param {Object} modules the blueprint modules
   */
  var sanitizeModules = function sanitizeModules(modules) {
    for (var _i = 0, _Object$keys = Object.keys(modules); _i < _Object$keys.length; _i++) {
      var moduleKey = _Object$keys[_i];
      var _module = modules[moduleKey];
      if (_module.hideNavItem) {
        delete modules[moduleKey];
        continue;
      }
      for (var _i2 = 0, _Object$keys2 = Object.keys(_module.controllers || {}); _i2 < _Object$keys2.length; _i2++) {
        var controllerKey = _Object$keys2[_i2];
        var controller = _module.controllers[controllerKey];
        if (controller.hideNavItem) {
          delete _module.controllers[controllerKey];
        }
      }
      if ((0, _lodash.isEmpty)(_module.controllers)) {
        delete modules[moduleKey];
      }
    }
  };

  /**
   * In event space `init` will be called twice, but we initialize the menu only
   * if blueprint is ready to return modules.
   */
  var init = function init() {
    if (!$rootScope.menuInitilaized && $rootScope.user && !$rootScope.hideNavs) {
      var modules = (0, _lodash.cloneDeep)(blueprintService.getModules());
      if (modules) {
        sanitizeModules(modules);
        window.BuildMenu($routeParams, $rootScope, $location, modules, $route);
        $rootScope.menuInitilaized = true;
      }
    }
  };

  // This will happen in the application list
  init();
  $rootScope.$on('$routeChangeSuccess', function (event, currentRoute) {
    if ($rootScope.hideNavs) return;

    // This will happen in event space
    init();

    // Each time the angular route changes we trigger the following event
    // in order to make the main menu aware of the actual context.
    window.BSTG.menu.$emit('route:change:success', currentRoute);
  });
  $rootScope.$on('$routeUpdate', function (event, currentRoute) {
    if ($rootScope.hideNavs) return;

    // Ensure menu is built before emitting events
    init();

    // This event gets triggered when search parameters change
    // We use this to support the marketplace routing which is
    // based on query parameters
    window.BSTG.menu.$emit('route:update', currentRoute);
  });
}];