
// Constants
import {
    LOCAL_STORAGE_KEY,
    LOCAL_STORAGE_CACHE_DURATION,
} from 'libs/utils/constants';

// Helpers

// add a prefix string to the local storage key
const addPrefixToKey = (key) => LOCAL_STORAGE_KEY + key;

// remove a prefix string to the local storage key
const removePrefixKey = (key) => key.substring(LOCAL_STORAGE_KEY.length, key.length);

// check if it is a key with the prefix
const isBackstageKey = (key) => key.indexOf(LOCAL_STORAGE_KEY) === 0;

// check if it the timestamp is out of date and remove out of date items
const removeOutOfDate = ([key, value]) => {
    const isUpToDate = !value || !value.timestamp
        // if there is no expiration, remove it
        ? false
        // otherwise check against duration or default duration
        : value.timestamp + (value.duration || LOCAL_STORAGE_CACHE_DURATION) > Date.now();

    if (!isUpToDate) {
        localStorage.removeItem(addPrefixToKey(key));
    }
    return isUpToDate;
};

// read the key from localStorage, JSON parse it, return as a key, value tuple
const readStorageToTuple = key => [removePrefixKey(key), JSON.parse(localStorage.getItem(key))];

// key for get/set interface of StorageService
const buildStorageServiceKey = key => `StorageService:${key}`;

/**
 * Provides utils for getting and saving values to and from the local storage.
 *
 * @example
 * import StorageService from 'libs/services/storage';
 * ...
 * const storage = new StorageService();
 */
export default class StorageService {
    /**
     * Builds the storage service and loads its initial value
     */
    constructor() {
        this.store = {};
        this._load();
    }

    /**
     * Gets the value from the local storage.
     *
     * @param {String} key the key where the value is saved
     *
     * @returns {*} the value or undefined if nothing is found.
     */
    get(key) {
        return this.getCache(buildStorageServiceKey(key)).get();
    }

    /**
     * Saves the value under the specified key on the local storage.
     *
     * @param {String} key the key under which save the value
     * @param {*} val the value to save on the storage
     */
    set(key, val) {
        this.getCache(buildStorageServiceKey(key)).set(val);
    }

    /**
     * Removes the given item from the local storage
     *
     * @param {string} key the key of the value to remove
     */
    remove(key) {
        const serviceKey = `_cache:${buildStorageServiceKey(key)}`;
        delete this.store[serviceKey];

        localStorage.removeItem(`${LOCAL_STORAGE_KEY}${serviceKey}`);
    }

    /**
     * Gets the cached values on the local storage.
     *
     * @param {String} key the key to use for the cache
     * @param {Number} [duration=LOCAL_STORAGE_CACHE_DURATION] the cache expiration in milliseconds. Defaults to 1month
     *
     * @returns {Object} the cache object that is composed by two functions: `get` and `set`.
     */
    getCache(key, duration = LOCAL_STORAGE_CACHE_DURATION) {
        const localKey = `_cache:${key}`;
        const self = this;

        return {
            get() {
                const existing = self._get(localKey);

                if (existing && existing.timestamp + duration > Date.now()) {
                    return existing.value;
                }

                return;
            },

            set(value) {
                self._set(localKey, {
                    value,
                    duration,
                    timestamp: Date.now()
                });
            }
        };
    }

    /**
     * Underlying method to get the value from the local storage,
     * without cache expiration
     * @private
     * @param {String} key the key where the value is saved
     * @returns {*} the value or undefined if nothing is found.
     */
    _get(key) {
        return this.store[key];
    }

    /**
     * Underlying method to saves the value under the specified key on the local storage,
     * without cache expiration
     * @private
     * @param {String} key the key under which save the value
     * @param {*} val the value to save on the storage
     */
    _set(key, val) {
        this.store[key] = val;
        this._save();
    }

    /**
     * Loads the store from the local storage.
     *
     * @private
     */
    _load() {
        try {
            this.store = {};

            const storedValues = Object.keys(localStorage)
                .filter(isBackstageKey)
                .map(readStorageToTuple)
                .filter(removeOutOfDate);

            for (const [key, value] of storedValues) {
                this.store[key] = value;
            }

        } catch (e) {
            console.warn('[StorageService] unable to parse local storage', e);
            this.store = {};
        }
    }

    /**
     * Saves the store to the local storage.
     *
     * @private
     */
    _save() {
        try {
            Object.entries(this.store).forEach(([key, value]) => {
                localStorage.setItem(
                    addPrefixToKey(key),
                    JSON.stringify(value)
                );
            });
        } catch (e) {
            this._resetStoreAndLogError(e);
        }
    }

    /**
     * Something wrong happened: rebuild store and log eror
     *
     * @private
     * @param {Error} error
     */
    _resetStoreAndLogError(error) {
        this.store = {};
        console.error('localstorage error', error);
    }
}


