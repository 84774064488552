const _ = require('underscore');

/**
 * NOTE: this utils is meant for angular use only,
 * if you need this on vue, please use
 *
 * ```
 * this.$services.event.getCurrentEventIdFromUrl()
 * ```
 */
function getEidFromLocation() {
    // manually extract info from route. At this stage angular routing has not kicked-in
    const href = location.href.split('/');
    // 0: protocol
    // 1: empty
    // 2: domain
    const startRoute = href[3];
    const eid = href[4];

    if (
        // route starting with event or events(backward compatibility)
        (startRoute === 'event' || startRoute === 'events')
        // then there should be an eid
        && !_.isEmpty(eid))
    {
        return eid;
    }
    return null;
}

module.exports.EID = getEidFromLocation();
module.exports.getEid = getEidFromLocation;
