module.exports = [() => ({
    scope: {
        value: '=',
        label: '='
    },
    link(scope, elem) {
        elem.attr({
            value: scope.value
        });
        return elem.html(scope.label);
    }
})];
