import BaseService from './base-service';
import MediaService from './media';

// Constants
import { API_BASE_PATH } from 'libs/utils/constants';

/**
 * Public assets API endpoint
 * @constant {string} PUBLIC_ASSETS_ENDPOINT
 * @private
 */
const PUBLIC_ASSETS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/public-assets`;

/**
 * Public assets API endpoint for legal documents
 * @constant {string} LEGAL_PUBLIC_ASSETS_ENDPOINT
 * @private
 */
const LEGAL_PUBLIC_ASSETS_ENDPOINT = `${API_BASE_PATH}/orgs/{{orgId}}/legal-public-assets`;

export default class PublicAssetsService extends BaseService {

    constructor() {
        super();
        this.media = new MediaService();
    }

    /**
     * Uploads a single asset to a URL
     *
     * @param {string} url
     * @param {File} file the file to upload
     * @param {string} assetName the name of the asset
     * @param {Function} onUploadProgress a function that is called on upload progress
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async _uploadAssetToUrl(url, file, assetName, onUploadProgress) {
        const bodyFormData = new FormData();
        const token = await this.getCsrfToken();

        bodyFormData.set('file', file);
        bodyFormData.set('filename', assetName || file.name);
        bodyFormData.set('_csrf', token);

        const { data } = await this.post(url, bodyFormData, {
            onUploadProgress,
            headers: {
                'Content-Type': file.type
            }
        });

        return data;
    }

    /**
     * Uploads a single asset
     *
     * @param {string} eventId the ID of the event
     * @param {File} file the file to upload
     * @param {string} assetName the name of the asset
     * @param {Function} onUploadProgress a function that is called on upload progress
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async uploadAsset(eventId, file, assetName, onUploadProgress) {
        const url = this.buildUrl(PUBLIC_ASSETS_ENDPOINT, { eventId });
        return this._uploadAssetToUrl(url, file, assetName, onUploadProgress);
    }

    /**
     * Uploads a legal asset
     *
     * @param {string} orgId the ID of the organization
     * @param {File} file the file to upload
     * @param {string} assetName the name of the asset
     * @param {Function} onUploadProgress a function that is called on upload progress
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async uploadLegalAsset(orgId, file, assetName, onUploadProgress) {
        const url = this.buildUrl(LEGAL_PUBLIC_ASSETS_ENDPOINT, { orgId });
        return this._uploadAssetToUrl(url, file, assetName, onUploadProgress);
    }

    /**
     * Gets the list of all email assets
     *
     * @param {string} eventId the ID of the event
     * @param {number} [page=1] the page of assets to load
     * @param {number} [limit=20] the number to item per page to load
     *
     * @returns {Promise<{ assets: object, total: number, hasMore: boolean}>}
     */
    async getAllAssets(eventId, page = 1, limit = 20) {
        const url = this.buildUrl(PUBLIC_ASSETS_ENDPOINT, { eventId });
        const { data } = await this.get(url, { params:
            {
                limit,
                skip: (page - 1) * limit
            }
        });
        data.hasMore = data.total > page * limit;
        return data;
    }

    /**
     * Uploads the file on the BE bucket without using the worker.
     * This is suitable only for small size files.
     *
     * @param {string} eventId the ID of the event
     * @param {File} file the file to upload
     * @param {Object} params
     * @param {string} [params.assetName] the name of the asset
     * @param {Function} [params.onUploadProgress] a function that is called on upload progress
     * @param {AbortSignal} [params.signal] an optional abort controller singal
     *
     * @returns {Promise<import('axios').AxiosResponse>} the remote server response
     */
    async directUpload(eventId, file, params) {
        const { assetName, onUploadProgress, signal, fpType, docId, type } = params;

        let urlData;
        if (fpType) {
            urlData = await this.media.getFpTypeUploadDocument(eventId, fpType, file, {
                id: docId,
                contentType: type
            });
        } else {
            urlData = await this.uploadAsset(eventId, file, assetName, onUploadProgress);
        }

        const { id, upload_url, url } = urlData;
        const { data } = await this.media.upload(upload_url || url, file, signal, onUploadProgress);

        return { id, ...data };
    }
}
