"use strict";

module.exports = function () {
  var resolver = /* @ngInject */function resolver($route, eventService) {
    var participantId = $route.current.params.participantId;
    var event = eventService.getCurrentEvent();
    return eventService.getEventParticipantInfoAndTimeline(event, participantId);
  };
  resolver.$inject = ["$route", "eventService"];
  return resolver;
};