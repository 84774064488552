"use strict";

angular.module('backstage.directives.externals').directive('bsTargetsExceptionsFloatingRuleEditor', /* @ngInject */["$compile", function ($compile) {
  return {
    templateUrl: '/static/partials/components/targets-exceptions-floating-rule-editor.html',
    // scope: true  # prototypal inheritance
    controller: 'TargetsExceptionsFloatingRuleEditorCtrl',
    link: function link(scope, elem) {
      var html = "                    <div data-bs-form-generator\n                        data-model='editingModel'\n                        data-fields='editingFields'\n                        data-omit-button-bar='true'\n                        data-show-remove-field-link\n                        data-remove-field-fn='removeConditionFromRule(editingRuleIdx, key)'\n                        class='ph-form-generator'>\n                    </div>                    ";
      var mode = 'empty';
      return scope.$watchCollection('editingFields', function (newEditingFields) {
        var placeholderEl = elem.find('.ph-form-generator');
        if (newEditingFields && newEditingFields.length) {
          // do not recreate form generator if it's already in its place
          if (mode !== 'form') {
            var formGeneratorEl = angular.element(html);
            placeholderEl.replaceWith(formGeneratorEl);
            $compile(formGeneratorEl)(scope);
          }
          return mode = 'form';
        } else {
          if (mode !== 'empty') {
            placeholderEl.replaceWith(angular.element('<div class="ph-form-generator"></div>'));
            $compile(placeholderEl)(scope);
          }
          return mode = 'empty';
        }
      });
    }
  };
}]);