"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var moment = require('moment-timezone');
var _ = require('underscore');
angular.module('backstage.controllers.widgets').controller('EventPictoTeaserIconListCtrl', /* @ngInject */["$scope", "eventService", function ($scope, eventService) {
  var _limit;
  $scope.visibleEventList = [];
  $scope.$watch('eventsList', function () {
    _limit = $scope.limitTo;
    var limitTo = _limit ? parseInt(_limit) : Number.MAX_VALUE;
    if ($scope.eventsList) {
      var eventIdsWithIcons = $scope.eventsList.map(function (event) {
        return event._id;
      });
      var newVisibleEventList = $scope.eventsList.filter(function (event) {
        return eventIdsWithIcons.includes(event._id);
      });
      newVisibleEventList = _.first(newVisibleEventList, limitTo);
      angular.copy(newVisibleEventList, $scope.visibleEventList);
    }
  });
  $scope.selectEvent = function (event, $event) {
    return $scope.selectEventFn({
      event: event,
      $event: $event
    });
  };
  $scope.showFullListView = function () {
    return $scope.showFullListFn({
      view: $scope.fullListViewToShow
    });
  };
  $scope.getStyleForPictoImage = function (event) {
    var iconUrl = eventService.getEventAppIconImageUrl(event);
    if (iconUrl) {
      return {
        'background-image': "url(".concat(iconUrl, ")")
      };
    } else {
      return {};
    }
  };
  $scope.getStyleForPictoText = function (event) {
    var iconUrl = eventService.getEventAppIconImageUrl(event);
    return {
      'background-image': "linear-gradient(0deg, rgba(80, 80, 80, 0.85) 0%, transparent 460px), ".concat(iconUrl ? "url(".concat(iconUrl, ")") : '', ")")
    };
  };
  $scope.getTimeBlurbForEvent = function (event) {
    var prefix;
    var endTime = event.description ? event.description.end_time : undefined;
    var startTime = event.description ? event.description.start_time : undefined;
    if (!_.isNumber(startTime + endTime)) {
      return 'n/a';
    }
    // jsify timestamps
    var _Array$from = Array.from([startTime * 1000, endTime * 1000]);
    var _Array$from2 = _slicedToArray(_Array$from, 2);
    startTime = _Array$from2[0];
    endTime = _Array$from2[1];
    var nowTime = new Date().getTime();
    var ts = startTime;
    if (endTime < nowTime) {
      prefix = 'ended';
      ts = endTime;
    } else if (startTime < nowTime) {
      prefix = 'started';
    } else {
      prefix = 'starting';
    }
    return "".concat(prefix, " ").concat(moment(ts).fromNow());
  };
  return $scope.getGeoOptimalEventUrl = eventService.generateGeoOptimalEventUrl;
}]);