"use strict";

module.exports = function (options) {
  var resolver = /* @ngInject */function resolver($route, eventService) {
    var event = eventService.getCurrentEvent();
    var id = options.id,
      failGracefully = options.failGracefully,
      routeParam = options.routeParam;
    var currentRouteDescriptor = $route.current ? $route.current.$$route : {};
    var crudOpts = currentRouteDescriptor.crudOpts || {};

    // check whether id should be set from route
    var routeBasedId = $route.current.params[routeParam];

    // fall back to looking in the route's crudOpts if a route based id can't be inferred
    // or an fp_type was not supplied as an arg
    var _id = routeBasedId || id || crudOpts.queryId;
    if (!_id) {
      throw Error('an id must be provided for this strategy');
    }
    return eventService.getEventDocById(event, _id).catch(function (err) {
      if (failGracefully) {
        return {};
      }
      throw err;
    });
  };
  resolver.$inject = ["$route", "eventService"];
  return resolver;
};