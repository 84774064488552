"use strict";

// bsExternalsSelectorNewItemWorkaroundForm

module.exports = /* @ngInject */["recursionHelper", function (recursionHelper) {
  return {
    restrict: 'A',
    template: "            <div data-bs-form-generator\n                data-model=\"newItemModel\"\n                data-fields=\"metadataFieldsForNewItem | onlyOneTargetsExceptionsField\"\n                data-omit-button-bar=\"true\"\n                data-show-link-to-metadata-manager=\"true\">\n            </div>\n            <div ng-show=\"formEssence.name === 'asset'\"\n                data-bs-new-file-upload-component\n                data-event-id=\"eventId\"\n                data-api-include-event-id-suffix\n                data-api-upload-type=\"'file'\"\n                data-asset-fp-type=\"newItemCreationParams.type\"\n                data-response-data=\"responseData\"\n                data-imported-from-filename=\"importedFromFilename\"\n                data-asset-fp-ext-id-to-force=\"newItemModel.fp_ext_id\"\n                data-custom-upload-button-text=\"'Save asset'\">\n            </div>            ",
    compile: function compile(elem) {
      return recursionHelper.compile(elem);
    }
  };
}];