"use strict";

module.exports = /* @ngInject */function () {
  return {
    restrict: 'E',
    templateUrl: '/static/partials/theme-editor/theme-editor-mini-preview.html',
    scope: {
      theme: '=',
      title: '=',
      selected: '='
    },
    link: function link() {}
  };
};