// bsFormGeneratorTextInput

module.exports = function() {
    return {
        link(scope, elem) {

            scope.$watch('isProtectedField', function(newValue) {
                if (newValue !== true) { return; }
                return elem.addClass('protected');
            });

            const fieldDescriptor = scope.fieldDescriptor;

            if (!fieldDescriptor) {
                return;
            }

            _.each(['maxlength', 'minlength'], function(attr) {
                if (
                    fieldDescriptor.validations
                    && fieldDescriptor.validations[attr]
                ) {
                    const limit = fieldDescriptor.validations[attr];

                    if ((parseInt(limit, 10) > 0)) {
                        elem.attr(attr, limit);
                    }
                }
            });

            if (
                fieldDescriptor.kind_options
                && fieldDescriptor.kind_options.rows

            ) {
                const rows = fieldDescriptor.kind_options.rows;

                if (parseInt(rows, 10) > 0) {
                    elem.attr('rows', rows);
                }
            }

            // type 'email' is based on `data-bs-form-generator-text-input`, but does not
            // like in the email is not trimmed
            const field = fieldDescriptor.field;
            const model = scope.model;

            if (fieldDescriptor.kind === 'email' && model[field]) {
                model[field] = model[field].trim();
            }
        }
    };
};
