"use strict";

var _url = require("libs/utils/url");
module.exports = /* @ngInject */["$sce", "$route", "$routeParams", "$scope", "urlService", "eventService", function ($sce, $route, $routeParams, $scope, urlService, eventService) {
  var eventId = $routeParams.eventId;
  var event = eventService.decorateScope(eventId);
  var spotletId = (($route.current || {}).$$route || {}).spotletId;
  if (!spotletId || !event) {
    return;
  }
  $scope.shouldShowNavTabs = $route.current.$$route.shouldShowNavTabs;
  $scope.isUsingLocalNodeHost = urlService.isLocalNodeOurHost();
  var iFrameSrc = (0, _url.getBackstageURL)($scope.event.node, "/spotletproxy/dbnode-ev-".concat(eventId, "/_design/spotlet/index.html#").concat(spotletId));
  $scope.getIFrameSrc = function () {
    return $sce.trustAsResourceUrl(iFrameSrc);
  };
}];