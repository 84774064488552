"use strict";

module.exports = function () {
  var resolver = /* @ngInject */function resolver($route, eventService, settingsService) {
    var event = eventService.getCurrentEvent();
    var eventId = event._id;
    return settingsService.compileSettings(eventId).then(function () {
      return settingsService.fetchSettings(event);
    })
    // resolve anyway on error
    .catch(function () {
      return {
        data: {
          _id: 'settings',
          fp_ext_id: 'settings'
        }
      };
    });
  };
  resolver.$inject = ["$route", "eventService", "settingsService"];
  return resolver;
};