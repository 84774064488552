// Classes
import WorkspaceService from './workspace';

// Utils
import { isEmpty, isString, chain, union, isArray } from 'lodash';
import { getBackstageURL, getInfraCookieDomain } from 'libs/utils/url';
import { localizeLabel } from 'libs/utils/locales';
import { tsToFormat, getBrowserDateFormat, addToDate } from 'libs/utils/time';
import * as WorkspacesUtils from 'libs/utils/workspaces';
import moment from 'moment-timezone';

// Constants
import {
    API_BASE_PATH,
    CREATE_WORKSPACE_ENDPOINT,
    EVENT_SERVICE_ENDPOINT,
    EVENT_CHECKLIST_ENDPOINT,
    EVENT_SERVICE_LOCAL_ENDPOINT,
    EVENT_CONVERT_TO_PRODUCTION,
    WORKSPACE_APP_ICON_PAYLOAD,
    EVENT_ASSETS_UPLOAD_ENDPOINT,
    DEFAULT_LANGUAGE,
    LAST_ACCESSED_EVENTS_KEY,
    LAST_ACCESSED_EVENTS_SAVED_COUNT,
    DEFAULT_DATETIME_FORMAT,
    CENTRAL_BACKSTAGE_URL,
    EVENT_ADD_TO_TEAM_ENDPOINT,
    STUDIO_EXPRESS_BASE_PATH
} from 'libs/utils/constants';

/**
 * @const {string} SAVE_EVENT_ENDPOINT The endpoint for saving the event data. Interpolation: `{{id}}`
 * @private
 */
const SAVE_EVENT_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/events/{{id}}`;

/**
 * Number of days until an event is archived after it has finished.
 * For now the process is manual and follows these guidelines: https://support.spotme.com/hc/en-us/articles/1500010275662-Workspace-lifecycle
 * @constant {String} EVENT_ARCHIVAL_DELAY_IN_DAYS
 */
const EVENT_ARCHIVAL_DELAY_IN_DAYS = 45;

/**
 * API path for restoring archived events. Interpolations: `{{eventId}}`.
 * @constant {String} RESTORE_ARCHIVED_EVENT_ENDPOINT
 * @private
 */
const RESTORE_ARCHIVED_EVENT_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/restore`;

/**
 * API path for archiving events. Interpolations: `{{eventId}}`.
 * @constant {String} ARCHIVE_EVENT_ENDPOINT
 * @private
 */
const ARCHIVE_EVENT_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/archive`;


/**
 * API path for getting the event icon from the DS. Interpolations: `{{eventId}}`.
 * @constant {String} ICON_FROM_DS_ENDPOINT
 * @private
 */
const ICON_FROM_DS_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/eid/{{eventId}}/event-icon-ds`;

/**
 * API endpoint for getting the event's team members
 * @const {String} TEAM_MEMBERS_ENDPOINT
 * @private
 */
const TEAM_MEMBERS_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/events/{{eventId}}/team`;

/**
 * API endpoint for remove a team member from the event's team members
 * @const {String} REMOVE_TEAM_MEMBERS_ENDPOINT
 * @private
 */
const REMOVE_TEAM_MEMBERS_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/users/{{email}}/events/{{eventId}}`;

/**
 * URL for BSTG users management
 * @const {String} NEW_USER_PATH
 * @private
 */
const NEW_USER_PATH = `${CENTRAL_BACKSTAGE_URL}/users/new/{{eventId}}/{{role}}?email={{email}}&org={{org}}`;

// Types
import 'libs/utils/typedefs';

/**
 * Provides utils for getting or saving information of an event.
 *
 * @example
 * import EventService from 'libs/services';
 * ...
 * const evt = new EventService();
 */
export default class EventService extends WorkspaceService {

    get NEW_USER_PATH() {
        return NEW_USER_PATH;
    }

    /**
     * Given and event id this method returns all event's details.
     *
     * @param {String} eventId the ID of the event
     * @param {Object} [user]
     * @return {Promise} Promise object that represents the event's details.
     */
    async getEvent(eventId, user) {

        let eventResult = null;

        try {
            eventResult = await this.get(EVENT_SERVICE_ENDPOINT.replace('{{eventId}}', eventId), { withCredentials: true });
        } catch (e) {
            console.warn('[EventService] Not able to get event from server, trying in local node');
            eventResult = await this.get(EVENT_SERVICE_LOCAL_ENDPOINT.replace('{{eventId}}', eventId));
        }

        if (!eventResult || !eventResult.data) {
            return null;
        }

        const event = WorkspacesUtils.transformEventAttributes(eventResult.data);

        if (event && user) {
            WorkspacesUtils.buildEventRole(event, user);
        }

        return event;
    }

    /**
     * Retrieves an event from cache if available and not expired,
     * otherwise fetches the event from the server and caches it.
     *
     * @param {string} eventId - The ID of the event to retrieve.
     * @param {object} user - The user object.
     * @param {number} [maxDuration=this.XHR_CACHE_DURATION] - The maximum duration in milliseconds for which the cached event is considered valid.
     *
     * @returns {Promise<object>} - A promise that resolves to the event object.
     */
    async getEventCached(eventId, user, maxDuration = this.XHR_CACHE_DURATION) {
        const cached = this.__CACHE[eventId];
        const currentTime = Date.now();

        if (cached) {
            // Even if we find a cached event we still have to check if it's not expired.
            const date = cached.__CACHE_TS;

            if (date && currentTime - date <= maxDuration) {
                console.debug('[EventService] Serving event from the cache', eventId);
                return await cached;
            }
        }

        console.debug('[EventService] Cached request not found or expired, hitting the server');
        const eventPromise = this.getEvent(eventId, user);
        eventPromise.__CACHE_TS = currentTime;
        this.__CACHE[eventId] = eventPromise;

        return eventPromise;
    }

    /**
     * Gets the list of user's last accessed events
     *
     * @param {Object} cookie interface for accessing cookies
     * @param {Object} storage interface for accessing local storage
     *
     * @returns {Array} events
     */
    getLastAccessed(cookie, storage) {
        try {
            let lsEvents = storage.get(LAST_ACCESSED_EVENTS_KEY);
            const lastEventIds = cookie.get(LAST_ACCESSED_EVENTS_KEY);
            let cEvents;

            try { cEvents = JSON.parse(lastEventIds); } catch (error) { /* Swallow the error */ }

            if (!cEvents) {
                cEvents = lastEventIds;
            }

            if (!isArray(lsEvents)) {
                lsEvents = [];
            }
            if (!isArray(cEvents)) {
                cEvents = [];
            }

            const events = union(cEvents, lsEvents).slice(0, LAST_ACCESSED_EVENTS_SAVED_COUNT);

            cookie.remove(LAST_ACCESSED_EVENTS_KEY, '/', getInfraCookieDomain());
            storage.set(LAST_ACCESSED_EVENTS_KEY, events);

            return events;
        } catch (error) {
            console.error('[EventService] Could not parse last accessed event cookie', error.message);
            return [];
        }
    }

    /**
     * Stores the last accessed event in the pool
     *
     * @param {Object} cookies interface for accessing cookies
     * @param {object} storage interface for accessing local storage
     * @param {string} eventId the ID of the event to add as last accessed
     */
    setLastAccessed(cookies, storage, eventId) {
        try {
            const events = this.getLastAccessed(cookies, storage).filter(id => id !== eventId);

            // Always put the last on top
            events.unshift(eventId);

            // This is needed for the cross domain (subdomain) issue (infra/cluster)
            cookies.set(LAST_ACCESSED_EVENTS_KEY, events, '2d', '/', getInfraCookieDomain());
            storage.set(LAST_ACCESSED_EVENTS_KEY, events);

        } catch (error) {
            console.error('[EventService] Could not store last accessed event', error.message);
        }
    }

    /**
     * Given an user, get the events associated
     *
     * @param {Object} user
     */
    getEventInstancesForUser(user) {
        return (
            chain(user.events || [])
                .map(event => {
                    event = WorkspacesUtils.transformEventAttributes(event);
                    if (event && user) {
                        WorkspacesUtils.buildEventRole(event, user);
                    }
                    return event;
                })
                .compact()
                .value());
    }

    /**
     * Tries to extract the event ID from the location URL.
     *
     * @param {String} [href=window.location.href] the url from which extract the EID
     *
     * @returns {String} the current event ID or null.
     */
    getCurrentEventIdFromUrl(href = window.location.href) {
        const parts = href.split('/');
        // 0: protocol
        // 1: empty
        // 2: domain
        const startRoute = parts[3];
        const eid = parts[4];

        if (
            // route starting with event or events(backward compatibility)
            (['event', 'events', 'live-session', 'studio', 'live-display', 'content-hub'].includes(startRoute))
            // then there should be an eid
            && !isEmpty(eid))
        {
            return eid;
        }
        return null;
    }

    /**
     * Given an event this method returns the correct event URL based on its node and path.
     *
     * @example
     * getEventUrl(event); // => //eustaging7.spotme.com/event/1234567890abcdef
     * getEventUrl(event, '/somewhere'); // => //eustaging7.spotme.com/event/1234567890abcdef/somewhere
     * getEventUrl(event, undefined, 'https'); // => https://eustaging7.spotme.com/event/1234567890abcdef
     *
     * @param {Workspace|Object} event the Event object
     * @param {String} [path=''] the optional path to go
     * @param {String} [protocol=''] the optional protocol to use
     *
     * @returns {String} the URL of the event
     */
    getEventUrl(event, path = '', protocol = '') {
        return getBackstageURL(this.getEventDomain(event), `/event/${event.id || event._id}${path}`, undefined, protocol);
    }

    /**
     * Given an event and a live stream ID this method returns the correct Studio URL based on its node and path.
     *
     * @example
     * getStudioUrl(event, liveStreamId); // => //eustaging7.spotme.com/studio/1234567890abcdef/fdfd-fdfd-ffdf-fdfd
     *
     * @param {Workspace|Object} event the Event object
     * @param {String} liveStreamId the live stream ID
     *
     * @returns {String} the URL of the event
     */
    getStudioUrl(event, liveStreamId) {
        return `${this.getLiveStreamUrl(event, liveStreamId)}/studio`;
    }

    /**
     * Given an event and a live stream ID this method returns the correct Studio URL based on its node and path.
     *
     * @example
     * getStudioUrl(event, liveStreamId); // => //eustaging7.spotme.com/studio/1234567890abcdef/fdfd-fdfd-ffdf-fdfd
     *
     * @param {Workspace|Object} event the Event object
     * @param {String} liveStreamId the live stream ID
     *
     * @returns {String} the URL of the event
     */
    getLiveStreamUrl(event, liveStreamId) {
        return getBackstageURL(this.getEventDomain(event), `${STUDIO_EXPRESS_BASE_PATH}/${event.id || event._id}/${liveStreamId}`, undefined, '');
    }

    /**
     * Given an event this method returns its App Icon URL
     *
     * @param {Workspace} event
     *
     * @returns {String} the URL of the event's app icon
     */
    getAppIconUrl(event) {
        if (event.hasIcon) {
            const id = event._id || event.id;
            return ICON_FROM_DS_ENDPOINT.replace('{{eventId}}', id);
        }

        return null;
    }

    /**
     * Set the boolean flag for a template on an event object.
     *
     * @param {Object} event the event object
     *
     * @returns {Promise} the REST promise
     */
    async convertToTemplate(event) {
        event.is_template = true;
        event.entitlements_enabled = false;
        event.entitlements_v2 = false;
        event.visibility = 'private';

        const url = EVENT_SERVICE_ENDPOINT
            .replace('{{eventId}}', event.id);

        return await this.post(url, event, { withCredentials: true });
    }

    /**
     * Convert a test workspace to production
     *
     * @param {Object} event the event object
     *
     * @returns {Promise} the REST promise
     */
    async convertWorkspaceToProduction(event) {
        const url = EVENT_CONVERT_TO_PRODUCTION
            .replace('{{eventId}}', event.id);

        return await this.post(url, event, { withCredentials: true });
    }

    /**
     * Given an event this method returns its checklist.
     *
     * @param {String} eventId the eventId to get the checklist for.
     *
     * @return {Promise<Checklist>} Promise object that represents the event's checklist.
     */
    async getChecklist(eventId) {
        const url = EVENT_CHECKLIST_ENDPOINT.replace('{{eventId}}', eventId);
        const { data } = await this.get(url);

        return data;
    }

    /**
     * Uploads an asset to the given event
     *
     * @param {File} appIcon the icon to upload to the event
     * @param {string} eventId the Event ID to upload the images to
     * @param {string} nodeId the ID of the node to use for the given event
     * @param {Record<string,unknown>} params "assets/upload" appscript params
     */
    async uploadAsset(file, eventId, nodeId, params) {
        if (!file || file.size === 0) {
            return;
        }

        const token = await this.getCsrfTokenViaRouter(nodeId);
        const url = getBackstageURL(
            nodeId,
            EVENT_ASSETS_UPLOAD_ENDPOINT
                .replace('{{eventId}}', eventId)
        );
        const bodyFormData = new FormData();

        bodyFormData.set('file', file);
        bodyFormData.set('_csrf', token);
        bodyFormData.set('params', JSON.stringify(params));

        const { data } = await this.post(url, bodyFormData, { withCredentials: true });

        return data;
    }

    /**
     * Uploads the app icon to the given event
     *
     * @param {File} appIcon the icon to upload to the event
     * @param {string} eventId the Event ID to upload the images to
     * @param {string} nodeId the ID of the node to use for the given event
     */
    async uploadAppIcon(appIcon, eventId, nodeId) {
        return this.uploadAsset(appIcon.file || appIcon, eventId, nodeId, WORKSPACE_APP_ICON_PAYLOAD);
    }

    /**
     * Gets the correct event name
     *
     * @param {Object} event the event to get the title of
     * @param {String} [locale] the locale to use for the name
     *
     * @return {String} the event name
     */
    getName(event, locale) {
        return WorkspacesUtils.getWorkspaceTitle(event, locale);
    }

    /**
     * Gets the date at which the event will be or has been archived
     *
     * @param {Object} event the event for which we retrieve the archival date
     *
     * @return {Date} the date at which the event will be or has been archived
     */
    getArchivalDate(event) {
        return addToDate(event.enddate, EVENT_ARCHIVAL_DELAY_IN_DAYS, 'days');
    }

    /**
     * Creates a workspace with the given data.
     *
     * @param {Object} workspaceData the payload needed to create a workspace.
     *
     * @returns {Promise<Object>} could be an object containing `jobId` or an `error` key.
     */
    async createEvent(workspaceData, user) {
        const tz = workspaceData.timezone;
        const startDate = moment(workspaceData.start_date).format(DEFAULT_DATETIME_FORMAT);
        const endDate = moment(workspaceData.end_date).format(DEFAULT_DATETIME_FORMAT);

        const payload = {
            anonymization: workspaceData.anonymization,
            anonymization_domains: workspaceData.anonymization_domains,
            brandedApp: workspaceData.container_app.fp_ext_id,
            categoryId: null,
            description: {
                title: { [DEFAULT_LANGUAGE]: workspaceData.name },
                subtitle: { [DEFAULT_LANGUAGE]: workspaceData.city },
                venue: { city: workspaceData.city },
                category: workspaceData.category,
                categories: workspaceData.categories,
            },
            enddate: moment.tz(endDate, tz).toISOString(),
            legal_documents: workspaceData.privacy_documents.map(e => e._id),
            name: workspaceData.name,
            org: { name: user.realName, email: user.email },
            ownerId: workspaceData.organization._id,
            realName: workspaceData.name,
            serverGroup: workspaceData.servers_location.value,
            stage: workspaceData.test_workspace ? 'sandbox' : 'production',
            startdate: moment.tz(startDate, tz).toISOString(),
            test_event: workspaceData.test_workspace,
            timezone: workspaceData.timezone,
            template_id: workspaceData.template.id,
            show_cookie_banner: workspaceData.show_cookie_banner,
            show_activation_codes: workspaceData.show_activation_codes,
            audience_type: workspaceData.audience_type,
            country_code: workspaceData.country_code,
            event_format: workspaceData.event_format,
            event_scope: workspaceData.event_scope,
            expected_attendance: workspaceData.expected_attendance,
            event_classification: workspaceData.event_classification,
            event_custom_classification: workspaceData.event_custom_classification
        };

        try {
            const { data } = await this.post(CREATE_WORKSPACE_ENDPOINT, payload);

            return data;

        } catch ({ response: { data, status } }) {
            const error = { error: 'errors.server.generic', message: data, status };

            if (status !== 200) {
                error.error = `errors.server.${status}`;
            }

            console.warn('[EventService] An error occurred while creating workspace:', error, status);
            return error;
        }
    }

    /**
     * Given an event and a locale this method returns the event searchable string
     *
     * @param {Object} event the event to get the search string from
     * @param {String} locale the locale to use for the search
     *
     * @returns {String} the event searchable string
     */
    getSearchString(event, locale) {
        const title = WorkspacesUtils.getWorkspaceTitle(event, locale);
        let { subtitle, venue, start_time_utc } = event.description || {};

        if (subtitle) {
            subtitle = localizeLabel(subtitle, locale);
        }

        if (!start_time_utc) {
            start_time_utc = event.start_time;
        }

        const city = venue && isString(venue.city) ? venue.city : '';
        const date = tsToFormat(start_time_utc || '', getBrowserDateFormat(locale));

        return `${title}${subtitle}${city}${date}`.toLocaleLowerCase(locale);
    }

    /**
     * Restores the given archived event
     *
     * @param {String} eventId ID of event to restore
     * @param {String} node node name
     * @returns {Promise<Object>} job data
     */
    async restoreArchivedEvent(eventId, node) {
        const url = getBackstageURL(
            node,
            RESTORE_ARCHIVED_EVENT_ENDPOINT.replace('{{eventId}}', eventId)
        );

        // TODO
        const { data } = await this.post(url, {}, { withCredentials: true });

        return data;
    }

    /**
     * Archives the given event
     *
     * @param {String} eventId ID of event to archive
     * @param {String} node node name
     * @returns {Promise<Object>} job data
     */
    async archiveEvent(eventId, node) {
        const url = getBackstageURL(
            node,
            ARCHIVE_EVENT_ENDPOINT.replace('{{eventId}}', eventId)
        );

        const { data } = await this.post(url, {}, { withCredentials: true });

        return data;
    }

    /**
     * Gets the team members for the specified event
     *
     * @param {string} eventId the ID of the workspace
     * @param {boolean} [includeRevoked = false] whether to include revoked members
     *
     * @returns {Promise<object[]>} the team members list
     */
    async getTeamMembers(eventId, includeRevoked = false) {
        const url = this.buildUrl(TEAM_MEMBERS_ENDPOINT, { eventId });
        const { data } = await this.get(url);

        if (includeRevoked) {
            return data;
        }

        return data.filter(m => m.role.role !== 'revoked');
    }

    /**
     * Sets the user's role for the given event
     *
     * @param {string} eventId the ID of the workspace
     * @param {string} email the user's email
     * @param {'manager'|'editor'|'viewer'} role the uers's role for the given event
     *
     * @returns {Promise<object>} the server respone
     */
    async setTeamMemberRole(eventId, email, role) {
        const url = this.buildUrl(EVENT_ADD_TO_TEAM_ENDPOINT, { email });
        const { data } = await this.post(url, { access: role, eid: eventId });
        return data;
    }

    /**
     * Removes the specified user from the given workspace
     *
     * @param {string} eventId the ID of the workspace
     * @param {string} email the user's email
     *
     * @returns {Promise<object>} the server respone
     */
    removeTeamMember(eventId, email) {
        const url = this.buildUrl(REMOVE_TEAM_MEMBERS_ENDPOINT, { eventId, email: email.toLocaleLowerCase() });
        return this.delete(url);
    }

    /**
     * Saves the event as provided
     *
     * @param {object} event the modified event document
     * @param {string} [reason] the reason for the change
     *
     * @returns {Promise<object>} the server response
     */
    async saveEvent(event, reason) {
        const id = event._id;
        const url = this.buildUrl(SAVE_EVENT_ENDPOINT, { id });

        if (typeof reason === 'string' && reason.length > 0) {
            event._change_reason = reason;
        }

        const { data } = await this.post(url, event);
        return data;
    }
}
