import BaseService from './base-service';
import {
    API_BASE_PATH,
    CENTRAL_BACKSTAGE_URL,
    LEGAL_DOCUMENTS_FOR_ORG_ENDPOINT,
    LEGAL_DOCUMENTS_TEMPLATES_ENDPOINT,
    LEGAL_DOCUMENTS_TEMPLATE_ENDPOINT,
    LEGAL_DOCUMENT_ENDPOINT,
    LEGAL_DOCUMENT_URL,
    LEGAL_DOCUMENT_MANAGER_PATH
} from 'libs/utils/constants';

// Users accepted legal docs. Interpolation: `{{orgName}}`
const ACCEPTED_LEGAL_DOCS_ENDPOINT = `${API_BASE_PATH}/orgs/{{orgName}}/legal/accepted-docs`;

/**
 * Provides methods necessary for managing legal documents.
 *
 * @example
 *
 * import LegalDocsService from 'libs/services/menu';
 * ...
 * const legal = new LegalDocsService();
 */
export default class LegalDocsService extends BaseService {

    /**
     * Use this method to get all organization's available legal documents.
     *
     * @param {String} orgName the organization's name
     * @param {Boolean} [cached=false] whether use the cache or not
     * @param {string} [event_id] event_id to be passed as a query parameter
     *
     * @return {Promise<Array>} the list of legal documents
     */
    async getLegalDocumentsForOrg(orgName, cached = false, event_id) {
        let url = this.interpolate(LEGAL_DOCUMENTS_FOR_ORG_ENDPOINT, { orgName });
        if (event_id) { url = `${url}?event_id=${encodeURIComponent(event_id)}`; }
        const requestMethod = cached ? this.getCached : this.get;
        const { data } = await requestMethod.apply(this, [url, { withCredentials: true }]);

        return data;
    }

    /**
     * Returns a list of all legal documents templates.
     *
     * @return {Promise<Array>} the list of legal document templates
     */
    async getAllDocumentTemplates() {
        const url = this.interpolate(LEGAL_DOCUMENTS_TEMPLATES_ENDPOINT);
        const { data } = await this.get(url, { withCredentials: true });

        return data;
    }

    /**
     * Given an ID this method returns the associated legal document template.
     *
     * @param {String} id the template id
     *
     * @return {Promise<Object>} the legal document template
     */
    async getDocumentTemplate(id) {
        const url = this.interpolate(LEGAL_DOCUMENTS_TEMPLATE_ENDPOINT, { id });
        const { data } = await this.get(url, { withCredentials: true });

        return data;
    }

    /**
     * Given an ID this method returns the URL of the associated legal document.
     *
     * @param {String} id the legal document id
     *
     * @returns {String} the legal document URL
     */
    getDocumentPreviewUrl(id) {
        return this.interpolate(LEGAL_DOCUMENT_URL, { id });
    }

    /**
     * Starting from an organization name and the legal document data this method
     * persists it in the database.
     *
     * @param {String} orgName the organization's name
     * @param {Object} doc the data needed to create the legal document.
     *
     * @return {Promise<Object>} the legal document creation response
     */
    async createLegalDocumentForOrg(orgName, doc) {
        const url = this.interpolate(LEGAL_DOCUMENTS_FOR_ORG_ENDPOINT, { orgName });
        const { data } = await this.post(url, doc, { withCredentials: true });

        return data;
    }

    /**
     * Given an ID this method returns the associated legal document.
     *
     * @param {String} id the legal document id
     *
     * @return {Promise<Object>} the legal document
     */
    async getLegalDocument(id) {
        const url = this.interpolate(LEGAL_DOCUMENT_ENDPOINT, { id });
        const { data } = await this.get(url, { withCredentials: true });

        return data;
    }

    /**
     * Given an ID and updated data object, this method tries to update the
     * associated legal document.
     *
     * @param {String} id the legal document id
     * @param {Object} updates the updated data object
     *
     * @return {Promise<Object>} the update response
     */
    async updateLegalDocument(id, updates) {
        const url = this.interpolate(LEGAL_DOCUMENT_ENDPOINT, { id });
        const { data } = await this.post(url, updates, { withCredentials: true });

        return data;
    }

    /**
     * Given an ID this method tries to delete the associated legal document.
     *
     * @param {String} id the legal document id
     *
     * @return {Promise<Object>} the legal document deletion response
     */
    async deleteLegalDocument(id) {
        const url = this.interpolate(LEGAL_DOCUMENT_ENDPOINT, { id });
        const { data } = await this.delete(url, { withCredentials: true });

        return data;
    }

    /**
     * Gets accepted legal docs in org for user with given email
     *
     * @param {String} orgName organization's name
     * @param {String} email user's email
     *
     * @return {Promise<{ok:boolean,accepted_legal_docs:Object[]}>}
     */
    async getAcceptedLegalDocs(orgName, email) {
        const postData = { email };
        const url = this.interpolate(ACCEPTED_LEGAL_DOCS_ENDPOINT, { orgName });
        const { data } = await this.post(url, postData, { withCredentials: true });

        return data;
    }

    /**
     * Given an url and a set of data this method interpolates the strings.
     *
     * @param {String} url the url to interpolate
     * @param {Object} [data] the data to use to interpolate the url
     *
     * @return {String} the interpolated URL
     */
    interpolate(url, data = {}) {
        return url
            .replace('{{baseUrl}}', CENTRAL_BACKSTAGE_URL)
            .replace('{{id}}', data.id)
            .replace('{{orgName}}', data.orgName);
    }

    /**
     * Given an organization name, this method gets the legal manager path
     *
     * @param {String} orgName the organization name
     *
     * @returns {String} the organization's legal document manager URL
     */
    getOrgLegalDocumentManagerPath(orgName) {
        return LEGAL_DOCUMENT_MANAGER_PATH.replace('{{orgName}}', orgName);
    }

    /**
     * @param {object} [doc]
     * @returns {string[]}
     */
    getLegalDocLanguages(doc = {}) {
        const keys = ['requirements', 'title', 'text', 'url'];
        const langs = new Set();
        for (const key of keys) {
            if (doc[key]) {
                for (const lang of Object.keys(doc[key])) {
                    langs.add(lang);
                }
            }
        }
        return Array.from(langs);
    }
}
