"use strict";

module.exports = /* @ngInject */["$route", "$scope", function ($route, $scope) {
  var currentRouteDescriptor = ($route.current || {}).$$route || {};
  $scope.downloadFpTypes = currentRouteDescriptor.downloadFpTypes;
  $scope.showOwnerSelection = !(currentRouteDescriptor.showOwnerSelection === false);
  $scope.apiUploadType = currentRouteDescriptor.uploadType || 'autodetect';
  return $scope.apiUploadOpts = {
    ownership: 'private'
  };
}];