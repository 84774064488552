// bsAtWho

module.exports = function() {
    return {
        restrict: 'AE',
        scope: {
            bsAtWho: '='
        },
        link(scope, element) {
            return scope.$watch('bsAtWho', function(config) {
                if (!config) { return; }
                element.atwho('destroy');
                return element.atwho(config);
            }, true);
        }
    };
};
