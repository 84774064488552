"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _ = require('underscore');
angular.module('backstage.controllers.widgets').controller('TargetsExceptionsTagListCtrl', /* @ngInject */["$scope", "eventService", "metadataService", function ($scope, eventService, metadataService) {
  var eventId = eventService.getCurrentEvent()._id;
  var originalRuleset = [];
  $scope.editingRuleIdx = -1;
  $scope.rulesetMetadata = [];
  $scope.originalRuleset = originalRuleset;
  var metadataForPerson = metadataService.getCachedMetadataForType(eventId, 'person', true);

  // replace field key with `in_app` in dynamic extension fields
  metadataForPerson = metadataService.displaceInAppFieldsAsFieldKeys(metadataForPerson);

  // add fp_ext_id as a field here
  metadataForPerson.fp_ext_id = {
    label: 'Unique Identifier'
  };

  // metadata processing methods

  // the post processing routine will ensure all fields of external kind have the single_doc flag set
  var postProcessMetadataFieldDescriptors = function postProcessMetadataFieldDescriptors(fieldDescriptors) {
    return fieldDescriptors.map(function (descriptor) {
      if (!_.isObject(descriptor)) {
        descriptor = {};
      }
      var kindOptions = _.isObject(descriptor.kind_options) ? descriptor.kind_options : {};
      if (descriptor.kind !== 'external' || kindOptions.single_doc) {
        return descriptor;
      }

      // _.clone is shallow, need to do both hashes
      var descriptorClone = _.clone(descriptor);
      descriptorClone.kind_options = _.clone(kindOptions);
      descriptorClone.kind_options.single_doc = true;
      return descriptorClone;
    });
  };

  // move ruleset into rulesetMetadata
  this.primeRulesetEditorMetadata = function () {
    var _originalRuleset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    // prime ruleset metadata with empty arrays
    $scope.rulesetMetadata.length = 0;
    for (var i = 0; i < _originalRuleset.length; i += 1) {
      $scope.rulesetMetadata.push([]);
    }
    _originalRuleset.forEach(function (ruleObj, ruleIdx) {
      var fields = _.chain(metadataForPerson).pick(_.keys(ruleObj)).tap(metadataService.convertMetadataHashToOrderedList).values().value();
      fields = postProcessMetadataFieldDescriptors(fields);
      return angular.copy(fields, $scope.rulesetMetadata[ruleIdx]);
    });

    // fill the currently displayed metadata
    // ⚑
    // TODO: this stops a metadata reload on every char entry
    var _editingFields = _.pluck($scope.editingFields, 'field');
    var _newMetadata = _.pluck($scope.rulesetMetadata[$scope.editingRuleIdx], 'field');
    if (JSON.stringify(_editingFields) !== JSON.stringify(_newMetadata)) {
      $scope.editingFields = ($scope.rulesetMetadata[$scope.editingRuleIdx] || []).map(function (field, index) {
        return _objectSpread(_objectSpread({}, field), {}, {
          // clone the object because we want to modify the oder
          order: index // order fields by how rules were added
        });
      });
    }
  };

  // manipulation methods

  $scope.addRule = function () {
    originalRuleset.push({});
    return originalRuleset.length - 1;
  };
  $scope.removeRule = function (ruleIdx) {
    if (ruleIdx === -1) {
      return ruleIdx;
    }
    if (!confirm('Are you sure you want to remove this rule?')) {
      return ruleIdx;
    }
    originalRuleset.splice(ruleIdx, 1);
    return -1;
  };
  $scope.hasRuleConditions = function (ruleIdx) {
    var ruleConditions = originalRuleset[ruleIdx];
    return ruleConditions && _.keys(ruleConditions).length > 0;
  };
  $scope.showConditionSummaryOverflowEllipsis = function (conditions) {
    return _.keys(conditions).length > 3;
  };
  $scope.getTagFieldLabelForCondition = function (conditionKey) {
    return (metadataForPerson[conditionKey] ? metadataForPerson[conditionKey].label : undefined) || conditionKey;
  };
  $scope.getTagValueLabelForCondition = function (conditionVal) {
    if (_.isArray(conditionVal)) {
      return '[...]';
    } else {
      return conditionVal ? conditionVal.toString() : undefined;
    }
  };
}]);