"use strict";

module.exports = /* @ngInject */["$rootScope", "bulkUpdateService", "fieldDescriptor", "model", function ($rootScope, bulkUpdateService, fieldDescriptor, model) {
  if (!model[fieldDescriptor.field]) {
    console.info('Creating deeplink');
    createToken().then(function (linkDocId) {
      model[fieldDescriptor.field] = linkDocId;
    }).catch(function () {
      alert('There was an error configuring the link.');
    });
  }
  function createToken() {
    var randStr1 = Math.random().toString(36).substring(2);
    var randStr2 = Math.random().toString(36).substring(2);
    var randStr3 = Math.random().toString(36).substring(2);
    var linkDocId = randStr1 + randStr2 + randStr3;
    var event = $rootScope.event;
    var doc = {
      fp_type: 'accredited_link',
      fp_owner: 'private',
      fp_ext_id: linkDocId,
      link_family: fieldDescriptor.kind_options.linkFamily,
      permissions: [{
        type: 'event_permission',
        role: fieldDescriptor.kind_options.permissionRole || 'manager',
        event_id: event._id
      }]
    };
    return bulkUpdateService.createDocs(event._id, event.node, 'accredited_link', doc).then(function () {
      return linkDocId;
    });
  }
}];