// directive to validate CIDR in IP Address
module.exports = () => ({
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {
        ctrl.$validators.validCidr = (modelValue, viewValue) => {
            const value = modelValue || viewValue;
            const cidrValue = parseInt(value, 10);

            return cidrValue > 0 && cidrValue < 33;
        };
    }
});
