class BrandedAppWelcomeMessageComponent {
    $onInit() {
        return this.showWelcomeMessage = !this.branding.fp_last_upload;
    }

    $onChanges(changes) {
        if (!changes.branding || !changes.branding.currentValue) { return; }
        return this.showWelcomeMessage = !changes.branding.currentValue.fp_last_upload;
    }
}

module.exports = angular.module('backstage.components.branded-app.branded-app-welcome-message', [])
    .component('brandedAppWelcomeMessage', {
        templateUrl: '/src/angular/components/branded-app/branded-app-welcome-message/branded-app-welcome-message.html',
        bindings: {
            branding: '<'
        },
        controller: BrandedAppWelcomeMessageComponent
    }).name;
