angular.module('backstage.services').factory('appScriptService', ['$rootScope', ($rootScope) => ({
    /**
     * Call an appscript by providing path and parameters
     *
     * @example
     * appScriptService
     * .callAppScript('questionnaire/get-questionnaires')
     * .then(function (questionnaires) {
     *     console.log('Questionnaires:', questionnaires);
     * })
     *
     * @param {string} path full path to the appscript
     * @param {object} [params={}] parameters to be passed to the appscript
     * @param {boolean} format of result: if returnData is true, pre-extract
     * the data field from the result, otherwise return result as is
     * @return Promise<any> promise for a returned value from the appscript
     */
    async callAppScript(path, params, returnData = true) {
        try {
            return await window.BSTG.services.appScripts.callAppScript($rootScope.event._id, path, params, returnData);
        } catch (error) {
            let resp = error;
            if (error.response) {
                resp = error.response;

                if (returnData) {
                    try {
                        resp = JSON.parse(resp.data);
                    } catch (err) {
                        resp = resp.data;
                    }
                }
            }

            console.error('[appScriptService] An error occurred:', resp);
            throw resp;
        }
    }

})]);
