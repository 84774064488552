module.exports = () => ({
    link(scope, elem) {
        const updateImageSourceFn = function() {
            const imgSrc = elem.attr('src');

            return elem.attr({
                src: imgSrc
            });
        };

        // ... and when we receive a "current-nav-item-icon-changed" event
        return scope.$on('event:current-nav-item-icon-changed', updateImageSourceFn);
    }
});
