import { memoize } from 'lodash';

/**
 * Load script with given source
 * @param {String} src
 * @return {Promise}
 */
module.exports.loadScript = memoize(src => new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.onerror = reject;
    script.onload = resolve;
    (document.head || document.getElementsByTagName('head')[0]).appendChild(script);
    script.src = src;
}));
