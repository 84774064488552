import BaseService from '../base-service';

// Constants
import { API_BASE_PATH } from 'libs/utils/constants';


const TAG_LIST_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/tags`;

const TAG_DOC_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/docbyid/{{documentId}}/tags`;
/**
 * Provide service related to Content Hubs Tags
 *
 * @example
 * import TagService from 'libs/services/content-hub/tag';
 * ...
 * const tagService = new TagService();
 */
export default class TagService extends BaseService {
    /**
     * Transform a tag list into a list appropriate for the multi select
     * @param {String[]} tags
     * @returns A list of value/label object suited for multi select
     */
    static tagsToDisplayTags(tags) {
        return tags.map(tag => { return { value: tag, label: tag }; });
    }

    /**
     * Fetches all tags for a given content hub
     * @param {String} contentHubId
     * @returns A list of tags for a multi select
     */
    async getAllTags(contentHubId) {
        const url = this.buildUrl(TAG_LIST_ENDPOINT, { eventId: contentHubId });
        const { data } = await this.get(url);
        return TagService.tagsToDisplayTags(data);
    }

    /**
     * Update the tags of a given content on a given content hub.
     * @param {String} contentHubId
     * @param {String} documentId
     * @param {Object[]} tags
     * @returns the new tags for that content
     */
    async updateTags(contentHubId, documentId, tags) {
        const tagValues = tags.map(tag => tag.value);
        const url = this.buildUrl(TAG_DOC_ENDPOINT, { eventId: contentHubId, documentId });
        const { data } = await this.post(url, tagValues);
        return TagService.tagsToDisplayTags(data);
    }
}
