module.exports = [() => ({
    restrict: 'AC',
    link(scope, elem, attrs) {
        // issue with pre-58 version of firefox where watch is defined on Object prototype, doing crazy things
        // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Object/watch
        if (!attrs.hasOwnProperty('watch')) {
            return;
        }
        return scope.$watch(attrs.watch, function(newValue, oldValue) {
            let delayMs;

            if ((newValue === null || newValue === undefined) &&
                (oldValue === null || oldValue === undefined)) {
                return;
            }
            let addFadeInClassFn = () => elem.addClass('in');

            if (!(delayMs = attrs.delayMs)) {
                return addFadeInClassFn();
            } else {
                setTimeout(addFadeInClassFn, parseInt(delayMs));
                return elem.removeClass('in');
            }
        });
    }
})];
