"use strict";

// bsRevealingInput

var defaultPropsToToggle = ['readonly'];
var toggleElementEditMode = function toggleElementEditMode(elem, propToToggle) {
  var propsToToggle = propToToggle ? [propToToggle] : defaultPropsToToggle;
  elem.toggleClass('in');
  return Array.from(propsToToggle).map(function (propName) {
    return elem.prop(propName, function (index, oldValue) {
      return !oldValue;
    });
  });
};
module.exports = /* @ngInject */["$rootScope", function ($rootScope) {
  return {
    require: '?^bsFormWithRevealingInputs',
    restrict: 'AC',
    link: function link(scope, elem, attrs) {
      if (attrs === null) {
        attrs = {};
      }
      if (attrs.bsRevealingInput !== 'true') return;
      var elems = [elem].concat(elem.find('button, input, select, textarea, .ql-editor'));
      return elems.forEach(function (_elem) {
        var toggleElementEditModeFn = toggleElementEditMode.bind(this, _elem, attrs.propToToggle);
        scope.$on('formEditModeToggled', toggleElementEditModeFn);
        toggleElementEditModeFn();
        return _elem.click(function () {
          // ignore if we are in edit mode
          if (!_elem.is('.in')) {
            return;
          }
          $rootScope.$broadcast('formEditModeToggled');
          return $rootScope.setNavigationWarningMessage('You may have made changes here. ' + 'Discard them?');
        });
      });
    }
  };
}];