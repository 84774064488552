const { isV4, isV6 } = require('src/utils/ip');

// directive to validate IP addresses
module.exports = () => ({
    restrict: 'A',
    require: 'ngModel',
    scope: {
        bsIpValidation: '@'
    },
    link: (scope, element, attrs, ctrl) => {
        ctrl.$validators.validIp = (modelValue, viewValue) => {
            // allow cidr value to be specified,
            // so it could be either just an IP or an IP range.
            const allowRange = scope.bsIpValidation === 'allowRange';
            const value = modelValue || viewValue || '';
            const parts = value.split('/');
            const withoutCidr = parts.length === 1;

            if (withoutCidr || parts.length === 2 && allowRange) {
                const [IP, cidr] = parts;
                const cidrValue = parseInt(cidr, 10);

                // ip v4 case
                if (isV4(IP)) {
                    return withoutCidr || allowRange && cidrValue > 0 && cidrValue < 33;
                } else if (isV6(IP)) {
                    return withoutCidr || allowRange && cidrValue > 0 && cidrValue < 128;
                }
            }

            return false;
        };
    }
});
