import BaseService from './base-service';
import categoriesMap from './categories-map.json';
import { partition } from 'lodash';
import { localeCompareByKey } from 'libs/utils/collections';

/**
 * Provides methods necessary for building the menu
 *
 * @example
 *
 * import MenuService from 'libs/services/menu';
 * ...
 * const menu = new MenuService();
 */
export default class MenuService extends BaseService {
    /**
     * Adds bullets and counters to marketplace menu category
     *
     * @param {Object[]} categories menu categories
     * @param {Number} pkgsToUpdate number of packages to update - marketplace bullet or null if we fail to fetch data
     */
    addMarketplaceBullets(categories, pkgsToUpdate) {
        if (!pkgsToUpdate) {
            return;
        }

        const mktCategory = categories.find(cat => cat.name === 'marketplace');

        mktCategory.bullet = true;
        mktCategory.counter = {
            count: pkgsToUpdate,
            tooltip: 'marketplace.updatable_packages'
        };

        const installed = mktCategory.unordered_links.find(link => link.name === 'installed');

        if (installed) {
            installed.counter = mktCategory.counter;
        }
    }

    /**
     * Matches modules into correct categories
     *
     * @param {Array<Object>} modules module routes from global blueprint
     * @param {Array<Object>} categories menu categories
     * @returns {Array<Object>} sorted menu categories with modules assigned to them
     */
    categorizeModules(modules, categories) {
        const filteredModules = this.filterOutModulesWithoutRoutes(modules);
        const menuControllers = [];

        // filter all controllers defining their own menu item and add them to modules
        for (const mod of filteredModules) {
            for (const ctrl of Object.values(mod.controllers)) {
                if (ctrl.bstgMenu) {
                    menuControllers.push(ctrl);
                }
            }
        }

        const allModules = filteredModules.concat(menuControllers);

        // All modules that are not listed in any category go here.
        const fallbackCategory = categories.find(cat => cat.name === 'other');

        for (const module of allModules) {
            let category;

            if (module.bstgMenu) {
                const categoryName = module.bstgMenu.category;

                category = categories.find(cat => categoryName === cat.name);
            } else if (module.category) {
                category = categories.find(cat => module.category === cat.name);
            } else {
                category = categories.find(cat =>
                    cat.modules.includes(module.name) || (cat.additional_types && cat.additional_types.includes(module.type))
                );
            }

            if (category) {
                module.category = category.name;
                let linksProperty;

                if (module.bstgMenu) {
                    linksProperty = module.bstgMenu.type || 'links';
                } else {
                    linksProperty = category.modules.includes(module.name) ? 'links' : module.type;
                }

                if (category.explode) {
                    category[linksProperty].push(...Object.values(module.controllers));
                } else {
                    category[linksProperty].push(module);
                }

                category.hasSubmenu = category.hasSubmenu || category[linksProperty].length > 1 || category.submenu;
            } else {
                fallbackCategory.links.push(module);
                fallbackCategory.hasSubmenu = fallbackCategory.links.length > 0;
            }
        }

        // remove empty categories
        categories = categories.filter(cat =>
            cat.links.length + cat.additional_types.reduce((acc, addType) => acc + cat[addType].length, 0)
        );

        return this.sortCategories(categories);
    }

    /**
     * Returns the categories needed for the main menu navigation.
     *
     * @returns {Object} an object which describes the categories used for the main menu.
     */
    getAndInitializeCategories() {
        return Object.values(categoriesMap)
            .map(category => {
                category.links = [];
                category.unordered_links = [];

                return category;
            });
    }

    /**
     * Filters module routes for the menu
     *
     * @private
     *
     * @param {Array<Object>} modules module routes from global blueprint
     * @returns {Array<Object>} filtered modules
     */
    filterOutModulesWithoutRoutes(modules) {
        return modules
            .filter(module =>
                // Pick only modules that declare controllers
                module.controllers &&
                (!module.type || module.type === 'setting')
            );
    }

    /**
     * Sorts menu categories
     *
     * @private
     *
     * @param {Array<Object>} categories menu categories
     * @returns {Array<Object>} sorted menu categories
     */
    sortCategories(categories) {
        for (const category of categories) {
            // special sorting for `analytics`
            if (category.name === 'analytics' || category.name === 'dev') {
                category.links = category.links.sort((a, b) => a.order - b.order);
                continue;
            }

            // sort links by `priority` in `bstgMenu` and `order` property defined in category
            // sort remaining links alphabetically
            const [modsWithPriority, modsWithoutPriority] = partition(category.links, mod => mod.bstgMenu && mod.bstgMenu.priority);
            const [modsWithOrder, modsWithoutOrder] = partition(modsWithoutPriority, mod => category.order.includes(mod.name));

            const links = modsWithPriority.sort((a, b) => a.bstgMenu.priority - b.bstgMenu.priority);

            category.links = links.concat(
                modsWithOrder.sort((a, b) => category.order.indexOf(a.name) > category.order.indexOf(b.name) ? 1 : -1)
            );
            category.unordered_links = modsWithoutOrder.sort((a, b) => localeCompareByKey('title', a, b));

            // sort additional types using the `order` property from package
            for (const addType of category.additional_types) {
                category[addType] = category[addType].sort((a, b) => a.order - b.order);
            }
        }

        return categories;
    }

    /**
     * Returns first link object from given category
     *
     * @param {Object} category menu category
     * @returns {Object|null} first link from category or null if category has none
     */
    getFirstCategoryLink(category) {
        const linksKeys = category.additional_types && category.additional_types.concat('links', 'unordered_links');

        for (const key of linksKeys) {
            if (category[key].length) {
                return category[key][0];
            }
        }

        return null;
    }
}
