import { IS_AWS, DEFAULT_APP_ROOT_DOMAIN, CENTRAL_BACKSTAGE_URL, EMAIL_MODAL_EVENT } from 'libs/utils/constants';

angular.module('backstage.services', ['ngRoute'])

    // # # # # # # #
    // app constants
    // # # # # # # #
    .value('APP_VERSION', BSTG.version)
    .value('APP_BASE_PATH', '/')

    .value('APP_SUBDOMAIN', 'backstage')
    .value('DEFAULT_APP_ROOT_DOMAIN', DEFAULT_APP_ROOT_DOMAIN)

    // Unfortunately we have to keep these because packages use it...
    .constant('isMSIE8', false)
    .constant('isMSIE9', false)
    .constant('isOldMSIE', false)

    .value('BASE_API_ENDPOINT', '/api/v1')
    .value('CENTRAL_BACKSTAGE_URL', CENTRAL_BACKSTAGE_URL)
    .value('IS_AWS', IS_AWS)
    .value('ROUTER_INFO_ENDPOINT', '/api/v1/info')
    .value('USER_SESSION_ENDPOINT', '/api/v1/session')
    .value('USER_QUERY_ENDPOINT', '/api/v1/users')
    .value('ORG_SERVICE_ENDPOINT', CENTRAL_BACKSTAGE_URL + '/api/v1/orgs')
    .value('SERVERS_SERVICE_ENDPOINT', CENTRAL_BACKSTAGE_URL + '/api/v1/servers')
    .value('USER_SERVICE_ENDPOINT', CENTRAL_BACKSTAGE_URL + '/api/v1/users')
    .value('EVENT_SERVICE_ENDPOINT', CENTRAL_BACKSTAGE_URL + '/api/v1/events')
    .value('ENTITLEMENTS_SERVICE_ENDPOINT', CENTRAL_BACKSTAGE_URL + '/api/v1/entitlements')
    .value('ENTITLEMENT_PRODUCT_CODES_SERVICE_ENDPOINT', CENTRAL_BACKSTAGE_URL + '/api/v1/product-codes')
    .value('SEND_USER_PASS_TOKEN_ENDPOINT', CENTRAL_BACKSTAGE_URL + '/api/v1/send-password-reset-token')
    .value('CONSUME_USER_PASS_TOKEN_ENDPOINT', CENTRAL_BACKSTAGE_URL + '/api/v1/consume-password-reset-token')
    .value('REQUEST_ACCOUNT_VALIDATION_ENDPOINT', CENTRAL_BACKSTAGE_URL + '/api/v1/users/request-account-validation')
    .value('APP_BRANDING_SERVICE_ENDPOINT', '/api/v1/app-brandings')
    .value('APP_PUBLISHING_SERVICE_ENDPOINT', '/api/v1/publishing-requests')
    .value('APP_PUBLISHING_ACCOUNTS_SERVICE_ENDPOINT', '/api/v1/publishing-accounts')
    .value('APP_PUBLISHING_CERTIFICATES_SERVICE_ENDPOINT', '/api/v1/push-certificates')

    .value('MAX_FILE_UPLOAD_SIZE_MBS', 100)

    .value('ROUTE_PATH_EVENT_NODE_REGEX', /\/\/(.+)\..+\..+\.[a-z]+\//)

    .value('BACKSTAGE_SKELETON_PACKAGE_NAME', 'backstage-skeleton')
    .value('BACKSTAGE_MIGRATION_PACKAGE_NAME', 'backstage-migration-oldbackstage')

    .value('BACKSTAGE_NAV_SPOTMAN_SEPARATOR_PREFIX', 'bstg_separator')
    .value('BACKSTAGE_NAV_SPOTMAN_LAUNCHER_PREFIX', 'bstg_launcher')

    .value('EMAIL_MODAL_EVENT', EMAIL_MODAL_EVENT)
    // accredited links and fullscreen modes constants
    .value('ACCREDITED_LINK_TOKEN_PARAM', 'accredited_link_token')
    .value('BLUEPRINT_FULLSCREEN_PARAM', 'fullscreen')

    // module help content config
    .value('BACKSTAGE_HELP_CONTENT_DOC_ID', '_design/backstage_help_content')

    // task progress modal constants
    .value('TASK_PROGRESS_MODAL_COMPLETION_DELAY', 1000)

    // externals and backlinks constants
    .value('BACKLINKS_QUERY_HANDLER', 'victorinox/query-backlinks')
    .value('BACKLINKS_REFRESH_HANDLER', 'victorinox/refresh-backlinks')

    // timezone and uberapp
    .value('DEFAULT_TIMEZONE_IDENTIFIER', 'Europe/Zurich')
    .value('DEFAULT_UBERAPP_ICON_FILENAME', 'uberapp.png')

    // SpotMe organisation id
    .value('SPOTME_ORG_ID', 'org.couchdb.user:SpotMe')

    // # # # #
    .factory('lastViewedParticipantService', function() {
        let lastViewedParticipant;
        let eventId = (lastViewedParticipant = null);

        return {
            setLastViewedParticipant(_participant, _eventId) {
                eventId = _eventId;
                return lastViewedParticipant = _participant;
            },

            getLastViewedParticipant(_eventId) {
                if (_eventId === eventId) { return lastViewedParticipant; } else { return lastViewedParticipant = null; }
            }
        };
    })

    // ripped from http://stackoverflow.com/a/18609594
    .factory('recursionHelper', ['$compile', $compile => {
        return {
            /*
            Manually compiles the element, fixing the recursion loop.
            @param element
            @param [link] A post-link function, or an object with function(s) registered via pre and post properties.
            @returns An object containing the linking functions.
            */
            compile(element, link) {
                // Normalize the link parameter
                if (angular.isFunction(link)) { link = { post: link }; }

                // Break the recursion loop by removing the contents
                let contents = element.contents().remove();
                let compiledContents = undefined;

                return {
                    pre: ((link && link.pre) ? link.pre : null),
                    /*
                Compiles and re-adds the contents
                */
                    post(scope, element) {

                        // Compile the contents
                        if (!compiledContents) { compiledContents = $compile(contents); }

                        // Re-add the compiled contents to the element
                        compiledContents(scope, function(clone) {
                            element.append(clone);
                        });

                        // Call the post-linking function, if any
                        if (link && link.post) { link.post.apply(null, arguments); }
                    }
                };
            }
        };
    }]);

