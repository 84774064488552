import { isFunction } from 'lodash';

export default class TriggerService {

    constructor() {
        window.triggers = {};
        this.handlers = {};
        this.environment = BSTG.env;
        this.hasSuperadminRole = undefined;
    }

    /**
     * @param {boolean} hasSuperadminRole
     */
    init(hasSuperadminRole) {
        this.hasSuperadminRole = hasSuperadminRole;
    }

    /**
     * add a global access to the passed error triggering function
     * @param name
     * @param fun
     */
    addTrigger(name, fun) {
        if (!isFunction(fun)) {
            return;
        }

        if (this.environment === 'production' && !this.hasSuperadminRole) {
            return;
        }

        if (!this.handlers[name]) {
            this.handlers[name] = [];
        }
        this.handlers[name].push(fun);

        window.triggers[name] = (...arg) => {
            for (const handler of this.handlers[name]) {
                if (isFunction(handler)) {
                    setTimeout(() => handler(...arg));
                }
            }
            console.log(`successfully triggered ${name}`);
        };

        return () => { this._removeTrigger(name, fun); };
    }

    /**
     * remove the global access of an error triggering function
     * @param name
     * @param fun
     */
    _removeTrigger(name, fun) {
        this.handlers[name] = this.handlers[name].filter((o) => {
            return o !== fun;
        });
    }
}
