import BaseService from './base-service';
import { API_BASE_PATH } from 'libs/utils/constants';

export const LOWER_THIRD_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/lower-thirds`;

export default class LowerThirdService extends BaseService {

    /**
     * Returns lower thirds document
     *
     * @param {String} eventId event ID
     * @param {String} liveStreamId live stream ID
     * @returns {Promise<Object>}
     */
    async getDoc(eventId, liveStreamId) {
        const url = this.getUrl(eventId, liveStreamId);

        const response = await this.get(url);
        return response.data;
    }

    /**
     * Creates or updates  lower third
     *
     * @param {String} eventId event ID
     * @param {Object} lowerThird lower third document
     * @returns {Promise<Object>} updated lower third document
     */
    async createOrUpdateDoc(eventId, lowerThird) {
        const url = this.getUrl(eventId, lowerThird.parent_doc_id);

        const response = await this.put(url, lowerThird);
        return response.data;
    }

    getUrl(eventId, liveStreamId) {
        return LOWER_THIRD_ENDPOINT.replace('{{eventId}}', eventId).replace('{{liveStreamId}}', liveStreamId);
    }
}
