angular.module('backstage.directives.externals').directive('bsTargetsOrExceptionsTagList', [() =>
    ({
        templateUrl: '/static/partials/components/targets-exceptions-tag-list.html',
        require: ['bsTargetsOrExceptionsTagList', '^ngModel'],
        scope: {
            noRulesDisplayText: '@',
            saveFn: '&'
        },
        controller: 'TargetsExceptionsTagListCtrl',
        link(scope, elem, attrs, controllers) {
            let editingRuleIdx = null;

            let [ctrl, ngModel] = Array.from(controllers);

            let DEFAULT_TARGETS_PROP_NAME = 'targets';

            let rulesPropName = attrs.rulesPropName || (attrs.rulesPropName = DEFAULT_TARGETS_PROP_NAME);
            let rulePropNameIsTargetsProp = rulesPropName === DEFAULT_TARGETS_PROP_NAME;

            scope.useTargetColourScheme = rulePropNameIsTargetsProp;
            scope.visibilityWording = rulePropNameIsTargetsProp ? 'shown to' : 'hidden from';

            // translate the rules into a form that works best for us
            scope.$watch(() => (ngModel.$modelValue || {})[rulesPropName], modelValue => {
                const _originalRuleset = _.filter((modelValue || []), (val) => !_.isEmpty(val));

                ctrl.primeRulesetEditorMetadata(_originalRuleset);
                if (!_.isEqual(_originalRuleset, scope.originalRuleset)) {

                    // Since directives in angularjs are subject to different lifecycle
                    // than components, we can't rely on some $destroy hook. For this reason
                    // the previous condition is not enough to prevent unwanted overwrites
                    // (I.E. switch between models).
                    //
                    // Overwrite only if some editing happened
                    if (editingRuleIdx !== scope.editingRuleIdx) {
                        editingRuleIdx = scope.editingRuleIdx;
                        return angular.extend(scope.originalRuleset, _originalRuleset);
                    }
                }
            });

            scope.$watch(() => JSON.stringify(scope.originalRuleset), () => {
                if (!_.isArray(scope.originalRuleset)) { return; }
                ctrl.primeRulesetEditorMetadata(scope.originalRuleset);

                // unfortunately the app needs this to be null when there are no rules to exhibit the behaviour we expect
                return ngModel.$modelValue[rulesPropName] =
                    scope.originalRuleset.length ? scope.originalRuleset : null;
            });

            scope.onSaveClick = function() {
                scope.editingRuleIdx = -1;
            };

            scope.onDeleteClick = function() {
                scope.editingRuleIdx = scope.removeRule(scope.editingRuleIdx);
            };
        }
    })

]);
