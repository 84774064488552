"use strict";

// bsCustomFormFieldValidations

module.exports = /* @ngInject */["appScriptService", "$q", "$timeout", function (appScriptService, $q, $timeout) {
  return {
    require: 'ngModel',
    link: function link(scope, element, attrs, ngModel) {
      var validatorPath = attrs.bsCustomFormFieldValidations;
      if (!validatorPath) return;
      ngModel.$asyncValidators.customValidator = _debounce(validate, 400);

      /**
       * Validates asynchronously the model using the validator path appscript.
       * The appscript should return `done(null, true/false)` depending on the validity
       * of the field. If the appscript fails the field is marked as valid.
       *
       * @param {any} modelValue
       * @param {any} viewValue
       * @returns {Promise} Whether the field is valid or not
       */
      function validate(modelValue, viewValue) {
        return appScriptService.callAppScript(validatorPath, {
          fieldValue: viewValue
        }).catch().then(function (response) {
          return response ? $q.resolve() : $q.reject();
        }, function (error) {
          // failing appscript will not be considered the field as invalid,
          // only proper falsy value will.
          console.error("Validation appscript (".concat(validatorPath, ") failed for element: "), element[0], error);
          return $q.resolve();
        });
      }

      /**
       * A helper function to debounce the calls to the validator appscript
       * the _.debounce function doesn't work here as it doesn't return the promise
       * from the original function
       *
       * @param {Function} func
       * @param {Number} wait
       * @returns {Promise} The promise returned by the function 'func'
       */
      function _debounce(func, wait) {
        var timeout;
        var deferred = $q.defer();
        return function () {
          var context = this,
            args = arguments;
          var later = function later() {
            timeout = null;
            deferred.resolve(func.apply(context, args));
            deferred = $q.defer();
          };
          if (timeout) {
            $timeout.cancel(timeout);
          }
          timeout = $timeout(later, wait);
          if (!timeout) {
            deferred.resolve(func.apply(context, args));
            deferred = $q.defer();
          }
          return deferred.promise;
        };
      }
    }
  };
}];