"use strict";

function DataSourceProvider() {
  this.$get = /* @ngInject */["$injector", "$log", function ($injector, $log) {
    return function (source) {
      if (!_.isObject(source) || !source.service || !source.method) {
        $log.error('Invalid service', source);
        return null;
      }
      var service = source.service,
        method = source.method;
      var svc = null;
      try {
        svc = $injector.get(service);
      } catch (error) {
        $log.error("Unable to inject requested service ".concat(service), error);
      }
      return {
        fetch: function fetch() {
          if (!svc) return;
          return svc[method].apply(svc, arguments);
        }
      };
    };
  }];
}
angular.module('backstage.services').provider('dataSourceProvider', DataSourceProvider);