"use strict";

// formTooltipAndHelp

var FormTooltipAndHelpCtrl = /* @ngInject */function FormTooltipAndHelpCtrl($scope, helpService) {
  var vm;
  $scope.vm = vm = {};
  vm.onHelpButtonClick = function () {
    var help = $scope.fieldDescriptor.help || {
      folder: '',
      file: ''
    };
    return helpService.openHelp(help.folder, help.file);
  };
  return vm.getHelpTooltip = function () {
    return $scope.fieldDescriptor.tooltip || 'Click for help';
  };
};
FormTooltipAndHelpCtrl.$inject = ["$scope", "helpService"];
module.exports = /* @ngInject */function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      fieldDescriptor: '='
    },
    controller: FormTooltipAndHelpCtrl,
    template: "            <span class=\"form-tooltip-and-help\">\n                <a ng-if=\"::fieldDescriptor.tooltip && !fieldDescriptor.help\"\n                class=\"icon-info-sign\"\n                strap-tooltip\n                title=\"{{::fieldDescriptor.tooltip}}\"\n                data-placement=\"right\"\n                toggle=\"tooltip\"></a>\n            \n                <a ng-if=\"::fieldDescriptor.help\"\n                ng-click=\"vm.onHelpButtonClick()\"\n                class=\"icon-info-sign\"\n                style=\"cursor: pointer\"\n                data-placement=\"right\"\n                strap-tooltip\n                title=\"{{::vm.getHelpTooltip()}}\"></a>\n            </span>            "
  };
};