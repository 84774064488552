const { FORMS } = require('src/branded-apps-metadata');

const ACTIVATION_FOOTER_ACTION_TYPES = [
    {
        type: 'url',
        label: 'URL'
    },
    {
        type: 'email',
        label: 'Email'
    }
];

class BrandedAppActivationActionEditorComponent {
    constructor() {
        this.actionTypes = ACTIVATION_FOOTER_ACTION_TYPES;
    }

    $onChanges() {
        if (this.action && this.action.type) {
            this.selectType(this.action.type);
        }
    }

    selectType(type) {
        return this.selectedTypeFields = FORMS[`activationFooterAction_${type}`];
    }
}

module.exports = angular.module('backstage.components.branded-app.branded-app-activation-action-editor', [])
    .component('brandedAppActivationActionEditor', {
        templateUrl: '/src/angular/components/branded-app/branded-app-activation-action-editor/branded-app-activation-action-editor.html',
        bindings: {
            action: '<',
            actionPlacement: '=',
            updateAction: '&'
        },
        controller: BrandedAppActivationActionEditorComponent
    }).name;
