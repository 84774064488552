import semver from 'semver';

/* eslint-disable
    eqeqeq,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
angular.module('backstage.services')

    .factory('customActionButtonsService', [
        '$injector',
        '$log',
        'urlService',
        'userService',
        'eventService',
        function($injector, $log, urlService, userService, eventService) {

            let AVAILABLE_TARGETS = ['route', 'window'];

            return {
                init(customActionButtons, eventId, routeInterpolationContext) {
                    if (customActionButtons == null) { customActionButtons = {}; }
                    if (routeInterpolationContext == null) { routeInterpolationContext = {}; }
                    return Object.keys(customActionButtons).reduce(function(arr, key) {
                        let descriptor = customActionButtons[key];
                        let hasPermission = true;

                        if (descriptor.service && descriptor.method) {
                            descriptor = _.clone(descriptor);

                            if (!$injector.has(descriptor.service)) {
                                $log.error(`[customActionButtons] service does not exist: ${descriptor.service}`);
                                return;
                            }
                            if (typeof $injector.get(descriptor.service)[descriptor.method] !== 'function') {
                                $log.error(`[customActionButtons] service ${descriptor.service} does not have method ${descriptor.method}`);
                                return;
                            }

                            descriptor._action = function(arg) {
                                if (!$injector.has(descriptor.service)) { return; }

                                let service = $injector.get(descriptor.service);

                                return service[descriptor.method](arg);
                            };
                        } else if (Array.from(AVAILABLE_TARGETS).includes(descriptor.target)) {
                            descriptor = _.clone(descriptor);
                            descriptor._targetRoute = urlService.getInterpolatedRoutePath(
                                descriptor.route, routeInterpolationContext, eventId);
                        }

                        const event = eventService.getCurrentEvent() || {};
                        if (
                            _.isArray(descriptor.permissions)
                            && semver.gt(event.versionEventDoc, '3.4.1')
                        ) {
                            hasPermission = _.every(descriptor.permissions, function(permission) {
                                return userService.hasPermission(permission, {
                                    event_id: event._id,
                                    org_id: event.ownerId
                                });
                            });
                        }

                        if (hasPermission) arr.push(descriptor);

                        return arr;
                    }
                    , []);
                }
            };
        }
    ]);
