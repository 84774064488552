"use strict";

module.exports = /* @ngInject */["$route", "$scope", "$routeParams", "$rootScope", "$location", function ($route, $scope, $routeParams, $rootScope, $location) {
  function init(component, props) {
    var instance = window.StartVue(component, $route, $routeParams, $rootScope, $location, props);
    angular.element('body').addClass('has-vue');
    $scope.$on('$destroy', function () {
      angular.element('body').removeClass('has-vue');
      deregisterRouteListener();
      instance.$children[0].clear();
    });
    var deregisterRouteListener = $rootScope.$on('$routeUpdate', function (event, currentRoute) {
      // This event gets triggered when search parameters change
      // We use this to support the marketplace routing which is
      // based on query parameters
      instance.$emit('route:update', currentRoute);
    });
  }
  $scope.StartVue = function (componentName, props) {
    console.info('[VueWrapperCtrl] Start vue with %s.', componentName, props);
    init(componentName, props);
  };
  var bpm = $route.current.$$route.blueprintMetadata;
  var _bpm$controller = bpm.controller,
    componentName = _bpm$controller.componentName,
    uiOptions = _bpm$controller.uiOptions;
  $scope.config = uiOptions || {};
  if (!componentName) {
    console.warn('[VueWrapperCtrl] Can\'t start vue without a component.');
    return;
  } else {
    init(componentName);
  }
}];