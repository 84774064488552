import { API_BASE_PATH } from 'libs/utils/constants';

// Classes
import BaseService from './base-service';

// Utils
import { get } from 'lodash';

// Constants

/**
 * @const {String} BACKLINKS_QUERY_LABELS_ENDPOINT backlinks by label endpoint. Interpolations: `eventId` and `fpType`
 * @private
 */
const BACKLINKS_QUERY_LABELS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/backlinks-by-label/{{fpType}}`;

/**
 * @const {Number} BACKLINKS_PKG_NEW_EXTERNALS_SUPPORT_VERSION minimum backlink supported package version
 * @private
 */
const BACKLINKS_PKG_NEW_EXTERNALS_SUPPORT_VERSION = 1.1;

/**
 * @const {Number} MAKESHIFT_BACKLINKS_REFRESH_WAIT_TIME_MS wait timeout for backlink refresh
 * @private
 */
const MAKESHIFT_BACKLINKS_REFRESH_WAIT_TIME_MS = 800;

/**
 * @const {String} BACKLINKS_QUERY_ENDPOINT backlinks endpoint. Interpolations: `eventId` and `fpType`
 * @private
 */
const BACKLINKS_QUERY_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/backlinks/{{fpType}}`;


/**
 * Provides utils for handling backlinks.
 *
 * @example
 * import BacklinksService from 'libs/services/backlinks';
 * ...
 * const backlinks = new BacklinksService();
 */
export default class BacklinksService extends BaseService {

    /**
     * Shorthand method to get the handler search url
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType backlinks fp type
     *
     * @returns {String} the handler search url
     */
    getSearchHandlerUrl(eventId, fpType) {
        return this.buildUrl(BACKLINKS_QUERY_LABELS_ENDPOINT, { eventId, fpType });
    }

    /**
     * Given an event ID this method will check if the backlink is supported or not
     *
     * @param {String} eventId the id of the event
     *
     * @return {Promise<Boolean>} whether the backlink is supported or not
     */
    async isBacklinksSearchAvailable(eventId) {
        try {
            const packages = await this._services.market.getInstalledPackages(eventId);

            // support for search is available in v1.1.0 and above
            const backlinkLibVersion = get(packages, 'lib-backlink', -1);

            return Number.parseFloat(backlinkLibVersion) >= BACKLINKS_PKG_NEW_EXTERNALS_SUPPORT_VERSION;

        } catch (error) {
            console.error('[BacklinksService] an error occurred while fetching the packages', error.message);
            return false;
        }
    }

    /**
     * Queries the backend for the backlink resources
     *
     * @param {String} eventId the ID of the event
     * @param {String} [fpType] fp type
     * @param {String} [idField] name of the ID field
     * @param {String[]} [ids]
     *
     * @return {Promise<Object[]>} the query result
     */
    async queryBacklinksWithKeys(eventId, fpType, idField, ids) {
        // We are doing this because during the porting we weren't able
        // to determine if sometimes we pass null values that will basically
        // override the default values.
        if (!fpType) { fpType = ''; }
        if (!ids) { ids = []; }
        if (!idField) { idField = 'fp_ext_id'; }


        const url = BACKLINKS_QUERY_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{fpType}}', fpType);

        const result = await this.post(url, { id_field: idField, ids });

        return result.data || [];
    }

    /**
     * Triggers and waits for the backlink to refresh
     *
     * @param {String} eventId the ID of the event
     *
     * @return {Promise<Object[]>} the refreshed backlink information
     */
    triggerAndWaitForBacklinksRefresh(eventId) {
        return new Promise((resolve) => {
            // Prepare the timeout handler
            setTimeout(() => resolve([]), MAKESHIFT_BACKLINKS_REFRESH_WAIT_TIME_MS);

            // ⚑
            // TODO: Broken handler :(
            // _services.handlers.callHandler(eventId, BACKLINKS_REFRESH_HANDLER)

            /* eslint-disable promise/prefer-await-to-then */
            this.queryBacklinksWithKeys(eventId)
                .then(result => resolve(result))
                .catch(() => {
                    console.log('[BacklinksService] Ignore that request error. That was just a soft refresh!');
                    resolve([]);
                });
            /* eslint-enable promise/prefer-await-to-then */
        });
    }

    /**
     * Gets the list of given items for the specified event
     *
     * @param {string} eventId  the ID of the event
     * @param {String} fpType backlinks fp type
     * @param {String?} field id field
     *
     * @returns {Promise<object[]>} the list of items for a given type for this event
     */
    async getItemsByFpType(eventId, fpType, field = 'fp_ext_id') {
        const url = this.getSearchHandlerUrl(eventId, fpType);
        const { data } = await this.get(url, { params: { field } });
        return data;
    }

    /**
     * Gets the list of email template for the specified event
     *
     * @param {string} eventId  the ID of the event
     *
     * @returns {Promise<object[]>} the list of email templates for this event
     */
    async getEmailTemplates(eventId) {
        return this.getItemsByFpType(eventId, 'mail_template');
    }
}
