"use strict";

module.exports = /* @ngInject */["$route", "$routeParams", "$scope", "$rootScope", "eventService", "metadataService", "bulkUpdateService", "backlinksService", "appScriptService", "essenceManager", function ($route, $routeParams, $scope, $rootScope, eventService, metadataService, bulkUpdateService, backlinksService, appScriptService, essenceManager) {
  var eventId;
  var event = eventService.decorateScope(eventId = $routeParams.eventId);
  var currentRouteDescriptor = $scope.descriptor || ($route.current || {}).$$route;
  $scope.genericNewItemCtrl = {};
  $scope.setModelAndFormEssence = function (fpType) {
    var modelFpType = $scope.modelFpType = fpType || 'person';
    var essenceName = (metadataService.getCachedMetadataForType(eventId, modelFpType) || {})._essence;
    var metadataForType = metadataService.getCachedMetadataForTypeAsArray(eventId, modelFpType);
    $scope.metadataFieldsToFillIfEmpty = metadataService.findFieldsWithFlag(metadataForType, 'generate_value_if_empty');

    // only let one targets or exceptions filter through because the single widget caters for both
    var processedMetadataFieldsForType = metadataService.filterDuplicateTargetsExceptionsFields(metadataForType);
    var defaultModelForType = currentRouteDescriptor.defaultModel || ($scope.defaultDocTemplates || {})[modelFpType];
    defaultModelForType = _.extend({}, metadataService.extractDefaults(processedMetadataFieldsForType), defaultModelForType);
    $scope.model = _.isObject(defaultModelForType) ? defaultModelForType : {};
    if (modelFpType === 'person' && $scope.model.fp_rsvp_status === 'pending') {
      // Very special case for the RSVP, defaut user created with fp_rsvp_status === 'pending' should be in hidden state
      // We clear the default, so the the backend can do the proper logic when the user is saved
      delete $scope.model.fp_status;
    }
    $scope.fields = processedMetadataFieldsForType;
    essenceManager.addEssenceToForm(essenceName, $scope.fields, modelFpType).then(function (formEssence) {
      $scope.genericNewItemCtrl.formEssence = formEssence;
    });
  };

  // add extra scope if present
  if (_.isObject(currentRouteDescriptor.extraScope)) {
    _.extend($scope, currentRouteDescriptor.extraScope);
  }
  if (currentRouteDescriptor.modelFpType) {
    $scope.setModelAndFormEssence(currentRouteDescriptor.modelFpType);
  }
  $scope.saveModel = function (model, overrideFpType) {
    var skipEssenceSave = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    $rootScope.setNavigationWarningMessage(null);
    if (!_.isEmpty($scope.form.$error)) {
      $rootScope.setFlashMessage('The item could not be saved because some fields are invalid.', 'danger');
      return;
    }
    var msg = $rootScope.navigationWarningMessage;
    var docFpType = overrideFpType || $scope.modelFpType;
    var eventCloudNode = event.node;
    if ($scope.saveInProgress) {
      return;
    }
    if (_.isString(msg) && !confirm(msg)) {
      return;
    }
    $scope.saveInProgress = true;
    var data;
    var saveCb = function saveCb(model) {
      return bulkUpdateService.createDocs(eventId, eventCloudNode, docFpType, model, {
        fieldsToFillIfEmpty: $scope.metadataFieldsToFillIfEmpty,
        include_upserted_doc_ids: true,
        source: 'backstage-single-doc',
        keep_id: true
      }).then(function (response) {
        data = response.data;
        var onAfterModelSaveAppScript = currentRouteDescriptor.onAfterModelSaveAppScript;
        var modelFpType = currentRouteDescriptor.modelFpType;
        var currentFpType = data.fp_type || (model || {}).fp_type || modelFpType;
        if (onAfterModelSaveAppScript && modelFpType === currentFpType) {
          return appScriptService.callAppScript(onAfterModelSaveAppScript, {
            model_id: !_.isEmpty(data.upsertedDocs) ? data.upsertedDocs[0].id : model._id,
            changed: !_.isEmpty(data.upsertedDocs)
          }).then(function () {
            return response;
          });
        }
        return response;
      });
    };
    var promise = skipEssenceSave ? saveCb(model) : $scope.genericNewItemCtrl.formEssence.save(model, saveCb);
    return promise.then(function () {
      if ($scope.disableNavigationOnSave) {
        $scope.saveInProgress = false;
      }
      // navigation will reset our scope
      $rootScope.setFlashMessage('An item has been added.');
      if (!$scope.disableNavigationOnSave) {
        var redirectRoute = (currentRouteDescriptor.afterSaveRedirectRoute || '').replace(':eventId', eventId);
        $rootScope.navigate(redirectRoute || "/event/".concat(eventId, "/").concat(docFpType, "s"));
      }
      return backlinksService.isBacklinksSearchAvailable(eventId, event.node);
    }).then(function (backlinksAreAvailable) {
      if (backlinksAreAvailable) {
        return backlinksService.triggerAndWaitForBacklinksRefresh(eventId);
      }
    }).then(function () {
      return data;
    }).catch(function (err) {
      var message = 'The item could not be saved.';
      if (err.data && typeof err.data.error === 'string') {
        message = err.data.error;
      } else if (typeof err.error === 'string') {
        message = err.error;
      }
      var parsedErrors = message.match(/fp_ext_id:[^:]*:(.*)/);
      if (parsedErrors) {
        message = parsedErrors[1];
      }
      $rootScope.setFlashMessage(message, 'danger');
      return {
        error: err
      };
    }).finally(function () {
      $scope.saveInProgress = false;
    });
  };

  // file upload handling (responseData is the post-upload server response)
  // ⚑ TODO: smells hacky down here
  return $scope.$watch('responseData', function (newResponseData) {
    if (!_.isArray((newResponseData || {}).successes)) {
      return;
    }
    return eventService.getEventDocsByType(event, $scope.newItemFpType || $scope.modelFpType).then(function (data) {
      var remoteModel;
      var uploadedAssetDocs = (data || {}).data || [];

      // newItemModel comes from bsExternalsSelectorNewItemWorkaroundForm directive
      var localModel = $scope.newItemModel || $scope.model;
      var fpExtId = (localModel || {}).fp_ext_id;
      if (fpExtId) {
        // we have an fp_ext_id set, look for ours
        remoteModel = _.findWhere(uploadedAssetDocs, {
          fp_ext_id: fpExtId
        });
      } else {
        // POTENTIAL OLD IE FALLBACK; unused in newer browsers
        // we do not have an fp_ext_id set, look for the uploaded file
        var successfulUploadFileName = _.first(newResponseData.successes.map(function (fileName) {
          return fileName.substring(0, fileName.lastIndexOf('.'));
        }));
        remoteModel = _.findWhere(uploadedAssetDocs, {
          fp_ext_id: successfulUploadFileName
        });
        var fpExtIdField = _.findWhere($scope.processedMetadataFieldsForType, {
          field: 'fp_ext_id'
        }) || {};
        fpExtIdField.kind = 'display-only';
      }

      // extend the remote model with all of the fields we have set in metadata
      var _fieldsToPickFromLocalModel = _.pluck($scope.processedMetadataFieldsForType, 'field');
      _.extend(remoteModel, _.pick.apply(_, [localModel].concat(_fieldsToPickFromLocalModel)));
      // and merge anything in the remote version back to our local one
      _.extend($scope.model, remoteModel);

      // now save the local model to remote
      // N.B. submit is put on the scope by the externals widget; let's use that if it's there
      if ($scope.submit) {
        // this is how the floating upload modal is closed when uploading externals inline
        return $scope.submit($scope.saveModel, remoteModel);
      } else {
        return $scope.saveModel($scope.model);
      }
    });
  });
}];