"use strict";

// bs-dynamic-extension-value-display-cell
// displays the value of a dynamic extension (when retreived from the associated handler)

var dynamicExtensionValueDisplayCellCtrl = /* @ngInject */function dynamicExtensionValueDisplayCellCtrl($scope, metadataService, dynamicExtensionsService) {
  var eventId = $scope.column.eventId;
  var type = $scope.dataRow.fp_type;
  var id = $scope.dataRow.fp_ext_id;
  var map = $scope.column.map;
  var mdFields = metadataService.getCachedMetadataForTypeAsArray(eventId, type, true);
  var applicableField = _.findWhere(mdFields, {
    field: map
  });
  if (!applicableField) {
    // in the past these were not prefixed with underscore in backstage
    // backward compatibility fix for that:
    applicableField = _.findWhere(mdFields, {
      field: '_' + map
    });
  }
  if (!applicableField) {
    return;
  }
  return dynamicExtensionsService.getDynamicExtensionPropertyRetrievalPromise(applicableField, id, eventId).then(function (value) {
    $scope.value = value;
    if (!_.isObject(value)) {
      return $scope.displayValue = value;
    }
  });
};
dynamicExtensionValueDisplayCellCtrl.$inject = ["$scope", "metadataService", "dynamicExtensionsService"];
module.exports = function () {
  return {
    restrict: 'AC',
    template: '<span ng-bind="displayValue"></span>',
    scope: true,
    controller: dynamicExtensionValueDisplayCellCtrl
  };
};