/* eslint-disable
    eqeqeq,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
angular.module('backstage.services')

// ⚑
// TODO: what is even going on here (refactor)

    .factory('clipboardService', [
        '$window',
        '$document',

        function(window, document) {
            // Code is Trello source code share on StackOverflow
            // https://stackoverflow.com/questions/17527870/how-does-trello-access-the-users-clipboard
            let clipboardService;

            return (clipboardService = class clipboardService {
                constructor(initialValue) {
                    this.value = initialValue ? initialValue : '';

                    $(document).keydown(e => {
                        // Only do this if there's something to be put on the clipboard, and it
                        // looks like they're starting a copy shortcut
                        if (!this.value || !(e.ctrlKey || e.metaKey)) {
                            return;
                        }

                        if ($(e.target).is('input:visible,textarea:visible')) {
                            return;
                        }

                        // Abort if it looks like they've selected some text (maybe they're trying
                        // to copy out a bit of the description or something)
                        if (__guard__(typeof window.getSelection === 'function' ? window.getSelection() : undefined, x => x.toString())) {
                            return;
                        }

                        if (document.selection != null ? document.selection.createRange().text : undefined) {
                            return;
                        }

                        return _.defer(() => {
                            let $clipboardContainer = $('#clipboard-container');

                            $clipboardContainer.empty().show();
                            return $('<textarea id=\'clipboard\'></textarea>')
                                .val(this.value)
                                .appendTo($clipboardContainer)
                                .focus()
                                .select();
                        });
                    });

                    $(document).keyup(function(e) {
                        if ($(e.target).is('#clipboard')) {
                            return $('#clipboard-container').empty().hide();
                        }
                    });
                }

                set(value) {
                    this.value = value;
                }
            });
        }

    ]);

function __guard__(value, transform) {
    return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
