/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
angular.module('backstage.services')

// keepin it Blobby
    .factory('scrollService', [
        '$window',
        '$document',
        '$rootScope',
        '$timeout',
        '$interval',
        function($window, $document, $rootScope, $timeout, $interval) {
            let handlers = [];

            let getScrollTop = () => $($document).scrollTop();
            let callAllHandlers = function() {
                handlers.forEach(handler => handler(getScrollTop()));
                if (!$rootScope.$$phase) { return $rootScope.$apply(); }
            };

            let isRegistered = callback => handlers.indexOf(callback) !== -1;

            $($window)
                .bind('scroll', callAllHandlers)
                .bind('resize', callAllHandlers)
                .bind('load', callAllHandlers);

            $($document)
                .bind('resize', callAllHandlers);

            $('body')
                .bind('resize', callAllHandlers);

            $interval(callAllHandlers, 1500);

            return {
                getScrollTop,
                onUpdate(callback) { return handlers.push(callback); },
                removeUpdateListener(callback) {
                    let index = handlers.indexOf(callback);

                    if (index > -1) {
                        return handlers.splice(index, 1);
                    }
                }
            };
        }
    ]);
