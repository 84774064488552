module.exports = [() => {
    return {
        link(scope, elem, attrs = {}) {
            elem.addClass('strap-tooltip');

            let reinitFn = function(showTip = false) {
                elem.tooltip({
                    html: true
                })
                    .attr('title', attrs.title)
                    .tooltip('fixTitle');
                if (showTip) {
                    return elem.tooltip('show');
                }
            };

            // late tooltip initialisation so that Angular has had a change to interpolate embedded atributes
            setTimeout(reinitFn, 0);

            scope.$watch(() => elem.attr('title'), reinitFn.bind(this, false));

            elem.bind('$destroy', () => elem.tooltip('hide'));

            // the data-watch attr will reinitialise the tooltip if the watched item changes
            // issue with pre-58 version of firefox where watch is defined on Object prototype, doing crazy things
            // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Object/watch
            if (!attrs.hasOwnProperty('watch') || !attrs.watch) {
                return;
            }
            return scope.$watch(attrs.watch, function(newValue, oldValue) {
                // we'll push this to the end of the event queue in order to delay processing
                if (newValue !== oldValue) {
                    return setTimeout(reinitFn.bind(this, true), 0);
                }
            });
        }
    };
}];
