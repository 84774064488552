/* eslint-disable
    eqeqeq,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
angular.module('backstage.services')

    .factory('settingsService', [
        '$http',
        'eventService',
        'metadataService',
        'bulkUpdateService',
        function($http, eventService, metadataService, bulkUpdateService) {

            let settingsService;
            let OVERRIDE_SETTINGS_DOC_ID = 'settings_changes';
            let OVERRIDE_SETTINGS_DOC_PRIORITY = 'highest';
            let OVERRIDE_SETTINGS_DOC_OWNER = 'private';

            let REGISTRY_DOC_ID = 'registry';
            let SETTINGS_DOC_ID = 'settings';

            // # # # #
            // public
            // # # # #

            return _.extend((settingsService = {}), {

                compileRegistry(eventId) {
                    return $http.post(`/api/v1/eid/${eventId}/compile/registry`);
                },

                compileSettings(eventId) {
                    return $http.post(`/api/v1/eid/${eventId}/compile/settings`);
                },

                fetchRegistry(event) {
                    return eventService.getEventDocById(event, REGISTRY_DOC_ID);
                },

                fetchSettings(event) {
                    return eventService.getEventDocById(event, SETTINGS_DOC_ID);
                },

                fetchOverrideSettingsDoc(event) {
                    return eventService.getEventDocById(event, OVERRIDE_SETTINGS_DOC_ID)
                        .then(data => data.data
                            , () =>
                                ({
                                    _id: OVERRIDE_SETTINGS_DOC_ID,
                                    fp_bit_priority: OVERRIDE_SETTINGS_DOC_PRIORITY,
                                    fp_owner: OVERRIDE_SETTINGS_DOC_OWNER
                                })
                        );
                },

                saveLocalSettingsAsOverrides(event, localSettingsDoc, localSettingsKey, remoteSettingsForKey, registry) {
                    if (registry == null) { registry = {}; }
                    let _settingsDoc =
                settingsService.diffSettingsWithRegistryDefaults(localSettingsDoc, _.keys(localSettingsDoc), registry, true);

                    _settingsDoc[localSettingsKey] =
                settingsService.diffRemoteSettingsWithLocalChanges(remoteSettingsForKey, _settingsDoc[localSettingsKey]);
                    return settingsService.fetchOverrideSettingsDoc(event)
                        .then(function(overrideDoc) {
                            overrideDoc[localSettingsKey] =
                    _.extend({}, overrideDoc[localSettingsKey], _settingsDoc[localSettingsKey]);
                            if (_.isEmpty(overrideDoc[localSettingsKey])) { delete overrideDoc[localSettingsKey]; } // bit compilation woes
                            return settingsService.saveSettingsOverrideDoc(event, overrideDoc, registry);
                        });
                },

                saveSettingsOverrideDoc(event, settingsDoc, registry) {
                    if (registry == null) { registry = {}; }
                    let settingsKeysInRegistry = _.intersection(_.keys(registry), _.keys(settingsDoc));

                    _.extend(settingsDoc, {
                        _id: OVERRIDE_SETTINGS_DOC_ID,
                        fp_ext_id: OVERRIDE_SETTINGS_DOC_ID,
                        fp_bit_priority: OVERRIDE_SETTINGS_DOC_PRIORITY,
                        fp_owner: OVERRIDE_SETTINGS_DOC_OWNER
                    }
                    );
                    settingsKeysInRegistry.forEach(function(key) {
                        let mdList = metadataService.convertMetadataHashToOrderedList(registry[key]);

                        return bulkUpdateService.ensureDocHasCorrectTimestamps(event._id, null, settingsDoc[key], mdList);
                    });
                    return bulkUpdateService.createDocs(event._id, event.node, OVERRIDE_SETTINGS_DOC_ID, settingsDoc, {
                        keepId: true,
                        ignoreAbsentIds: true
                    });
                },

                diffRemoteSettingsWithLocalChanges(remoteSettings, localSettings) {
                    const diff = _.chain(localSettings)
                        .pairs()
                        .filter(function(pair) {
                            let str = function(val) { if (_.isObject(val)) { return JSON.stringify(val); } else { return val; } };

                            return str(remoteSettings[pair[0]]) !== str(pair[1]); }).object()
                        .value();

                    // add deleted properties to diff with `null` values
                    const deletedKeys = Object.keys(remoteSettings).filter(key => !localSettings.hasOwnProperty(key));

                    for (const key of deletedKeys) {
                        diff[key] = null;
                    }

                    return diff;
                },

                diffSettingsWithRegistryDefaults(dirtySettingsDoc, settingsKeys, registry, includeEvenIfMatching) {
                    if (dirtySettingsDoc == null) { dirtySettingsDoc = {}; }
                    if (settingsKeys == null) { settingsKeys = []; }
                    if (registry == null) { registry = {}; }
                    dirtySettingsDoc = _.clone(dirtySettingsDoc);
                    settingsKeys.forEach(function(settingsKey) {
                        let defaults = registry[settingsKey] ? metadataService.extractDefaults(registry[settingsKey]) : {};

                        dirtySettingsDoc[settingsKey] =
                _.pairs(dirtySettingsDoc[settingsKey]).reduce(function(settings, pair) {
                    if (includeEvenIfMatching || !_.isEqual(defaults[pair[0]], pair[1])) {
                        settings[pair[0]] = pair[1];
                    }
                    return settings;
                }
                , {});
                        if (_.isEmpty(dirtySettingsDoc[settingsKey])) { return dirtySettingsDoc[settingsKey] = undefined; } });
                    return dirtySettingsDoc;
                },

                // entries may be arrays or objects in the blueprint. this helper function processes these two possible inputs
                pluckSettingsKeyEntriesFromHashOrList(entriesListOrHash) {
                    // the processed list will always be an array of strings, as was solely supported before
                    if (entriesListOrHash == null) { entriesListOrHash = []; }
                    let processed = []; // non-supported type default

                    if (_.isArray(entriesListOrHash)) {
                        processed = entriesListOrHash;
                    } else if (_.isObject(entriesListOrHash)) {
                        // possible forms:
                        //   { entries: { "person": "person", "linkedin": "linkedin" } }
                        //   { entries: { "person": {"key": "person", "order": 1}, "linkedin": {"key": "linkedin", "order": 2} } }
                        processed = _
                            .chain(entriesListOrHash)
                            .sortBy(entry => entry.order || entry).map(entry => entry.key || entry).value();
                    }
                    return processed;
                },

                getSetting(eventId, settingPath) {
                    return window.BSTG.services.settings.getSetting(eventId, settingPath);
                }
            }
            );
        }
    ]);
