const QRCode = require('qrcode');

module.exports = () => {
    return {
        restrict: 'E',
        template: '<img ng-src="{{dataUrl}}"></img>',
        replace: true,
        scope: {
            code: '<',
            width: '<'
        },
        async link(scope) {
            const width = scope.width || 100;
            const dataUrl = await QRCode.toDataURL(scope.code, { width: width * 2 });

            scope.dataUrl = dataUrl;
        }
    };
};
