const bsNewFileUploadComponent = require('./bsNewFileUploadComponent');
const bsDatepickerDirective = require('./bsDatepicker');
const bsVideoCallDirective = require('./bsVideoCallsWidget');

angular.module('backstage.directives.forms', [])
    .directive('bsBrowserAutofillConsciousForm', require('./bsBrowserAutofillConsciousForm'))
    .directive('bsFormWithRevealingInputs', require('./bsFormWithRevealingInputs'))
    .directive('bsFormGenerator', require('./bsFormGenerator'))
    .directive('bsFormGeneratorField', require('./bsFormGeneratorField'))
    .directive('bsFormGeneratorTextInput', require('./bsFormGeneratorTextInput'))
    .directive('bsFormGeneratorCustom', require('./bsFormGeneratorCustom'))
    .directive('bsFormGeneratorLocale', require('./bsFormGeneratorLocale'))
    .directive('bsFormGeneratorArrayField', require('./bsFormGeneratorArrayField'))
    .directive('bsRevealEditFieldsLink', require('./bsRevealEditFieldsLink'))
    .directive('bsRevealingInput', require('./bsRevealingInput'))
    .directive('bsMutableTagList', require('./bsMutableTagList'))

    .directive('bsDatepicker', bsDatepickerDirective) // for back compat
    .directive('bsDateRangeDatepickerPair', bsDatepickerDirective) // for back compat

    .directive('bsNewFileUploadComponent', bsNewFileUploadComponent)
    .directive('bsFileUploadComponent', bsNewFileUploadComponent) // the old version of this directive, retained to keep old references happy

    .directive('bsAtWho', require('./bsAtWho'))
    .directive('bsFocusWhenSet', require('./bsFocusWhenSet'))
    .directive('bsExternalsSelectorNewItemWorkaroundForm', require('./bsExternalsSelectorNewItemWorkaroundForm'))
    .directive('formTooltipAndHelp', require('./bsFormTooltipAndHelp'))
    .directive('bsCustomFormFieldValidations', require('./bsCustomFormFieldValidations'))

    .directive('bsVideoCallsWidget', bsVideoCallDirective);
