"use strict";

angular.module('backstage.services').factory('taskQueueService', /* @ngInject */["$http", function ($http) {
  /**
   * Returns status for job given it's id
   * Uses backstage router if node is specified
   *
   * @param {string} jobId
   * @returns {promise} - A promise that resolves with object containing job's status
   */
  var getJobStatus = function getJobStatus(jobId) {
    var url = "/api/v1/task-queue/job/".concat(jobId, "/status");
    return $http.get(url).then(function (_ref) {
      var data = _ref.data;
      return data;
    });
  };
  return {
    getJobStatus: getJobStatus
  };
}]);