import { datadogRum } from '@datadog/browser-rum';

/**
 * Provides methods necessary for managing crash reporting tool
 *
 * @example
 *
 * import CrashReportingService from 'libs/services/crash-reporting';
 * ...
 * const crashReporting = new CrashReportingService();
 */
export default class CrashReportingService {

    /**
     * Builds an instance of crash reporting service.
     */
    constructor() {
        const datadogSettings = window.BSTG.datadog || { appId: undefined, token: undefined };
        if (datadogSettings.appId) {
            datadogRum.init({
                applicationId: datadogSettings.appId,
                clientToken: datadogSettings.token,
                site: 'datadoghq.com',
                service: 'backstage',
                env: window.BSTG.env,
                version: window.BSTG.version,
                sampleRate: 100,
                trackInteractions: true,
                defaultPrivacyLevel: 'allow'
            });

            datadogRum.startSessionReplayRecording();
            this.enabled = true;
        }
    }

    /**
     * Sets extra parameters to the crashing reporting tool.
     * @param {Object} extra an object containing the extra keys to set on the scope
     */
    setScope({ eid }) {
        if (this.enabled) {
            datadogRum.addRumGlobalContext('eid', eid);
        }
    }

    /**
     * Sets current user identifier to the crash reporting tool.
     * @param {Object} user
     */
    setUser({ tracking_key } = {}) {
        if (tracking_key && this.enabled) {
            datadogRum.setUser({
                id: tracking_key,
            });
        }
    }

    /**
     * Captures the exception and sends it to the reporting service
     *
     * @param {Error} error the error
     */
    captureException(error) {
        console.error('[CrashReporting] an error occurred:', error.message);
        if (this.enabled) {
            datadogRum.addError(error);
        }
    }

    /**
     * Logs and sends an error to the crash reporting tool by following the syntax:
     * '[label] reason - error'
     * @param {String} [label] something to identify the reporter
     * @param {String} [reason] a textual description on the error
     * @param {Error} [error] the error itself to report
     */
    reportError(label, reason, error) {
        const msgParts = [];
        if (label) {
            msgParts.push('[', label, ']');

            if (reason || error) {
                msgParts.push(' ');
            }
        }
        if (reason) {
            msgParts.push(reason);

            if (error) {
                msgParts.push(' - ');
            }
        }
        if (error) {
            msgParts.push(error);
        }
        const msg = msgParts.join('');

        if (msg) {
            if (error && error instanceof Error) {
                error.message = msg;
                this.captureException(error);
            } else {
                this.captureException(new Error(msg));
            }
        }
    }
}
