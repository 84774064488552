module.exports = [() => ({
    link(scope, elem) {

        const DEFAULT_CONSTRAINED_HEIGHT_PX = 44; // the magic number
        const DEFAULT_CONSTRAINED_HEIGHT_CLASS = 'default-constrained-height';

        let isShowingAllModules = false;

        const getLastModule = () => elem.find('.nav-module-item:visible').last();

        const getListHeightForModule = function(moduleElem) {
            let moduleElemOffset = (moduleElem.position() || {}).top || 0;
            let moduleElemHeight = DEFAULT_CONSTRAINED_HEIGHT_PX;

            return Math.floor(moduleElemOffset + moduleElemHeight);
        };

        const getMaxListHeight = () => getListHeightForModule(getLastModule());

        const constrainHeight = function(height, disableButtonIfAtMaxHeight = true) {
            // height -= CONSTRAINED_HEIGHT_SUBTRACT_PX if doSubtract
            let disableRevealModulesButtonElem;

            elem.css({
                height
            });
            if (height === DEFAULT_CONSTRAINED_HEIGHT_PX) {
                elem.addClass(DEFAULT_CONSTRAINED_HEIGHT_CLASS);
            } else {
                elem.removeClass(DEFAULT_CONSTRAINED_HEIGHT_CLASS);
            }

            // are we near the max height for the module list?
            if (!isShowingAllModules && (Math.abs(elem.height() - getMaxListHeight()) < 5)) {
                // yes, disable expand/collapse button
                disableRevealModulesButtonElem = disableButtonIfAtMaxHeight;
            } else {
                disableRevealModulesButtonElem = false;
            }

            return elem.find('.btn-reveal-modules')
                .prop('disabled', disableRevealModulesButtonElem);
        };

        const constrainHeightForModule = moduleElem => constrainHeight(getListHeightForModule(moduleElem));

        // main module selection and expansion hook
        scope.$watch('revealAllModules', function(revealAllModules) {
            if (!revealAllModules) {
                return constrainHeightToActiveModule();
            }
            isShowingAllModules = true;
            return constrainHeightForModule(getLastModule());
        });

        // route change hook
        const constrainHeightToActiveModule = function() {
            isShowingAllModules = false;
            // setTimeout because sometimes the active-making happens after this flow
            return setTimeout(function() {
                let activeModule = elem.find('.nav-module-item.active');

                if (!activeModule.length) {
                    return constrainHeight(DEFAULT_CONSTRAINED_HEIGHT_PX, false);
                }
                return constrainHeightForModule(activeModule);
            }, 0);
        };

        scope.$on('$routeChangeSuccess', constrainHeightToActiveModule);

        const _sel = '.nav-module-item a';

        elem.on('click', _sel, function(ev) {
            if (scope.inModuleReorderMode) {
                return;
            }
            const clickedModule =
                angular.element(ev.currentTarget || ev.target) // cross-browser shite
                    .closest('.nav-module-item');

            return constrainHeightForModule(clickedModule);
        });

        return scope.$on('$destroy', () => elem.off('click', _sel));
    }
})];
