import { getAttachmentMetadata, getAttachmentNames } from 'libs/utils/attachments';

// bs-asset-file-preview-cell

module.exports = () =>
    ({
        link(scope, elem) {
            const attachmentsNames = getAttachmentNames(scope.dataRow);
            if (!attachmentsNames.length) {
                scope.dataRow.hasNoAttachment = true;
                return;
            }
            const ct = getAttachmentMetadata(scope.dataRow, attachmentsNames[0]).content_type;

            const contentType = ct.substring(0, ct.indexOf('/'));

            if (contentType !== 'image') {
                return elem.remove();
            }
        }
    });
