"use strict";

module.exports = /* @ngInject */["$parse", function ($parse) {
  return {
    restrict: 'A',
    compile: function compile(el, attrs) {
      var fn = $parse(attrs.bsFileUpload);
      return function (scope, element) {
        element.on('change', function (event) {
          scope.$apply(function () {
            var context = {
              $event: event
            };
            if (attrs.multiple) {
              context.$files = event.target.files;
            } else {
              context.$file = event.target.files[0];
            }
            fn(scope, context);
          });
        });
      };
    }
  };
}];