module.exports = ['$sce', $sce => ({
    restrict: 'A', // only activate on element attribute
    require: '?ngModel', // get a hold of NgModelController
    link(scope, element, attrs, ngModel) {
        // do nothing if no ng-model
        let onchange, read;

        if (!ngModel) {
            return;
        }

        // Specify how UI should be updated
        let lastHtml = null;

        ngModel.$render(onchange = function() {
            // do not update if user is editing content
            if (lastHtml === ngModel.$viewValue) {
                return;
            }
            return element.html($sce.getTrustedHtml(ngModel.$viewValue || ''));
        });

        scope.$watch(() => ngModel.$modelValue, onchange);

        // Listen for change events to enable binding
        element.on('blur keyup change', () => scope.$apply(read));

        // Write data to the model
        return read = function() {
            let html = element.html();

            // When we clear the content editable the browser leaves a <br> behind
            // If strip-br attribute is provided then we strip this out
            if (attrs.stripBr && (html === '<br>')) {
                html = '';
            }
            return ngModel.$setViewValue(lastHtml = html);
        };
    }
})];
