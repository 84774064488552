import { API_BASE_PATH } from 'libs/utils/constants';
import BaseService from './base-service';

/**
 * API for downloading starting the analytics report export
 * @private
 */
const ANALYTICS_EXPORT_REPORT_URL = `${API_BASE_PATH}/events/{{eventId}}/reports/{{reportName}}`;

/**
 * API endpoint for live-stream intereactions export
 * @const {string} LIVE_STREAM_EXPORT_ENDPOINT
 * @private
 */
export const LIVE_STREAM_EXPORT_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/viewers-export`;


/**
 * API for getting the analytics export result
 * @private
 */
const ANALYTICS_EXPORT_REPORT_RESULT_URL = `${API_BASE_PATH}/events/{{eventId}}/reports/{{reportId}}`;

/**
 * API for getting the analytics export result for a specific live session
 * @private
 */
const LIVE_STREAM_EXPORT_REPORT_RESULT_URL = `${API_BASE_PATH}/events/{{eventId}}/live-stream/{{liveStreamId}}/viewers-export/{{reportId}}`;

/**
 * API for getting the analytics export progress
 * @private
 */
const ANALYTICS_EXPORT_REPORT_STATUS_URL = `${ANALYTICS_EXPORT_REPORT_RESULT_URL}/progress`;

/**
 * API for getting the analytics chart data
 * @private
 */
const ANALYTICS_CHART_DATA_URL = `${API_BASE_PATH}/events/{{eventId}}/analytics/charts`;

/**
 * API for getting the analytics activity data
 * @private
 */
const ANALYTICS_ACTIVITY_DATA_URL = `${API_BASE_PATH}/events/{{eventId}}/analytics/activity`;

/**
 * API for getting the registrations analytics data
 * @private
 */
const ANALYTICS_REGISTRATIONS_DATA_URL = `${API_BASE_PATH}/events/{{eventId}}/analytics/registrations`;

/**
 * Service for managing analytics exports
 */
export default class AnalyticsService extends BaseService {

    constructor() {
        super();
    }

    /**
     * Get url for the export task-queue job progress
     *
     * @param {string} eventId id of a workspace
     * @param {string} reportId the ID of the report job
     *
     * @returns {string}
     */
    getReportProgressUrl(eventId, reportId) {
        return this.buildUrl(ANALYTICS_EXPORT_REPORT_STATUS_URL, { eventId, reportId });
    }

    /**
     * Get url for the export task-queue job result
     *
     * @param {string} eventId id of a workspace
     * @param {string} reportId the ID of the report job
     * @param {string} liveStreamId the ID of the live stream, if exists
     *
     * @returns {string}
     */
    getReportResultUrl(eventId, reportId, liveStreamId = null) {
        console.debug('[AnalyticsService] Getting export results for', reportId);
        return liveStreamId ?
            this.buildUrl(LIVE_STREAM_EXPORT_REPORT_RESULT_URL, { eventId, liveStreamId, reportId }) :
            this.buildUrl(ANALYTICS_EXPORT_REPORT_RESULT_URL, { eventId, reportId });
    }

    /**
     * Given a name this method requests the report and returns
     * the taks object containing the job ID to track for the download.
     *
     * @param {string} eventId the id of a workspace
     * @param {string} reportName the name of the report
     * @param {ReportRequest} config the export options
     *
     * @returns {Promise<object>} the report task with the job ID
     */
    async requestReportByName(eventId, reportName, config) {
        console.debug('[AnalyticsService] Starting export for', reportName, config);
        const url = reportName === 'live-session-interactions' ?
            this.buildUrl(LIVE_STREAM_EXPORT_ENDPOINT, { eventId, liveStreamId: config.liveStreamId }) :
            this.buildUrl(ANALYTICS_EXPORT_REPORT_URL, { eventId, reportName });
        const { data } = await this.post(url, config);
        return data;
    }

    /**
     * Given a name this method requests the corresponding chart data
     * of the specified event.
     *
     * @param {string} eventId the id of a workspace
     * @param {object} [params={}] the chart options
     *
     * @returns {Promise<object[]>} the charts data
     */
    async loadChartData(eventId, params = {}) {
        const url = this.buildUrl(ANALYTICS_CHART_DATA_URL, { eventId });
        const { data } = await this.get(url, { params });
        return data;
    }

    /**
     * @typedef {object} ActivityData
     * @prop {number}
     * @prop {number} created_users
     * @prop {number} activated_users
     * @prop {number} active_users_last_day
     * @prop {number} active_users_last_hour
     */

    /**
     * @param {string} eventId
     * @returns {Promise<ActivityData>}
     */
    async loadActivityData(eventId) {
        const url = this.buildUrl(ANALYTICS_ACTIVITY_DATA_URL, { eventId });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * @typedef {object} RegistrationsStats
     * @property {{current: number, today: number, limits?: number}} self_registered_users
     * @property {{current: number, today: number}} self_unregistered_users
     * @property {{current: number, today: number, total: number}} rsvp_accepted
     * @property {{current: number, today: number, total: number}} rsvp_declined
     * @property {{current: number, total: number}} rsvp_pending
     */
    /**
     * Loads registrations statistics
     *
     * @param {string} eventId the id of a workspace
     * @param {object} [params={}] the chart options
     *
     * @returns {Promise<RegistrationsStats>} the charts data
     */
    async loadRegistrationStats(eventId, params = {}) {
        const url = this.buildUrl(ANALYTICS_REGISTRATIONS_DATA_URL, { eventId });
        const { data } = await this.get(url, { params });
        return data;
    }
}
