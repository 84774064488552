"use strict";

/**
 * Local Storage Synchronizer
 * You can add thinks to this synchronizer and it will
 * keep it in sync with the localStorage
 */
angular.module('backstage.services').factory('btLocalStorageSync', /* @ngInject */["$rootScope", function ($rootScope) {
  var PREFIX = 'localStorageSync';
  var encode = angular.toJson.bind(angular);
  var decode = angular.fromJson.bind(angular);
  var watchersDestructors = {};
  function getPropertyPath(objName, property) {
    return [PREFIX, objName, property].join('.');
  }

  /**
   * @param {string} namespace object name
   * @param {object} obj object that contains the property
   * @param {string} property property name
   */
  function startSync(namespace, obj, property) {
    var path = getPropertyPath(namespace, property);
    var valEncoded = localStorage.getItem(path);
    var val;
    if (valEncoded === undefined && obj[property] !== undefined) {
      localStorage.setItem(path, encode(obj[property]));
    } else if (valEncoded !== undefined) {
      val = decode(localStorage.getItem(path));
    }
    watchersDestructors[path] = $rootScope.$watch(function watcherFunction() {
      return encode(obj[property]);
    }, function onChange(newValue) {
      localStorage.setItem(path, newValue);
    });
    return val;
  }

  /**
   * @param {string} namespace object name
   * @param {string} property property name
   * @return {boolean} true if the watcher was present and successfully removed
   */
  function stopSync(namespace, property) {
    var path = getPropertyPath(namespace, property);
    if (watchersDestructors[path]) {
      watchersDestructors[path]();
      delete watchersDestructors[path];
      return true;
    }
    return false;
  }
  return {
    startSync: startSync,
    stopSync: stopSync
  };
}]);