export const __guard__ = (value, transform) => {
    return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
};

export const startsWithPrefix = (s, url) => {
    return s.startsWith('https://' + url) || s.startsWith('https://www.' + url);
};

export const extractUrlFromIframe = (input) => {
    const wrapper = document.createElement('div');
    wrapper.style.display = 'none';
    wrapper.innerHTML = input;

    const iframes = wrapper.querySelectorAll('iframe, embed');
    const iframeFound = iframes[0];
    wrapper.remove();
    return iframeFound ? iframeFound.src : input;
};

export const transformToEmbeddedUrls = (input) => {
    if (/https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/.test(input)) {
        return 'https://www.figma.com/embed?embed_host=astra&url=' + input;
    }

    input = extractUrlFromIframe(input);
    const url = new URL(input);

    if (startsWithPrefix(url.href, 'youtube.com')) {
        url.href = url.href.replace('youtube.com/watch?v=', 'youtube.com/embed/');
    } else if (startsWithPrefix(url.href, 'youtu.be')) {
        url.href = url.href.replace('youtu.be/', 'youtube.com/embed/');
    } else if (startsWithPrefix(url.href, 'miro.com')) {
        url.href = url.href.replace('miro.com/app/board/', 'miro.com/app/live-embed/');
    }

    // preserve {{ }} in url
    return url.toString().replace(/%7B%7B/g, '{{').replace(/%7D%7D/g, '}}');
};
