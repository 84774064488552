// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
/*
Author: Luke Plaster (me@lukep.org)
*/

let MdHtmlForm = require('./mdHtmlForm');

let TEXTAREA_MIRROR_ATTRS = ['required', 'maxlength', 'rows'];

angular.module('ngMdHtmlForm', [])

    .directive('mdHtmlForm', ([
        '$rootScope',
        $rootScope =>
            ({
                restrict: 'A',
                require: '?ngModel',
                template: `\
<div>
        <p>
            <textarea class="form-control ng-mdhtmlform-md"   ng-show='! inHtmlEditMode'></textarea>
            <textarea class="form-control ng-mdhtmlform-html" ng-show='  inHtmlEditMode'></textarea>
        </p>
        <small class="ng-mdhtmlform-ctrl-md", ng-show='isInputModeEnabled("markdown")'>
            <span ng-show='! inHtmlEditMode'>Markdown</span>
            <a    ng-show='inHtmlEditMode' ng-click='inHtmlEditMode = false' href='javascript:void(0)'>Markdown</a>
        </small>
        <small class="ng-mdhtmlform-ctrl-html", ng-show='isInputModeEnabled("html")'>
            <span ng-show='inHtmlEditMode'>HTML</span>
            <a    ng-show='! inHtmlEditMode' ng-click='inHtmlEditMode = true' href='javascript:void(0)'>HTML</a>
        </small>
</div>\
`,
                replace: true,
                scope: {
                    inputModes: '=',
                    defaultInputMode: '='
                },
                controller: ['$scope', function($scope) {
                    let isInputModeEnabled = ($scope.isInputModeEnabled = function(mode) {
                        if (!Array.isArray($scope.inputModes)) { return true; }
                        return $scope.inputModes.indexOf(mode) !== -1;
                    });

                    if (!isInputModeEnabled('markdown') || ($scope.defaultInputMode === 'html')) {
                        return $scope.inHtmlEditMode = true;
                    }
                }
                ],
                link(scope, rootEl, attrs, ngModel) {
                    let mdTextAreaEl = rootEl.find('.ng-mdhtmlform-md');
                    let htmlTextAreaEl = rootEl.find('.ng-mdhtmlform-html');

                    let form = new MdHtmlForm(mdTextAreaEl[0], rootEl);
                    let beaconEl = form.htmlChangeBeaconEl;
                    let oldval = form.html;
                    let newval = oldval;

                    let placeholderTargetEls = undefined;
                    let processModelValueChangeFn = undefined;

                    // apply validation attrs (required and maxlength) to markdown textarea
                    // if/when present on root el
                    let attrsToWatch = TEXTAREA_MIRROR_ATTRS;

                    scope.$watch(() =>
                        attrsToWatch.reduce((function(vals, attr) {
                            vals += rootEl.attr(attr);
                            return vals;
                        }), '')

                    , function() {
                        let attrsToApply = {};

                        if (rootEl.attr('required')) {
                            attrsToApply.required = true;
                        }
                        if (rootEl.attr('maxlength')) {
                            attrsToApply.maxlength = rootEl.attr('maxlength');
                        }
                        if (rootEl.attr('rows')) {
                            attrsToApply.rows = rootEl.attr('rows');
                        }
                        if (Object.keys(attrsToApply).length) {
                            mdTextAreaEl.attr(attrsToApply);
                            if (attrsToApply.rows) { htmlTextAreaEl.attr('rows', attrsToApply.rows); }
                        }
                    });

                    // pretty horrible stuff here but IE8 only supports defineProperty on DOM elements
                    delete beaconEl.html;
                    Object.defineProperty(beaconEl, 'html', {
                        get() {
                            return newval;
                        },
                        set(val) {
                            oldval = newval;
                            if ((ngModel !== undefined) && (ngModel !== null)) {
                                // we are intentionally not applying the scope here to avoid real-time md correction
                                if (!$rootScope.$$phase) {
                                    scope.$apply(function() {
                                        ngModel.$setViewValue(val);
                                    });
                                }
                            }
                            newval = val;
                        },
                        configurable: true
                    }
                    );

                    // reflect the current value of the model in the UI
                    scope.$watch((() => ngModel.$modelValue),

                        (processModelValueChangeFn = function(_html) {
                            _html = typeof _html !== 'string' ? '' : _html;
                            if (_html === form.html) {
                                return;
                            }
                            form.html = _html;
                            form.convertHtmlToMd();
                            form.render();
                        })
                    );

                    // hide the control buttons based on options passed in as attributes
                    if (attrs.hideHtmlView) {
                        rootEl.find('.ng-mdhtmlform-ctrl-html').remove();
                    }
                    if (attrs.hideMarkdownView) {
                        rootEl.find('.ng-mdhtmlform-ctrl-html').remove();
                    }

                    ngModel.$viewChangeListeners.push(function() {
                        processModelValueChangeFn(ngModel.$modelValue);
                    });

                    // set placeholders
                    if (attrs && attrs.placeholder) {
                        placeholderTargetEls = $(mdTextAreaEl);
                        placeholderTargetEls.push(htmlTextAreaEl[0]);
                        placeholderTargetEls.attr('placeholder', attrs.placeholder);
                    }
                }
            })

    ])
    );
