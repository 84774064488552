"use strict";

module.exports = /* @ngInject */["$scope", "$routeParams", "eventService", "modalService", "themeService", "userService", function ($scope, $routeParams, eventService, modalService, themeService, userService) {
  var eventId = $routeParams.eventId;
  var vm = $scope.themeAssetsCtrl = {};
  var event = eventService.decorateScope(eventId);
  if (event.theme_editor_v2_enabled) {
    $scope.themeEditorV2 = true;
    $scope.event = event;
    $scope.user = userService.getCurrentUser();
    return;
  }
  vm.eventId = eventId;
  vm.settingsHaveChanged = function () {
    return true;
  };
  vm.refresh = function () {
    vm.cacheBuster = Date.now();
    vm.appIconModel = {
      _id: 'theme_asset.app-icon',
      fp_ext_id: 'app-icon',
      asset_name: 'app-icon.png',
      fp_owner: 'private',
      fp_type: 'theme_asset'
    };
    vm.splashScreenPhoneModel = {
      _id: 'theme_asset.background_phone',
      fp_ext_id: 'background_phone',
      asset_name: 'background_phone.png',
      fp_owner: 'private',
      fp_type: 'theme_asset'
    };
    vm.splashScreenTabletModel = {
      _id: 'theme_asset.background_tablet',
      fp_ext_id: 'background_tablet',
      asset_name: 'background_tablet.png',
      fp_owner: 'private',
      fp_type: 'theme_asset'
    };
  };
  vm.refresh();
  $scope.showImporter = event.theme_editor_v2_enabled;
  $scope.openImporter = function () {
    modalService.openModal({
      templateUrl: '/static/partials/theme-editor/theme-editor-importer.html',
      scope: {}
    }).then(function () {
      return $scope.setFlashMessage('Import complete.');
    }).finally(function () {
      return themeService.compileTheme(eventId, event.node);
    });
  };
}];