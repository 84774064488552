"use strict";

module.exports = /* @ngInject */["appBrandingService", function (appBrandingService) {
  return {
    restrict: 'EC',
    scope: {
      branding: '=',
      platform: '@'
    },
    templateUrl: '/static/partials/apps/components/screenshot-uploader.html',
    link: function link(scope /* , elem */) {
      scope.screenshots = [];
      scope.sizes = appBrandingService.getScreenshotTypesForPlatform(scope.platform);
      var appendNextScreenshot = function appendNextScreenshot() {
        return scope.screenshots.push(appBrandingService.getNextScreenshotsFilename(scope.screenshots, scope.platform, scope.activeSize));
      };
      scope.setActiveSize = function (size) {
        scope.activeSize = size;
        scope.screenshots = appBrandingService.getScreenshotFilenamesOfType(scope.branding, scope.platform, size);
        return appendNextScreenshot();
      };
      scope.onUploadComplete = appendNextScreenshot;
      scope.setActiveSize(_.keys(scope.sizes)[0]);
      scope.$watch('branding._rev', function (rev) {
        if (!rev) {
          return;
        }
        scope.screenshots = [];
        return scope.setActiveSize(scope.activeSize || _.keys(scope.sizes)[0]);
      });
    }
  };
}];