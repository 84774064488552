module.exports = [() => ({
    restrict: 'AC',
    scope: {
        total: '=',
        legend: '='
    },
    link(scope, elem) {
        const buildSeries = () =>
            _.pairs(scope.legend).filter(pair => _.isNumber(pair[1]))

        ;
        const buildColours = function(series = []) {
            return series.map(function(s) {
                switch (s[0].toLowerCase()) {
                    case 'activated':
                        return '#2dd269';
                    case 'invited':
                        return '#FF9500';
                    case 'arrived':
                        return '#AAB3BB';
                    case 'not invited':
                        return '#3185FC';
                }
            });
        };
        const buildChart = function() {
            const series = buildSeries();

            return elem.highcharts({
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                colors: buildColours(series),
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false
                },
                title: {
                    text: null
                },
                tooltip: {
                    pointFormat: '<b>{point.y}</b> ({point.percentage:.1f}%)'
                },
                plotOptions: {
                    pie: {
                        cursor: 'pointer',
                        showInLegend: false,
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}',
                            style: {
                                color: 'black'
                            }
                        }
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Participants',
                    data: series
                }]
            });
        };

        scope.$watch('legend', function() {
            const hc = elem.highcharts();

            if (hc) {
                hc.destroy();
            }
            return buildChart();
        }, true);
        return scope.$on('$destroy', () => {
            const hc = elem.highcharts();

            if (hc) {
                return hc.destroy();
            }
        });
    }
})];
