"use strict";

var _require = require('src/branded-apps-metadata'),
  TABLES = _require.TABLES,
  FORMS = _require.FORMS;
var moment = require('moment');
var AppsListCtrl = /* @ngInject */function AppsListCtrl($route, $scope, $sanitize, $q, $filter, userService, modalService, appBrandingService, taskSeriesService, taskExecutorService) {
  var pluralizeIfSeveral = $filter('pluralizeIfSeveral');
  var allBrandings = [];
  $scope.userIsAppPublisher = userService.matchesRoles({
    globalRole: 'app_publisher'
  }, {
    globalRole: 'superadmin'
  });
  $scope.tableConfig = {
    maxSize: 9,
    itemsByPage: 10,
    displaySelectionCheckbox: $scope.userIsAppPublisher,
    selectionMode: 'multiple',
    isGlobalSearchActivated: true
  };
  _.each(TABLES.appsList, function (column) {
    return column.formatFunction = $sanitize;
  });
  $scope.columnCollection = TABLES.appsList;
  var now = moment().unix();
  $scope.filters = {
    recurring: {
      label: 'Recurring',
      type: 'toggle',
      fn: function fn(appBranding) {
        // when showing recurring apps, we're going to show only
        // ones that are still scheduled for updates
        if (this.value) {
          var updateUntil = appBranding.info.update_until;
          if (!updateUntil) {
            return false;
          }
          return updateUntil >= now;
        }
        return appBranding.info.recurring === false;
      }
    },
    isPublished: {
      label: 'Published to app stores',
      type: 'toggle',
      fn: function fn(appBranding) {
        return appBranding.info.is_published_on_stores === this.value;
      }
    },
    isUpToDate: {
      label: 'Up-To-Date',
      type: 'toggle',
      fn: function fn(appBranding) {
        return appBranding.info.is_up_to_date === this.value;
      }
    },
    publishingAccount: {
      label: 'Publishing account',
      type: 'choice',
      fn: function fn(appBranding) {
        return appBranding.info.publishing_account === this.value;
      },
      options: {}
    }
  };
  var applyFilters = function applyFilters() {
    return $scope.rowCollection = _.filter(allBrandings, function (row) {
      return _.every($scope.filters, function (filter) {
        var value = filter.value;
        if (value === undefined || value === null || value === '') {
          return true;
        }
        return filter.fn(row);
      });
    });
  };

  // $scope API
  $scope.$watch('rowCollection', function (collection) {
    if (!collection) {
      return;
    }
    return $scope.selectedBrandings = _.where(collection, {
      isSelected: true
    });
  }, true);
  $scope.setFilterValue = function (_ref) {
    var filter = _ref.filter,
      value = _ref.value;
    if ($scope.filters[filter].value === value) {
      return;
    }
    $scope.filters[filter].value = value;
    return applyFilters();
  };
  var currentUsersOrganisations = _.chain(userService.getCurrentUser().orgs)
  // Issue #1203 some orgs doesn't have names
  .map(function (org) {
    return [org._id, org.display_name || 'Unknown'];
  }).sortBy(function (org) {
    return org[1].toLowerCase();
  }).object().value();
  $scope.showAppCreationModal = function () {
    var fields = angular.copy(FORMS.createApp);
    fields.fp_owner.kind_options = {
      values: currentUsersOrganisations
    };
    return modalService.openModalWithForm({
      title: 'Create an app',
      saveLabel: 'Create',
      fields: fields,
      formCanBeSaved: function formCanBeSaved(model) {
        return model.springboard_name && model.fp_ext_id && model.fp_owner && appBrandingService.isBrandingIdValid(model.fp_ext_id);
      }
    }).then(function (model) {
      // Default store name = app name
      model.store_config = {
        name: model.springboard_name
      };
      return appBrandingService.createBranding(model).then(function () {
        appBrandingService.logChange(model, {
          type: 'created'
        });
        $scope.navigate("/app/".concat(model.fp_ext_id));
        return $scope.setFlashMessage('Your app has been created.');
      }, function (res) {
        if (res.data && typeof res.data.error === 'string') {
          return alert(res.data.error);
        } else {
          return alert('An error occured. Please try again later.');
        }
      });
    });
  };
  $scope.bulkCreatePublishingRequest = function () {
    var brandings = $scope.selectedBrandings;
    if (!confirm("Are you sure you want to create ".concat(brandings.length, " publishing requests?"))) {
      return;
    }
    var tasks = [];
    var taskNames = [];
    _.each(brandings, function (branding) {
      taskNames.push(branding.fp_ext_id);
      return tasks.push(function () {
        return appBrandingService.createPublishingRequest(branding, {
          silent: true
        });
      });
    });
    var taskSeries = taskSeriesService.createTaskSeries(tasks, taskNames);
    var executor = taskExecutorService.executeTaskSeries(taskSeries);
    return taskExecutorService.openModalWithTaskSeriesProgress(executor, {
      title: 'Creating publishing requests...'
    });
  };
  $scope.bulkStartJobs = function () {
    var brandings = $scope.selectedBrandings;
    return modalService.openModalWithForm({
      title: 'Start jobs',
      fields: FORMS.buildJobs,
      saveLabel: "Start ".concat(brandings.length, " ").concat(pluralizeIfSeveral('job', brandings)),
      formCanBeSaved: function formCanBeSaved(model) {
        return model.type;
      }
    }).then(function (options) {
      var tasks = [];
      var taskNames = [];
      _.each(brandings, function (branding) {
        taskNames.push(branding.fp_ext_id);
        return tasks.push(function () {
          return appBrandingService.buildBranding(branding.fp_ext_id, options);
        });
      });
      var taskSeries = taskSeriesService.createTaskSeries(tasks, taskNames);
      var executor = taskExecutorService.executeTaskSeries(taskSeries);
      return taskExecutorService.openModalWithTaskSeriesProgress(executor, {
        title: 'Starting builds...'
      });
    });
  };
  $scope.bulkUpdateStoreVersions = function () {
    var brandings = $scope.selectedBrandings;
    return modalService.openModalWithForm({
      title: 'Update store versions',
      fields: FORMS.storeVersions,
      model: {},
      saveLabel: "Update ".concat(brandings.length, " ").concat(pluralizeIfSeveral('app', brandings)),
      formCanBeSaved: function formCanBeSaved(model) {
        return model.ios_version || model.android_version;
      }
    }).then(function (updates) {
      _.each(updates, function (value, key) {
        if (_.isEmpty(value)) {
          return delete updates[key];
        }
      });
      var tasks = [];
      var taskNames = [];
      _.each(brandings, function (branding) {
        taskNames.push("Fetching branding ".concat(branding.fp_ext_id));
        tasks.push(function () {
          return appBrandingService.getBranding(branding.fp_ext_id);
        });
        taskNames.push("Updating branding ".concat(branding.fp_ext_id));
        return tasks.push(function (resp) {
          var brandingDoc = resp.data;
          brandingDoc.store = brandingDoc.store || {};
          _.extend(brandingDoc.store, updates);
          return appBrandingService.saveBranding(brandingDoc);
        });
      });
      var taskSeries = taskSeriesService.createTaskSeries(tasks, taskNames);
      var executor = taskExecutorService.executeTaskSeries(taskSeries);
      return taskExecutorService.openModalWithTaskSeriesProgress(executor, {
        title: 'Updating brandings...'
      }).then(function () {
        return $route.reload();
      });
    });
  };
  $scope.loading = true;
  return $q.all({
    accounts: function () {
      if ($scope.userIsAppPublisher) {
        return appBrandingService.getAllPublishingAccounts();
      } else {
        return $q.when({
          data: []
        });
      }
    }(),
    apps: appBrandingService.getAllBrandings(),
    baseApp: appBrandingService.getBranding('spotme')
    // this is only use to have some versions
    .catch(function () {
      return {
        data: {
          store: {
            android_version: '*',
            ios_version: '*'
          }
        }
      };
    })
  }).then(function (_ref2) {
    var accounts = _ref2.accounts,
      apps = _ref2.apps,
      baseApp = _ref2.baseApp;
    var baseAppAndroidVersion = baseApp.data.store.android_version;
    var baseAppIosVersion = baseApp.data.store.ios_version;
    var versionsMatch = appBrandingService.versionsMatch;
    allBrandings = $scope.rowCollection = _.map(_.compact(apps.data), function (row) {
      row.info.android_version_is_latest = versionsMatch(row.store.android_version, baseAppAndroidVersion);
      row.info.ios_version_is_latest = versionsMatch(row.store.ios_version, baseAppIosVersion);
      row.info.is_up_to_date = row.info.android_version_is_latest && row.info.ios_version_is_latest;

      // allows users to type id:xyz in search box
      row._search_helper_id = "id:".concat(row.fp_ext_id);
      row._search_helper_name = "aname:".concat(row.springboard_name);
      row._search_helper_store_name = "sname:".concat(row.store_name);
      return row;
    });
    return $scope.filters.publishingAccount.options = _.chain(accounts.data).compact().map(function (account) {
      return [account._id, account.name];
    }).object().value();
  }).finally(function () {
    return $scope.loading = false;
  });
};
AppsListCtrl.$inject = ["$route", "$scope", "$sanitize", "$q", "$filter", "userService", "modalService", "appBrandingService", "taskSeriesService", "taskExecutorService"];
AppsListCtrl.$inject = ["$route", "$scope", "$sanitize", "$q", "$filter", "userService", "modalService", "appBrandingService", "taskSeriesService", "taskExecutorService"];
angular.module('backstage.controllers.brandedApps').controller('AppsListCtrl', AppsListCtrl);