import BaseService from './base-service';

// Constants
import { API_BASE_PATH } from 'libs/utils/constants';

/**
 * @constant {String} FP_TYPE_LISTING_PATH FP Type specific backstage path. Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @private
 */
const FP_TYPE_LISTING_PATH = '/event/{{eventId}}/{{fpType}}s';

/**
 * Blueprint document API endpoint. Interpolations: `{{eventId}}`.
 * @constant {String} BLUEPRINT_DOCUMENT_ENDPOINT
 */
export const BLUEPRINT_DOCUMENT_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/docbyid/blueprint`;

/**
 * Compile blueprint API endpoint. Interpolations: `{{eventId}}`.
 * @constant {String} COMPILE_BLUEPRINT_ENDPOINT
 */
export const COMPILE_BLUEPRINT_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/compile/blueprint`;

// Tools
import { sortByOrder } from 'libs/utils/collections';
import { isEqual, isEmpty, get } from 'lodash';

/**
 * Provides utils for blueprint modules.
 *
 * @example
 * import BlueprintService from 'libs/services/blueprint';
 * ...
 * const blueprint = new BlueprintService();
 */
export default class BlueprintService extends BaseService {

    /**
     * Initializes service with given blueprint
     *
     * @param {Object} blueprint current blueprint object
     */
    init(blueprint) {
        this.blueprint = blueprint;
    }

    /**
     * Compiles a blueprint for the specified event.
     *
     * @param {string} eventId - The ID of the event.
     *
     * @returns {Promise} - A promise that resolves with the compiled blueprint.
     */
    compile(eventId) {
        const compileUrl = COMPILE_BLUEPRINT_ENDPOINT.replace('{{eventId}}', eventId);
        return this.post(compileUrl, {});
    }

    /**
     * WARNING: this method is highly simplified, see the angular blueprint service for more.
     *
     * Fetches blueprint for given event and stores it in the service
     *
     * @param {String} eventId ID of an event
     * @returns {Promise<Object>} blueprint
     */
    async fetchBlueprint(eventId) {
        await this.compile(eventId);
        const url = BLUEPRINT_DOCUMENT_ENDPOINT.replace('{{eventId}}', eventId);
        const { data: blueprint } = await this.get(url);

        if (!blueprint) {
            throw new Error(`Missing blueprint for event ${eventId}`);
        }

        this.blueprint = blueprint;

        return blueprint;
    }

    /**
     * Get the blueprint modules for the current event,
     * where restricted modules and controllers have been removed
     *
     * @param {String} [type] if specified, returns modules of the given type
     * @returns {Object}
     */
    getModules(type) {
        const modules = this.blueprint.modules;
        const filtered = {};

        for (const moduleKey of Object.keys(modules)) {
            const moduleDescriptor = modules[moduleKey] || {};
            // if module have no type, it belongs the the event main navigation type
            const moduleType = moduleDescriptor.type || 'event';

            // filter according to type
            if (modules[moduleKey] && (!type || moduleType === type)) {
                filtered[moduleKey] = moduleDescriptor;
            }
        }

        return filtered;
    }

    /**
     * Retrieve the module given its name
     *
     * @param {String} name the name of the module
     * @returns {Object|undefined} the module or undefined
     */
    getModuleByName(name) {
        return (this.blueprint?.modules || {})[name];
    }

    /**
     * Checks if the given module is installed in the current event/
     *
     * @param {String} name the name of the module to check
     *
     * @returns {boolean} true if the module is installed
     */
    isModuleInstalled(name) {
        return Boolean(this.getModuleByName(name));
    }

    /**
     * Retrieve a controller from a module given its name
     *
     * @param {String} name the name of the module
     * @param {String} controller the name of the controller
     * @returns {Object|undefined} the controller or undefined
     */
    getControllerByName(name, controller) {
        const module = (this.blueprint.modules || {})[name] || {};
        return get(module, ['controllers', controller]);
    }

    /**
     * Given a module or controller this method checks if it matches with the current route.
     *
     * @param {Object} currentRoute the actual route.
     * @param {Object} currentSearchParams the actual search parameters
     * @param {Object} module the module or controller on which perform the lookup
     * @param {Boolean} [perfectMatch=false] if the match must be perfect or partial
     *
     * @return {Boolean} true if the link matches with the current route, false otherwise
     */
    matchRoute(currentRoute, currentSearchParams, module, perfectMatch = false) {
        // Set routes for this link if not set yet.
        if (!module.routes) {
            module.routes = this.getRoutesForModule(module);
        }

        // Look for first matching
        for (const route of module.routes) {
            // matches route and query params if the module defines those
            if (perfectMatch && currentRoute.originalPath === route &&
                    (!module.searchParams || isEqual(module.searchParams, currentSearchParams))) {
                return true;
            } else if (!perfectMatch && (currentRoute.originalPath || '').indexOf(route) === 0) {
                return true;
            }
        }

        return false;
    }

    /**
     * Given a blueprint or controller module this method returns the first relevant route.
     *
     * @param {Object} module the module or controller on which perform the lookup
     *
     * @return {String} the first relevant route or an empty string
     */
    getRouteForModule(module) {
        // Pick the default controller if defined or the first of the list.
        // On exploded menu, the passed module is controller
        const ctrl = this.getRelevantControllerForModule(module);

        // Route can be either an array or a string.
        // In former case pick the first.
        const route = Array.isArray(ctrl.route) ? ctrl.route[0] : ctrl.route;

        return route || '';
    }

    /**
     * Given a blueprint or controller module this method returns the first relevant controller.
     *
     * @param {Object} module the module or controller on which perform the lookup
     *
     * @return {Object} the first relevant controller or an empty object
     */
    getRelevantControllerForModule(module) {
        // Pick the default controller if defined or the first of the list.
        // On exploded menu, the link itself could define the controller.
        let ctrl = module.controllers ? module.controllers.default : module;

        if (!ctrl) {
            ctrl = sortByOrder(module.controllers)[0];
        }

        return ctrl || {};
    }

    /**
     * Given a blueprint module or controller this method returns all *unsorted* routes associated to it.
     *
     * @private
     *
     * @param {Object} module the module or controller on which perform the lookup
     *
     * @return {String[]} all available link's routes
     */
    getRoutesForModule(module) {
        const routes = [];

        for (const ctrl of this.getControllersForModule(module)) {
            // Route can be either an array or a string.
            if (Array.isArray(ctrl.route)) {
                routes.push(...ctrl.route);
            } else {
                routes.push(ctrl.route);
            }
        }
        return routes;
    }

    /**
     * Given a blueprint module or controller this method returns all its controllers sorted by `order`
     *
     * @private
     *
     * @param {Object} module the module or controller on which perform the lookup
     *
     * @return {Object[]} all available module's controllers
     */
    getControllersForModule(module) {
        if (!module.controllers) {
            return [module];
        }

        const ctrls = sortByOrder(module.controllers);

        return ctrls.filter(ctrl => !ctrl.bstgMenu);
    }

    /**
     * Generates relative link for given module
     *
     * @param {Object} module module from blueprint
     * @param {Object} params replacement parameters for route
     * @param {Object} query query parameters
     * @returns {String} relative link
     */
    getLinkForModule(module, params, query = {}) {
        let path = this.getRouteForModule(module);

        for (const param of Object.keys(params)) {
            path = path.replace(`:${param}`, params[param]);
        }

        if (!isEmpty(query)) {
            const queryString = Object.keys(query)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
                .join('&');

            path = `${path}?${queryString}`;
        }

        return path;
    }

    /**
     * Compares given array of modules with the one from current blueprint and
     * returns new controllers with `showOnInstall` flag.
     *
     * @param {Array<Object>} modules modules from blueprint
     * @returns {Array<Object>} new `showOnInstall` controllers
     */
    getShowOnInstallCtrls(modules) {
        const currentModules = this.getModules();
        const result = [];

        for (const moduleKey of Object.keys(currentModules)) {
            const module = currentModules[moduleKey];
            const showOnInstallCtrls = Object.values(module.controllers || {}).filter(ctrl => ctrl.showOnInstall);

            if (!showOnInstallCtrls.length) {
                continue;
            }

            const prevModule = modules[moduleKey] || {};

            for (const ctrl of showOnInstallCtrls) {
                const prevModuleCtrls = Object.values(prevModule.controllers || {});
                const prevCtrl = prevModuleCtrls.find(c => c.showOnInstall && c.controller === ctrl.controller);

                if (!prevCtrl) {
                    result.push(ctrl);
                }
            }
        }

        return result;
    }

    /**
     * Given an FP type and and Event ID, this method returns the path of
     * the page where we list that type of documents.
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the desired FP type
     *
     * @return {String} the path of the page where we list the given FP type
     */
    getFpTypeListingPath(eventId, fpType) {
        return this.buildUrl(FP_TYPE_LISTING_PATH, { eventId, fpType });
    }
}
