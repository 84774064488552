// Classes
import BaseService from './base-service';

// Utils
import { groupByStage } from 'libs/utils/workspaces';

// Constants
import {
    ORGANIZATION_ID_PREFIX,
    API_ORG_ENDPOINT,
    WEBHOOK_API_ENDPOINT,
    WEBHOOK_DELETE_API_ENDPOINT,
    WORKSPACE_DEFAULT_SETTINGS_ENPOINT,
} from 'libs/utils/constants';

/**
 * Organization's events endpoint. Interpolation: `{{orgName}}`.
 * @constant {String} ORG_EVENTS_ENDPOINT
 * @private
 */
const ORG_EVENTS_ENDPOINT = `${API_ORG_ENDPOINT}/events`;

/**
 * Organization's content hubs endpoint. Interpolation: `{{orgName}}`.
 * @constant {String} ORG_CONTENT_HUBS_ENDPOINT
 * @private
 */
const ORG_CONTENT_HUBS_ENDPOINT = `${API_ORG_ENDPOINT}/content-hubs`;

/**
 * Organization's webinars endpoint. Interpolation: `{{orgName}}`.
 * @constant {String} ORG_EVENTS_WEBINARS
 * @private
 */
const ORG_EVENTS_WEBINARS = `${API_ORG_ENDPOINT}/webinars`;

/**
 * Organization's members endpoint. Interpolation: `{{orgName}}`.
 * @constant {String} ORG_USERS_ENDPOINT
 * @private
 */
const ORG_USERS_ENDPOINT = `${API_ORG_ENDPOINT}/users`;

/**
 * Endpoint for the workspaces types options.  Interpolation: `{{orgName}}`.
 * @constant {String} WKS_TYPES_ENDPOINT
 * @private
 */
const WKS_TYPES_ENDPOINT = `${API_ORG_ENDPOINT}/workspace-creation-settings`;

/**
 * Provides utils for managing organization and other general
 * purpose utils related to the organization.
 *
 * @example
 * import OrganizationService from 'libs/services/organization';
 * ...
 * const org = new OrganizationService();
 */
export default class OrganizationService extends BaseService {
    constructor() {
        super();

        /** @type {Map<string,string>} key is _id value is display_name */
        this.displayNamesMap = new Map();
    }

    /** @param {Record<string,unknown>} org */
    addToDisplayNamesMap(org) {
        if (org._id && org.display_name) {
            if (!this.displayNamesMap.has(org._id)) {
                this.displayNamesMap.set(org._id, org.display_name);
            }
        }
    }

    /** @type {(orgId: string) => string} */
    getDisplayName(orgId) {
        return this.displayNamesMap.get(this.getIdFromName(orgId))
            || this.getNameFromId(orgId);
    }

    /**
     * Given the ID of an organization this method returns its name.
     *
     * @param {String} orgId the ID of the organization
     *
     * @returns {String} the name of the organization
     */
    getNameFromId(orgId) {
        return (orgId || '').replace(ORGANIZATION_ID_PREFIX, '');
    }

    /**
     * Given the name of an organization this method returns its ID.
     *
     * @param {String} orgName the name of the organization
     *
     * @returns {String} the ID of the organization
     */
    getIdFromName(orgName) {
        if (orgName.startsWith(ORGANIZATION_ID_PREFIX)) return orgName;
        return `${ORGANIZATION_ID_PREFIX}${orgName}`;
    }

    /**
     * Given a user object this method returns its first relevant organization ID.
     *
     * @param {Object} user the user to get the org for.
     *
     * @returns {String} the first relevant user's organization ID
     */
    getFirstRelevantOrg(user) {
        let orgId = null;

        if (user && Array.isArray(user.orgs) && user.orgs.length) {
            for (const org of user.orgs) {
                this.addToDisplayNamesMap(org);
            }
            orgId = user.orgs[0]._id;
        }

        return orgId;
    }

    /**
     * Given an organization name this method loads its details.
     *
     * @param {String} orgName the organization name
     *
     * @return {Promise<Object>} the server response
     */
    async getOrg(orgName) {
        const url = API_ORG_ENDPOINT.replace('{{orgName}}', orgName);
        const { data } = await this.get(url, { withCredentials: true });
        this.addToDisplayNamesMap(data);
        return data;
    }

    /**
     * Given an organization name and webhook parameters, this method
     * persists the webook on the organization document.
     *
     * @param {String} orgName the organization name
     * @param {Object} webhook the webhook parameters
     *
     * @return {Promise<Object>} the server response
     */
    async saveWebhook(orgName, webhook) {
        const url = WEBHOOK_API_ENDPOINT.replace('{{orgName}}', orgName);
        const method = webhook.id ? 'put' : 'post';
        const { data } = await this[method](url, webhook);

        return data;
    }

    /**
     * This method will return all the organizations in the system
     *
     * @return {Promise<Object[]>} the server response containing all user's orgs
     */
    getAllOrgs() {
        const orgs = this.getOrg('');
        if (Array.isArray(orgs)) {
            for (const org of orgs) {
                this.addToDisplayNamesMap(org);
            }
        }
        return orgs;
    }

    /**
     * Given an organization name and webhook ID, this method
     * deletes the webook from the organization document.
     *
     * @param {String} orgName the organization name
     * @param {String} webhookId the webhook to remove
     *
     * @return {Promise<Object>} the server response
     */
    async deleteWebhook(orgName, webhookId) {
        const url = WEBHOOK_DELETE_API_ENDPOINT
            .replace('{{orgName}}', orgName)
            .replace('{{id}}', webhookId);
        const { data } = await this.delete(url);

        return data;
    }

    /**
     * Given an organization name this method updates
     * the organization default workspaces settings with the given data.
     *
     * @param {String} orgName the organisation name
     * @param {Object} defaults the default organisation's workspace settings
     *
     * @return {Promise<Object>} the server response
     */
    async saveWorkspaceDefaults(orgName, defaults) {
        const url = WORKSPACE_DEFAULT_SETTINGS_ENPOINT.replace('{{orgName}}', orgName);
        const { data } = await this.put(url, defaults, { withCredentials: true });

        return data;
    }

    /**
     * Given an organization name this method returns all associated events.
     *
     * @param {String} orgName the organization's name
     * @param {boolean} [includeTemplates=true] whether to include templates or not
     *
     * @returns {Promise<Object>} the server response
     */
    async getAllOrgEvents(orgName, includeTemplates = true) {
        const url = ORG_EVENTS_ENDPOINT.replace('{{orgName}}', orgName);
        const { data } = await this.getCached(url, { withCredentials: true });

        if (includeTemplates) {
            return data;
        }

        return data.filter(e => !e.is_template);
    }

    /**
     * Given an organization name this method returns all associated content hubs.
     *
     * @param {String} orgName the organization's name
     *
     * @returns {Promise<Object>} the server response
     */
    async getAllOrgContentHubs(orgName) {
        const url = this.buildUrl(ORG_CONTENT_HUBS_ENDPOINT, { orgName });
        const { data } = await this.getCached(url, { withCredentials: true });
        return data;
    }

    /**
     * Given an organization name this method returns all associated events.
     *
     * @param {String} orgName the organization's name
     *
     * @returns {Promise<Object>} the server response
     */
    async getAllOrgWebinars(orgName) {
        const url = ORG_EVENTS_WEBINARS.replace('{{orgName}}', orgName);
        const { data } = await this.getCached(url, { withCredentials: true });

        return data;
    }

    /**
     * Returns the org events, grouped by future, live, past events and all events
     *
     * @param {String} orgName the organization's name
     * @param {String[]} exclude workspace IDs to exclude
     * @param {*} filtering function to exlude workspace based upon specific charateristics
     *
     * @returns {Promise<Object>} Promise with object of the grouped events
     */
    async getAllWorkspacesGrouped(orgName, exclude = [], filtering = () => { return true; }) {
        let events = await this.getAllOrgEvents(orgName, false);
        events = events.filter(event => !exclude.includes(event._id) && filtering(event));
        return groupByStage(events, []);
    }

    /**
     * Returns the org webinars, grouped by future, live, past events and all events
     *
     * @param {String} orgName the organization's name
     * @param {String[]} exclude webinar IDs to exclude
     *
     * @returns {Promise<Object>} Promise with object of the grouped events
     */
    async getAllWebinarsGrouped(orgName, exclude = []) {
        let events = await this.getAllOrgWebinars(orgName);
        events = events.filter(event => !exclude.includes(event.id));
        return groupByStage(events, []);
    }

    /**
     * Gets the emails of all members of the given organization
     *
     * @param {string} orgName the name of the organization
     * @param {string[]} [emailsToExclude=[]] emails set to exclude from the returned data
     *
     * @returns {Promise<String>[]} the list of the emails of every org's member
     */
    async getMemberEmails(orgName, emailsToExclude) {
        if (!emailsToExclude) {
            emailsToExclude = [];
        }

        const users = await this.getAllMembers(orgName);
        return users.map(u => u.email).filter(e => !emailsToExclude.includes(e));
    }

    /**
     * Loads all the members of the given organization
     *
     * @param {string} orgName the name of the organization
     *
     * @returns {Promise<Object[]>} the list of members
     */
    async getAllMembers(orgName) {
        const url = this.buildUrl(ORG_USERS_ENDPOINT, { orgName });
        const { data } = await this.getCached(url, {}, 10 * 1000);
        return data;
    }

    /**
     * Loads the options needed for event creation
     *
     * @param {string} orgName the name of the organization
     * @param {string} [audienceType] the audience type
     *
     * @returns {Promise<Object[]>} the options
     */
    async getCreationOptions(orgName, audienceType) {
        const url = this.buildUrl(WKS_TYPES_ENDPOINT, { orgName });
        const { data: { workspace_types } } = await this.getCached(url);
        const optionsObj = workspace_types[audienceType] || workspace_types['all'];

        return Object.keys(optionsObj)
            .map(v => ({ value: v, label: optionsObj[v].label }))
            .sort((a, b) => {
                if (a.value === 'other') {
                    return 1;
                }

                if (b.value === 'other') {
                    return -1;
                }

                return a.label.localeCompare(b.label);
            });
    }
}
