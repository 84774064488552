module.exports = [() => ({
    scope: true,
    controller: ['$scope', function($scope) {
        $scope.revealAllModules = false;

        $scope.toggleDisplayAllModules = function() {
            $scope.inModuleReorderMode = false; // exit harlem shake mode
            return $scope.revealAllModules = !$scope.revealAllModules;
        };

        $scope.collapseExtendedModuleDisplay = () => $scope.revealAllModules = false;

        $scope.getExpandCollapseModulesButtonTooltipText = function() {
            if ($scope.revealAllModules) {
                return 'Collapse';
            } else {
                return 'Show all';
            }
        };

        return $scope.getReorderModulesButtonTooltipText = function() {
            if ($scope.inModuleReorderMode) {
                return 'Save ordering';
            } else {
                return 'Reorder modules';
            }
        };
    }]
})];
