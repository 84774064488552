"use strict";

module.exports = function () {
  var resolver = /* @ngInject */function resolver($route, $q) {
    var blueprintMetadata = $route.current.$$route.blueprintMetadata;
    if (!blueprintMetadata) {
      return $q.resolve();
    }
    var _blueprintMetadata$mo = blueprintMetadata.module,
      module = _blueprintMetadata$mo === void 0 ? {} : _blueprintMetadata$mo,
      _blueprintMetadata$co = blueprintMetadata.controller,
      controller = _blueprintMetadata$co === void 0 ? {} : _blueprintMetadata$co;
    var moduleSourceUrls = _.flatten([module.sourceUrl || []]);
    var ctrlrSourceUrls = _.flatten([controller.sourceUrl || []]);
    return BSTG.require(_.union(moduleSourceUrls, ctrlrSourceUrls));
  };
  resolver.$inject = ["$route", "$q"];
  return resolver;
};