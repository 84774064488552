/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
angular.module('backstage.services')

    .factory('frontstageService', [
        '$http',
        'eventService',
        ($http, eventService) =>

            ({
                compilePages(eventId) {
                    return $http.post(`/api/v1/eid/${eventId}/compile/fstg-pages`);
                }
            })


    ]);
