"use strict";

angular.module('backstage.services').factory('btUrl', /* @ngInject */["$rootScope", "$interpolate", "BASE_API_ENDPOINT", function ($rootScope, $interpolate, BASE_API_ENDPOINT) {
  /**
   * Takes query parameters string (with '&' and '=')
   * and converts them to literal object
   *
   * @param {string} queryParamsString
   * @return {Object}
   */
  function queryParamsStringToMap(queryParamsString) {
    var andSignSplit = queryParamsString.split('&');
    var queryParams = {}; // the output

    andSignSplit.forEach(function (keyValuePairString) {
      var equalSignSplit = keyValuePairString.split('=');
      var key = equalSignSplit[0];
      var value = equalSignSplit[1];
      queryParams[key] = value;
    });
    return queryParams;
  }

  /**
   * Takes a map of query params and converts it to query string (with '=' and '&')
   *
   * @param {Object} queryParams
   * @return {string}
   */
  function queryParamsToString(queryParams) {
    var keyValPairs = [];
    _.mapObject(queryParams, function (val, key) {
      keyValPairs.push(key + '=' + val);
    });
    return keyValPairs.join('&');
  }

  /**
   * Adds queryParams to url. Prevents creating duplicates
   * @param {string} url
   * @param {Object} queryParams
   * @return {string}
   */
  function addQueryParamsToUrl(url, queryParams) {
    if (_.isEmpty(queryParams)) {
      return url;
    }
    var urlParts = url.split('?');
    var urlBase = urlParts[0];
    // if for some reason there was more then one "?":
    var queryParamsInUrlString = urlParts.slice(1).join('?');
    var queryParamsInUrl = queryParamsInUrlString ? queryParamsStringToMap(queryParamsInUrlString) : {};
    return urlBase + '?' + queryParamsToString(angular.extend(queryParamsInUrl, queryParams));
  }

  /**
   * Parses and returns url. Adds `event` variable to url template params
   *
   * @param {string} url a template of the url. You can use angular "{{}}" here
   * @param {Object} params additional params (event param is included) for the `url` template
   * @param {Object} queryParams query parameters (stuff after "?" sign) to append to url
   * @return {string} parsed url
   */
  function btUrl(url, params, queryParams) {
    params = params || {};
    params.event = $rootScope.event;
    url = BASE_API_ENDPOINT + $interpolate(url)(params);
    return addQueryParamsToUrl(url, queryParams);
  }

  /**
   * Returns url for calling the handler
   *
   * @param {string} handler a handler name (for example: agenda/get-sessions)
   * @param {Object} queryParams
   * @return {string} url for calling the handler
   */
  btUrl.handler = function (handler, queryParams) {
    return btUrl('/eid/{{event._id}}/nodehandlers/{{handler}}', {
      handler: handler
    }, queryParams);
  };

  /**
   * Returns url for getting doc from couch
   *
   * @param {string} docId
   * @param {Object} queryParams
   * @return {string} url for getting a document from couch
   */
  btUrl.doc = function (docId, queryParams) {
    return btUrl('/events/{{event._id}}/docbyid/{{docId}}', {
      docId: docId
    }, queryParams);
  };

  /**
   * Returns url for bit-compilation
   *
   * @param {string} bitId
   * @param {Object} queryParams
   * @return {stirng} url for compiling bits
   */
  btUrl.compile = function (bitId, queryParams) {
    return btUrl('/eid/{{event._id}}/compile/{{bitId}}', {
      bitId: bitId
    }, queryParams);
  };

  /**
   * Returns url for putting document
   *
   * @param {string} oid
   * @param {string} fpType
   * @param {Object} queryParams
   * @return {string} url for putting documents to couch
   */
  btUrl.putDoc = function (id, fpType, queryParams) {
    return btUrl('/eid/{{event._id}}/doc/{{fpType}}/{{id}}', {
      id: id,
      fpType: fpType
    }, queryParams);
  };

  /**
   * Returns url for posting documents (array of docs) to couch
   *
   * @param {string} fpType
   * @param {object} queryParams
   * @return {string} url for posting documents to couch
   */
  btUrl.postDocs = function (fpType, queryParams) {
    return btUrl('/eid/{{event._id}}/data/{{fpType}}?keep_id=true&pretty_field_name=true', {
      fpType: fpType
    }, queryParams);
  };

  /**
   * Returns all bits of a given fpType
   *
   * @param {string} fpType
   * @param {object} queryParams
   * @return {string} url for getting the bits
   */
  btUrl.bits = function (fpType, queryParams) {
    return btUrl('/events/{{event._id}}/docsbytype/{{fpType}}', {
      fpType: fpType
    }, queryParams);
  };
  return btUrl;
}]);