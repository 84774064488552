import BaseService from './base-service';
import { API_BASE_PATH, APP_BRANDING_SERVICE_ENDPOINT, APP_BRANDING_EVENTSPACE, CENTRAL_BACKSTAGE_URL } from 'libs/utils/constants';

/**
 * Branding fetch infra API endpoint. Interpolations: `{{branding}}`.
 * @constant {String} BRANDING_EXISTS_ENDPOINT
 * @private
 */
const INFRA_BRANDING_ENDPOINT = `${API_BASE_PATH}/app-service/branding/{{branding}}`;

/**
 * Branding fetch API cluster endpoint. Interpolations: `{{branding}}`.
 * @constant {String} BRANDING_EXISTS_ENDPOINT
 * @private
 */
const CLUSTER_BRANDING_ENDPOINT = `${API_BASE_PATH}/app-brandings/{{branding}}`;

/**
 * Branding existance check API endpoint
 * @constant {String} BRANDING_EXISTS_ENDPOINT
 */
const BRANDING_EXISTS_ENDPOINT = `${API_BASE_PATH}/app-service/branding/{{branding}}/exists`;

/**
 * Registration page URL existance check API endpoint. Interpolations: `{{branding}}`, `{{path}}`.
 * @constant {String} REGISTRATION_URL_EXISTS_ENDPOINT
 */
const REGISTRATION_URL_EXISTS_ENDPOINT = `${API_BASE_PATH}/app-service/branding/{{branding}}/public-login/{{path}}`;

/**
 * URL used to access app-brandings API
 * @constant {String} INFRA_APP_BRANDING_SERVICE_ENDPOINT
 */
const INFRA_APP_BRANDING_SERVICE_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${APP_BRANDING_SERVICE_ENDPOINT}`;

/**
 * Provides methods necessary for managing apps branding
 *
 * @example
 *
 * import BrandingService from 'libs/services/branding';
 * ...
 * const brand = new BrandingService();
 */
export default class BrandingService extends BaseService {

    /**
     * Returns a list of all branded apps
     *
     * @param {string} [org] optional organization name to filter the branded apps
     * @param {Boolean} [prependDefaultBrandings = true] whether to add defaults brandings or not.
     *
     * @returns {Promise<Array>} the list of all branded apps.
     */
    async getAllBrandings(org, prependDefaultBrandings = true) {
        const { data } = await this.getCached(INFRA_APP_BRANDING_SERVICE_ENDPOINT, { params: { org } });

        // sanitize data
        const brandings = data.filter(b => b && b.fp_ext_id);

        if (prependDefaultBrandings) {

            const hasBranding = brandings.find(b =>
                b.fp_ext_id === APP_BRANDING_EVENTSPACE.fp_ext_id
            );

            if (!hasBranding) {
                brandings.unshift(APP_BRANDING_EVENTSPACE);
            }
        }

        return brandings;
    }

    /**
     * Given a branding name, this method returns the branding details
     *
     * @param {string} branding the name of the branding
     * @param {Boolean} fromInfra true if we're fetching from infra.
     * @returns {Promise<object>} the branding configuration
     */
    async getBranding(branding, fromInfra = false) {
        const url = this.buildUrl(fromInfra ? INFRA_BRANDING_ENDPOINT : CLUSTER_BRANDING_ENDPOINT, { branding });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Checks whether the given branding exists in our systems or not.
     *
     * @param {string} branding the branding name to check
     * @returns {Promise<boolean>} true if the brand exists, false otherwise
     */
    async brandExists(branding) {
        const url = this.buildUrl(BRANDING_EXISTS_ENDPOINT, { branding });
        const { status } = await this.head(url);
        return status === 200;
    }

    /**
     * Checks whether the given registration URL exists in our systems or not.
     *
     * @param {string} branding the branding name to check
     * @param {string} path the path name to check
     *
     * @returns {Promise<boolean>} true if the URL exists, false otherwise
     */
    async registrationUrlExists(branding, path) {
        const url = this.buildUrl(REGISTRATION_URL_EXISTS_ENDPOINT, { branding, path });
        const { status } = await this.head(url);
        return status === 200;
    }

    /**
     * Saves an existing branding doc
     *
     * @param {Record<string,unknown>} doc
     */
    async updateBrandingDoc(doc) {
        const url = this.buildUrl(CLUSTER_BRANDING_ENDPOINT, { branding: doc.fp_ext_id });
        await this.post(url, doc);
    }
}
