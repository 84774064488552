/* eslint-disable
    eqeqeq,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
angular.module('backstage.services')

    .factory('dynamicExtensionsService', [
        '$q',
        'metadataService',
        'handlerService',
        function($q, metadataService, handlerService) {

            // # # # #
            // public
            // # # # #

            let dynamicExtensionsService;

            return _.extend((dynamicExtensionsService = {}), {

                getDynamicExtensionPropertyRetrievalPromise(fieldDescriptor, idForHandler, eventId) {
                    let handlerPath = fieldDescriptor.handler_path;

                    if (!handlerPath) { return $q.when(null); }
                    let handlerOpts = fieldDescriptor.handler_options || {};
                    // build the params to send to the handler
                    let params = _.clone(handlerOpts.base_params || {}); // we don't want to mirror changes back into the origin object

                    params[handlerOpts.id_param || 'id'] = idForHandler;
                    // we are relying on angular's promise unwrapping here, a deprecated (but good) feature!
                    return handlerService.callHandler(eventId, handlerPath, params);
                },

                retrieveAndDecorateRowsWithDynamicExtensionsPropertiesValues(rows, fpType, eventId) {
                    rows = dynamicExtensionsService.retrieveAndDecorateRowsWithDynamicExtensionsPropertiesPromises(
                        rows, fpType, eventId);
                    rows.forEach((row, rIdx) =>
                        _.forEach(row, (val, key) =>
                            $q.when(val)
                                .then(data => rows[rIdx][key] = data)
                        )
                    );
                    return rows;
                },

                retrieveAndDecorateRowsWithDynamicExtensionsPropertiesPromises(rows, fpType, eventId) {
                    let metadataFieldsWithDynExts = metadataService.getCachedMetadataForTypeAsArray(eventId, fpType, true);
                    let extendedFieldsWithHandlers =
                metadataService.displaceInAppFieldsAsFieldKeys(metadataFieldsWithDynExts, false)
                    .filter(fieldDescriptor => fieldDescriptor.handler_path);

                    if (!extendedFieldsWithHandlers.length || !rows.length) { return rows; }
                    return rows.map(function(row) {
                        row = _.clone(row);
                        extendedFieldsWithHandlers.forEach(fieldDescriptor =>
                            row[fieldDescriptor.field] =
                    dynamicExtensionsService.getDynamicExtensionPropertyRetrievalPromise(
                        fieldDescriptor, row.fp_ext_id, eventId)
                        .then(resp => resp != null ? resp.data : undefined)
                        );
                        return row;
                    });
                }
            }
            );
        }

    ]);
