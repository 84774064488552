"use strict";

var _lodash = require("lodash");
angular.module('backstage.services').factory('helpService', /* @ngInject */["$controller", "$rootScope", "$http", "modalService", function ($controller, $rootScope, $http, modalService) {
  return {
    isViewerRegistered: function isViewerRegistered() {
      return true;
    },
    openHelp: function openHelp(folder, file, additionalParams) {
      var modalConfig = {
        cssClasses: 'xl-modal',
        templateUrl: '/static/partials/help-content/modal-help-viewer.html'
      };
      modalService.openModal(modalConfig);
      var folders = (0, _lodash.get)(additionalParams, 'resolvers.helpContent.options.folders') || [folder];
      $http.get('/api/v1/bstg-help-content/' + $rootScope.event._id + '/' + folders.toString()).then(function (resp) {
        return resp.data;
      }).then(function (helpContent) {
        modalConfig.defaultViewerController = ['$scope', function ($scope) {
          if (file) {
            $scope.selectedKey = folder + '/' + file;
          }
          $controller('HelpContentViewerCtrl', {
            $scope: $scope,
            helpContent: helpContent
          });
        }];
      });
    }
  };
}]);