module.exports = () => ({
    restrict: 'C',
    require: '^smartTable',
    link(scope, element, attrs, ctrl) {
        scope.searchValue = null;
        return scope.$watch('searchValue', function(value) {
            if (value === null) {
                return;
            }
            return ctrl.search(value, scope.column);
        });
    }
});
