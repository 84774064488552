"use strict";

module.exports = /* @ngInject */["urlService", function (urlService) {
  return {
    require: 'ngModel',
    link: function link($scope, element, attrs, ctrl) {
      ctrl.$validators.url = function (modelValue, viewValue) {
        var url = modelValue || viewValue;
        return ctrl.$isEmpty(url) || urlService.validateUrl(url) || urlService.validateRelativeUrl(url);
      };
    }
  };
}];