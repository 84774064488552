"use strict";

var _utils = require("./utils");
var _underscore = require("underscore");
// bsFormGeneratorArrayField

var Mustache = require('mustache');
var _tmpl = "    <div>\n    <table class=\"form-generator-array-field-container\">\n        <tr ng-repeat-reorder=\"item in visibleArray\" ng-repeat-reorder-handle=\"div.reorder-handle\">\n            <td>\n                <div class=\"form-removables\">\n                    <span ng-switch=\"getInputType()\" class=\"ph-item-editor-container\">\n                        <!-- CHECKBOX -->\n                        <select ng-switch-when=\"checkbox\"\n                                ng-model=\"item.value\"\n                                ng-options=\"item for item in [true, false]\">\n                        </select>\n                        <!-- CHOICE LIST -->\n                        <select ng-switch-when=\"choice-list\"\n                                ng-model=\"item.value\"\n                                ng-options=\"val.value as val.label for val in choiceListValues\">\n                        </select>\n                        <!-- ELSE -->\n                        <div class=\"reorder-handle\" ng-switch-default>\n                            <input ng-model=\"item.value\"\n                                    type=\"{{getInputType()}}\">\n                        </div>\n                    </span>\n                    <button type=\"button\"\n                            class=\"btn btn-delete-item btn-icon single btn-danger\"\n                            ng-class=\"removeButton.class\"\n                            title=\"Delete this item\"\n                            ng-click=\"deleteItem($index)\">\n                        <i ng-class=\"removeButton.icon\"></i>\n                    </button>\n                </div>\n            </td>\n        </tr>\n    </table>\n    <button type=\"button\"\n            class=\"btn btn-primary btn-icon single\"\n            ng-class=\"addButton.class\"\n            ng-click=\"addItem()\">\n        <i ng-if=\"addButton.icon\" ng-class=\"addButton.icon\"></i>\n        {{addButton.label}}\n    </button>\n    </div>";
module.exports = /* @ngInject */["$compile", "$timeout", "metadataService", function ($compile, $timeout, metadataService) {
  return {
    require: 'ngModel',
    scope: {
      itemType: '=',
      itemName: '=',
      itemNameTemplate: '=',
      nestedObjectFields: '=',
      kindOptions: '=',
      choiceListValues: '='
    },
    template: _tmpl,
    link: function link(scope, elem, attrs, ngModel) {
      var _scope$kindOptions, _scope$kindOptions2;
      scope.visibleArray = [];
      var getInputEls = function getInputEls() {
        return angular.element('input, button, select, button', elem);
      };
      scope.addButton = (0, _underscore.assign)({
        label: '',
        icon: 'ic-plus',
        class: 'btn-primary'
      }, (_scope$kindOptions = scope.kindOptions) === null || _scope$kindOptions === void 0 ? void 0 : _scope$kindOptions.add_button);
      scope.removeButton = (0, _underscore.assign)({
        icon: 'ic-x',
        class: 'btn-danger'
      }, (_scope$kindOptions2 = scope.kindOptions) === null || _scope$kindOptions2 === void 0 ? void 0 : _scope$kindOptions2.remove_button);

      // ⚑
      // TODO: Remove hack
      var isDisabled = attrs.bsRevealingInput === 'true';
      var mapArr = function mapArr(arr) {
        if (typeof arr === 'undefined') {
          return;
        }
        if (arr === null) {
          arr = ngModel.$modelValue || [];
        }
        if (!_.isArray(arr)) {
          arr = [];
        }
        scope.visibleArray = arr.map(function (item) {
          return {
            value: item
          };
        });
        return scope.backingArray = arr;
      };
      mapArr();
      var refreshDisabledState = function refreshDisabledState() {
        return getInputEls().prop('disabled', isDisabled);
      };
      refreshDisabledState();

      // monitor for model changes
      scope.$watch(function () {
        return ngModel.$modelValue;
      }, function (newValue) {
        if (JSON.stringify(scope.backingArray) === JSON.stringify(newValue)) {
          return;
        }
        mapArr(newValue);
        return $timeout(refreshDisabledState, 0);
      }, true);

      // monitor for view changes
      scope.$watch(function () {
        return scope.visibleArray;
      }, function (newArray) {
        if (!newArray) {
          return;
        }
        var arr;
        var mapped = newArray.map(function (item) {
          switch (scope.itemType) {
            case 'number':
              return parseFloat(item.value);
            case 'boolean':
              if (item.value === true || item.value === 'true') {
                return true;
              } else {
                return false;
              }
            default:
              return item.value;
          }
        });
        angular.copy(mapped, arr = scope.backingArray);
        return ngModel.$setViewValue(arr);
      }, true);
      var getInputType = scope.getInputType = function () {
        switch (scope.itemType) {
          case 'boolean':
            return 'checkbox';
          default:
            return scope.itemType || 'text';
        }
      };
      scope.addItem = function () {
        if (!(0, _underscore.isArray)(scope.backingArray)) {
          scope.backingArray = [];
        }
        scope.backingArray.push('');
        return scope.visibleArray.push({});
      };
      scope.deleteItem = function (index) {
        return scope.visibleArray.splice(index, 1);
      };
      if (attrs.bsRevealingInput === 'true') {
        scope.$on('formEditModeToggled', function () {
          isDisabled = !isDisabled;
          return refreshDisabledState();
        });
      }

      // beyond this point comes logic for handling a list of nested-objects
      if (getInputType() !== 'nested-object') {
        return;
      }
      var renderItemNameTemplateFn = Mustache.render;
      scope.getNestedObjectItemFieldDescriptor = _.memoize(function (index) {
        var expr, label;
        if ((0, _utils.__guard__)(expr = scope.itemNameTemplate, function (x) {
          return x.length;
        })) {
          label = renderItemNameTemplateFn(expr, scope.visibleArray[index].value || {});
          scope.$watch(function () {
            return _.isObject(scope.visibleArray[index]) ? scope.visibleArray[index].value : undefined;
          }, function (newValue) {
            if (!_.isObject(newValue)) {
              newValue = {};
            }
            return obj.label = renderItemNameTemplateFn(expr, newValue);
          }, true);
        } else {
          label = "".concat(scope.itemName || 'item', " ").concat(index + 1);
        }
        var obj = {
          label: label,
          field: 'value',
          kind: 'nested-object',
          kind_options: {
            fields: metadataService.replacePlaceholdersInTree(scope.nestedObjectFields || {})
          }
        };
        return obj;
      });

      // ⚑
      // TODO: Revisit the display: table hack here ok
      var nestedObjectEditorHtml = "                <div class=\"reorder-handle\"\n                    ng-switch-default\n                    data-bs-form-generator-field\n                    data-prop-to-toggle='disabled'\n                    data-model=\"item\"\n                    data-field-descriptor=\"getNestedObjectItemFieldDescriptor($index)\"\n                    data-omit-field-label=\"true\"\n                    style=\"width: 100%; display: table;\">                ";
      var _elem = angular.element(_tmpl);
      var _fieldEl = _elem.find('.ph-item-editor-container');
      _fieldEl.html(nestedObjectEditorHtml);
      elem.html(_elem.html());
      $compile(elem.contents())(scope);
      return refreshDisabledState();
    }
  };
}];