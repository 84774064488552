"use strict";

// bsNewFileUploadComponent

var _require = require('src/slugify-utils'),
  slugify = _require.slugify;
var _require2 = require('libs/utils/url'),
  getBackstageURL = _require2.getBackstageURL;
var NewFileUploadComponentCtrl = /* @ngInject */function NewFileUploadComponentCtrl($window, $scope, $http, $rootScope, BASE_API_ENDPOINT) {
  var MAX_RETRIES = 5;
  var retries = 0;
  function refreshCsrf() {
    // Force CSRF endpoint to be call on the same subdomain than upload action URLs (i.e. backstage subdomain instead of api subdomain)
    // as if we move everything to api subdomain we end up having a ng-upload error related to the fact that you can't have an iframe running CORS
    return $http.get(getBackstageURL(window.location.host, "".concat(BASE_API_ENDPOINT, "/eid/").concat($rootScope.event._id, "/csrf-token"), '', 'https')).then(function (result) {
      return $scope.csrfToken = result.data.token;
    });
  }
  refreshCsrf();
  $scope.getUploadActionUrl = function () {
    if ($scope.apiUploadType === 'excel') {
      return "/api/v1/events/".concat($rootScope.event._id, "/convert/excel");
    }
    if ($scope.apiUploadType === 'paxphoto') {
      return "/api/v1/eid/".concat($scope.eventId, "/assets/paxphoto");
    }
    var uniq = $scope.assetFpType;
    if ($scope.assetFpType !== $scope.apiUploadType) {
      uniq = "".concat($scope.assetFpType, "/").concat($scope.apiUploadType);
    }
    return "/api/v1/eid/".concat($scope.eventId, "/assets/").concat(uniq);
  };
  return $scope.uploadComplete = function () {
    var content = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (content.error) {
      return $window.alert("Oops! ".concat(content.error));
    }
    try {
      if (_.isString(content)) {
        content = JSON.parse(content);
      }
    } catch (e) {
      retries += 1;
      var isCsrfError = content.indexOf('invalid csrf') !== -1;
      if (isCsrfError && retries < MAX_RETRIES) {
        return refreshCsrf().then($scope.manualSubmit);
      }
      if (isCsrfError) {
        $window.alert('There was a temporal connection error. Please try again.');
      } else {
        $window.alert('Your file could not be uploaded. Be aware that the ' + 'maximum acceptable file size is ' + $scope.MAX_FILE_UPLOAD_SIZE_MBS + ' MB.');
      }
    }
    retries = 0;
    if (_.isObject(content)) {
      $scope.uploadStatus = null;
      $scope.responseData = content.data;
      return $scope.importedFromFilename = content.importedFrom;
    } else {
      return $scope.uploadStatus = content;
    }
  };
};
NewFileUploadComponentCtrl.$inject = ["$window", "$scope", "$http", "$rootScope", "BASE_API_ENDPOINT"];
module.exports = /* @ngInject */["MAX_FILE_UPLOAD_SIZE_MBS", "$timeout", function (MAX_FILE_UPLOAD_SIZE_MBS, $timeout) {
  return {
    scope: {
      eventId: '=',
      responseData: '=',
      apiUploadType: '=',
      assetFpType: '=',
      assetFpExtIdToForce: '=?',
      importedFromFilename: '=',
      customUploadButtonText: '='
    },
    templateUrl: '/static/partials/components/inline-file-upload-component.html',
    controller: NewFileUploadComponentCtrl,
    link: function link(scope, elem) {
      scope.MAX_FILE_UPLOAD_SIZE_MBS = MAX_FILE_UPLOAD_SIZE_MBS;
      angular.element('input[type=file]', elem).change(function () {
        var el = angular.element(this);

        // file size < 100 MB check
        if (!_.isEmpty(_.toArray(this.files))) {
          var fileSizeMBs = scope.fileSizeMBs = this.files[0].size / 1000 / 1000;
          scope.isFileSizeWarningShown = false;
          if (fileSizeMBs >= MAX_FILE_UPLOAD_SIZE_MBS) {
            scope.isFileSizeWarningShown = true;
            return scope.$applyAsync();
          }
        }
        if (!scope.assetFpExtIdToForce) {
          var path = el.val() || '';
          var filename = path.split('\\').pop();
          var defaultFpExtId = slugify(filename.substring(0, filename.lastIndexOf('.')));

          // if no id has been set, used this value
          scope.assetFpExtIdToForce = defaultFpExtId;
        }
        scope.manualSubmit();
        return scope.$applyAsync();
      });
      scope.manualSubmit = function () {
        $timeout(function () {
          return angular.element('input[type=submit]', elem).click();
        }, 100);
      };
    }
  };
}];