"use strict";

var _underscore = require("underscore");
module.exports = /* @ngInject */["$scope", "$rootScope", "eventService", "themeService", "themeImporterService", function ($scope, $rootScope, eventService, themeService, themeImporterService) {
  var currentEvent = eventService.getCurrentEvent();
  var eventId = currentEvent._id;
  var userEvents;
  var eventIndex;
  themeImporterService.getAllEvents($rootScope.user.email).then(function (user) {
    userEvents = (0, _underscore.uniq)(user.data.events, '_id');
    eventIndex = (0, _underscore.chain)(userEvents).map(function (event) {
      return [event._id, event];
    }).object().value();
    $scope.vm = {
      userEvents: (0, _underscore.filter)(userEvents, function (event) {
        return event._id !== eventId;
      }),
      working: false,
      selectedEvent: null,
      selectedEventIsOk: null,
      importerIsReady: false,
      sourceEventTheme: null
    };
  });
  var showThemePreview = function showThemePreview(theme) {
    return $scope.vm.sourceEventTheme = themeService.compactTheme(theme);
  };
  $scope.import = function () {
    if (!$scope.vm.selectedEvent || !$scope.vm.selectedEventIsOk) return;
    $scope.vm.working = true;
    themeImporterService.importTheme($scope.vm.selectedEvent, currentEvent).then(function () {
      return $scope.$$modal.resolve();
    }, function () {
      return alert('There was an error while importing the theme. Please try again.');
    }, function (status) {
      console.log('import status', status);
      $scope.vm.importStatus = status;
    }).finally(function () {
      $scope.vm.working = false;
      $scope.vm.importStatus = null;
    });
  };
  $scope.checkIfSelectedEventCanBeImported = function (eid) {
    var selectedEvent = eventIndex[eid];
    if (!selectedEvent) return;
    $scope.vm.selectedEvent = selectedEvent;
    $scope.vm.working = true;
    $scope.vm.sourceEventTheme = null;
    $scope.vm.sourceEventIconUrl = themeImporterService.getAppIconUrl(selectedEvent);
    themeService.getEventTheme(selectedEvent._id, themeImporterService.getEventNode(selectedEvent)).then(function (theme) {
      // something else was selected while we were loading data
      if ($scope.selectedEid !== eid) return;
      if (!(0, _underscore.size)(theme)) {
        $scope.vm.selectedEventIsOk = false;
        return;
      }
      $scope.vm.selectedEventIsOk = true;
      return showThemePreview(theme);
    }).finally(function () {
      return $scope.vm.working = false;
    });
  };
}];