/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
angular.module('backstage.services').factory('paginationService', [
    function() {
        class Paginator {
            constructor(data, config) {
                this.data = data;
                this.perPage = (config != null ? config.perPage : undefined) || 10;
                this.currentPage = (config != null ? config.currentPage : undefined) || 1;
                this.visibleData = this.data.slice(0);
            }

            getPageRecords() {
                let numPages = this.getNumPages();
                let currPage = this.currentPage <= numPages ? this.currentPage : numPages;
                let startFrom = (currPage - 1) * this.perPage;

                return this.visibleData.slice(startFrom, startFrom + this.perPage);
            }

            getNumPages() {
                return Math.ceil((this.visibleData != null ? this.visibleData.length : undefined) / this.perPage);
            }

            getNumRecords() {
                return (this.data != null ? this.data.length : undefined);
            }

            getPagesArray() {
                let x;
                let numPages = this.getNumPages();

                if (numPages <= 5) {
                    return ((() => {
                        let result = [];

                        for (x = 1, end = numPages, asc = end >= 1; asc ? x <= end : x >= end; asc ? x++ : x--) {
                            var asc, end;

                            result.push(x);
                        }
                        return result;
                    })());
                }
                if (this.currentPage <= 3) {
                    return ((() => {
                        let result1 = [];

                        for (x = 1; x <= 5; x++) {
                            result1.push(x);
                        }
                        return result1;
                    })());
                }
                if ((this.currentPage + 2) >= numPages) {
                    return ((() => {
                        let result2 = [];

                        for (start = numPages - 5, x = start, end1 = numPages, asc1 = start <= end1; asc1 ? x <= end1 : x >= end1; asc1 ? x++ : x--) {
                            var asc1, end1, start;

                            result2.push(x);
                        }
                        return result2;
                    })());
                }
                return ((() => {
                    let result3 = [];

                    for (start1 = this.currentPage - 2, x = start1, end2 = this.currentPage + 2, asc2 = start1 <= end2; asc2 ? x <= end2 : x >= end2; asc2 ? x++ : x--) {
                        var asc2, end2, start1;

                        result3.push(x);
                    }
                    return result3;
                })());
            }

            setCurrentPage(page) {
                let numPages = this.getNumPages();

                if (page > numPages) {
                    page = numPages;
                }
                if (page < 1) {
                    page = 1;
                }
                return this.currentPage = page;
            }

            nextPage() {
                return this.setCurrentPage(this.currentPage + 1);
            }

            previousPage() {
                return this.setCurrentPage(this.currentPage - 1);
            }

            hasPrevious() {
                return this.currentPage > 1;
            }

            hasNext() {
                return this.currentPage < this.getNumPages();
            }

            filter(callback) {
                return this.visibleData = this.data.filter(callback);
            }
        }

        return {
            create(data, config) {
                return new Paginator(data, config);
            }
        };
    }
]);
