// Utils
import { get, isFunction } from 'lodash';
import { ensureNumberValue } from 'libs/utils/numbers';

// Constants
import { QUEUE_JOB_STATUS_PATH } from 'libs/utils/constants';
import BaseService from './base-service';

/**
 * Provides a facility to monitor the status of a specific running job.
 *
 * @example
 * import JobsService from 'libs/services/jobs';
 * ...
 * const jobs = new JobsService();
 */
export default class JobsService extends BaseService {
    /**
     * Callback to update a job progress
     *
     * @private
     * @callback UpdateProgress
     *
     * @param {Number} progress the job's current progress.
     * @param {Number} position the job's position in the queue.
     * @param {String} state the job's current status.
     */

    /**
     * Callback to update a job progress in the UI
     *
     * @callback UpdateUIProgress
     *
     * @param {Number} progress the job's current progress.
     */

    /**
     * Given a job ID this method returns its status on the server.
     *
     * @param {Number} jobId the ID of the job to observe
     * @param {String} [statusUrl=QUEUE_JOB_STATUS_PATH] url for status
     *
     * @returns {Promise<Object>} the job status.
     */
    async getStatus(jobId, statusUrl = QUEUE_JOB_STATUS_PATH) {
        const url = statusUrl.replace('{{jobId}}', jobId.toString());
        const config = { withCredentials: true };

        const { data } = await this.get(url, config);

        return data;
    }

    /**
     * Given a job ID this method returns its execution results.
     *
     * @param {Number} jobId the ID of the job to get the result of.
     * @param {String} [path=QUEUE_JOB_STATUS_PATH] the path to get the results from.
     *
     * @returns {Promise<Object>} the job's execution results.
     */
    async getResults(jobId, path = QUEUE_JOB_STATUS_PATH) {
        const url = path.replace('{{jobId}}', jobId.toString());
        const config = { withCredentials: true };

        const { data } = await this.get(url, config);

        return data;
    }

    /**
     * Polls the server for a specific job status progress.
     *
     * @param {Number} jobId the ID of the job to get the result of.
     * @param {UpdateProgress} monitor the update progress callback.
     * @param {Number} [interval=1500] an optional interval time between each poll requests expressed in milliseconds.
     * @param {Number} [pollTimeout=Infinity] an optional timeout, how long the Job should be polled
     * @param {String} [statusUrl] statusUrl from router
     * @param {Function} [interruptFunction] function evaluated to see if the job needs to be interrupted
     * @param {boolean} [rejectsOnErrors=false] whether rejecting on any error while getting the status
     */
    pollProgress(jobId, monitor, statusUrl, interruptFunction, interval = 1500, pollTimeout = Infinity, rejectsOnErrors = false) {
        let iterations = 0;
        let lastProgress = 0;
        let lastPosition = 0;

        const i = setInterval(async () => {
            let status;

            try {
                status = await this.getStatus(jobId, statusUrl);
            } catch (error) {
                const { data, status } = error.response || {};

                console.warn('[JobsService] An error occurred while polling status:', error.message, data, status);

                try {
                    monitor(lastProgress, lastPosition, 'error', error);
                } catch (e) {
                    console.error('[JobsService] An error occurred on the monitor callback:', e);
                }

                if (rejectsOnErrors) {
                    clearInterval(i);
                }

                return;
            }

            let { progress, position, state, error } = status;

            // If we have an interrupt, we change the state to avoid downloads if the completion occurs within that same tick
            if (interruptFunction()) {
                state = 'interrupted';
            }

            // In case of error, progress and position may be null.
            progress = ensureNumberValue(progress, 100);
            position = ensureNumberValue(position, lastPosition);

            lastProgress = progress;
            lastPosition = position;

            if (error) {
                console.warn('[JobsService] Job sent an error', error);
            }

            const timedout = (interval * iterations) >= pollTimeout;

            if (['failed', 'complete', 'interrupted'].includes(state) || timedout) {
                clearInterval(i);
            }

            try {
                monitor(progress, position, timedout ? 'timeout' : state);
            } catch (e) {
                console.error('[JobsService] An error occurred on the monitor callback for timeout:', e);
            }

            iterations++;
        }, interval);
    }

    /**
     * Waits for a job to complete and return its status
     *
     * @param {Object} config
     * @param {Number} config.jobId the ID of the job to get the result of.
     * @param {String} config.resultPath the path to get the results from.
     * @param {String} [config.statusPath] the path to get the status from.
     * @param {UpdateUIProgress} [config.updateUIProgress] (optional) callback for progress updates for the UI
     * @param {Number} [config.interval=1500] (optional) time in ms for the polling intervals
     * @param {Number} [config.minutesToTimeout=20] an optional timeout, how long, in minutes, the Job should be polled
     * @param {Function} [config.interruptFunction=() => {return false;}] an optional function that should return truthy when the poll should stop, falsey otherwise
     * @param {boolean} [config.rejectsOnErrors=false] whether rejecting on any error while polling for progress
     * @returns {Promise<Object>} the job's execution results.
     */
    async waitFor({
        jobId,
        resultPath,
        statusPath,
        updateUIProgress = null,
        interval = 1500,
        minutesToTimeout = 20,
        interruptFunction = () => { return false; },
        rejectsOnErrors = false
    }) {
        return new Promise((resolve, reject) => {
            this.pollProgress(jobId, async (progress, position, state, error) => {
                if (isFunction(updateUIProgress)) {
                    try {
                        updateUIProgress(progress);
                    } catch (err) {
                        console.log('[JobsService] Could not updateUIProgress', err);
                    }
                }

                if (rejectsOnErrors && error) {
                    return reject(error);
                }

                if (state === 'timeout') {
                    return reject({ message: 'Error: Timeout' });
                }

                if (['failed', 'complete'].includes(state)) {
                    try {
                        const result = await this.getResults(jobId, resultPath);

                        // the condition for `result.status` is specific for marketplace
                        // where some of the jobs can fail, but still need to return data
                        // like which packages have conflicting dependencies during install/update
                        // in those cases the job completes, but the result has `status: 'error'`
                        if (state === 'complete' && result.status !== 'error') {
                            resolve(result);
                        } else {
                            return reject(result);
                        }
                    } catch (error) {
                        return reject(get(error, 'response.data', error));
                    }
                }
            },
            statusPath,
            interruptFunction,
            interval,
            60000 * minutesToTimeout,
            rejectsOnErrors
            );
        });
    }
}
