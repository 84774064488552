module.exports = [() => ({
    link(scope, elem, attrs) {
        // issue with pre-58 version of firefox where watch is defined on Object prototype, doing crazy things
        // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Object/watch
        if (!attrs.hasOwnProperty('watch')) {
            return;
        }
        return scope.$watch(attrs.watch, function(newValue, oldValue) {
            if ((newValue === null || newValue === undefined) &&
                (oldValue === null || oldValue === undefined)) {
                return;
            }
            if (_.isString(attrs.ifVisibleEl)) {
                if (!$(attrs.ifVisibleEl).is(':visible')) {
                    return;
                }
            }
            return elem.collapse('hide');
        });
    }
})];
