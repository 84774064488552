import Quill from 'quill';
import _ from 'underscore';

const INTERNAL_LINK_PROTOCOL = 'open-document://';
const ASSET_PROTOCOL = 'fp-asset://';
const ASSET_CLASS_NAME = 'fp-asset-src';

const BASE_TOOLBAR = [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    // dropdown with defaults from theme
    [
        {
            color: [
                '#000000',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#bbbbbb',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#888888',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#444444',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466',
                'custom-color'
            ]
        }
    ],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
    ],
    [{ align: [] }],
    ['blockquote'],
    ['link', 'image']
];

const ASSETS_ALIGNMENTS = {
    'left-text': 'Left',
    'pull-left': 'Left (wrap around text)',
    'center-block': 'Center',
    'pull-right': 'Right (wrap around text)'
};

const ASSET_ALIGNMENT_CLASSES = _.keys(ASSETS_ALIGNMENTS);

const Block = Quill.import('blots/block');
const Image = Quill.import('formats/image');
const Link = Quill.import('formats/link');
const List = Quill.import('formats/list');

function setAssetAttributes(node, { baseAssetUrl, assetType, asset, align }) {
    let prefix = assetType === 'internal' ? `${baseAssetUrl}/` : '';

    node.setAttribute('src', `${prefix}${asset}`);
    node.setAttribute('class', `${ASSET_CLASS_NAME} ${align}`);

    return node;
}

function isInternalUrl(url) {
    if (url === null) {
        url = '';
    }
    return url.indexOf(INTERNAL_LINK_PROTOCOL) === 0;
}

function parseInternalUrl(url) {
    try {
        return JSON.parse(
            decodeURIComponent(url).replace(INTERNAL_LINK_PROTOCOL, '') || '{}'
        );
    } catch (e) {
        return {};
    }
}

function buildInternalUrl(doc) {
    return `${INTERNAL_LINK_PROTOCOL}${encodeURIComponent(
        JSON.stringify(doc)
    )}`;
}

function setInternalLink(node, link) {
    node.setAttribute('href', buildInternalUrl(link));
    node.setAttribute('class', 'open-document-link');
    node.removeAttribute('target');
    return node;
}

function setExternalLink(node, link) {
    node.setAttribute('href', link);
    node.setAttribute('target', '_blank');
    const className = node.getAttribute('class');

    if (className) {
        node.setAttribute('class', className.replace('open-document-link', ''));
    }

    return node;
}

class QuillBlock extends Block {
    constructor(domNode) {
        super(domNode);
        domNode.setAttribute('sce', '__BSTG__');
    }
}

QuillBlock.tagName = 'DIV';

class QuillList extends List {
    constructor(domNode) {
        super(domNode);
        domNode.setAttribute('class', 'ql-list');
    }
}

const IMG_ADDITIONAL_ATTRIBUTES = ['class'];

class BstgImage extends Image {
    static create(value) {
        const node = super.create(value);
        let type = 'internal';

        if (_.isString(value)) {
            let asset = value
                .replace(BstgImage.baseAssetUrl, '')
                .replace(ASSET_PROTOCOL, '');

            if (/^(http|https|\/\/)/.test(value)) {
                type = 'external';
            }

            if (asset.indexOf('/') === 0) {
                asset = asset.substring(1, asset.length);
            }

            value = {
                baseAssetUrl: BstgImage.baseAssetUrl,
                asset: asset,
                assetType: type,
                align: 'left-text'
            };

        }

        return setAssetAttributes(node, value);
    }

    static formats(domNode) {
        const defaultFormats = super.formats(domNode);
        const additionalFormats = IMG_ADDITIONAL_ATTRIBUTES.reduce((formats, attribute) => {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});

        return Object.assign(defaultFormats, additionalFormats);
    }

    format(name, value) {
        if (IMG_ADDITIONAL_ATTRIBUTES.includes(name)) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

class BstgLink extends Link {
    static create(value) {
        const node = super.create(value);

        BstgLink.setOrigin(node, value);

        return node;
    }

    static sanitize(url) {
        if (_.isObject(url)) {
            return buildInternalUrl(url);
        }

        return super.sanitize(url);
    }

    static formats(node) {
        let href = node.getAttribute('href');

        if (isInternalUrl(href)) {
            href = parseInternalUrl(href);
        }

        // This might happen when transforming an internal link to an external one.
        if (_.isObject(href) && href.linkType === 'external') {
            href = href.url;
        }

        return href;
    }

    format(name, value) {
        super.format(name, value);

        if (name !== this.statics.blotName || !value) {
            return;
        }

        BstgLink.setOrigin(this.domNode, value);
    }

    static setOrigin(node, value) {
        value = BstgLink.normalizeValue(value);
        if (value.linkType === 'internal') {
            setInternalLink(node, value.doc);
        }

        if (value.linkType === 'external') {
            setExternalLink(node, value.url);
        }
    }

    static normalizeValue(value) {
        if (_.isObject(value)) {
            if (value.doc && (value.doc.doc || value.doc.linkType)) {
                value = value.doc;
            }

            if (value.fp_type) {
                value = {
                    linkType: 'internal',
                    doc: value
                };
            }
        } else {
            value = {
                linkType: 'external',
                url: value
            };
        }

        return value;
    }
}

BstgLink.PROTOCOL_WHITELIST = [
    'http',
    'https',
    'mailto',
    'tel',
    INTERNAL_LINK_PROTOCOL
];

Quill.register(QuillBlock, true);
Quill.register(QuillList, true);

Quill.register(
    {
        'formats/image': BstgImage,
        'formats/link': BstgLink
    },
    true
);

// overrides default image formatter
function extractAlignmentClassName(classNames) {
    if (_.isEmpty(classNames)) {
        return;
    }
    return _.find(
        classNames.split(' '),
        className => ASSET_ALIGNMENT_CLASSES.indexOf(className) !== -1
    );
}

function extractAssetTypeFromUrl(url, baseAssetUrl) {
    if (url.indexOf(baseAssetUrl) === 0 || url.indexOf(ASSET_PROTOCOL) === 0) {
        return 'internal';
    } else {
        return 'external';
    }
}

function extractAssetModelFromNode(node, baseAssetUrl) {
    let src = node.getAttribute('src');

    return {
        assetType: extractAssetTypeFromUrl(src, baseAssetUrl),
        asset: src.replace(baseAssetUrl + '/', '').replace(ASSET_PROTOCOL, ''),
        align: extractAlignmentClassName(node.getAttribute('class'))
    };
}

function createNewEditor(element, scope) {
    let toolbarOptions = {
        container: element.find('.qe-toolbar').get(0)
    };

    if (scope.enhancedTools) {
        toolbarOptions = BASE_TOOLBAR;
    }

    if (scope.readOnly) {
        toolbarOptions = false;
    }

    return new Quill(element.find('.qe-text-area').get(0), {
        readOnly: scope.readOnly,
        theme: 'snow',
        modules: {
            toolbar: toolbarOptions
        }
    });
}

angular
    .module('backstage.directives.quill', [])

    .directive('quillEditor', [
        'eventService',
        'assetsService',
        'modalService',
        (eventService, assetsService, modalService) => ({
            require: 'ngModel',
            restrict: 'A',
            templateUrl: '/static/partials/components/quill-editor.html',
            scope: {
                allowInternalLinks: '=',
                allowInternalAssets: '=',
                assetType: '=',
                enhancedTools: '='
            },
            link(scope, elem, attrs, ngModel) {
                const currentEvent = eventService.getCurrentEvent();
                const baseAssetUrl = assetsService.baseAssetUrl(
                    currentEvent !== null ? currentEvent._id : undefined
                );

                BstgImage.baseAssetUrl = baseAssetUrl;

                /* insert link modal */
                function insertLink(create) {
                    const selection = editor.getSelection(true);

                    // If user is trying to create a new link without having selected
                    // some text we return.
                    if (!selection || (create && !selection.length)) {
                        return;
                    }

                    // If the user didn't select any text try to determine it from
                    // the bolt.
                    if (!selection.length) {
                        const leaf = editor.getLeaf(selection.index);

                        if (leaf && leaf.length) {
                            const bolt = leaf[0];

                            selection.index = editor.getIndex(bolt);
                            selection.length = bolt.text.length;
                        }
                    }

                    const modalModel = angular.copy(
                        extractLinkModelFromSelection(selection) || {}
                    );

                    if (!scope.allowInternalLinks) {
                        modalModel.linkType = 'external';
                    }

                    const modalScope = {
                        allowInternalLinks: scope.allowInternalLinks,
                        model: modalModel,
                        fields: [
                            {
                                order: 1,
                                field: 'doc',
                                label: 'Item to open',
                                kind: 'external',
                                kind_options: {
                                    type: 'any',
                                    single_doc: true
                                }
                            }
                        ]
                    };

                    return modalService
                        .openModal({
                            templateUrl:
                                '/static/partials/components/modal-insert-link.html',
                            scope: modalScope
                        })
                        .then(function(link) {
                            // we have to restore the selection as inputs in the modal will steal focus
                            editor.focus();
                            editor.setSelection(selection);
                            editor.focus();

                            return editor.formatText(
                                selection,
                                'link',
                                link,
                                'user'
                            );
                        });
                }

                /* insert image */
                function insertAsset(node) {
                    const selection = editor.getSelection(true);

                    if (!selection && !node) {
                        return;
                    }

                    const modalScope = {
                        allowInternalAssets: scope.allowInternalAssets,
                        alignments: ASSETS_ALIGNMENTS,
                        model: _.isObject(node)
                            ? extractAssetModelFromNode(node, baseAssetUrl)
                            : {
                                assetType: scope.allowInternalAssets
                                    ? null
                                    : 'external',
                                align: 'left-text'
                            },
                        fields: [
                            {
                                order: 1,
                                field: 'asset',
                                label: 'Asset',
                                kind: 'external',
                                kind_options: {
                                    type: scope.assetType || 'generic-asset',
                                    id_field: 'fp_asset',
                                    single_doc: true
                                }
                            }
                        ]
                    };

                    return modalService
                        .openModal({
                            templateUrl:
                                '/static/partials/components/modal-insert-asset.html',
                            scope: modalScope
                        })
                        .then(function(model) {
                            model.baseAssetUrl = baseAssetUrl;

                            if (_.isObject(node)) {
                                setAssetAttributes(node, model);
                            } else {
                                if (_.isEmpty(model.asset)) {
                                    return;
                                }

                                editor.insertEmbed(
                                    selection.index,
                                    'image',
                                    model,
                                    'user'
                                );
                                editor.focus();
                                editor.setSelection(selection.index + 1);
                            }

                            ngModel.$setViewValue(
                                formatHtmlForModel(editor.root.innerHTML)
                            );
                        });
                }

                scope.readOnly =
                    typeof attrs.toggleDisabledStateOnEvent === 'string';

                const editor = createNewEditor(elem, scope);

                if (scope.readOnly) {
                    scope.$on(attrs.toggleDisabledStateOnEvent, () => {
                        // not sure why readOnly=false change takes a lot of time
                        // to be applied when not using scope.$apply
                        return scope.$apply(function() {
                            scope.readOnly = !scope.readOnly;
                            if (scope.readOnly) {
                                return editor.disable();
                            } else {
                                return editor.enable();
                            }
                        });
                    });
                } else {
                    const toolbar = editor.getModule('toolbar');

                    toolbar.addHandler('link', insertLink);
                    toolbar.addHandler('image', insertAsset);

                    if (scope.enhancedTools) {
                        // customize the color tool handler
                        toolbar.addHandler('color', value => {
                            // if the user clicked the custom-color option, show a prompt window to get the color
                            if (value === 'custom-color') {
                                value = prompt(
                                    'Enter Hex, RGB, or RGBA value (e.g. #FF0023)'
                                );
                            }

                            editor.format('color', value);
                        });
                    }
                }

                const extractLinkModelFromSelection = function(selection) {
                    const contents = editor.getFormat(selection);

                    if (_.isEmpty(contents)) {
                        return;
                    }

                    const model = {
                        linkType: _.isObject(contents.link)
                            ? 'internal'
                            : 'external'
                    };

                    if (model.linkType === 'internal') {
                        model.doc = contents.link;
                    } else {
                        model.url = contents.link;
                    }

                    return model;
                };

                /* ngModel bindings */
                const formatHtmlForEditor = function(html) {
                    const wrapper = $('<div/>').html(html);

                    wrapper.find(`.${ASSET_CLASS_NAME}`).each(function() {
                        const model = extractAssetModelFromNode(this);

                        if (model.assetType !== 'internal') {
                            return;
                        }
                        if (!currentEvent) {
                            return;
                        }
                        model.baseAssetUrl = baseAssetUrl;

                        return setAssetAttributes(this, model);
                    });
                    return wrapper.html();
                };

                const formatHtmlForModel = function(html) {
                    const wrapper = $('<div/>').html(html);

                    wrapper.find(`.${ASSET_CLASS_NAME}`).each(function() {
                        let src = this.getAttribute('src');

                        if (src.indexOf(baseAssetUrl) !== 0) {
                            return;
                        }

                        let newSrc = src.replace(
                            `${baseAssetUrl}/`,
                            ASSET_PROTOCOL
                        );

                        return this.setAttribute('src', newSrc);
                    });

                    return wrapper.html();
                };

                editor.on('text-change', () => {
                    ngModel.$setViewValue(
                        formatHtmlForModel(editor.root.innerHTML)
                    );
                });

                ngModel.$render = () => editor.pasteHTML(formatHtmlForEditor(ngModel.$viewValue || ''));

                /* misc */
                elem.addClass('quill-editor');
                elem.on('click', 'a', e => e.preventDefault() && false);

                if (!scope.readOnly) {
                    elem.on('click', `.${ASSET_CLASS_NAME}`, function(e) {
                        return scope.$evalAsync(() =>
                            insertAsset(e.currentTarget)
                        );
                    });
                }
            }
        })
    ]);
