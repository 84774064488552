"use strict";

module.exports = /* @ngInject */["$scope", "$window", "ngProgressLite", "orgService", function ($scope, $window, ngProgressLite, orgService) {
  $scope.model = {
    owner: $scope.user.realname,
    roles: []
  };
  $scope.createOrg = function (orgParams) {
    ngProgressLite.start();
    return orgService.createOrg(orgParams).then(function (data) {
      ngProgressLite.done();
      $window.location.href = "/org/".concat(orgService.getNameFromId(data.id));
    }, function (err) {
      ngProgressLite.done();
      $scope.errorMessage = err.data && err.data.error;
      alert($scope.errorMessage);
    });
  };
}];