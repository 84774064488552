"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var _require = require('underscore'),
  chain = _require.chain,
  extend = _require.extend,
  findWhere = _require.findWhere,
  isArray = _require.isArray,
  isObject = _require.isObject,
  isString = _require.isString,
  sortBy = _require.sortBy;
angular.module('backstage.directives.externals').controller('AnyFpTypeComboWidgetCtrl', /* @ngInject */["$q", "$scope", "$filter", "$rootScope", "urlService", "eventService", "metadataService", "modalService", "backlinksService", "$timeout", "$element", function ($q, $scope, $filter, $rootScope, urlService, eventService, metadataService, modalService, backlinksService, $timeout, $element) {
  var scope = $scope;
  var LABEL_SEARCH_MINIMUM_INPUT_LENGTH = 0;
  var LEGACY_SEARCH_MINIMUM_RESULTS_FOR_SEARCH_BOX = 5;
  var options = scope.options || {};
  extend(scope, options);
  var idField = scope.idField = options.id_field || 'fp_ext_id';
  var cachedSortedItemList = scope.cachedSortedItemList = [];
  var cachedIdToNameMappings = scope.cachedIdToNameMappings = {};
  var promisedCache = {};
  var cachedIdToStatusMappings = {};
  var cachedIdToAssetMappings = {};
  var event = eventService.getCurrentEvent();
  var eventId = scope.eventId = event._id;
  var eventNode = event.node;
  var metadata = metadataService.getCachedMetadata(event._id);
  var allowedFpTypes = chain(options.allowed_types || {}).map(function (allowed, type) {
    if (allowed) {
      return type;
    }
  }).compact().value();

  // # # # #

  scope.selection = {
    fpType: null,
    fpExtId: null
  };
  scope.$watch('selection.fpType', function () {
    return scope.selection.fpExtId = null;
  });
  var filteredMetadataTypes = chain(metadata).pairs().filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    if (!isObject(value) || key[0] === '_') {
      return false;
    }
    if (!allowedFpTypes.length) {
      return true;
    }
    return allowedFpTypes.indexOf(key) !== -1;
  }).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 1),
      key = _ref4[0];
    return key;
  }).value();
  scope.fpTypeSelect2Options = {
    placeholder: 'Choose a type',
    data: metadataService.getTypeRepresentations(filteredMetadataTypes, metadata)
  };

  // BACKEND FEATURE DETECTION ⚑⚑⚑
  // use better remote-based externals search if we have a compatible pkg-lib-backlink

  var legacyModeDetectionPromise = backlinksService.isBacklinksSearchAvailable(eventId, eventNode).then(function (isBacklinksSearchAvailable) {
    scope.isInLegacyModeDetermined = true;
    if (scope.isInLegacyMode = !isBacklinksSearchAvailable) {
      return scope.select2Options = {
        placeholder: 'Choose an item',
        minimumResultsForSearch: LEGACY_SEARCH_MINIMUM_RESULTS_FOR_SEARCH_BOX
      };
    } else {
      // go remote
      return scope.select2Options = {
        placeholder: 'Find an item',
        minimumInputLength: LABEL_SEARCH_MINIMUM_INPUT_LENGTH,
        minimumResultsForSearch: LEGACY_SEARCH_MINIMUM_RESULTS_FOR_SEARCH_BOX,
        ajax: {
          url: function url() {
            return backlinksService.getSearchHandlerUrl(eventId, (scope.selection.fpType || {}).id);
          } /* params */,
          dataType: 'json',
          quietMillis: 250,
          data: function data(term) {
            // ' ' = return all
            if (term === null) {
              term = '';
            }
            return {
              query: term.length ? term : ' ',
              field: idField
            };
          },
          results: function results(list) {
            if (list === null) {
              list = [];
            }
            var field = $scope.model[$scope.field];
            if (field) {
              var ids = field;
              if (isObject(field[0])) {
                ids = field.map(function (o) {
                  return o.id || o._id || o.fp_ext_id;
                });
              }
              list = list.filter(function (i) {
                return !ids.includes(i.id || i._id || i.fp_ext_id);
              });
            }
            return {
              results: list
            };
          },
          cache: false
        }
      };
    }
  }, function () {
    // fall back to legacy mode on error
    scope.isInLegacyMode = true;
    scope.isInLegacyModeDetermined = true;
  });
  var getItemsArray = scope.getItemsArray = function () {
    var model = scope.model,
      field = scope.field;
    // set the default field value
    // if single_doc then this is null
    // or we'll set an empty array (to be filled with rows)

    var modelField = model[field];
    if (!modelField) {
      return [];
    } else {
      // add a warning if a list is expected, but value is not a list
      if (scope.single_doc) {
        if (!isObject(modelField)) {
          console.warn("Wrong value for field ".concat(field, ": ").concat(modelField, ". It should be an object."));
          modelField = model[field] = undefined;
        }
      } else {
        if (!isArray(modelField)) {
          console.warn("Wrong value for field ".concat(field, ": ").concat(modelField, ". It should be an array."));
          modelField = model[field] = [];
        }
      }
    }

    // now return the array of ids that we'll use to render the list
    if (scope.single_doc) {
      return [modelField];
    }
    return modelField;
  };
  var indexKeyForDoc = function indexKeyForDoc(doc) {
    if (isObject(doc)) {
      return "".concat(doc.fp_type, ":").concat(doc[idField]);
    }
    if (arguments.length === 2) {
      return "".concat(arguments[0], ":").concat(arguments[1]);
    }
  };
  var humanize = $filter('humanize');
  var uppercaseFirstChar = $filter('uppercaseFirstChar');
  scope.getFieldLabel = function () {
    var fieldLabel = scope.fieldDescriptor.label || scope.field;
    return uppercaseFirstChar(humanize(fieldLabel));
  };
  scope.canAddAnItem = function () {
    var currentNumber = getItemsArray().length;
    var maxNumber = scope.single_doc ? 1 : scope.options.maxNumber;
    return !maxNumber || currentNumber < maxNumber;
  };
  scope.getDisplayForId = function (fpType, fpExtId) {
    if (!fpExtId) {
      return;
    }
    var index = indexKeyForDoc(fpType, fpExtId);
    var promise = promisedCache[index];
    var cached = cachedIdToNameMappings[index];
    if (promise) {
      return promise;
    }
    if (cached) {
      var _id = (findWhere(cachedSortedItemList, _defineProperty({}, idField, fpExtId)) || {})._id;
      var result = {
        label: cached,
        id: _id
      };
      var _promise = Promise.resolve().then(function () {
        return result;
      });
      angular.extend(_promise, result);
      promisedCache[index] = _promise;
      return _promise;
    }
    var event = eventService.getCurrentEvent();
    var newPromise = promisedCache[index] = getItem(fpType, fpExtId).then(function (doc) {
      var reprLabel = metadataService.getReprLabelForItem(event._id, doc);
      var fallbackLabel = function fallbackLabel() {
        if (doc.fp_type === 'person') {
          return doc.fullname || "".concat(doc.fname, " ").concat(doc.lname);
        }
        return (doc.name || {}).en || doc.name || doc.title || doc.subject || doc.label || doc.fp_ext_id || doc._id;
      };
      var result = {
        label: reprLabel || fallbackLabel(),
        id: doc._id
      };
      angular.extend(newPromise, result);
      return result;
    });
    return newPromise;
  };
  function getItem(fpType, idOrFpExtId) {
    return $q.resolve().then(function () {
      if (idField === '_id') {
        return eventService.getEventDocById(event, idOrFpExtId).then(function (response) {
          return response.data || {};
        });
      }
      if (idField === 'fp_ext_id') {
        return eventService.getEventDocByFpExtId(event, idOrFpExtId, fpType);
      }
      return Promise.resolve({});
    });
  }
  scope.onItemClick = function (fpType, fpExtId) {
    if (!urlService.isValidAppRoute("/event/:eventId/".concat(fpType, "s/:modelId"))) {
      return null;
    }
    scope.getDisplayForId(fpType, fpExtId).then(function (display) {
      modalService.closeAll();
      $rootScope.navigate("/event/".concat(eventId, "/").concat(fpType, "s/").concat(display.id));
    });
  };
  scope.getIconClassForType = function (type) {
    switch (type) {
      case 'person':
      case 'presenter':
        return 'icon-user';
      case 'session':
        return 'icon-calendar';
      default:
        return 'icon-link';
    }
  };
  scope.getVisibilityClassesForId = function (fpExtId, enableStrikeThrough) {
    if (enableStrikeThrough === null) {
      enableStrikeThrough = true;
    }
    var strikethrough = enableStrikeThrough ? 'strikethrough' : '';
    switch (cachedIdToStatusMappings[fpExtId]) {
      case 'cancelled':
        return "translucent ".concat(strikethrough);
      case 'hidden':
        return 'translucent';
      default:
        return '';
    }
  };
  scope.revealAddDropdownControls = function () {
    if (scope.isInLegacyMode && (!cachedSortedItemList || !cachedSortedItemList.length)) {
      // if the list is empty we'll try to fetch again
      // (this is particularly useful when the user has just created a new item in a new tab/window and wants to see the list refresh)
      scope.loading = true;
      eventService.getEventDocsByType(event, scope.type).then(legacyGetEventDocsByTypeCb);
    }
    scope.showAddControls = true;
    $timeout(function () {
      $element.find('.btn-add-item').select2('open');
    }, 250);
  };
  var findRowIndex = function findRowIndex(rows, fpType, id) {
    for (var idx = 0; idx < rows.length; idx++) {
      var row = rows[idx];
      if (row.fp_type === fpType && row[idField] === id) {
        return idx;
      }
    }
    return -1;
  };
  scope.addFpExtId = function (fpType, fpExtId) {
    var ids = getItemsArray();
    if (!isString(fpExtId)) {
      return;
    }
    if (findRowIndex(ids, fpType, fpExtId) !== -1) {
      return;
    }
    var row = {};
    row.fp_type = fpType;
    row[idField] = fpExtId;
    var model = scope.model,
      field = scope.field;
    if (scope.single_doc) {
      model[field] = row;
    } else {
      if (!isArray(model[field])) {
        model[field] = [];
      }
      model[field].push(row);
    }
    scope.selection.fpType = null;
  };
  scope.removeFpExtId = function (fpType, fpExtId) {
    var model = scope.model,
      field = scope.field;
    var ids = getItemsArray();
    var idx = findRowIndex(ids, fpType, fpExtId);
    if (!ids || idx === -1) {
      return;
    }
    if (scope.single_doc) {
      model[field] = null;
    } else {
      ids.splice(idx, 1);
    }
  };
  var legacyGetEventDocsByTypeCb = function legacyGetEventDocsByTypeCb(evDocsListData) {
    var _list = evDocsListData ? evDocsListData.data : undefined;
    if (!_list) {
      return;
    }
    return fetchAndMapItems(_list);
  };
  var triggerLegacyModeFetchAndMapItems = function triggerLegacyModeFetchAndMapItems() {
    scope.loading = true;
    return eventService.getEventDocsByType(event, scope.type, {
      cached: true
    }).then(legacyGetEventDocsByTypeCb);
  };
  if (scope.isInLegacyModeDetermined) {
    if (scope.isInLegacyMode) {
      triggerLegacyModeFetchAndMapItems();
    }
  } else {
    legacyModeDetectionPromise.then(function () {
      if (scope.isInLegacyMode) {
        return triggerLegacyModeFetchAndMapItems();
      }
    });
  }
  var fetchAndMapItems = function fetchAndMapItems(docsList) {
    // ⚑ UNAVOIDABLE HACK ALERT
    // TODO: REMOVE this when we have fully transitioned away from hosted_docs
    docsList = docsList.map(function (_doc) {
      if (!_doc._id || _doc.fp_type !== 'hosted_doc') {
        return _doc;
      }
      var props = _doc.bs_fileprops;
      var ext = (props || {}).extension;
      if (!props || !ext) {
        return _doc;
      }
      if (_doc._id.substring(_doc._id.length - (ext.length + 1)) === ".".concat(ext)) {
        return _doc;
      }
      _doc._id = "".concat(_doc._id, ".").concat(ext);
      return _doc;
    });
    var idToNameMappings = docsList.reduce(function (memo, doc) {
      var reprLabel = metadataService.getReprLabelForItem(eventId, doc);
      var fallbackLabel = function () {
        switch (doc.fp_type) {
          case 'person':
            return doc.fullname || "".concat(doc.fname, " ").concat(doc.lname);
          default:
            return (doc.name ? doc.name.en : undefined) || doc.name || doc.title || doc.subject || doc.label || doc.fp_ext_id || doc._id;
        }
      }();
      memo[indexKeyForDoc(doc)] = reprLabel || fallbackLabel;
      return memo;
    }, {});
    angular.extend(cachedIdToNameMappings, idToNameMappings);
    var idToStatusMappings = docsList.reduce(function (memo, doc) {
      if (doc.fp_status) {
        memo[indexKeyForDoc(doc)] = doc.fp_status;
      }
      return memo;
    }, {});
    angular.extend(cachedIdToStatusMappings, idToStatusMappings);
    var filteredSortedItemList = $filter('filterOutPaxWithFpStatusCancelled')(sortBy(docsList, 'fname', 'title'));
    angular.copy(filteredSortedItemList, cachedSortedItemList);
    var idToAssetMappings = docsList.reduce(function (memo, doc) {
      if (doc.fp_asset) {
        memo[doc[idField]] = doc.fp_asset;
      }
      return memo;
    }, {});
    angular.extend(cachedIdToAssetMappings, idToAssetMappings);
    return scope.loading = false;
  };
}]);
angular.module('backstage.directives.externals').directive('bsAnyFpTypeComboWidget', function () {
  return {
    templateUrl: '/static/partials/components/any-fp-type-selector.html',
    require: '?ngModel',
    priority: 100,
    scope: {
      model: '=ngModel',
      fieldDescriptor: '=',
      field: '=',
      label: '=',
      options: '='
    },
    link: function link(scope, elem, attr, ctrl) {
      ctrl.$validators.required = function (modelValue, viewValue) {
        var isRequired = ((scope.fieldDescriptor || {}).validations || {}).required;
        return !(isRequired && ctrl.$touched && ctrl.$isEmpty(viewValue[scope.fieldDescriptor.field]));
      };
      scope.$watch('model[fieldDescriptor.field]', function (newValue, oldValue) {
        if (newValue !== undefined && oldValue !== newValue) {
          ctrl.$setTouched();
          ctrl.$validate();
        }
      });
    },
    controller: 'AnyFpTypeComboWidgetCtrl'
  };
});