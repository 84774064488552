"use strict";

var WebappLinkService = /* @ngInject */function WebappLinkService($http, eventService, handlerService, modalService, userService) {
  function getWebAppLink(participantId, redirect) {
    var eventId = eventService.getCurrentEvent()._id;
    if (participantId) {
      return $http({
        url: handlerService.generateBackendUrl(eventId, 'backstage/webapp-login-url'),
        params: {
          redirect: redirect || '',
          pid: participantId,
          format: 'json'
        }
      }).catch(function () {
        // fallback on getting for the current user, to avoid issues on event post 3.4.1
        return $http.post('/api/v1/event/' + eventId + '/backstage/webapp-login-url', {
          redirect: redirect || '',
          userId: userService.getCurrentUser()._id,
          format: 'json'
        });
      }).then(function (resp) {
        return resp.data.url;
      });
    } else {
      return $http.post('/api/v1/event/' + eventId + '/backstage/webapp-login-url', {
        redirect: redirect || '',
        userId: userService.getCurrentUser()._id,
        format: 'json'
      }).catch(function () {
        // fallback on old API for event of the previous generation
        return $http({
          url: handlerService.generateBackendUrl(eventId, 'backstage/webapp-login-url'),
          params: {
            redirect: redirect || '',
            pid: userService.getCurrentUser()._id,
            format: 'json'
          }
        });
      }).then(function (resp) {
        return resp.data.url;
      });
    }
  }
  function presentLoginLinkModalForParticipant(participant) {
    participant = angular.copy(participant);
    var modalScope = {
      participant: participant,
      error: {}
    };
    modalService.openModal({
      templateUrl: '/static/partials/components/modal-webapp-login.html',
      scope: modalScope
    });
    getWebAppLink(participant._id).then(function onSuccess(url) {
      participant._webapp_link = url;
    }, function onFailure(err) {
      console.error(err);
      modalScope.error.message = 'Whoops, there was an error while issuing the link.' + '<br/>Please try again later or contact support.';
    });
  }
  return {
    getWebAppLink: getWebAppLink,
    presentLoginLinkModalForParticipant: presentLoginLinkModalForParticipant
  };
};
WebappLinkService.$inject = ["$http", "eventService", "handlerService", "modalService", "userService"];
WebappLinkService.$inject = ["$http", "eventService", "handlerService", "modalService", "userService"];
angular.module('backstage.services').factory('webAppUrlService', WebappLinkService);