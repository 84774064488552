module.exports = ['ngProgressLite', ngProgressLite => ({
    link(scope, elem, attrs) {
        elem.hide();
        return scope.$watch(attrs.watchBool, function(newValue, oldValue) {
            if ((newValue === null || newValue === undefined) &&
                (oldValue === null || oldValue === undefined)) {
                return;
            }
            let shouldShowProgressBar = Boolean(newValue);

            if (shouldShowProgressBar && !ngProgressLite.isRendered()) {
                ngProgressLite.start();
            }
            if (!shouldShowProgressBar && ngProgressLite.isRendered()) {
                return ngProgressLite.done();
            }
        });
    }
})];
