/** @module Routes */

/**
 * Builds path from angular route object
 *
 * @param {Object} routeObj angular route object
 *
 * @returns {String} path
 */
export function buildPathFromRoute(routeObj) {
    return Object.keys(routeObj.pathParams).reduce((path, prop) => {
        if (routeObj.pathParams.hasOwnProperty(prop)) {
            return path.replace(new RegExp(`:${prop}`, 'gi'), routeObj.pathParams[prop].toString());
        }
        return path;
    }, routeObj.$$route.originalPath);
}
