// ⚑
// TODO: Review this Irish code
module.exports = [() => ({
    link(scope, element, attrs) {
        let childLists;
        let trueValue = attrs.trueValue ? attrs.trueValue : true;
        let falseValue = attrs.falseValue ? attrs.falseValue : false;

        let {
            property
        } = attrs;
        let allChecked = true;

        if (attrs.childList) {
            childLists[0] = attrs.childList;
        }
        if (attrs.childLists) {
            childLists = JSON.parse(attrs.childLists);
        }

        element.bind('change', () =>
            scope.$apply(function() {
                let isChecked = element.prop('checked');

                return angular.forEach(childLists, childList =>
                    angular.forEach(scope.$eval(childList), function(child) {
                        if (attrs.fieldMappings) {
                            child[property] = isChecked;
                        } else {
                            child[property] = isChecked ? trueValue : falseValue;
                        }
                        return allChecked = isChecked;
                    })
                );
            })
        );

        return angular.forEach(childLists, childList =>
            scope.$watch(childList, (function(newValue) {
                let hasChecked = false;
                let hasUnchecked = false;

                angular.forEach(newValue, function(child) {
                    if (child[property] === trueValue) {
                        return hasChecked = true;
                    } else {
                        return hasUnchecked = true;
                    }
                });

                if (hasUnchecked) {
                    allChecked = false;
                } else if (hasChecked && allChecked) {
                    allChecked = true;
                }

                if (allChecked) {
                    return element.prop('checked', true);
                } else {
                    return element.prop('checked', false);
                }
            }), true)
        );
    }
})];
