"use strict";

var _require = require('src/slugify-utils'),
  slugify = _require.slugify;
var _require2 = require('lodash'),
  isEmpty = _require2.isEmpty,
  get = _require2.get;
module.exports = /* @ngInject */["$route", "$routeParams", "$scope", "$rootScope", "eventService", "metadataService", "bulkUpdateService", function ($route, $routeParams, $scope, $rootScope, eventService, metadataService, bulkUpdateService) {
  var eventId = $routeParams.eventId;
  var event = eventService.decorateScope(eventId);
  var currentRouteDescriptor = ($route.current || {}).$$route;
  var vm = $scope.vm = {};
  vm.eventId = eventId;
  vm.apiUploadType = (currentRouteDescriptor || {}).uploadType;
  var assetFpType = vm.assetFpType = (currentRouteDescriptor || {}).assetFpType || vm.apiUploadType || 'hosted_doc';
  if (!vm.apiUploadType) {
    vm.apiUploadType = 'autodetect';
  }
  vm.fields = metadataService.getCachedMetadataForTypeAsArray(eventId, assetFpType);
  var fpOwnerField = vm.fields.find(function (f) {
    return f.field === 'fp_owner';
  });
  vm.showSendToApps = !isEmpty(get(fpOwnerField, 'kind_options.values'));
  vm.triggerEditAssetDoc = function (assetDoc) {
    return vm.assetDocToEdit = assetDoc;
  };
  vm.saveAllAssetDocs = function (assetDocs) {
    vm.assetDocToEdit = null;
    return bulkUpdateService.createDocs(eventId, event.node, assetFpType, assetDocs, {
      keepId: true
    }).then(function () {
      vm.uploadedAssetDocsDirtyFlag = false;
      return $rootScope.setFlashMessage('The assets\' settings were saved.');
    }, function (err) {
      return alert((err.data || {}).error || 'The item could not be saved.');
    });
  };
  $scope.$watch('vm.responseData', function (newWorkbookData) {
    if (!_.isArray((newWorkbookData || {}).successes)) {
      return;
    }
    return eventService.getEventDocsByType(event, vm.assetFpType).then(function (data) {
      var successfulUploadFileNames = newWorkbookData.successes.map(function (fileName) {
        return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
      });
      var uploadedAssetDocs = ((data || {}).data || []).filter(function (doc) {
        return doc.fp_asset && successfulUploadFileNames.some(function (fileName) {
          return doc.fp_asset.indexOf("".concat(assetFpType, ".").concat(slugify(fileName), "/")) === 0;
        });
      });
      vm.uploadedAssetDocs = uploadedAssetDocs;
    });
  });
  $scope.$watch('vm.uploadedAssetDocs', function (newUploadedAssetsDocs, oldUploadedAssetsDocs) {
    // we only care about changes when the list is already populated
    if (!_.isArray(oldUploadedAssetsDocs) || _.isEmpty(oldUploadedAssetsDocs)) {
      return;
    }
    return vm.uploadedAssetDocsDirtyFlag = true;
  }, true);
}];