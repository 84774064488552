module.exports = ['$rootScope', 'urlService', 'metadataService', ($rootScope, urlService, metadataService) => ({
    restrict: 'EA',
    scope: {
        customActionButtons: '=',
        context: '='
    },
    templateUrl: '/static/partials/components/custom-action-buttons.html',
    link(scope) {
        scope.triggerCustomActionButton = function(buttonDescriptor) {
            if (_.isFunction(buttonDescriptor._action)) {
                return buttonDescriptor._action(scope.context);
            } else {
                return urlService.openInterpolatedRoutePath(
                    buttonDescriptor._targetRoute, buttonDescriptor.target);
            }
        };

        scope.getLabelForCustomActionButton = (buttonDescriptor, context, alt = '') => {
            if (buttonDescriptor.label) {
                return metadataService.translateObjects(buttonDescriptor.label, context);
            } else {
                return alt;
            }
        };

        for (const buttonDescriptor of scope.customActionButtons) {
            buttonDescriptor.visible = true;

            const { method } = buttonDescriptor;
            const { context: { fp_type } } = scope;

            if (fp_type === 'person' && method === 'presentLoginLinkModalForParticipant') {
                buttonDescriptor.visible = !$rootScope.event.hasOwnProperty('show_access_as') || $rootScope.event.show_access_as;
            }
        }
    }
})];
