"use strict";

angular.module('backstage.services').factory('btApplyMetadataDefaults', /* @ngInject */["btGenerateUniqueId", function (btGenerateUniqueId) {
  /**
   * Applies default values from metadata
   *
   * @param {Object} metadata
   * @param {Object} model
   * @param {boolean} overrideAutogenerated if true then isAutoGenerated fields will be set
   */
  return function btApplyMetadataDefaults(metadata, model, overrideAutogenerated) {
    Object.keys(metadata).forEach(function (field) {
      if (model[field] === undefined) {
        if (metadata[field].default) {
          model[field] = metadata[field].default;
        } else if (metadata[field].isAutoGenerated) {
          model[field] = btGenerateUniqueId();
        }
      } else if (overrideAutogenerated && metadata[field].isAutoGenerated) {
        model[field] = btGenerateUniqueId();
      }
    });
  };
}]);