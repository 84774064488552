"use strict";

var _underscore = _interopRequireDefault(require("underscore"));
var _tinycolor = _interopRequireDefault(require("tinycolor2"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var WHITE = '#fff';
var BLACK = '#000';
module.exports = /* @ngInject */["$window", "$scope", "$timeout", "eventService", "themeService", function ($window, $scope, $timeout, eventService, themeService) {
  var currentEvent = eventService.getCurrentEvent();
  var eventId = currentEvent._id;

  // default value is 128, our change makes more colours 'dark'
  var isDark = function isDark(c) {
    return c.getBrightness() < 180;
  };
  var generateThemeSuggestion = function generateThemeSuggestion(colour) {
    var c = (0, _tinycolor.default)(colour);
    var cIsDark = isDark(c);
    var primaryText = cIsDark ? WHITE : BLACK;
    var menuBackground = WHITE;
    var menuText = BLACK;
    var altMenuBackground = WHITE;
    var altMenuText = '#999999';
    return {
      primaryColor: colour,
      toolbar: {
        color: primaryText,
        backgroundColor: colour
      },
      spotman: {
        view: {
          color: menuText,
          backgroundColor: menuBackground,
          iconsColorEnabled: true,
          iconsColor: menuText,
          selected: {
            color: BLACK,
            backgroundColor: '#e6e6e6'
          }
        },
        alt_view: {
          color: altMenuText,
          backgroundColor: altMenuBackground
        },
        profile: {
          backgroundColor: menuBackground,
          roundedPhoto: true,
          title: {
            color: WHITE
          },
          subtitle: {
            color: '#e6e6e6'
          }
        }
      },
      countBadge: {
        backgroundColor: menuText,
        color: menuBackground
      },
      mainTabBar: {
        activeColor: colour
      },
      iosStatusBar: primaryText,
      splashscreen: {
        backgroundColor: '#000',
        backgroundColorOpacity: 0,
        margin: {
          phone: 40,
          tablet: 45
        },
        text: '#000',
        spinner: {
          color: '#666'
        },
        progressBar: {
          backgroundColor: '#f3f3f3',
          fill: {
            backgroundColor: '#666'
          }
        }
      }
    };
  };
  $scope.eventId = eventId;
  $scope.selectedIndex = -1;

  // Hack alert:
  // For reasons unknown, when using a directive in ngRepeat with
  // a staggering animation, the animation is not played out the first time
  // this fixes it
  $scope.samples = [null];
  $timeout(function () {
    return $scope.samples = [];
  });
  $scope.onColoursExtracted = function (palette) {
    $scope.selectedIndex = -1;
    $scope.samples = _underscore.default.map(palette, generateThemeSuggestion);
  };
  $scope.selectSample = function (index) {
    return $scope.selectedIndex = index;
  };
  $scope.save = function () {
    $scope.saving = true;
    var theme = $scope.samples[$scope.selectedIndex];
    themeService.saveThemeBit(eventId, currentEvent.node, theme).then(function () {
      $scope.setFlashMessage('Theme has been saved. Sync your app.');
      $scope.navigate("/event/".concat(eventId, "/modules/theme-editor/theme"));
    }, function () {
      return $window.alert('An error occured while saving your theme. Please try again in a few moments.');
    });
  };
}];