"use strict";

module.exports = function () {
  return {
    restrict: 'E',
    template: '<div style="width:100%" class="highchart"></div>',
    replace: true,
    scope: {
      config: '='
    },
    controller: /* @ngInject */["$scope", "$element", function controller($scope, $element) {
      $scope.$watch('config', function () {
        if (!$scope.config) {
          $element.html('');
          return;
        }
        $element.highcharts(angular.copy($scope.config, {}));
      }, true);
    }]
  };
};