"use strict";

module.exports = function (options) {
  var resolver = /* @ngInject */function resolver($http, eventService) {
    var eventId = eventService.getCurrentEvent()._id;
    var folders = options.folders;
    return $http.get("/api/v1/bstg-help-content/".concat(eventId, "/").concat(folders.toString())).then(function (resp) {
      return resp.data;
    });
  };
  resolver.$inject = ["$http", "eventService"];
  return resolver;
};