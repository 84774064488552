// Utils
import { isFunction } from 'lodash';

// Constants

/**
 * The URL for loading the Intercom Javacript. Interpolation: `{{appId}}`
 * @constant {String}
 * @private
 */
const INTERCOM_SCRIPT_URL = 'https://widget.intercom.io/widget/{{appId}}';

/**
 * @typedef IntercomCompany
 *
 * @property {String} company_id The company ID of the company
 * @property {String} name The name of the company
 * @property {Number} created_at The time the company was created in your system
 * @property {String} plan The name of the plan the company is on
 * @property {String} monthly_spend How much revenue the company generates for your business
 * @property {Number} user_count Indicates the number of users in Intercom associated to the company
 * @property {Number} size The number of employees in the company
 * @property {String} website The URL for the company website
 * @property {String} industry The industry of the company
 *
 * @see https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#section-company-object
 */

/**
 * This service is used to get and set information
 * for support purposes.
 */
export default class SupportService {

    /**
     * Builds a service instance
     *
     * @param {Object} options the service configuration
     * @param {String} options.intercom the Intercom APP ID
     */
    constructor({ intercom }) {
        this.intercomAppId = intercom;
    }

    /**
     * Sets the user details for support purposes
     *
     * @param {Object} user the backstage user to use for tracking
     */
    setUser(user) {
        this.user = user;
    }

    /**
     * Given an APP ID this method returns the URL to load the Intercom Javascript
     *
     * @returns {String} the Intercom widget javascript URL
     */
    getIntercomScriptURL() {
        return INTERCOM_SCRIPT_URL.replace('{{appId}}', this.intercomAppId);
    }

    /**
     * This method initializes Intercom widget
     *
     * @param {Function} intercom the intercom widget instance
     */
    initIntercom(intercom) {
        if (!isFunction(intercom)) {
            console.error('[SupportService] Trying to initialize intercom with not valid Intercom instance');
            return;
        }

        if (!this.user) {
            console.error('[SupportService] Could not initialize Intercom without a user');
            return;
        }

        intercom('reattach_activator');
        intercom('update', {
            app_id: this.intercomAppId,
            name: this.user.realname,
            email: this.user.email,
            companies: this._buildIntercomCompanies(),
            hide_default_launcher: true,

            // This are specific to backstage as reported in
            // https://spotme.atlassian.net/browse/EP-4142
            // We wanted to hardcode them here since the intercom instance
            // will be the same for web (www.spotme.com) and backstage
            alignment: 'left',
            horizontal_padding: 72,
            vertical_padding: 20
        });
    }

    /**
     * Builds the Intercom's companies array
     *
     * @returns {IntercomCompany[]} a list containing user's company mapped to Intercom obect
     *
     * @private
     */
    _buildIntercomCompanies() {
        return (this.user.orgs || []).map(org => ({
            company_id: org._id,
            name: org.display_name
        }));
    }
}
