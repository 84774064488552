"use strict";

var _ = require('underscore');
var $ = require('jquery');
module.exports = /* @ngInject */["$window", "$rootScope", "urlService", "clipboardService", function ($window, $rootScope, urlService, clipboardService) {
  var clipboard = new clipboardService();
  var cleanDataRow = function cleanDataRow(model) {
    return _.chain(model).omit('_id', '_rev', 'fp_ext_id', 'fp_owner', 'fp_type', 'fp_last_changes', 'name', 'accredited_link', '$$hashKey', 'isSelected').map(function (value, key) {
      return [key, _.isString(value) ? value : +value];
    }).filter(function (field) {
      return field[1];
    }).object().value();
  };
  return {
    restrict: 'C',
    link: function link(scope, element, attrs) {
      scope.generatePermalink = function (model) {
        var eventInstanceUrl = $rootScope.event.instanceurl;
        if (urlService.isLocalNodeOurHost()) {
          eventInstanceUrl = 'https://' + urlService.getAppRootDomain();
        }
        var params = $.param(cleanDataRow(model)).replace(/(\+)/g, '%20');
        var url = '{root}/spotletproxy/dbnode-ev-{eid}/{doc}?accredited_link_token={token}#spotlet_{spotlet}?{params}'.replace('{root}', eventInstanceUrl).replace('{doc}', '_design/spotlet/index.html').replace('{eid}', $rootScope.event._id).replace('{token}', model.accredited_link).replace('{spotlet}', attrs.spotlet).replace('{params}', params);
        console.log(url);
        return url;
      };
      scope.launchPermalink = function (model) {
        $window.open(scope.generatePermalink(model), '_blank');
      };
      scope.copyToClipboard = clipboard.set.bind(clipboard);
    }
  };
}];