"use strict";

EventCopyReportCtrl.$inject = ["eventService", "appScriptService"];
/* @ngInject */
function EventCopyReportCtrl(eventService, appScriptService) {
  var _this = this;
  var eventId = eventService.getCurrentEvent()._id;
  this.getAssetUrlForFile = function (file) {
    return "/api/v1/events/".concat(eventId, "/assets/").concat(file);
  };
  this.toggleChangeReport = function (change) {
    if (!change.changes || !change.changes.length) {
      return;
    }
    change._isExpanded = !change._isExpanded;
    if (change._isExpanded && !change._representations) {
      _this.getRepresentationsForChangedIds(change);
    }
  };
  this.getRepresentationsForChangedIds = function (change) {
    if (!change.changes || !change.changes.length || change.type !== 'data') {
      return;
    }
    appScriptService.callAppScript('representation/resolve-for-ids', {
      ids: change.changes
    }).then(function (reprs) {
      return change._representations = reprs;
    }, function () {
      return {};
    });
  };
  this.isImage = function (item) {
    return /\.(png|jpeg|jpg|gif)$/i.test(item);
  };
}
module.exports = angular.module('backstage.components.events.copy.copy-report', []).component('eventCopyReport', {
  templateUrl: '/src/angular/components/events/copy/copy-report/copy-report.html',
  bindings: {
    report: '<'
  },
  controller: EventCopyReportCtrl
}).name;