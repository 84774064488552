import BaseService from './base-service';

import { API_BASE_PATH } from 'libs/utils/constants';

const IMPORT_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/excel/import`;

export default class ExcelService extends BaseService {

    /**
     * @param {string} eventId
     * @param {object} file
     * @param {string} importType
     *
     * @returns {Promise<{jobId: string}>}
     */
    async import(eventId, file, importType) {
        const url = this.buildUrl(IMPORT_ENDPOINT, { eventId });

        const token = await this.getCsrfToken();

        const bodyFormData = new FormData();
        bodyFormData.set('file', file);
        bodyFormData.set('import_type', importType);
        bodyFormData.set('_csrf', token);

        const { data: { jobId } } = await this.post(url, bodyFormData);

        return {
            jobId
        };
    }
}
