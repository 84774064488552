// bs-table-cell-tick

module.exports = function() {
    // include smart table controller to use its API if needed
    return {
        require: '^smartTable',
        template: '<small></small>',
        replace: true,
        link(scope, element) {
            let char = '×';

            if (scope.formatedValue) {
                char = '✔';
            }
            return element.html(char);
        }
    };
};
