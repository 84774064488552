// Types
import 'libs/utils/typedefs';

// Classes
import WorkspaceService from './workspace';

// Utils
import { get, pick, partition } from 'lodash';
import { getWorkspaceTitle } from 'libs/utils/workspaces';
import { compareByKeyTypeWise } from 'libs/utils/collections';

// Constants
import {
    CREATE_WORKSPACE_ENDPOINT,
    TEMPLATE_ICON_PATH,
    CENTRAL_BACKSTAGE_URL,
    API_BASE_PATH
} from 'libs/utils/constants';

/**
 * The Preselected Default Template
 *
 * @constant {String} DEFAULT_TEMPLATE
 *
 * @private
 */
export const DEFAULT_TEMPLATE = 'SpotMe Template';

/**
 * API path for getting the templates list
 *
 * @const TEMPLATES_API_ENDPOINT
 *
 * @private
 */
export const TEMPLATES_API_ENDPOINT = `${API_BASE_PATH}/backstage/templates`;

/**
 * API path for getting the template details. Interpolation: `{{templateId}}`.
 *
 * @const {String} TEMPLATE_API_ENDPOINT
 *
 * @private
 */
const TEMPLATE_API_ENDPOINT = `${TEMPLATES_API_ENDPOINT}/{{templateId}}`;

/**
 * API path for uploading templates screenshots via router. Interpolations: `{{nodeId}}`, `{{templateId}}`, `{{fileName}}`.
 *
 * @constant {String} TEMPLATES_ASSETS_ENDPOINT
 *
 * @private
 */
const TEMPLATES_ASSETS_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${TEMPLATE_API_ENDPOINT}/image/{{filename}}`;

/**
 * Template categories endpoint
 *
 * @constant {String} TEMPLATE_CATEGORIES_ENDPOINT
 *
 * @private
 */
const TEMPLATE_CATEGORIES_ENDPOINT = `${API_BASE_PATH}/backstage/templates/categories`;

/**
 * Provides utils for getting or saving information of a template.
 *
 * @example
 * import TemplateService from 'libs/services/template';
 * ...
 * const tmpl = new TemplateService();
 */
export default class TemplateService extends WorkspaceService {

    setUser(user) {
        this.user = user;
    }

    /**
     * Given and organziation ID this method returns an alphabetically
     * sorted list of templates that can be used in a select.
     *
     * NOTE: SpotMe default template will be listed first
     *
     * @param {String} orgId the organization ID for which load the templates
     * @param {String} locale the locale to use for sorting the result
     *
     * @returns {Promise.<Object[]>} a sorted list of organization templates
     */
    async getTemplatesForSelection(orgId, locale) {
        const { templates } = await this.getOrgTemplates(orgId, true);
        return this.sortByOrderAndName(
            templates.filter(template => !template.lifecycle || template.lifecycle.stage !== 'archived'),
            locale
        );
    }

    /**
     * Given an organization ID this method returns a templates list.
     * NOTE: if no `orgId` is provided, all templates for all orgs the user belongs to is returned.
     *
     * @param {String} orgId the ID of the organization for which load the template from
     * @param {Boolean} [includeGlobal=false] flag to include global templates or not
     *
     * @return {Promise<Object>} an object containing resulting templates
     *
     */
    async getOrgTemplates(orgId, includeGlobal = false) {
        const config = { params: { orgId, includeGlobal } };
        const { data } = await this.get(TEMPLATES_API_ENDPOINT, config);

        data.templates.forEach(template => {
            const org = this.user.orgs.find(org => org.name === template.org);
            template.orgDisplayName = org?.display_name || template.org;
        });

        return data;
    }

    /**
     * Given a template ID this method returns the template details
     *
     * @param {String} templateId the ID of the template to load
     * @param {Boolean} [packages=true] whether to load installed packages too or not
     * @param {Boolean} [onlyPaid=false] whether to load only paid modules or the full list of packages
     *
     * @return {Promise<Object>} an object containing resulting template details
     */
    async getTemplateDetails(templateId, packages = true, onlyPaid = false) {
        const url = TEMPLATE_API_ENDPOINT.replace('{{templateId}}', templateId);
        const { data } = await this.get(url, {
            params: { packages, only_paid: onlyPaid }
        });

        return data;
    }

    /**
     * Creates a template with the given data.
     *
     * @param {Object} workspaceData the payload needed to create a template.
     *
     * @returns {Promise<Object>} could be an object containing `jobId` or an `error` key.
     */
    async createTemplate(workspaceData) {
        /** @type {object} */
        const description = pick(workspaceData.parent.description, ['title', 'subtitle', 'img']);
        const locales = Object.keys(description.title);
        const categoryEnabled = get(workspaceData, 'organization.workspace_defaults.enable_category', false);

        if (categoryEnabled) {
            description.category = workspaceData.category;
            description.categories = workspaceData.categories;
        }

        for (const locale of locales) {
            description.title[locale] = workspaceData.eventname;

            if (description.subtitle) {
                description.subtitle[locale] = '';
            }
        }

        const payload = {
            description,
            branded_app: workspaceData.parent.branded_app,
            name: workspaceData.eventname,
            realName: workspaceData.eventname,
            ownerId: workspaceData.organization._id,
            serverGroup: workspaceData.storage.value,
            test_event: false,
            visibility: workspaceData.visibility,
            is_template: true,
            template_id: workspaceData.parent.id || workspaceData.parent._id,
            template_description: workspaceData.template_description || '',
            template_long_description: workspaceData.template_long_description || '',

            // This are needed for making backstage not crashing
            startdate: workspaceData.parent.startdate,
            enddate: workspaceData.parent.enddate,
            timezone: workspaceData.parent.timezone
        };

        console.info('[TemplateService] Creation payload:', payload);

        try {
            const { data } = await this.post(CREATE_WORKSPACE_ENDPOINT, payload);

            return data;
        } catch ({ response: { data, status } }) {
            const error = { error: 'errors.server.generic', message: data, status };

            if (status !== 200) {
                error.error = `errors.server.${status}`;
            }

            console.warn('[TemplateService] An error occurred while creating template:', error, status);
            return error;
        }
    }

    /**
     * Uploads the images to the given template
     *
     * @param {Object.<String, PickedFile>} images the images to upload to the template
     * @param {String} templateId the template ID to upload the images to
     */
    async uploadImages(images, templateId) {
        const url = TEMPLATES_ASSETS_ENDPOINT.replace('{{templateId}}', templateId);

        for (const [filename, pickedFile] of Object.entries(images)) {
            if (pickedFile && pickedFile.size > 0) {
                const bodyFormData = new FormData();
                const token = await this.getCsrfToken(CENTRAL_BACKSTAGE_URL);

                bodyFormData.set('file', pickedFile.file);
                bodyFormData.set('_csrf', token);

                await this.post(url.replace('{{filename}}', filename), bodyFormData, { withCredentials: true });
            }
        }
    }

    /**
     * Deletes the specified image from the given template
     *
     * @param {String} filename the filename of the image to remove
     * @param {String} templateId the template ID to remove the image from
     */
    deleteImage(filename, templateId) {
        const url = TEMPLATES_ASSETS_ENDPOINT
            .replace('{{templateId}}', templateId)
            .replace('{{filename}}', filename);

        return this.delete(url, { withCredentials: true });
    }

    /**
     * Saves the template settings
     *
     * @param {Object} templateData the template object
     *
     * @returns {Promise.<import('axios').AxiosResponse>} the server response
     */
    save(templateData) {
        const payload = pick(
            templateData,
            'id',
            'bs_owner_id',
            'eventname',
            'description',
            'template_description',
            'template_long_description',
            'visibility',
            'template_category',
            'template_order');

        // The property to change the owner ID `bs_owner_id`
        payload.bs_owner_id = templateData.organization._id;

        // Sanitize types
        payload.template_order = payload.template_order ? parseInt(payload.template_order, 10) : undefined;

        return super.save(payload);
    }

    /**
     * Given a template this method returns its App Icon URL
     *
     * @param {Workspace} template
     *
     * @returns {String} the URL of the template's app icon
     */
    getAppIconUrl(template) {
        if (template.hasIcon) {
            const id = template._id || template.id;
            return TEMPLATE_ICON_PATH.replace('{{templateId}}', id);
        }

        return null;
    }

    /**
     * This method returns a list of all available public template's categories.
     *
     * @returns {Promise.<Object>} the promise of a list of available categories
     */
    async getCategories() {
        const { data } = await this.get(TEMPLATE_CATEGORIES_ENDPOINT);

        return data;
    }

    /**
     * Given a list of workspace this method will sort them by order and name.
     *
     * @param {Object[]} workspaces te list of workspace to sort
     * @param {String} [locale] the locale to use for sorting
     */
    sortByOrderAndName(workspaces, locale) {
        let [ordered, unordered] = partition(workspaces, (w => w.hasOwnProperty('template_order')));

        const titleSort = (a, b) => {
            const labelA = getWorkspaceTitle(a, locale);
            const labelB = getWorkspaceTitle(b, locale);

            if (labelB === DEFAULT_TEMPLATE) {
                return 1;
            }

            if (labelA === DEFAULT_TEMPLATE) {
                return -1;
            }

            return labelA.localeCompare(labelB);
        };

        ordered = ordered.sort((a, b) => {
            // If two workspaces have the same order, sort them by title
            const sorting = compareByKeyTypeWise('template_order', a, b);

            return sorting === 0 ? titleSort(a, b) : sorting;
        });

        unordered = unordered.sort(titleSort);

        return ordered.concat(unordered);
    }
}
