module.exports = [() => ({
    restrict: 'AEC',
    link(scope, elem) {
        // do it on load
        let isWizardActionsElShown = false;
        const wizardActionsEl = elem.find('.wizard-actions');
        // the following will adjust the top margin of .wizard-actions based on the height of the current step pane
        // this is necessary because the pane itself is absolute positioned (for animation efficacy)
        const currentStepWatchCb = function(newCurrentStep) {
            if (!_.isNumber(newCurrentStep)) {
                return;
            }

            // show the actions container after the first anim has completed
            if (isWizardActionsElShown) {
                return;
            }
            setTimeout(function() {
                isWizardActionsElShown = true;

                return wizardActionsEl.show();
            }, 200);
        };

        scope.$watch('currentStep', currentStepWatchCb);
        scope.$watch('viewState.currentStep', currentStepWatchCb);

        wizardActionsEl.hide();
        return currentStepWatchCb(1);
    }
})];
