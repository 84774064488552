angular.module('backstage.directives', [
    'siyfion.sfTypeahead',
    'jsTag'
])

    .directive('appVersion', require('./appVersion'))

    .directive('body', require('./body'))

    .directive('ngHref', require('./ngHref'))

    .directive('iconSpinner', require('./iconSpinner'))

    .directive('smartTablePaginationPersist', require('./smartTablePaginationPersist'))

    .directive('bsOrgEntitlementsDisplayWidgets', require('./bsOrgEntitlementsDisplayWidgets'))

    .directive('bsFullscreenRequestElement', require('./bsFullscreenRequestElement'))

    .directive('bsDiagnosticsLoadingThrobber', require('./bsDiagnosticsLoadingThrobber'))

    .directive('bsAuthBounceNotice', require('./bsAuthBounceNotice'))

    .directive('bsModuleContextNavbar', require('./bsModuleContextNavbar'))

    .directive('bsSmartHeightConstrainedModuleListContainer', require('./bsSmartHeightConstrainedModuleListContainer'))

    .directive('bsReorderableModuleList', require('./bsReorderableModuleList'))

    .directive('bsDynamicExtensionsDisplay', require('./bsDynamicExtensionsDisplay'))

    .directive('bsCustomActionButtons', require('./bsCustomActionButtons'))

    .directive('bsCollapseOnEventChangeRequested', require('./bsCollapseOnEventChangeRequested'))

    .directive('bsCollapseOnChange', require('./bsCollapseOnChange'))

    .directive('bsUncollapseOnChange', require('./bsUncollapseOnChange'))

    .directive('bsCollapsedWhen', require('./bsCollapsedWhen'))

    .directive('bsFadeInAsap', require('./bsFadeInAsap'))

    .directive('bsFadeInOnChange', require('./bsFadeInOnChange'))

    .directive('bsActionStatusProgressBarBinder', require('./bsActionStatusProgressBarBinder'))

    .directive('bsImgPaxPhoto', require('./bsImgPaxPhoto'))

    .directive('bsImgNavItemIcon', require('./bsImgNavItemIcon'))

    .directive('bsEventPictoTeaserIconList', require('./bsEventPictoTeaserIconList'))

    .directive('bsImgEventIcon', require('./bsImgEventIcon'))

    .directive('bsActivityWidget', require('./bsActivityWidget'))

    .directive('bsAssetManagerModalTrigger', require('./bsAssetManagerModalTrigger'))

    .directive('bsNewAssetManagerModalTrigger', require('./bsNewAssetManagerModalTrigger'))

    .directive('bsSummaryCollapseToggler', require('./bsSummaryCollapseToggler'))

    .directive('bsOpenableInNewWindow', require('./bsOpenableInNewWindow'))

    .directive('bsExcelImportIndeterminateCheckbox', require('./bsExcelImportIndeterminateCheckbox'))

    .directive('bsTaskExecutionProgressModal', require('./bsTaskExecutionProgressModal'))

    .directive('bsHelpContentImageSourceRewriter', require('./bsHelpContentImageSourceRewriter'))

    .directive('bsActivatedPaxChart', require('./bsActivatedPaxChart'))

    .directive('wizard', require('./wizard'))

    .directive('bsOnclickPropagationCatcher', require('./bsOnclickPropagationCatcher'))

    .directive('contenteditable', require('./contenteditable'))

    .directive('bsFileUpload', require('./bsFileUpload'))

// # # # IE 8 workarounds

    .directive('bsOptionBindingWorkaround', require('./bsOptionBindingWorkaround'))

    .directive('bsOldIeCrossServerLinkHrefWorkaround', require('./bsOldIeCrossServerLinkHrefWorkaround'))

// # # # Bootstrap workarounds

    .directive('strapModal', require('./strapModal'))

    .directive('modalBackdrop', require('./modalBackdrop'))

    .directive('strapTooltip', require('./strapTooltip'))

    .directive('strapDropdown', require('./strapDropdown'))

    .directive('columnFilter', require('./columnFilter'))

    .directive('overwriteEmailValidator', require('./overwriteEmailValidator'))

    .directive('submitParentFormOnChange', require('./submitParentFormOnChange'));
