module.exports = ['$route', $route => ({
    template: '\
<aside  data-watch=\'routeChangeInProgress\' \
data-delay-ms=\'2000\' \
class=\'label label-important label-auth-bounce-notice bs-fade-in-on-change hide fade\'> \
<i class=\'icon-warning-sign\'></i> \
Oops, that action required permissions you don\'t have. Please try again later. \
</aside>\
',
    link(scope, elem) {
        const getNoticeEl = () => angular.element('aside', elem);
        const hideNoticeEl = () => getNoticeEl().addClass('hide');

        scope.$on('$routeChangeStart', hideNoticeEl);
        scope.$watch(() => {
            return (($route.current || {}).params || {}).showAuthBounceNotice;
        }, showAuthBounceNotice => {
            if (showAuthBounceNotice) {
                return getNoticeEl().removeClass('hide');
            } else {
                return hideNoticeEl();
            }
        });
    }
})];
