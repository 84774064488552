"use strict";

// bs-toggle-agenda-registration-buttons-cell

var controller = /* @ngInject */function controller($rootScope, $scope, bulkUpdateService) {
  $scope.toggleRegistrationState = function () {
    $scope.canRegister = $scope.dataRow.can_register = Boolean(!$scope.dataRow.can_register);
    bulkUpdateService.updateDoc($rootScope.event._id, $rootScope.event.node, 'session', $scope.dataRow);
  };
};
controller.$inject = ["$rootScope", "$scope", "bulkUpdateService"];
module.exports = function () {
  return {
    template: '<div ng-transclude></div>',
    transclude: true,
    controller: controller,
    link: function link(scope) {
      return scope.canRegister = scope.dataRow.can_register;
    }
  };
};