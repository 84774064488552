// Classes
import BaseService from './base-service';

// Utils
import { getBackstageURL } from 'libs/utils/url';

// Constants
import { API_BASE_PATH } from 'libs/utils/constants';

/**
 * Provides utils for handling handlers scripts.
 *
 * @example
 * import HandlerService from 'libs/services/handler';
 * ...
 * const handler = new HandlerService();
 */
export default class HandlerService extends BaseService {

    /**
     * Generates a local handler url
     *
     * @param {String} eventId the ID of the event
     * @param {String} handler the handler script path
     *
     * @return {String} the handler url
     */
    generateHandlerUrl(eventId, handler) {
        // simply libnode for all. it will handle other content types (not just application/json)
        return `${API_BASE_PATH}/eid/${eventId}/nodehandlers/${handler}`;
    } // libnode

    /**
     * Generates the backend handler's url
     *
     * @param {String} eventId the ID of the event
     * @param {String} handler the handler script path
     *
     * @return {String} the backend handler url
     */
    generateBackendUrl(eventId, handler) {
        return `${API_BASE_PATH}/eid/${eventId}/${handler}`;
    }

    /**
     * Generates a backstage relative handler's url
     *
     * @param {String} eventId the ID of the event
     * @param {String} eventNode the node of the event
     * @param {String} handler the handler script path
     *
     * @return {String} the backstage handler url
     */
    generateRemoteHandlerUrl(eventId, eventNode, handler) {
        return getBackstageURL(eventNode, this.generateHandlerUrl(eventId, handler));
    }

    /**
     * Performs the handler's request
     *
     * @param {String} eventId the ID of the event
     * @param {String} handler the handler script path
     * @param {Object} [params={}] handler's request parameters
     * @param {String} [method='get'] the HTTP verb
     * @param {Boolean} [directToBackend] whether to perform the request against backend or not
     *
     * @return {Promise<Object[]>} the handler response
     */
    async callHandler(eventId, handler, params = {}, method = 'get', directToBackend = false) {
        const url = directToBackend ?
            this.generateBackendUrl(eventId, handler) :
            this.generateHandlerUrl(eventId, handler);

        if (method === 'get') {
            params = { params };
        }

        const { data } = await this[method](url, params);

        return data;
    }

    /**
     * Calls the handler with an object's options instead of a spread
     * arguments.
     *
     * @param {Object} opts argument options
     * @param {String} opts.eventId the ID of the event
     * @param {String} opts.handler the handler's script path
     * @param {String} [opts.method='post'] the HTTP verb
     * @param {*} [opts] extra arguments
     *
     * @return {Promise<import('axios').AxiosResponse>} the server response
     */
    callHandlerWithObjectArg(opts) {
        const [eventId, handler, method, params] = Array.from([opts.eventId, opts.handler, opts.method || 'post', opts]);

        // @ts-ignore
        return this.callHandler(eventId, handler, params, method);
    }

    /**
     * Loads a couch view
     *
     * @param {String} eventId the ID of the event
     * @param {String} designDoc
     * @param {String} viewName
     * @param {Object} [params={}]
     *
     * @return {Promise<Object[]>} the handler response
     */
    callCouchView(eventId, designDoc, viewName, params = {}) {
        return this.callHandler(eventId, `backstage/call_view?ddoc=${designDoc}&view=${viewName}`, params, 'post', true);
    }

    /**
     * Loads a couch list
     *
     * @param {String} eventId the ID of the event
     * @param {String} designDoc
     * @param {String} listName
     * @param {String} viewName
     * @param {Object} [params={}]
     *
     * @return {Promise<Object[]>} the handler response
     */
    callCouchList(eventId, designDoc, listName, viewName, params = {}) {
        return this.callHandler(eventId, `backstage/call_list?ddoc=${designDoc}&view=${viewName}&list=${listName}`, params, 'post', true);
    }
}
