"use strict";

require('systemjs');
var _ = require('underscore');
module.exports = /* @ngInject */["$compile", "btLoadSystem", function ($compile, btLoadSystem) {
  return {
    restrict: 'A',
    replace: true,
    template: '<div></div>',
    link: function link(scope, element) {
      function generateTemplate(directive, directiveAttributes) {
        directiveAttributes = directiveAttributes || {};
        var directiveAttributesTpl = _.pairs(directiveAttributes).map(function (pair) {
          return pair.join('="') + '"';
        }).join(' ');
        return '<' + scope.directive + ' ' + directiveAttributesTpl + '/>';
      }
      scope.$watchGroup(['directive', 'directiveAttributes'], function () {
        if (!scope.directive) {
          return;
        }
        btLoadSystem().then(function () {
          return System.import('bstg-source/' + scope.directive + '-directive.js');
        }).then(function () {
          var tpl = generateTemplate(scope.directive, scope.directiveAttributes);
          var el = $compile(tpl)(scope);
          element.html(el);
        });
      });
    }
  };
}];