"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _ = require('underscore');
angular.module('backstage.controllers.widgets').controller('TargetsExceptionsFloatingRuleEditorCtrl', /* @ngInject */["$scope", "eventService", "metadataService", function ($scope, eventService, metadataService) {
  var eventId = eventService.getCurrentEvent()._id;
  var participantMetadata = metadataService.getCachedMetadataForType(eventId, 'person', true);
  var participantFields = metadataService.displaceInAppFieldsAsFieldKeys(metadataService.getCachedMetadataForTypeAsArray(eventId, 'person', true));
  $scope.participantFields = participantFields;

  // add fp_ext_id as a field here
  participantFields.unshift({
    label: 'Unique Identifier',
    field: 'fp_ext_id'
  });
  if (!$scope.editingFields) {
    $scope.editingFields = [];
  }
  $scope.$watch('editingRuleIdx', function (newEditingRuleIdx, lastRuleIdx) {
    if (!_.isNumber(newEditingRuleIdx)) {
      return;
    }
    $scope.editingModel = $scope.originalRuleset[newEditingRuleIdx];
    $scope.editingFields = ($scope.rulesetMetadata[newEditingRuleIdx] || []).map(function (field, index) {
      return _objectSpread(_objectSpread({}, field), {}, {
        // clone the object because we want to modify the oder
        order: index // order fields by how rules were added
      });
    });

    // empty rule removal logic
    if (newEditingRuleIdx !== -1 || newEditingRuleIdx === lastRuleIdx || !_.isEmpty($scope.originalRuleset[lastRuleIdx])) {
      return;
    }

    // delete empty element
    $scope.originalRuleset.splice(lastRuleIdx, 1);
  });
  $scope.addConditionToRule = function (ruleIdx, ruleConditionKey) {
    if (!ruleConditionKey) {
      return;
    }
    var fieldDescriptor = participantMetadata[ruleConditionKey] || {};
    if (fieldDescriptor.kind === 'boolean') {
      $scope.originalRuleset[ruleIdx][ruleConditionKey] = false;
    } else {
      $scope.originalRuleset[ruleIdx][ruleConditionKey] = null;
    }
    return $scope.originalRuleset[ruleIdx][ruleConditionKey];
  };
  $scope.removeConditionFromRule = function (ruleIdx, ruleConditionKey) {
    return delete $scope.originalRuleset[ruleIdx][ruleConditionKey];
  };
  $scope.getAvailableParticipantFields = function (ruleIdx) {
    var conditions = $scope.originalRuleset[ruleIdx] || {};
    return _.reject(participantFields, function (fd) {
      return conditions[fd.field] || conditions[fd.field] === null || fd.is_dynamic_ext && fd.field !== '_registered_sessions';
    });
  };
}]);