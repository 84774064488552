const _ = require('underscore');

const FLATTENED_OBJECT_KEY_DELIM = '.';
const FLATTENED_OBJECT_KEY_SPLIT_EXCEPTIONS = ['_ext'];

const unflattenProperty = function(obj, val, key, newsplit, noSplitExceptions) {
    // ⚑
    // TODO: REMOVE HACK THAT MAKES DYNAMIC EXTENSIONS UNFLATTEN PROPERLY !
    if (!noSplitExceptions) {
        noSplitExceptions = FLATTENED_OBJECT_KEY_SPLIT_EXCEPTIONS;
    }
    let splitPartIdx = -1;
    const exceptionEncountered =
        noSplitExceptions.some((exception) => (splitPartIdx = newsplit ? newsplit.indexOf(exception) : undefined) > -1);

    if (exceptionEncountered && (splitPartIdx > -1)) {
        const end = splitPartIdx + 2;
        const combined = newsplit.slice(splitPartIdx, end).join('.');

        newsplit = [].concat(newsplit.slice(0, splitPartIdx), combined, newsplit.slice(end));
    }
    // # #

    const split = newsplit || key.split(FLATTENED_OBJECT_KEY_DELIM);
    const [tok, nexttok] = split;

    if (nexttok) {
        // [] and {} only plz; remember typeof null is 'object' in js
        if ((typeof obj[tok] !== 'object') || (obj[tok] === null)) { delete obj[tok]; }
        const inexttok = parseInt(nexttok);

        if (!obj[tok]) { obj[tok] = inexttok || (inexttok === 0) ? [] : {}; }
        unflattenProperty(obj[tok], val, null, split.slice(1));
    } else { // we're at a leaf
        // prevent non-numeric key association in array
        const itok = parseInt(tok);

        if (_.isArray(obj[tok]) && !itok && (itok !== 0)) { return; }
        if (!_.isString(val) || !_.isEmpty(val)) { return obj[tok] = val; }
    }
};

const postProcessUnflattenedObject = function(obj) {
    const isArray = _.isArray(obj);

    if (!isArray && !_.isObject(obj)) { return obj; }
    let out = isArray ? [] : {};

    _.map(obj, function(val, idx) {
        if (!_.isObject(val) || !_.isEmpty(val)) {
            return out[idx] = postProcessUnflattenedObject(val);
        }
    });
    if (isArray) {
        out = _.compact(out);
    }
    return out;
};

// sortKeys: sorting the keys is tricky and important here !
// each key is weighted by whether it's an array or a string, its index, etc.
// you might want to run the tests after playing with this logic!
const sortKeys = function(keys = []) {
    return _.sortBy(keys, function(key) {
        let split = key.split('.');

        if (split.length) { split.shift(); } // shallow level gets a -1 weight
        return split.reduce(function(weight, cur, idx) {
            if (_.isNaN(parseInt(cur))) { cur = 10; } // string indexes follow numeric ones *
            return Math.max(0, weight + (cur / Math.pow(10, idx)));
        }
        // * this is because if a numeric idx is encountered we create an array, otherwise an object
        , -1);
    });
};

exports.unflattenPropertiesInObject = function(obj = {}) {
    obj = _.clone(obj);
    for (let key of Array.from(sortKeys(_.keys(obj)))) {
        if (!_.has(obj, key) || !obj[key] || !Array.from(key).includes(FLATTENED_OBJECT_KEY_DELIM)) {
            // no need to expand this one, it's not flattened. onward!
            continue;
        }
        unflattenProperty(obj, obj[key], key);
        delete obj[key];
    }
    return postProcessUnflattenedObject(obj);
};

exports.unflattenPropertiesInAllObjects = function(list = []) {
    return list.map(exports.unflattenPropertiesInObject);
};
