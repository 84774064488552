/** @module Permissions */

import { compact } from 'lodash';

import AccessManager from 'access-manager';
const accessManager = new AccessManager();

/**
 * Determine if a user has a permission given her list of roles and a context
 * @param  {Object[]}  roles list of roles that the user has (in the old format)
 * @param  {string}  roles.role name of the role (i.e. admin)
 * @param  {string}  roles.type type of the role (global_permission or event_permission or account_permission)
 * @param  {string}  [roles.event_id] if the role is in the context of an event
 * @param  {string}  [roles.org_id] if the role is in the context of an org
 * @param  {string}  attributedRoles.attrCtx1 attribute 1 determining the context in which the user has the permission
 * @param  {string}  permissionName name of the permission for which we want to know if the user has access
 * @param  {Object}  context context determining if the user has permission
 * @param  {string}  [context.event_id] context attribute event_id if in the context of an event
 * @param  {string}  [context.org_id] context attribute org_id if in the context of an org
 * @return {Boolean} true if the user has a role enabling the permission and the role is either a global role or
 * every attribute required for the role are both present in the attributed role and the context and are all equal
 * false otherwise
 */
export function hasPermission(roles, permission, context = {}) {
    const hasPermission = accessManager.hasPermission(
        mapRolesToAMRoleFormat(roles),
        permission,
        context
    );

    return hasPermission;
}


/**
 * Determine if a user has a permission given her list of roles and a context
 * @param  {Object[]}  roles list of roles that the user has (in the old format)
 * @param  {string}  roles.role name of the role (i.e. admin)
 * @param  {string}  roles.type type of the role (global_permission or event_permission or account_permission)
 * @param  {string}  [roles.event_id] if the role is in the context of an event
 * @param  {string}  [roles.org_id] if the role is in the context of an org
 * @param  {object}  requiredRole required role in old format
 * @param  {string}  requiredRole.role name of the role for which we want to know if the user has access
 * @param  {string}  [requiredRole.event_id] context attribute event_id if in the context of an event
 * @param  {string}  [requiredRole.org_id] context attribute org_id if in the context of an org
 * @return {Boolean} true if the user has a role enabling the permission and the role is either a global role or
 * every attribute required for the role are both present in the attributed role and the context and are all equal
 * false otherwise
 */
export function canAssignRole(roles, requiredRole) {
    const role = mapRolesToAMRoleFormat([requiredRole])[0];
    const canAssignRole = accessManager.isRoleInherited(
        mapRolesToAMRoleFormat(roles),
        role
    );

    return canAssignRole;
}

/**
 * The new accessManager uses a new Role Format with a different name, this function converts it to the new format.
 * @param {Array<Object>} roles
 * @returns {Array<Object>} new role format
 */
function mapRolesToAMRoleFormat(roles) {
    return compact(roles.map(role => {
        // i.e. { type: 'global_permission', role: 'beta_tester' } => 'global_beta_tester'
        if (!role) return;
        const name = role.type.replace('permission', '') + role.role;

        return {
            role: name,
            event_id: role.event_id,
            org_id: role.org_id,
        };
    }));
}
