import ObservableService from './observable-service';
import { isFunction } from 'lodash';

/**
 * @typedef {Object} Device
 *
 * @property {String} deviceId
 * @property {'videoInput'|'audioInput'} kind
 * @property {String} label
 */

/**
 * @typedef {Object} Devices
 *
 * @property {Device[]} audioDevices
 * @property {Device[]} videoDevices
 * @property {Error} error
 */

const DEVICE_CHANGE_EVENT = 'device-change';

/**
 * Service to manage the media devices.
 */
export default class MediaDevicesService extends ObservableService {
    constructor(mediaDevicesProvider) {
        super();

        this.devicesError = null;

        this.audioDevices = [];
        this.videoDevices = [];

        this.streamsWithAudio = [];
        this.streamsWithVideo = [];

        this.mediaDevicesProvider = mediaDevicesProvider;

        this.mediaDevicesProvider.ondevicechange = async () => {
            console.log('[MediaDevicesService] Detected a change of devices');
            await this.deviceChangeHandler();
        };
        this.storeDeviceList();
    }

    async deviceChangeHandler() {
        try {
            await this.storeDeviceList();
            this.emit(DEVICE_CHANGE_EVENT, this.getDeviceList());
        } catch (error) {
            this.emit(DEVICE_CHANGE_EVENT, { error });
        }
    }

    /**
     * @returns {Devices}
     */
    getDeviceList() {
        return { audioDevices: this.audioDevices, videoDevices: this.videoDevices, error: this.devicesError };
    }

    async storeDeviceList() {
        this.devicesError = null;
        try {
            const devices = await this.mediaDevicesProvider.enumerateDevices();
            const audioDevices = devices
                .filter(input => input.kind === 'audioinput' && input.deviceId)
                .map(({ deviceId, label }) => ({ deviceId, label, kind: 'audioInput' }));
            const videoDevices = devices
                .filter(input => input.kind === 'videoinput' && input.deviceId)
                .map(({ deviceId, label }) => ({ deviceId, label, kind: 'videoInput' }));

            this.audioDevices = audioDevices || [];
            this.videoDevices = videoDevices || [];

            if (this.audioDevices.length === 0) {
                console.warn('No audio devices found', devices);
            }

            if (this.videoDevices.length === 0) {
                console.warn('No video devices found', devices);
            }
        } catch (error) {
            console.error('[MediaDevicesService] Failed to retrieve the devices', error.message);
            this.audioDevices = [];
            this.videoDevices = [];
            this.devicesError = error;
        }
    }

    /**
     * Subscribes to the `device-change` event.
     *
     * @param {Function} handler Function to be executed whenever the devices change. It must follow the signature:
     * ({ audioDevices: Object, videoDevices: Object, error: Error }) => Any
     */
    onDevicesChange(handler) {
        if (!handler || !isFunction(handler)) {
            return;
        }
        console.log(`[MediaDevicesService] New subscription to '${DEVICE_CHANGE_EVENT}'`);
        this.addEventListener(DEVICE_CHANGE_EVENT, handler);
        handler(this.getDeviceList());
    }

    /**
     * Unsubscribes from the `device-change` event.
     *
     * @param {Function} handler The same function that was used for the subscription.
     */
    offDevicesChange(handler) {
        console.log(`[MediaDevicesService] Unsubscribing from '${DEVICE_CHANGE_EVENT}'`);
        this.removeEventListener(DEVICE_CHANGE_EVENT, handler);
    }

    /**
     * navigator's getUserMedia + storage of all accessed streams and release when new ones are gotten
     *
     * @param {Object} constraints options for navigator.mediaDevices.getUserMedia(constraints)
     * @param {Object} retriesByErrorName Dictionary mapping an error name with a number. The number indicates how many times the
     * operation will be retried if the error specified is thrown. E.g., { 'NotReadableError': 3, 'NotFoundError': 1 } indicates
     * that the operation will be retried once if the `NotFoundError` is thrown, and 3 times if the error `NotReadableError` is thrown.
     * @param {number} retryDelayMs Milliseconds to wait between retry calls
     */
    async getUserMedia(constraints, retriesByErrorName = {}, retryDelayMs = 1000) {
        console.log('[MediaDevicesService] getUserMedia', retriesByErrorName);

        // TODO: someday we could avoid re stopping already stopped streams
        if (constraints.audio) {
            this.stopStreamsWithAudio();
        }
        if (constraints.video) {
            this.stopStreamsWithVideo();
        }

        let stream;
        try {
            stream = await this.mediaDevicesProvider.getUserMedia(constraints);
        } catch (error) {
            if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
                console.error('[MediaDevicesService] getUserMedia error: required track is missing');
            } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
                console.error('[MediaDevicesService] getUserMedia error: webcam or mic are already in use');
            } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
                console.error('[MediaDevicesService] getUserMedia error: constraints can not be satisfied by avb. devices');
            } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                console.error('[MediaDevicesService] getUserMedia error: permission denied in browser');
            } else if (error.name === 'TypeError' || error.name === 'TypeError') {
                console.error('[MediaDevicesService] getUserMedia error: empty constraints object');
            } else {
                console.error('[MediaDevicesService] getUserMedia error:', error);
            }

            const retries = retriesByErrorName[error.name];
            if (!retries) {
                throw error;
            }

            const updatedRetriesByErrorName = { ...retriesByErrorName };
            updatedRetriesByErrorName[error.name] = retries - 1;
            await new Promise(resolve => setTimeout(() => resolve(), retryDelayMs));
            return await this.getUserMedia(constraints, updatedRetriesByErrorName, retryDelayMs);
        }

        if (constraints.audio) {
            this.streamsWithAudio.push(stream);
        }
        if (constraints.video) {
            this.streamsWithVideo.push(stream);
        }

        return stream;
    }

    /**
     * Request webcam and mic permissions just to avoid doing it once the user has entered the Studio room.
     *
     * @returns {Promise<Void>}
     */
    async requestPermissions() {
        await this.getUserMedia({
            video: {
                facingMode: 'user',
                aspectRatio: 16 / 9,
                width: { ideal: 1280 },
                height: { ideal: 720 }
            },
            audio: true
        });

        this.stopAllStreams();

        // when permission are not granted yet, the storeDeviceList of the constructor fetches empty arrays
        await this.deviceChangeHandler();
    }

    /**
     * stop all tracks of all stored audio streams
     */
    stopStreamsWithAudio() {
        this.stopAllStreamTracks(this.streamsWithAudio);
    }

    /**
     * stop all tracks of all stored video streams
     */
    stopStreamsWithVideo() {
        this.stopAllStreamTracks(this.streamsWithVideo);
    }

    /**
     * stop all tracks of all stored streams
     */
    stopAllStreams() {
        this.stopStreamsWithVideo();
        this.stopStreamsWithAudio();
    }

    /**
     * Stop an HTML video element and its tracks
     */
    stopVideoElement(video, killStream = true) {
        console.log('[MediaDevicesService] stop video element');
        if (video) {
            try {
                video.pause();
            } catch (error) {
                /** video already paused or in a stale state */
            }
            try {
                if (killStream) {
                    const stream = video.srcObject;
                    this.stopAllStreamTracks([stream]);
                }
                video.srcObject = null;
                video.src = '';
                video.removeAttribute('src');
                video.load();
            } catch (error) {
                /** video already empty */
                console.warn('[MediaDevicesService] stopVideoElement error', error);
            }
        }
    }

    /**
     * stop all tracks of all streams in a stream array
     *
     * @param {Array} streams array of stream
     */
    stopAllStreamTracks(streams) {
        if (!streams || !streams.length) {
            return;
        }
        for (const stream of streams) {
            if (!stream) {
                continue;
            }
            if (isFunction(stream.getTracks)) {
                for (const track of stream.getTracks()) {
                    // backward compatibility (https://developers.google.com/web/updates/2015/07/mediastream-deprecations?hl=en#stop-ended-and-active)
                    if (isFunction(track.stop)) {
                        track.stop();
                    }
                }
            }
            // backward compatibility (https://developers.google.com/web/updates/2015/07/mediastream-deprecations?hl=en#stop-ended-and-active)
            if (isFunction(stream.stop)) {
                stream.stop();
            }
        }
    }
}
