module.exports = ['$window', $window => ({
    link(scope, elem) {
        return elem.on('click', function(ev) {
            // user agent conditional: IE 8 & 9 only!
            let ua = ($window.navigator || {}).userAgent;

            if ((!ua) ||
                (!~ua.indexOf('MSIE 8.') && !~ua.indexOf('MSIE 9.'))) {
                return;
            }
            ev.preventDefault();
            let linkHref = $(this).attr('href');

            $window.location.href = linkHref;
        });
    }
})];
