// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let helpTooltipTemplate = `\
<span class="form-tooltip-and-help">
    <a ng-if="text && !folder"
       class="icon-info-sign"
       strap-tooltip
       title="{{::vm.getHelpTooltip()}}"
       data-placement="right"
       toggle="tooltip"></a>
    <a ng-if="::folder"
       ng-click="vm.onHelpButtonClick()"
       class="icon-info-sign"
       style="cursor: pointer"
       data-placement="right"
       strap-tooltip
       title="{{::vm.getHelpTooltip()}}"></a>
</span>\
`;

angular.module('backstage.directives')
    .directive('bsHelpTooltip', [
        () =>
            ({
                restrict: 'E',
                replace: true,
                controller: 'HelpTooltipCtrl',
                template: helpTooltipTemplate,
                scope: {
                    text: '@',
                    folder: '=',
                    file: '='
                }
            })

    ])
    .controller('HelpTooltipCtrl', [
        '$scope',
        'helpService',
        function($scope, helpService) {
            let vm;

            $scope.vm = (vm = {});

            vm.onHelpButtonClick = () => helpService.openHelp($scope.folder, $scope.file);

            return vm.getHelpTooltip = () => $scope.text || 'Click for help';
        }

    ]);
