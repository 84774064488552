module.exports = class LazyAppModule {
    constructor(
        $controllerProvider,
        $compileProvider,
        $filterProvider,
        $provide,
        $injector
    ) {
        this.$controllerProvider = $controllerProvider;
        this.$compileProvider = $compileProvider;
        this.$filterProvider = $filterProvider;
        this.$provide = $provide;
        this.$injector = $injector;
        this.helpViewers = [];
        this.metadataEssences = {};
    }

    filter(name, fn) {
        this.$filterProvider.register(name, fn);
        return this;
    }

    factory(name, fn) {
        this.$provide.factory(name, fn);
        return this;
    }

    service(name, fn) {
        this.$provide.service(name, fn);
        return this;
    }

    controller(name, fn) {
        this.$controllerProvider.register(name, fn);
        return this;
    }

    directive(name, fn) {
        this.$compileProvider.directive(name, fn);
        return this;
    }

    constant(name, val) {
        this.$provide.constant(name, val);
        return this;
    }

    component(name, options) {
        this.$compileProvider.component(name, options);
        return this;
    }

    helpViewer(name, val) {
        this.helpViewers.push([name, val]);
        return this;
    }

    metadataEssence(name, fn) {
        if (this.metadataEssences[name]) {
            throw new Error(name + ' essence already registered');
        }
        this.metadataEssences[name] = fn;
    }
};
