"use strict";

// bs-navigate-to-view-generic-item

module.exports = /* @ngInject */["$sanitize", "$route", "eventService", "urlService", function ($sanitize, $route, eventService, urlService) {
  return {
    template: '<a href=\'javascript:void(0)\'></a>',
    link: function link(scope, elem) {
      var event = eventService.getCurrentEvent();

      // which field do we display as text for our link?
      var currentRouteDescriptor = $route.current ? $route.current.$$route : undefined;
      var crudOpts = currentRouteDescriptor ? currentRouteDescriptor.crudOpts : undefined;
      var visibleFields = crudOpts ? crudOpts.visibleFields : undefined;
      var firstVisibleField = _.first(visibleFields);

      // write out the text for said link
      var val = scope.dataRow[firstVisibleField];
      var translatedRowText = (val ? val.en : undefined) || val;
      scope.text = translatedRowText || '(untitled)';
      // ⚑
      // TODO: Use the contextSensitiveNavKey if it is present in the route descriptor
      var pluralizedViewItemSegment = (crudOpts ? crudOpts.pluralizedItemName : undefined) || "".concat(scope.dataRow.fp_type, "s");
      var modelId = scope.dataRow._id;
      var linkHref = '/event/:eventId/:pluralizedViewItemSegment/:modelId';

      // include the base path because we're setting the href on a link anchor
      linkHref = urlService.substituteParamsInRoutePathWithBasePath(linkHref, event ? event.id : undefined, pluralizedViewItemSegment, modelId);
      elem.attr('href', linkHref);
      elem.html($sanitize(scope.text));
    }
  };
}];