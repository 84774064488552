"use strict";

module.exports = /* @ngInject */function () {
  return {
    restrict: 'A',
    require: '^btDndDraggable',
    link: function link(scope, element, attrs, draggableCtrl) {
      draggableCtrl.attachEvents(element);
    }
  };
};