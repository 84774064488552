"use strict";

var _constants = require("libs/utils/constants");
// bsFormGeneratorCustom

module.exports = /* @ngInject */["$controller", function ($controller) {
  var template = "        <div>\n            <div ng-if=\"controller\"\n                ng-controller=\"controller\"\n                ng-include=\"templateUrl\">\n            </div>\n        </div>        ";
  return {
    scope: {
      model: '=',
      fieldDescriptor: '=',
      saveFn: '='
    },
    template: template,
    link: function link(scope) {
      var templateUrl = scope.fieldDescriptor.kind_options.templateUrl;
      scope.templateUrl = _constants.TEMPLATE_URL_MAPPING[templateUrl] || templateUrl;
      return scope.controller = ['$scope', function (controllerScope) {
        return $controller(scope.fieldDescriptor.kind_options.controller, {
          fieldDescriptor: scope.fieldDescriptor,
          $scope: controllerScope,
          model: scope.model,
          saveFn: scope.saveFn
        });
      }];
    }
  };
}];