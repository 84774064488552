"use strict";

var _underscore = require("underscore");
module.exports = /* @ngInject */["$window", function ($window) {
  return {
    restrict: 'A',
    link: function link(scope, elem) {
      var elemHeight = elem.height();
      var windowHeight = $($window).height();

      // on small screens we keep the preview inplace on scroll as it otherwise
      // covers most of the browser window and makes it difficult for use
      if (windowHeight <= elemHeight * 3) {
        return;
      }
      var elOffset = -1;
      var affixed = false;
      var onScroll = function onScroll() {
        var pos = $window.scrollY;
        if (elOffset === -1) {
          elOffset = elem.offset().top;
        }

        // when user scrolls past the boundary first time
        if (pos >= elOffset && !affixed) {
          affixed = true;
          elem.removeClass('affix-top').addClass('affix');
        }

        // scroll back up
        if (affixed && pos < elOffset) {
          elOffset = -1;
          affixed = false;
          elem.removeClass('affix').addClass('affix-top');
        }
      };
      $($window).on('scroll', (0, _underscore.throttle)(onScroll, 50));
      scope.$on('$destroy', function () {
        return $(window).off('scroll', onScroll);
      });
    }
  };
}];