"use strict";

// bs-agenda-item-capacity-stats-display-cell

module.exports = /* @ngInject */["$rootScope", "eventService", function ($rootScope, eventService) {
  return {
    restrict: 'AC',
    transclude: true,
    template: '<span ng-transclude></span>',
    link: function link(scope, elem) {
      var event = $rootScope.event;
      if (!scope.dataRow) {
        return;
      }
      var showNotApplicableState = function showNotApplicableState() {
        elem.html('n/a');
      };

      // On 05/12/2014, at 16:45, Steve Genoud wrote:
      // > if there is neither targets nor registration open
      // > there is no reason to have the capacity stuff shown
      var _scope$dataRow = scope.dataRow,
        targets = _scope$dataRow.targets,
        exceptions = _scope$dataRow.exceptions;
      var isRegistrationOpen = !!scope.dataRow.can_register;
      if (_.isEmpty(targets) && _.isEmpty(exceptions) && !isRegistrationOpen) {
        return showNotApplicableState();
      }
      elem.on('click', function (ev) {
        return ev.stopPropagation();
      });

      // ⚑
      // TODO: Remove hack
      // scope.capacityStatsRevealed = no
      return scope.revealCapacityStats = function () {
        scope.capacityStatsRevealed = true;
        eventService.getEventSessionCapacityInfo(event, scope.dataRow.fp_ext_id).then(function (capacityData) {
          if (!capacityData || !_.isObject(capacityData)) {
            return showNotApplicableState();
          }
          var seatsTakenPlaceholderEl = angular.element('.ph-seats-taken', elem);
          var seatsCapacityPlaceholderEl = angular.element('.ph-seats-capacity', elem);
          var capacity;
          if (capacityData.capacity === false) {
            capacity = '∞';
          } else {
            capacity = capacityData.capacity;
          }
          var registered = capacityData.registered || 0;
          seatsTakenPlaceholderEl.text(registered);
          seatsCapacityPlaceholderEl.text(capacity);
        });
      };
    }
  };
}];