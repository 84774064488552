module.exports = [() => ({
    restrict: 'AC',
    scope: {
        limitTo: '=',
        eventsList: '=',
        selectedEvent: '=',
        selectEventFn: '&',
        showFullListFn: '&',
        fullListViewToShow: '@'
    },
    templateUrl: '/static/partials/components/event-picto-icon-list-item.html',
    controller: 'EventPictoTeaserIconListCtrl'
})];
