"use strict";

var _brandedAppsMetadata = require("src/branded-apps-metadata");
var _constants = require("libs/utils/constants");
var deepClone = function deepClone(x) {
  return angular.fromJson(angular.toJson(x));
};
var formatForFormGenerator = function formatForFormGenerator(pages) {
  return _.map(deepClone(pages), function (page) {
    if (!page.form) {
      return page;
    }
    page.form = _.map(page.form, function (row, key) {
      row.field = key;
      return row;
    });
    return page;
  }).filter(function (page) {
    return page.type !== 'public_login';
  });
}; // removing support for old public login

var formatForApp = function formatForApp(pages) {
  return _.map(deepClone(pages), function (page) {
    if (!page.form) {
      return page;
    }
    page.form = _.chain(page.form).filter(function (row) {
      return row && row.field;
    }).map(function (row) {
      return [row.field, _.omit(row, 'field')];
    }).object().value();
    return page;
  });
};
module.exports = /* @ngInject */["modalService", "metadataService", function (modalService, metadataService) {
  return {
    restrict: 'E',
    scope: {
      branding: '='
    },
    templateUrl: '/static/partials/apps/components/activation-screen-editor.html',
    link: function link(scope) {
      scope.getPageTypeLabel = function (index) {
        var storedPage = scope.pages[index];
        if (!storedPage) return;
        var type = storedPage.type;
        var page = _brandedAppsMetadata.ACTIVATION_PAGES[type];
        return storedPage._representation || (page || {})._representation || type;
      };
      scope.setActivePage = function (index) {
        if (scope.activeIndex === index) {
          return;
        }
        if (index >= scope.pages.length) {
          return;
        }
        return scope.activeIndex = index;
      };
      scope.activePage = function () {
        var _scope$pages;
        return (_scope$pages = scope.pages) !== null && _scope$pages !== void 0 && _scope$pages.length ? scope.pages[scope.activeIndex] : undefined;
      };
      scope.fieldsForActivePage = function () {
        if (scope.activeIndex === undefined) {
          return;
        }
        return _brandedAppsMetadata.ACTIVATION_PAGES[scope.activePage().type];
      };
      scope.addNewPage = function () {
        var activationPages = angular.copy(_brandedAppsMetadata.FORMS.newActivationPage);
        var opts = activationPages.type.kind_options.values;
        var ssoTypeLabel = _brandedAppsMetadata.ACTIVATION_PAGES['sso']._representation;
        var _loop = function _loop() {
          var confId = _Object$keys[_i];
          // Prevents duplication insertions
          if (!scope.pages.find(function (_ref) {
            var id = _ref.id;
            return id === confId;
          })) {
            var config = scope.branding.sso_config[confId].conf;
            opts[confId] = "".concat(ssoTypeLabel, " (").concat(config && config.label ? config.label : confId, ")");
          } else {
            delete opts[confId];
          }
        };
        for (var _i = 0, _Object$keys = Object.keys(scope.branding.sso_config || {}); _i < _Object$keys.length; _i++) {
          _loop();
        }
        return modalService.openModalWithForm({
          title: 'Add a login form',
          fields: activationPages,
          saveLabel: 'Add',
          formCanBeSaved: function formCanBeSaved(model) {
            return model.type;
          }
        }).then(function (model) {
          var fields = _brandedAppsMetadata.ACTIVATION_PAGES[model.type];
          if (!fields) {
            fields = _brandedAppsMetadata.ACTIVATION_PAGES['sso'];
          }
          var page = deepClone(metadataService.extractDefaults(fields));
          if (page.type === 'sso') {
            var confId = model.type;
            var config = scope.branding.sso_config[confId];
            page.endpoint = _constants.APP_BRANDING_SSO_ENTRYPOINT.replace('{{branding}}', scope.branding.fp_ext_id).replace('{{id}}', confId);
            page.id = model.type;
            page._representation = "".concat(fields._representation, " (").concat(config.conf.label || confId, ")");
          }
          scope.pages.push(page);
          return scope.setActivePage(scope.pages.length - 1);
        });
      };
      scope.removePage = function (index) {
        if (scope.pages.length === 1) {
          return;
        }
        if (!confirm('This cannot be undone. Continue?')) {
          return;
        }
        scope.pages.splice(index, 1);
        return scope.setActivePage(scope.pages.length - 1);
      };

      // direction: -1 for left, 1 for right
      scope.movePage = function (index, direction) {
        var target = index + direction;
        var pages = scope.pages;
        if (target < 0 || target > pages.length) {
          return;
        }
        var page = pages[index];
        var replacedPage = pages[target];
        pages[target] = page;
        return pages[index] = replacedPage;
      };

      // this is a bit sad :/
      scope.$watch('pages', function (pages) {
        if (!pages) {
          return;
        }
        if (!scope.branding.activation_pages) {
          scope.branding.activation_pages = {
            pages: []
          };
        }
        return scope.branding.activation_pages.pages = formatForApp(pages);
      }, true);
      return scope.$watch('branding._rev', function (rev) {
        if (!rev) {
          return;
        }
        if (!_.isObject(scope.branding.activation_pages)) {
          scope.branding.activation_pages = {};
        }
        if (!_.isArray(scope.branding.activation_pages.pages)) {
          scope.branding.activation_pages.pages = [angular.copy(_brandedAppsMetadata.DEFAULT_ACTIVATION_PAGE)];
        }
        scope.pages = formatForFormGenerator(scope.branding.activation_pages.pages);
        if (scope.activeIndex === undefined) {
          return scope.setActivePage(0);
        }
      });
    }
  };
}];