module.exports = [() => ({
    transclude: true,
    template: `\
<div class="modal-backdrop hide required"></div>
<div data-ng-transclude></div>\
`,
    link(scope, elem) {

        document.addEventListener('fullscreenchange', () => {
            if (!document.fullscreenElement && scope.$root && !scope.$root.fullframeScreen) {
                scope.$root.exitFullscreenModes();
            }
        });

        return scope.$on('event:request-browser-full-screen:main-container', async () => {
            // show the ios "add to home screen" guide on iOS devices
            if ((navigator.userAgent || '').match(/(iPad|iPhone|iPod)/g)) {
                return elem.children('.modal-backdrop').removeClass('hide');
            } else {
                const el = elem[0];
                let fsFunction = el.requestFullscreen
                    || el.msRequestFullscreen
                    || el.mozRequestFullScreen
                    || el.webkitRequestFullscreen;

                if (typeof fsFunction === 'function') {
                    try {
                        await fsFunction.apply(el);
                    } catch (error) {
                        console.error('[BsFullscreenRequestElement] Unable to go fullscreen', error);
                    }

                } else {
                    console.warn('[BsFullscreenRequestElement] Browser has no fullscreen capability');
                }
            }
        });
    }
})];
