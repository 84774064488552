// Classes
import BaseService from './base-service';

// Utils
import { getBackstageDomain } from 'libs/utils/url';
import { getRootNodeName } from 'libs/utils/workspaces';

// Constants
import {
    EVENT_SERVICE_ENDPOINT
} from 'libs/utils/constants';

/**
 * Provides utils for getting or saving information of a workspace.
 * Known sub classes: EventService, TemplateService
 */
export default class WorkspaceService extends BaseService {

    /**
     * Saves the workspace data
     *
     * @param {Object} workspaceData the workspace data object
     *
     * @returns {Promise.<import('axios').AxiosResponse>} the server response
     */
    async save(workspaceData) {
        const url = EVENT_SERVICE_ENDPOINT.replace('{{eventId}}', workspaceData.id);
        const { data } = await this.post(url, workspaceData, { withCredentials: true });

        return data;
    }

    /**
     * Given an event this method returns the correct domain based on its node.
     *
     * @param {Workspace} event the Event object
     *
     * @returns {String} the full domain of the event
     */
    getEventDomain(event) {
        const nodeName = getRootNodeName(event);

        return getBackstageDomain(nodeName);
    }

}
