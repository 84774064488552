// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
// this placeholder contains the markup
// taken from /static/partials/components/form-generator-field-nested-object-editor.html
// on 1/7/15
module.exports = `\
<button type="button"
        class="btn btn-glow"
        ng-click="viewState.isEditorModalOpen = true"
        data-extra-button-attributes-placeholder="">
    Edit
     {{fieldDescriptor.label || fieldDescriptor.field | humanize | uppercaseFirstChar}}...
</button>
<div data-strap-modal="data-strap-modal" data-show-modal-when="viewState.isEditorModalOpen">
    <form name="form" ng-submit="viewState.isEditorModalOpen = false">
        <div class="modal-header">
            <h3>
                Editing
                 "{{fieldDescriptor.label || fieldDescriptor.field | humanize | uppercaseFirstChar}}"
            </h3>
        </div>
        <div class="modal-body">
            <fieldset ng-if="viewState.isEditorModalOpen">
                <div data-bs-form-generator="data-bs-form-generator"
                        data-model="model[fieldDescriptor.field]"
                        data-fields="fieldDescriptor.kind_options.fields"
                        data-show-link-to-metadata-manager="true"
                        data-omit-button-bar="true">
                </div>
            </fieldset>
        </div>
        <div class="modal-footer">
            <button type="submit"
                    class="btn btn-glow">
                Close
            </button>
        </div>
    </form>
</div>\
`;
