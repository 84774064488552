"use strict";

var _ = require('underscore');
angular.module('backstage.controllers.widgets').controller('TaskExecutionProgressModalCtrl', /* @ngInject */["$scope", "$timeout", "taskExecutorService", "TASK_PROGRESS_MODAL_COMPLETION_DELAY", function ($scope, $timeout, taskExecutorService, TASK_PROGRESS_MODAL_COMPLETION_DELAY) {
  $scope.isModalForcablyDismissed = false;
  var inExtendedCompletedWaitPeriod = false;
  $scope.shouldShowProgressModal = function () {
    var executor = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : $scope.taskExecutor;
    if ($scope.isModalForcablyDismissed) {
      return false;
    }
    if (!executor) {
      return false;
    }
    return executor.isInProgress() || executor.errored || inExtendedCompletedWaitPeriod;
  };
  $scope.closeModal = function (executor) {
    if (!executor) {
      executor = $scope.taskExecutor;
    }
    $scope.isModalForcablyDismissed = true;

    // deferred rejection happens on modal close if we encountered an error
    if (executor.errored) {
      if (executor.deferred !== null) {
        executor.deferred.reject();
      }
    }
  };
  $scope.getModalStyle = function (executor) {
    if (!executor) {
      executor = $scope.taskExecutor;
    }
    return {
      cursor: executor && executor.isInProgress() && !executor.errored ? 'wait' : 'default'
    };
  };
  $scope.getProgressBarStyle = function (executor) {
    if (!executor) {
      executor = $scope.taskExecutor;
    }
    return {
      width: "".concat((executor ? executor.getProgressPercentage() : undefined) || 0, "%")
    };
  };
  $scope.getProgressBarClasses = function (executor) {
    if (!executor) {
      executor = $scope.taskExecutor;
    }
    return {
      active: executor && executor.isInProgress() && !executor.errored,
      'progress-danger': executor ? executor.errored : undefined
    };
  };
  $scope.tryAgainOnErrorFn = function (executor) {
    if (!executor) {
      executor = $scope.taskExecutor;
    }
    if (!executor || !executor.errored) {
      return;
    }
    return taskExecutorService.resumeTaskSeriesAfterError(executor);
  };
  $scope.$watch('taskExecutor', function (newTaskSeries) {
    if (!_.isObject(newTaskSeries)) {
      return;
    }
    $scope.isModalForcablyDismissed = false;
  });
  $scope.$watch('taskExecutor.completed', function (isTaskCompleted, wasTaskCompleted) {
    if (isTaskCompleted !== true || wasTaskCompleted !== false) {
      return;
    }
    inExtendedCompletedWaitPeriod = true;

    // wait 0.6s for the progress bar to animate to 100%
    $timeout(function () {
      return inExtendedCompletedWaitPeriod = false;
    }, TASK_PROGRESS_MODAL_COMPLETION_DELAY);
  });
  $scope.$on('$destroy', function () {
    $('.modal').modal('hide');
    $('.modal-open').removeClass('modal-open');
  });
}]);