"use strict";

var $ = require('jquery');
module.exports = /* @ngInject */["btDnd", function (btDnd) {
  return {
    restrict: 'A',
    link: function link(scope, element, attrs) {
      element.addClass('bt-draggable');
      attrs.$observe('btDndDraggable', function (newVal) {
        element.data('btDndDraggable', scope.$eval(newVal));
      });
      attrs.$observe('btDndFixedSize', function (newVal) {
        element.data('btDndFixedSize', scope.$eval(newVal));
      });
    },
    controller: ['$element', '$scope', '$timeout', '$window', function ($element, $scope, $timeout, $window) {
      var offset, startingPosition;
      function startDrag(dragStartingPoint) {
        btDnd.startDrag({
          $view: $element,
          data: $element.data('btDndDraggable'),
          offset: dragStartingPoint,
          fixedSize: $element.data('btDndFixedSize')
        });
      }
      function distance(point1, point2) {
        return Math.sqrt(Math.pow(point1.x - point2.x, 2), Math.pow(point1.y - point2.y, 2));
      }
      this.attachEvents = function (el) {
        function onMouseMove(event) {
          event.preventDefault();
          var p = {
            x: event.clientX,
            y: event.clientY
          };
          if (distance(p, startingPosition) > 1) {
            startDrag(offset);
            $($window).unbind('mousemove', onMouseMove);
          }
        }
        el.on('mousedown', function (event) {
          event.preventDefault();
          var elementRect = $element[0].getBoundingClientRect();
          var clientX = event.clientX;
          var clientY = event.clientY;
          startingPosition = {
            x: clientX,
            y: clientY
          };
          offset = {
            x: clientX - elementRect.left,
            y: clientY - elementRect.top
          };
          $($window).on('mousemove', onMouseMove);
        });
        el.on('mouseup', function () {
          $($window).unbind('mousemove', onMouseMove);
        });
      };
    }]
  };
}];