"use strict";

var moment = require('moment');
var _require = require('src/branded-apps-metadata'),
  TABLES = _require.TABLES;
angular.module('backstage.controllers.brandedApps').controller('PushCertificatesListCtrl', /* @ngInject */["$scope", "$sanitize", "userService", "appBrandingService", function ($scope, $sanitize, userService, appBrandingService) {
  if (!userService.matchesRoles({
    globalRole: 'app_publisher'
  }, {
    globalRole: 'superadmin'
  })) {
    return;
  }
  $scope.tableConfig = {
    maxSize: 9,
    itemsByPage: 20,
    displaySelectionCheckbox: false,
    isGlobalSearchActivated: true
  };
  _.each(TABLES.pushCertificatesList, function (column) {
    return column.formatFunction = $sanitize;
  });
  $scope.columnCollection = TABLES.pushCertificatesList;
  return appBrandingService.getAllPushCertificates().then(function (resp) {
    return $scope.rowCollection = _.map(resp.data, function (row) {
      row.expires_at = moment.unix(row.push_cert_expiration_date).format('YYYY-MM-DD');
      return row;
    });
  }, console.error);
}]);