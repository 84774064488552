"use strict";

var _lodash = require("lodash");
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var _require = require('src/utils/object'),
  unflattenPropertiesInAllObjects = _require.unflattenPropertiesInAllObjects;
var FLATTENED_OBJECT_KEY_DELIM = '.';
module.exports = /* @ngInject */["$log", "$route", "$routeParams", "$scope", "$filter", "metadataService", "eventService", "bulkUpdateService", "fieldFormatterService", "backlinksService", "ngProgressLite", function ($log, $route, $routeParams, $scope, $filter, metadataService, eventService, bulkUpdateService, fieldFormatterService, backlinksService, ngProgressLite) {
  var finalDataRowsToImport;
  var eventId = $routeParams.eventId;
  var event = eventService.decorateScope(eventId);
  var currentRouteDescriptor = ($route.current || {}).$$route;
  var dataImportFpType = currentRouteDescriptor.dataImportFpType;
  $scope.currentStep = 1;
  $scope.inverseMappingsImportEmptyCells = {};
  $scope.prevStep = function () {
    $scope.errorMessage = null;
    return $scope.currentStep--;
  };
  $scope.nextStep = function (totalSteps) {
    if ($scope.currentStep === totalSteps) {
      return;
    }
    return $scope.currentStep++;
  };

  // there's a fair bit of state to keep in scope between views
  _.extend($scope, {
    workbookLoaded: false,
    responseData: {},
    curtailedWorkbookData: {},
    selectedColumnHeadingsRow: 0,
    sheetChangeCounter: 0,
    showTableForSheet: {},
    columnHeadingRowsForSheets: {},
    columnHeadingsRowIndexesForSheets: {},
    sheetFieldMappings: {},
    newCustomTargetObjectField: {},
    ignoredRows: {},
    finalDataRowsToImport: finalDataRowsToImport = [],
    referenceCheckPassed: false
  });

  // we support custom fields for all fp_types now that they're all stored in metadata
  $scope.showNewCustomTargetObjectFieldForm = true;
  $scope.importAllEmptyCells = false;
  var metadataForImportFpType = metadataService.getCachedMetadataForTypeAsArray(eventId, dataImportFpType);
  var sourceMetadata = function () {
    var result = [];
    for (var _i = 0, _Array$from = Array.from(metadataForImportFpType); _i < _Array$from.length; _i++) {
      var descriptor = _Array$from[_i];
      result.push(_.omit(_.extend({}, descriptor, {
        map: descriptor.field,
        is_custom_field: descriptor.is_custom_field || descriptor.is_custom_entry,
        // for back compat
        importEmptyCells: false
      }), 'field'));
    }
    return result;
  }();
  var customTargetObjectFields = $scope.customTargetObjectFields = sourceMetadata.filter(function (fieldDescriptor) {
    return fieldDescriptor.is_custom_field || fieldDescriptor.dirty;
  });
  var targetObjectFields = $scope.targetObjectFields = sourceMetadata.filter(function (fieldDescriptor) {
    return !fieldDescriptor.is_custom_field && !fieldDescriptor.dirty;
  }).concat($scope.customTargetObjectFields);

  // always show the fp_ext_id as a field to be mapped
  targetObjectFields.unshift({
    map: 'fp_ext_id',
    label: 'Unique Identifier'
  });
  targetObjectFields.unshift({
    map: 'Do Not Import',
    label: '- Do Not Import -'
  });

  // step 1: initial upload
  //   populate curtailedWorkbookData with shortened subsets of row cells (for column heading row selection)
  $scope.$watch('responseData', function (newWorkbookData, oldValue) {
    if (newWorkbookData === oldValue || !_.isObject(newWorkbookData)) {
      return;
    }
    if (_.isEmpty(newWorkbookData)) {
      return alert('No sheets were found in the uploaded workbook.');
    }
    // we want to grab a subset of the data so that we can allow the user to choose the headings row
    $scope.curtailedWorkbookData = {};
    for (var workbook in newWorkbookData) {
      // grab the first 3 rows for rendering the heading row selection step
      var rows = newWorkbookData[workbook];
      var batch = _.first(rows, 3);
      // work out our max # of cols in rows
      var idxCeil = _.values(batch).reduce(function (idxCeil, row) {
        var curMax;
        if ((curMax = _.max(_.keys(row))) > idxCeil) {
          return curMax;
        }
        return idxCeil;
      }, -Infinity);
      // map over all key -> value assocs with padding in lieu of undefineds
      // we must recreate the object from scratch so that they come through in the expected order in IE

      if (idxCeil > 0) {
        batch = batch.map(function (row) {
          var _row = {};
          for (var i = 0; i <= idxCeil; i += 1) {
            _row[i] = row[i] || '';
          }
          return _row;
        });
      }
      $scope.curtailedWorkbookData[workbook] = batch;
    }
    // setting sail for excel import paradise at this point
    $scope.workbookLoaded = true;
    // figure out the id of the (cogs config) doc that will store our import config
    var uploadedFileName = $filter('extractAlphaNumerics')($scope.importedFromFilename);
    return $scope.cogsConfigDocId = "excel-import-".concat(uploadedFileName);
  });
  $scope.toggleIgnoreRowAtIndex = function (index, $event) {
    if (!_.isNumber(index)) {
      return;
    }
    if ($scope.selectedColumnHeadingsRow === index && !$scope.ignoredRows[index]) {
      return alert('This row is marked as the one containing your column headings. Please select another row first.');
    }
    $scope.ignoredRows[index] = Boolean(!$scope.ignoredRows[index]);

    // on recent browsers only $event.stopPropagation() is needed
    if ($event.stopPropagation) {
      $event.stopPropagation();
    }
    if ($event.preventDefault) {
      $event.preventDefault();
    }
    $event.cancelBubble = true;
    return $event.returnValue = false;
  };
  $scope.chooseSelectedColumnHeadingsRow = function (index) {
    if ($scope.ignoredRows[index]) {
      return;
    }
    return $scope.selectedColumnHeadingsRow = index;
  };
  $scope.returnToColumnHeadingsStep = function () {
    return $scope.columnHeadingsSelected = false;
  };

  // step 2: set the row that contains the column headings
  $scope.setSelectedColumnHeadingsRow = function (sheetName) {
    var columnHeadingsRowIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var columnHeadingsRow = arguments.length > 2 ? arguments[2] : undefined;
    // do not allow selection if row is excluded from data set
    if ($scope.ignoredRows[columnHeadingsRowIndex]) {
      return;
    }
    $scope.columnHeadingsSelected = true;
    $scope.sheetName = sheetName;
    var headingKeys = _.without(Object.keys(columnHeadingsRow), '$$hashKey');
    $scope.columnHeadingsRowIndexesForSheets[sheetName] = columnHeadingsRowIndex;
    var columnHeadingRows = [].concat(headingKeys.map(function (columnHeadingsRowCellIndex) {
      return {
        index: columnHeadingsRowCellIndex,
        title: columnHeadingsRow[columnHeadingsRowCellIndex]
      };
    }));
    $scope.columnHeadingRowsForSheets[sheetName] = $filter('withoutFieldHeadingsWithExplicitArrayObjectNotation')(columnHeadingRows);
    var sheetFieldMappings = $scope.sheetFieldMappings[sheetName] = {};
    var assignInverseFieldMappingsFn = function assignInverseFieldMappingsFn() {
      return $scope.sheetFieldMappingsInverse = _.invert(sheetFieldMappings);
    };

    // try to "guess" mappings

    // first prepare rows
    var rowsToMatch = columnHeadingRows.filter(function (row) {
      return row && _.isString(row.title) && row.title;
    }).map(function (row) {
      // we don't care about anything proceeding a dot '.' in the keys
      var dotIdx = row.title.indexOf('.');
      var titleToMatch = dotIdx > 0 ? row.title.substr(0, dotIdx) : row.title;
      var titleToMatchLower = titleToMatch.toLowerCase();
      return {
        title: titleToMatchLower,
        index: row.index
      };
    });

    // first try, match column headings with field with exact naming
    // to support properly the extract/reimport case
    targetObjectFields.forEach(function (_targetObjectField) {
      _targetObjectField.importEmptyCells = false;
      if (!_.isString(_targetObjectField.map) || !_targetObjectField.map) {
        return;
      }
      var toMatch = _targetObjectField.map.toLowerCase();
      var row = _.find(rowsToMatch, function (row) {
        return toMatch === row.title;
      });
      if (!row) {
        return;
      }
      sheetFieldMappings[_targetObjectField.map] = row.index;
    });

    // second try if first try failed: match with labels
    targetObjectFields.forEach(function (_targetObjectField) {
      if (!_.isString(_targetObjectField.map || !_targetObjectField.map)
      // do not erase results from first try
      || sheetFieldMappings[_targetObjectField.map] !== undefined) {
        return;
      }
      // label can be an object (_to_prune bug, or wrong configuration)
      if (!_.isString(_targetObjectField.label)) {
        return;
      }
      var toMatch = (_targetObjectField.label || '').toLowerCase();
      var row = _.find(rowsToMatch, function (row) {
        return toMatch && row.title.indexOf(toMatch) === 0;
      });
      if (!row) {
        return;
      }
      var targetIndex = row.index;

      // check that this column has not already been assigned
      if (_.some(sheetFieldMappings, function (rowIndex) {
        return rowIndex === targetIndex;
      })) {
        return;
      }
      sheetFieldMappings[_targetObjectField.map] = targetIndex;
    });
    $scope.rowMappings = {};
    _.each(columnHeadingRows, function (c) {
      return $scope.rowMappings[c.index] = c.title;
    });
    _.each($scope.rowMappings, function (value, key) {
      $scope.inverseMappingsImportEmptyCells[key] = {};
      return $scope.inverseMappingsImportEmptyCells[key].importEmptyCells = false;
    });

    // load an existing mapping from cogs-config if there is one available
    eventService.getEventCogsConfigs(event).then(function (response) {
      var cogsConfigs = (response || {}).data;
      if (!cogsConfigs) {
        return;
      }
      var applicableCogsConfig = $scope.applicableCogsConfig = _.findWhere(cogsConfigs, {
        _id: $scope.cogsConfigDocId
      });
      var storedFieldMappings = (applicableCogsConfig || {})[dataImportFpType];
      if (!storedFieldMappings) {
        return;
      }
      _.pairs(storedFieldMappings).forEach(function (pair) {
        var _Array$from2 = Array.from(pair),
          _Array$from3 = _slicedToArray(_Array$from2, 2),
          targetField = _Array$from3[0],
          mappingDescriptor = _Array$from3[1];
        var colMappingIdx = (_.findWhere(columnHeadingRows, {
          title: (mappingDescriptor || {}).field
        }) || {}).index;
        // remove any existing mapping that may conflict with what we have have in cogs
        var existingMapping = _.findWhere(_.pairs(sheetFieldMappings), {
          1: colMappingIdx
        });
        if (existingMapping) {
          delete sheetFieldMappings[existingMapping[0]];
        }
        return sheetFieldMappings[targetField] = colMappingIdx;
      });
      return assignInverseFieldMappingsFn();
    });
    assignInverseFieldMappingsFn();
    $scope.showAddFieldModal = false;
    return $scope.kindOptions = metadataService.getKindOptions();
  };
  var sanitizeNewCustomFieldKey = $scope.sanitizeNewCustomFieldKey = function (key) {
    if (!_.isString(key)) {
      return key;
    }
    return key.toLowerCase().replace(/\s/g, '_');
  };
  $scope.switchImportEmptyCells = function (label, map) {
    var selectedObj = _.find(targetObjectFields, function (obj) {
      return obj.map === map;
    });
    return selectedObj.importEmptyCells = !selectedObj.importEmptyCells;
  };
  $scope.getImportCellsForMappings = function (label, map, index) {
    if (!map || map === 'Do Not Import') {
      return;
    }
    var selectedObj = _.find(targetObjectFields, function (obj) {
      return obj.map === map;
    });
    $scope.inverseMappingsImportEmptyCells[index].importEmptyCells = selectedObj.importEmptyCells;
    return _.each($scope.sheetFieldMappingsInverse, function (v, i) {
      if (i !== index && v === map) {
        if (confirm("This action will unmap ".concat($scope.sheetFieldMappingsInverse[i]))) {
          return $scope.sheetFieldMappingsInverse[i] = null;
        } else {
          return $scope.sheetFieldMappingsInverse[index] = null;
        }
      }
    });
  };

  // step 2.5: add custom fields to the metadata upon the user's request

  var updateCustomTargetObjectFieldFn = function updateCustomTargetObjectFieldFn() {
    var fieldDescriptor = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var allMetadata = [].concat(metadataForImportFpType).concat([_.omit(fieldDescriptor, 'map', 'importEmptyCells')]);
    return metadataService.saveMetadataFieldsToOverride(eventId, event.node, dataImportFpType, allMetadata);
  };
  $scope.addCustomTargetObjectField = function () {
    var fieldDescriptor = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    fieldDescriptor.map = sanitizeNewCustomFieldKey(fieldDescriptor.map);
    angular.extend(fieldDescriptor, {
      order: metadataForImportFpType.length,
      field: fieldDescriptor.map,
      map: fieldDescriptor.map,
      label: fieldDescriptor.label,
      kind: fieldDescriptor.kind || '',
      crypt: false,
      is_custom_field: true,
      importEmptyCells: false
    });
    $scope.customTargetObjectFields.push(fieldDescriptor);
    $scope.targetObjectFields.push(fieldDescriptor);
    $scope.newCustomTargetObjectField = {
      map: '',
      label: '',
      kind: ''
    };
    return updateCustomTargetObjectFieldFn(fieldDescriptor).then(function () {
      return $scope.addFieldStatus = "<i class=\"icon-check-sign\"></i>\nNew field <code>".concat(fieldDescriptor.label, "</code>\nof kind <code>").concat(fieldDescriptor.kind, "</code>\nadded");
    }, function () {
      return $scope.addFieldStatus = 'Oops, something went wrong. Try again?';
    });
  };

  // step 3: perform the final mappings in prep for data import
  $scope.performFieldMappingForImport = function (sheetName) {
    var fieldToSheetColumnIndexMapping = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    fieldToSheetColumnIndexMapping = _.invert(fieldToSheetColumnIndexMapping);
    // ⚑
    // TODO: Perform a sanity check to ensure the user has defined enough mappings in the UI

    // strip mappings to fields that are not defined as default nor custom
    Object.keys(fieldToSheetColumnIndexMapping).forEach(function (fieldKey) {
      // ensure the key exists as a field
      var foundTargetObjectFields = _.findWhere(targetObjectFields || [], {
        map: fieldKey
      });
      var foundCustomTargetObjectFields = _.findWhere(customTargetObjectFields || [], {
        map: fieldKey
      });
      if (!foundTargetObjectFields && !foundCustomTargetObjectFields) {
        delete fieldToSheetColumnIndexMapping[fieldKey];
      }
      if (fieldKey === 'Do Not Import') {
        return delete fieldToSheetColumnIndexMapping[fieldKey];
      }
    });

    // invert the object we just created (so we have column indexes mapped to field names)
    var sheetColumnIndexToFieldMapping = _.invert(fieldToSheetColumnIndexMapping);

    // define a function to persist the cogs config
    var updateCogsConfigFn = function updateCogsConfigFn() {
      var columnHeadingsForSheet = $scope.columnHeadingRowsForSheets[sheetName];
      var configDocFpType = 'cogs-config';
      var configDocId = $scope.cogsConfigDocId;
      var sourceFields = {};
      var priorConfigDoc = $scope.applicableCogsConfig || ($scope.applicableCogsConfig = {});
      var configDoc = {
        _id: configDocId,
        fp_ext_id: configDocId,
        fp_type: configDocFpType,
        fp_owner: 'private'
      };
      configDoc[dataImportFpType] = _.pairs(sheetColumnIndexToFieldMapping).reduce(function (memo, pair) {
        var _heading = _.findWhere(columnHeadingsForSheet, {
          index: pair[0]
        });
        if (!_heading) {
          return memo;
        }
        var _columnHeadingForColumnHeading = _heading.originalTitle || _heading.title;
        sourceFields[_columnHeadingForColumnHeading] = true;
        memo[pair[1]] = {
          field: _columnHeadingForColumnHeading
        };
        return memo;
      }, {});
      if (!configDoc.source_fields) {
        configDoc.source_fields = {};
      }
      configDoc.source_fields[dataImportFpType] = Object.keys(sourceFields);
      _.extend(priorConfigDoc, configDoc);

      // save the cogs-config
      return bulkUpdateService.updateDoc(eventId, event.node, configDocFpType, configDoc, {
        keepId: true
      }).catch(function () {
        // if fail, try inserting the cogs-config as a new doc instead
        return bulkUpdateService.createDocs(eventId, event.node, configDocFpType, configDoc, {
          keepId: true
        });
      });
    };
    // silently ignore failure at this point (it just means the user will have to remap fields next time around)

    updateCogsConfigFn();

    // the big one: map the final data to import
    // step 3.1: map everything to Excel col headings
    var columnHeadingsRow = $scope.responseData[sheetName][$scope.columnHeadingsRowIndexesForSheets[sheetName]];
    var _rawFinalDataRowsToImport = $scope.responseData[sheetName].map(function (sourceDataRow, index) {
      // do not include the row containing the column headings nor any ignored rows
      if (index === $scope.columnHeadingsRowIndexesForSheets[sheetName] || Array.from(Object.keys($scope.ignoredRows)).includes(String(index))) {
        return;
      }

      // create a new object for each row created; this will contain mappings for just the field we need
      var dataRowToImport = {};
      for (var columnIndex in columnHeadingsRow) {
        var fieldMapping = columnHeadingsRow[columnIndex];
        if (fieldMapping.indexOf('_to_prune') === -1) {
          dataRowToImport[fieldMapping] = sourceDataRow[columnIndex];
        }
      }
      if (!Object.keys(dataRowToImport).find(function (key) {
        return dataRowToImport[key] && ('' + dataRowToImport[key]).trim() !== '';
      })) {
        // there is no data in this row, we skip it
        return;
      }
      return dataRowToImport;
    });

    // step 3.2: unflatten row hashes, map the column headings to our desired field keys
    var _unflatFinalDataRowsToImport = unflattenPropertiesInAllObjects(_.compact(_rawFinalDataRowsToImport));
    var fieldToSheetColumnTitleMapping = {};
    for (var tgt in fieldToSheetColumnIndexMapping) {
      var idx = fieldToSheetColumnIndexMapping[tgt];
      if (!columnHeadingsRow[idx]) {
        continue;
      }
      fieldToSheetColumnTitleMapping[tgt] = columnHeadingsRow[idx];
    }

    // step 3.3: do the final remapping
    //   at this point we'll want to make sure we're overwriting fields with `null` if importEmptyCells is not `true`
    //   or make them `undefined` if we don't want the bulk data API to change them
    var _metadataForImportFpTypeMap = metadataService.getCachedMetadataForType(eventId, dataImportFpType);
    var _remappedFinalDataRowsToImport = _unflatFinalDataRowsToImport.map(function (unflatDataRow) {
      var dataRowToImport = {};
      var delim = FLATTENED_OBJECT_KEY_DELIM;
      for (tgt in fieldToSheetColumnTitleMapping) {
        var val;
        var src = fieldToSheetColumnTitleMapping[tgt];
        src = Array.from(src).includes(delim) ? src.substring(0, src.indexOf(delim)) : src;

        // let's respect the importEmptyCells setting when copying values
        // eslint-disable-next-line
        if (unflatDataRow[src] == null || unflatDataRow[src] === '') {
          // the cell is empty
          var objectFieldData = _.find(targetObjectFields, function (t) {
            return t.map === tgt;
          });
          if (objectFieldData === undefined) {
            objectFieldData = _.find(customTargetObjectFields, function (t) {
              return t.map === tgt;
            });
          }
          val = objectFieldData.importEmptyCells || objectFieldData.map === 'fp_ext_id' ? null : undefined;
        } else {
          // the cell contains data, copy it (in string form)
          var srcVal = unflatDataRow[src];
          val = function () {
            switch (_typeof(srcVal)) {
              case 'object':
              case 'string':
                return srcVal;
              default:
                return String(srcVal);
            }
          }();

          // trim strings to avoid imported excel white spaces
          if (val && (0, _lodash.isString)(val)) {
            val = val.trim();
          }
          // apply the formatter for this field kind
          var _metadataForTargetImportFpType = _metadataForImportFpTypeMap[tgt];
          val = fieldFormatterService.applyFormatter(val, (_metadataForTargetImportFpType || {}).kind, (_metadataForTargetImportFpType || {}).kind_options);
        }
        dataRowToImport[tgt] = val;
      }
      return dataRowToImport;
    });
    angular.copy(_remappedFinalDataRowsToImport, finalDataRowsToImport);
    angular.copy(_remappedFinalDataRowsToImport, $scope.originalFinalDataRowsToImport = []);

    // step 3.5: check references
    //  .. get the metadata fields of kind `external` for this type
    //     .. we only care about `external` fields where `ensure_referential_integrity` is not false
    var fieldDescriptorsToReferenceCheck = metadataForImportFpType.filter(function (field) {
      if ((field.kind_options || {}).ensure_referential_integrity === false) {
        return;
      }
      return field.kind === 'external';
    });

    //  .. get the big list of fp_ext_ids
    var idDescriptors = _.compact(finalDataRowsToImport.reduce(function (memo, row) {
      return memo.concat(fieldDescriptorsToReferenceCheck.reduce(function (_memo, _field) {
        if (_field && _field.kind_options && _field.kind_options.type === 'any') {
          return _memo;
        }
        var _f = (_field || {}).field;
        if (!_f || !row[_f]) {
          return _memo;
        }
        var _ids = _.flatten([_.isObject(row[_f]) ? _.values(row[_f]) : row[_f]]);
        return _memo.concat(_ids.map(function (_id) {
          return {
            field: (_field.kind_options || {}).id_field || 'fp_ext_id',
            value: _id
          };
        }));
      }, []));
    }, []));

    //  .. do the reference check
    return bulkUpdateService.checkExternalIdReferences(eventId, idDescriptors).then(function (_data) {
      var _idDescriptorValues = _.flatten(_.pluck(idDescriptors, 'value'));
      var _diff = $scope.referenceCheckDifferences = _.uniq(_.difference(_idDescriptorValues, _data.data) || []);
      return $scope.referenceCheckPassed = !_diff.length;
    });
  };

  // step 4: data confirmed, upload it through the bulk data API. If options.dry == true,
  // then make a dry call to fetch the import stats
  $scope.finalizeAndCompleteImport = function () {
    var finalDataRowsToImport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (!$scope.referenceCheckPassed) {
      return;
    }
    ngProgressLite.start();
    $scope.importOperationInProgress = true;
    $scope.errorMessage = $scope.errorDetailMessage = null;
    options.prettyFieldName = false;
    return bulkUpdateService.createDocs(eventId, event.node, dataImportFpType, finalDataRowsToImport, options).then(function (response) {
      ngProgressLite.done();
      if (options.dry) {
        $scope.creating = response.data.creatingArray;
        $scope.updating = response.data.updatingArray;
        $scope.dataImportStats = response.data.summary;
        return $scope.importOperationInProgress = false;
      } else {
        $scope.setFlashMessage("The ".concat(dataImportFpType, " records were imported."));
        backlinksService.isBacklinksSearchAvailable(eventId, event.node).then(function (backlinksAreAvailable) {
          if (backlinksAreAvailable) {
            return backlinksService.triggerAndWaitForBacklinksRefresh(eventId);
          }
        });
        $scope.importOperationInProgress = false;
        return $scope.finalImportStats = response.data;
      }
    }, function (err) {
      $log.error('Bulk insertion failed', err);
      ngProgressLite.done();
      $scope.importOperationInProgress = false;
      $scope.err = err;
      if (err && (err.message === 'Request failed with status code 504' || err.message === 'Network Error')) {
        $scope.importingWarning = true;
        $scope.errorMessage = 'A Network error happened. This usually happens when a large amount of data is sent. Import is still processing and the result will soon appear in your event.';
        return;
      }
      $scope.importingError = true;
      var errData = (0, _lodash.get)(err, 'data') || (0, _lodash.get)(err, 'response.data');
      var errorMessage = (errData || {}).error || JSON.stringify(err) || 'Error!';
      if (errorMessage === 'Email is already in use by a different user') {
        $scope.errorMessage = 'This import either have duplicated emails, or will create participant with duplicated emails';
      } else if (errorMessage === 'duplicate fp_ext_id') {
        $scope.errorMessage = 'Import data contains duplicate fp_ext_ids';
        $scope.errorDetailMessage = "Duplicate IDs: ".concat(errData.duplicates.toString());
      } else if (errorMessage === 'fp_ext_ids missing') {
        $scope.errorMessage = 'Some import data rows are missing fp_ext_ids';
      } else if (errorMessage === 'non string fp_ext_ids') {
        $scope.errorMessage = 'Import data contains fp_ext_ids with non-string values';
        $scope.errorDetailMessage = "Non-string IDs: ".concat(errData.rows.toString());
      } else {
        $scope.errorRows = errorMessage.split(';');
        $scope.errorMessage = 'Invalid rows';
      }
    });
  };
  $scope.triggerActiveSheetChange = function () {
    return $scope.sheetChangeCounter++;
  };
  $scope.returnToFieldMappingStep = function () {
    return finalDataRowsToImport = $scope.finalDataRowsToImport = [];
  };
  $scope.returnToReadyToImportStep = function () {
    $scope.dataImportStats = null;
    return angular.copy($scope.originalFinalDataRowsToImport, finalDataRowsToImport);
  };
  $scope.restartImportFromScratch = function () {
    return $route.reload();
  };
}];