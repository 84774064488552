module.exports = [() => ({
    link(scope, elem, attrs) {
        let reactFn = function(test) {
            let hasInClass;

            if (_.isString(attrs.ifVisibleEl)) {
                if (!$(attrs.ifVisibleEl).is(':visible')) {
                    return;
                }
            }
            if (test && (hasInClass = elem.hasClass('in'))) {
                return elem.collapse('hide');
            } else if (!test && !hasInClass) {
                return elem.collapse('show');
            }
        };

        return scope.$watch(attrs.bsCollapsedWhen, function(newValue, oldValue) {
            if ((newValue === null || newValue === undefined) &&
                (oldValue === null) || oldValue === undefined) {
                return;
            }
            return reactFn(newValue);
        });
    }
})];
