
import BaseService from './base-service';


// constants
import {
    API_BASE_PATH
} from 'libs/utils/constants';

/**
 * The endpoint to schedule a video breakout call. Interpolations: `{{eventId}}`
 * @constant {String} VIDEO_CALL_SCHEDULE_ENDPOINT
 */
export const VIDEO_CALL_SCHEDULE_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/video-calls/schedule`;

/**
 * The endpoint to cancel a scheduled video breakout call. Interpolations: `{{eventId}}`
 * @constant {String} VIDEO_CALL_CANCEL_ENDPOINT
 */
export const VIDEO_CALL_CANCEL_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/video-calls/cancel`;

/**
 * The endpoint to get a migrate a legacy videoCall. Interpolations: `{{eventId}}`
 * @constant {String} VIDEO_CALL_MIGRATE_LEGACY_API_ENDPOINT
 */
export const VIDEO_CALL_MIGRATE_LEGACY_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/video-calls/migrate`;

/**
 * The endpoint to get a video call doc. Interpolations: `{{eventId}}`
 * @constant {String} VIDEO_CALL_DOC_API_ENDPOINT
 */
export const VIDEO_CALL_DOC_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/document/video_call/{{videoCallId}}`;

/**
 * API path for updating video call. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} VIDEO_CALL_DOC_UPDATE_API_ENDPOINT
 * @private
 */
export const VIDEO_CALL_DOC_UPDATE_API_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/video-calls/{{videoCallId}}`;

/**
 * API path for notifying of new moderators. Interpolation: `{{eventId}}`, `{{liveStreamId}}`.
 * @const {string} VIDEO_CALL_DOC_NOTIFY_MODERATOR_CHANGE_ENDPOINT
 * @private
 */
export const VIDEO_CALL_DOC_NOTIFY_MODERATOR_CHANGE_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/video-calls/notify-moderators`;


/**
 * Provides utils for video calls management.
 *
 * @example
 * import VideoCallsService from 'libs/services/video-calls';
 * ...
 * const videoCalls = new VideoCallsService();
 */
export default class VideoCallsService extends BaseService {


    /**
     * Returns video call document
     *
     * @param {String} eventId event ID
     * @param {String} videoCallId video call ID
     * @returns {Promise<Object>}
     */
    async getDoc(eventId, videoCallId) {

        const videoCallUrl = VIDEO_CALL_DOC_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{videoCallId}}', videoCallId);

        const { data: videoCallDoc } = await this.get(videoCallUrl);

        return videoCallDoc;
    }

    /**
     * Returns video call document after migrating a legacy videocall
     *
     * @param {String} eventId event ID
     * @param {String} sessionDocumentId session ID
     * @param {String} videoCallId video call ID
     * @returns {Promise<Object>}
     */
    async migrateLegacyVideoCall(eventId, sessionDocumentId, videoCallId) {

        const videoCallUrl = VIDEO_CALL_MIGRATE_LEGACY_API_ENDPOINT
            .replace('{{eventId}}', eventId);

        const { data: { sessionDocument_rev, videoCallDoc } } = await this.post(videoCallUrl, { sessionDocumentId, videoCallId });

        return { sessionDocument_rev, videoCallDoc };
    }

    /**
     * Updates existing video call document
     *
     * @param {String} eventId event ID
     * @param {Object} videoCall object
     * @returns {Promise<Object>}
     */
    updateDoc(eventId, videoCall) {
        const url = VIDEO_CALL_DOC_UPDATE_API_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{videoCallId}}', videoCall._id);

        return this.put(url, videoCall);
    }


    /**
     * Given a document Id for an event this method schedules a video call
     *
     * @param {String} eventId the event ID
     * @param {String} docId the ID of the document with video call field
     *
     * @returns {Promise<Object>} the updated doc
     */
    async schedule(eventId, docId) {
        const url = VIDEO_CALL_SCHEDULE_ENDPOINT
            .replace('{{eventId}}', eventId);

        const { data } = await this.post(url, { docId });
        return data;
    }

    /**
     * Given a document Id for an event, this method cancels a scheduled a video call
     *
     * @param {String} eventId the event ID
     * @param {String} docId the ID of the document with video call field
     *
     * @returns {Promise<Object>} the updated doc
     */
    async cancel(eventId, docId) {
        const url = VIDEO_CALL_CANCEL_ENDPOINT
            .replace('{{eventId}}', eventId);

        const { data } = await this.post(url, { docId });
        return data;
    }

    /**
     * Notify that a new list of moderators is available
     * @param {String} eventId
     * @param {String} parentDocId
     * @param {Array<String>} moderators
     *
     * @returns {Promise<Object>} the updated doc
     */
    async notifyOnModeratorChange(eventId, parentDocId, moderators) {
        const url = VIDEO_CALL_DOC_NOTIFY_MODERATOR_CHANGE_ENDPOINT
            .replace('{{eventId}}', eventId);

        const { data } = await this.post(url, { parentDocId, moderators });
        return data;
    }
}
