"use strict";

var AccountService = /* @ngInject */function AccountService(USER_QUERY_ENDPOINT, USER_SERVICE_ENDPOINT, $http) {
  return {
    getUser: function getUser(username, options) {
      // options = { noEvents: boolean, skip: number, limit: number }
      return $http.get(USER_QUERY_ENDPOINT + "/".concat(username), {
        params: options
      });
    },
    getUserOrgs: function getUserOrgs(username) {
      return $http.get(USER_SERVICE_ENDPOINT + "/".concat(username, "/orgs"), {
        withCredentials: true
      });
    },
    createUser: function createUser() {
      var userParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return $http.post(USER_SERVICE_ENDPOINT, userParams, {
        withCredentials: true
      });
    }
  };
};
AccountService.$inject = ["USER_QUERY_ENDPOINT", "USER_SERVICE_ENDPOINT", "$http"];
AccountService.$inject = ["USER_QUERY_ENDPOINT", "USER_SERVICE_ENDPOINT", "$http"];
angular.module('backstage.services').factory('accountService', AccountService);