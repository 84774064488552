// Classes
import ObservableService from './observable-service';
import { getBackstageURL, getNodeName, getWebappRegistrationUrl } from 'libs/utils/url';
import { get } from 'lodash';
import WebinarTemplate from '../models/webinar/webinar-template';

// Constants
import { API_BASE_PATH, WEBINAR_ICON_ENDPOINT, WEBINAR_BANNER_ENDPOINT, STUDIO_EXPRESS_BASE_PATH } from 'libs/utils/constants';

// Utils
import { pick } from 'lodash';

const PAYLOAD_DATA_FIELDS = ['orgId', 'name', 'startAt', 'endAt', 'timezone', 'bundle', 'serverGroup', 'webinarSpecific', 'fromTemplateId', 'brandedApp', 'pathName', 'category', 'categories'];

/**
 * API endpoint for getting the webinar settings
 * @constant WEBINAR_SETTINGS_ENDPOINT
 * @private
 */
const SETTINGS_ENDPOINT = `${API_BASE_PATH}/webinar/{{eventId}}/settings`;

/**
 * API endpoint for webinar conversion
 * @constant WEBINAR_TO_TEMPLATE_API_ENDPOINT
 * @private
 */
const WEBINAR_TO_TEMPLATE_API_ENDPOINT = `${API_BASE_PATH}/webinar/{{eventId}}/template`;

/**
 * API endpoint for the webinar templates
 * @constant TEMPLATES_API_ENDPOINT
 * @private
 */
const TEMPLATES_API_ENDPOINT = `${API_BASE_PATH}/orgs/{{orgId}}/webinar/template`;

/**
 * API endpoint for specific webinar template
 * @constant TEMPLATES_SINGLE_API_ENDPOINT
 * @private
 */
const TEMPLATES_SINGLE_API_ENDPOINT = `${API_BASE_PATH}/orgs/{{orgId}}/webinar/template/{{templateId}}`;

/**
 * Provides utils for webinars
 *
 * @example
 * import WebinarsService from 'libs/services/webinar';
 * ...
 * const webinar = new WebinarsService();
 */
export default class WebinarsService extends ObservableService {
    /**
     * Creates a webinar with the given data.
     *
     * @param {Object} webinarData the payload needed to create a webinar.
     *
     * @returns {Promise<Object>} could be an object containing `jobId` or an `error` key.
     */
    async createWebinar(webinarData, files) {
        const payload = pick(webinarData, PAYLOAD_DATA_FIELDS);

        console.info('[WebinarsService] Creation payload:', payload);

        const formData = new FormData();
        const token = await this.getCsrfToken();
        formData.set('_csrf', token);

        for (const assetName in files) {
            const file = files[assetName];
            if (file && file.size > 0) {
                formData.set(assetName, file.file);
            }
        }

        formData.set('payload', JSON.stringify(payload));

        try {
            const path = `${API_BASE_PATH}/orgs/${webinarData.orgId}/webinars`;
            const { data } = await this.post(path, formData);

            return data;
        } catch ({ response: { data, status } }) {
            const error = { error: 'errors.server.generic', message: data, status };

            if (status !== 200) {
                error.error = `errors.server.${status}`;
            }

            console.warn('[WebinarsService] An error occurred while creating webinar:', error, status);
            return error;
        }
    }

    /**
     * @returns {String} the URL of the webinar
     */
    getWebinarUrl(webinar) {
        return getBackstageURL(getNodeName(webinar.node), `${STUDIO_EXPRESS_BASE_PATH}/${webinar.id}/${webinar.live_stream_id}`);
    }

    /**
     * Given the name of the event's app and a name of the path
     * this method returns the URL for the webapp registration page
     *
     * @param {object} webinar the webinar's object
     * @param {string} pathName the name of the path
     * @param {boolean} [noCache] whether to return a non-cacheable version of the URL or not
     * @returns {String} the URL of the webinar's registration page
     */
    getRegistrationUrl(webinar, pathName, noCache) {
        return getWebappRegistrationUrl(webinar.branded_app, pathName, noCache);
    }

    /**
     * Gets the webinar settings
     *
     * @param {String} eventId event ID
     * @returns {Promise<Object>}
     */
    async getSettings(eventId) {
        const url = this.buildUrl(SETTINGS_ENDPOINT, { eventId });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Saves the webinar settings
     *
     * @param {String} eventId event ID
     * @returns {Promise<Object>}
     */
    async saveSettings(eventId, settings) {
        const url = SETTINGS_ENDPOINT
            .replace('{{eventId}}', eventId);

        const { data } = await this.put(url, settings);
        return data;
    }

    /**
     * Updates the icon image for the webinar
     * (uploads a new one or deletes the current one)
     *
     * @param {string} eventId
     * @param {[PickedFile]|null} images the v-model of the imagePicker
     * @param {string} previousIconUrl, the current icon's URL
     * @returns {Promise}
     */
    async updateIconImage(eventId, images, previousIconUrl) {
        return await this.updateImage(eventId, WEBINAR_ICON_ENDPOINT, images, previousIconUrl);
    }

    /**
     * Updates the banner image for the webinar
     * (uploads a new one or deletes the current one)
     *
     * @param {string} eventId
     * @param {[PickedFile]|null} images the v-model of the imagePicker
     * @param {string} previousBannerUrl, the current banner URL
     * @returns {Promise}
     */
    async updateBannerImage(eventId, images, previousBannerUrl) {
        return await this.updateImage(eventId, WEBINAR_BANNER_ENDPOINT, images, previousBannerUrl);
    }

    /**
     * Updates the icon image for the webinar
     * (uploads a new one or deletes the current one)
     *
     * @param {string} eventId
     * @param {string} endpoint the proper endpoint to modify the image
     * @param {[PickedFile]|null} images the v-model of the imagePicker
     * @param {string} previousImageUrl the current image URL
     * @returns {Promise}
     */
    async updateImage(eventId, endpoint, images, previousImageUrl) {

        const currentUrl = images && images[0] && (images[0].url || images[0]);
        const imageWasChanged = currentUrl !== previousImageUrl;
        const imageWasDeleted = images && images.length === 0 && previousImageUrl;
        if (!imageWasChanged && !imageWasDeleted) {
            return;
        }

        const url = this.buildUrl(endpoint, { eventId });

        const image = images[0];
        if (image && image.size > 0) {
            const token = await this.getCsrfToken('');
            const bodyFormData = new FormData();

            bodyFormData.set('file', image.file);
            bodyFormData.set('_csrf', token);

            await this.put(url, bodyFormData, { withCredentials: true });
        } else {
            try {
                await this.delete(url);
            } catch (err) {
                if (err.response?.status !== 404) {
                    throw err;
                }
            }
        }
    }

    /**
     * Updates the icon image for the webinar from the given template
     *
     * @param {string} eventId
     * @param {string} templateId, template id
     * @returns {Promise}
     */
    async updateBannerImageFromTemplate(eventId, templateId) {
        const url = WEBINAR_BANNER_ENDPOINT.replace('{{eventId}}', eventId);

        await this.put(url, { templateId }, { withCredentials: true });
    }

    /**
     * Updates the icon image for the webinar from the given template
     *
     * @param {string} eventId
     * @param {string} templateId, template id
     * @returns {Promise}
     */
    async updateIconImageFromTemplate(eventId, templateId) {
        const url = WEBINAR_ICON_ENDPOINT.replace('{{eventId}}', eventId);

        await this.put(url, { templateId }, { withCredentials: true });
    }

    /**
     * get all templates for a give orgId or user
     *
     * @param {string} orgId
     * @returns {Promise<object>}
     */
    async getTemplates(orgId) {
        const { data } = await this.get(
            this.buildUrl(TEMPLATES_API_ENDPOINT, { orgId })
        );

        return get(data, 'templates', []);
    }

    /**
     * get template by UUID
     *
     * @param {string} orgId
     * @param {string} templateId
     * @returns {Promise<object>}
     */
    async getTemplate(orgId, templateId) {
        const { data } = await this.get(
            this.buildUrl(TEMPLATES_SINGLE_API_ENDPOINT, { orgId, templateId })
        );
        return data;
    }

    /**
     * convert webinar template data to form data
     *
     * @param {WebinarTemplate} template
     */
    async templateToFormData(template) {
        const token = await this.getCsrfToken();
        const formData = new FormData();

        // add images to the form data
        const banner = get(template, 'banner.0.file');
        const background = get(template, 'background.0.file');
        const icon = get(template, 'icon.0.file');
        if (banner) {
            formData.set('banner', banner);
        }
        if (background) {
            formData.set('background', background);
        }
        if (icon) {
            formData.set('icon', icon);
        }

        // add payload and token to formData
        const payload = WebinarTemplate.mapToSnakeCase(template);
        formData.set('json', JSON.stringify(payload));
        formData.set('_csrf', token);

        return formData;
    }

    /**
     * create a new template or edit existing one
     *
     * @param {WebinarTemplate} data
     * @param {string} orgId
     * @returns {Promise<object>}
     */
    async createTemplate(data, orgId) {
        const formData = await this.templateToFormData(data);
        const { data: response } = await this.post(
            this.buildUrl(TEMPLATES_API_ENDPOINT, { orgId }),
            formData
        );

        return response;
    }

    /**
     * Create a new template from an existing webinar
     *
     * @param {string} eventId
     * @param {string} newName
     * @returns {Promise<object>}
     */
    async convertToTemplate(eventId, newName) {
        const { data: response } = await this.post(
            this.buildUrl(WEBINAR_TO_TEMPLATE_API_ENDPOINT, { eventId }),
            { newName },
            { headers: { 'Content-Type': 'application/json' } });
        return response;
    }

    /**
     * create a new template or edit existing one
     *
     * @param {WebinarTemplate} data
     * @param {string} orgId
     * @returns {Promise<object>}
     */
    async updateTemplate(data, orgId) {
        console.debug('[Webinar] Updating template', data);
        const formData = await this.templateToFormData(data);
        const { data: response } = await this.put(
            this.buildUrl(TEMPLATES_SINGLE_API_ENDPOINT, { orgId, templateId: data._id }),
            formData
        );

        return response;
    }
}
