"use strict";

module.exports = function () {
  var resolver = /* @ngInject */function resolver($route, eventService, settingsService) {
    var event = eventService.getCurrentEvent();
    var eventId = event._id;
    return settingsService.compileRegistry(eventId).then(function () {
      return settingsService.fetchRegistry(event);
    });
  };
  resolver.$inject = ["$route", "eventService", "settingsService"];
  return resolver;
};