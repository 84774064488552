// Resolvers:
// below are functions which will return a 'injectable' angular function as required by
// the 'resolve' config of $routeProvider
// they all take only on option argument used to control the behaviour of the returned function
const handlerResolver = require('./handlerResolver');
const crudItemResolver = require('./crudItemResolver');
const crudItemsResolver = require('./crudItemsResolver');
const crudItemFieldsResolver = require('./crudItemFieldsResolver');
const dryRunCompiledNavResolver = require('./dryRunCompiledNavResolver');
const settingsRegistryResolver = require('./settingsRegistryResolver');
const settingsResolver = require('./settingsResolver');
const helpContentResolver = require('./helpContentResolver');
const participantsResolver = require('./participantsResolver');
const participantInfoAndTimelineResolver = require('./participantInfoAndTimelineResolver');
const appScriptResolver = require('./appScriptResolver');

const customSourcesResolver = require('./customSourcesResolver');

const DEFAULT_RESOLVER_KEY = 'default';

// get key in resolver object based on the provided key and the default key to use if key if 'default'
const getResolverKey = (key, defaultKey) => key === DEFAULT_RESOLVER_KEY ? defaultKey : key;

// help which creates a resolve function where key (or default key) will be assigned the result of the factory
const buildSimpleResolver = (defaultKey, factory, resolverOptions) =>
    (resolve, resolverKey, options) => {
        const key = getResolverKey(resolverKey, defaultKey);

        if (resolve[key]) {
            // notify if a strategy as already been defined
            console.warn(`[Resolvers] Strategy ${key} is already defined`, resolve);
        }
        resolve[key] = factory(options, resolverOptions);
    };


// mapping of strategy name => function which to change the resolve object using the resolvers defined above
const strategies = {
    // simple mapping, where the generated function with be assigned to a key in the resolve object
    retrieve_handler_response: buildSimpleResolver('handlerResponse', handlerResolver),
    retrieve_by_id: buildSimpleResolver('crudItem', crudItemResolver),
    retrieve_all_item_fields: buildSimpleResolver('crudItemFields', crudItemFieldsResolver),
    retrieve_dry_run_compiled_nav: buildSimpleResolver('compiledNav', dryRunCompiledNavResolver),
    retrieve_settings_registry: buildSimpleResolver('settingsRegistry', settingsRegistryResolver),
    retrieve_settings: buildSimpleResolver('settings', settingsResolver),
    retrieve_module_help_content: buildSimpleResolver('helpContent', helpContentResolver),

    // this strategy resolves both items and fields, and assign fields to a specific resolve key if needed
    // which makes other strategies over complicated...
    retrieve_all: function(resolve, resolverKey, options) {
        const { resolveFieldsAsKey } = options;

        // use the already existing fields strategy
        strategies.retrieve_all_item_fields(
            resolve,
            resolveFieldsAsKey ? resolveFieldsAsKey : DEFAULT_RESOLVER_KEY,
            options
        );
        // and use a simple resolvers for items
        const crudItemsStrategy = buildSimpleResolver('crudItems', crudItemsResolver);

        crudItemsStrategy(resolve, resolverKey, options);
    },

    retrieve_generic: function(resolve, resolverKey, options) {
        const { resolveFieldsAsKey } = options;

        // use the already existing fields strategy
        strategies.retrieve_all_item_fields(
            resolve,
            resolveFieldsAsKey ? resolveFieldsAsKey : DEFAULT_RESOLVER_KEY,
            options
        );
        // and use a simple appscript resolvers for items
        const crudItemsStrategy = buildSimpleResolver('crudItems', appScriptResolver, {
            returnData: false
        });

        crudItemsStrategy(resolve, resolverKey, options);
    },

    // FP TYPE SPECIFIC, NOT TO BE USED

    retrieve_all_sessions: function(resolve, resolverKey) {
        return strategies.retrieve_all(
            resolve,
            getResolverKey(resolverKey, 'eventAgendaItems'),
            {
                fpType: 'session',
                resolveFieldsAsKey: 'eventAgendaItemsFields'
            }
        );
    },
    retrieve_all_pax: buildSimpleResolver('eventParticipantsInfo', participantsResolver),
    retrieve_all_pax_info_and_timeline: buildSimpleResolver('eventParticipantInfoAndTimeline', participantInfoAndTimelineResolver),

    // for reference: not used or only internally and removed:
    //   retrieve_bundles
    //   retrieve_available_licenses_count
    //   retrieve_event_consumed_licenses_count
    //   retrieve_all_users
    //   retrieve_all_orgs_users_events
    //   retrieve_all_agenda_items
    //   retrieve_all_agenda_items_and_pax
    //   retrieve_first_agenda_items_and_pax
};

// add alias
strategies['retrieve_all_items_and_fields'] = strategies['retrieve_all'];

// to avoid resolver collision for automatic resolvers, find a free key in object
const getFreeKeyInObject = (obj, requiredKey) => requiredKey in obj ?
    getFreeKeyInObject(obj, `_${requiredKey}`)
    : requiredKey;

/**
 * Turns resolver configuration into route `resolve`, and inject the default resolvers as well
 * @param {Object} resolvers
 * @return {Object} to be used directly as the `resolve` property when defining a route
 */
module.exports = function buildResolvers(resolvers) {
    const resolve = {};

    _.each(resolvers, ({ strategy, options = {} }, resolverKey) => {
        const definedStrategy = strategies[strategy];

        if (definedStrategy) {
            definedStrategy(resolve, resolverKey, options);
        }
    });
    // add custom sources default resolver
    resolve[getFreeKeyInObject(resolve, '_sources')] = customSourcesResolver();
    return resolve;
};
