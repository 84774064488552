// Utils
import { get, set } from 'lodash';

// Classes
import BaseService from './base-service';

// Constants
import { API_BASE_PATH } from 'libs/utils/constants';

/**
 * @const {string} COMPILE_NAVS_ENDPOINT - The endpoint to compile the navs. Interpolations: eventId, route.
 */
const COMPILE_NAVS_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/compile/{{route}}`;

/**
 * @const {string} OVERRIDE_NAV_BIT_ID_PREFIX - The prefix for the override nav bit ID.
 */
const OVERRIDE_NAV_BIT_ID_PREFIX = 'bstg-nav-bit:';

/**
 * @const {string} OVERRIDE_NAV_BIT_TYPE - The type for the override nav bit.
 */
const OVERRIDE_NAV_BIT_TYPE = 'nav-bit:nav_spotman';

/**
 * Provides methods to interact with the Navs.
 *
 * @example
 * import NavsService from 'libs/services';
 * ...
 * const apiDoc = new NavsService();
 */
export default class NavsService extends BaseService {

    /**
     * Constructs an instance of the class.
     * Initializes the parsers and serializers objects.
     */
    constructor() {
        super();
        this.parsers = {};
        this.serializers = {};
    }

    /**
     * Injects a parser for a given navigation editor key.
     *
     * @param {string} navEditorKey - The key for the navigation editor.
     * @param {Function} parser - The parser function to be injected.
     */
    injectParser(navEditorKey, parser) {
        this.parsers[navEditorKey] = parser;
    }

    /**
     * Injects a serializer for a given navigation editor key.
     *
     * @param {string} navEditorKey - The key for the navigation editor.
     * @param {Function} serializer - The serializer function to be injected.
     */
    injectSerializer(navEditorKey, serializer) {
        this.serializers[navEditorKey] = serializer;
    }

    /**
     * Retrieves the parser function associated with the given navigation editor key.
     *
     * @param {string} navEditorKey - The key identifying the navigation editor.
     *
     * @returns {Function|undefined} The parser function associated with the given key, or undefined if no parser is found.
     */
    getParser(navEditorKey) {
        return this.parsers[navEditorKey];
    }

    /**
     * Retrieves the serializer associated with the given navigation editor key.
     *
     * @param {string} navEditorKey - The key identifying the navigation editor.
     *
     * @returns {Function|undefined} The serializer function associated with the given key, or undefined if no serializer is found.
     */
    getSerializer(navEditorKey) {
        return this.serializers[navEditorKey];
    }

    /**
     * Generates a function to compile navigation data.
     *
     * @param {boolean} expectNavId - Indicates whether a navigation ID is expected.
     * @returns {Function} - A function that compiles navigation data.
     *
     * The returned function has the following signature:
     *
     * @param {string} eventId - The event ID.
     * @param {string} [navId] - The navigation ID (only if expectNavId is true).
     * @param {boolean} [doDryRun=false] - If true, performs a dry run using a GET request; otherwise, uses a POST request.
     * @param {boolean} [enableBitPruning=false] - If true, enables bit pruning in the query.
     *
     * @returns {Promise} - A promise that resolves with the result of the HTTP request.
     *
     * @private
     */
    generateNavCompileFns(expectNavId) {
        const fullFn = (eventId, navId, doDryRun = false, enableBitPruning = false) => {
            const method = doDryRun ? 'get' : 'post';
            const route = expectNavId ? `nav/${navId}` : 'navs';
            const query = enableBitPruning ? '' : '?prune=false';

            const url = this.buildUrl(COMPILE_NAVS_ENDPOINT, { eventId, route });

            return this[method](`${url}${query}`);
        };

        if (expectNavId) {
            return fullFn;
        } else {
            return (eventId, doDryRun, enableBitPruning) => fullFn(eventId, null, doDryRun, enableBitPruning);
        }
    }

    /**
     * Generates an override bit ID for a given navigation ID.
     *
     * @param {string} navId - The ID of the navigation item.
     * @returns {string} The generated override bit ID.
     */
    getNavOverrideBitId(navId) {
        return `${OVERRIDE_NAV_BIT_ID_PREFIX}-${navId}`;
    }

    /**
     * Retrieves navigation data for a given event and navigation ID.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} navId - The ID of the navigation.
     * @param {boolean} [prune=false] - Whether to prune the navigation data.
     * @returns {Promise<Object>} A promise that resolves to the navigation data.
     */
    async getNav(eventId, navId, prune = false) {
        const { data } = await this.generateNavCompileFns(true)(eventId, navId, true, prune);
        return data?.[0];
    }

    /**
     * Retrieves navigation configurations for the given event and navigation items.
     *
     * @param {string} eventId - The ID of the event.
     * @param {Object} navs - An object containing navigation items.
     * @param {Object} options - Additional options.
     * @param {Object} options.builder - A builder object used to construct the final configuration.
     * @returns {Promise<Object>} A promise that resolves to the built navigation configuration.
     */
    async getNavConfig(eventId, navs, { builder }) {
        const navIdsAndPaths = Object.values(navs);
        const configs = [];

        for (const { navId } of navIdsAndPaths) {
            const config = await this.getNav(eventId, navId);
            configs.push(config);
        }

        return this.buildConfig(configs, navs, builder);
    }

    /**
     * Builds a model based on the provided navigation items.
     *
     * @param {Array} configs - An array of navigation configurations to build the model from.
     * @param {Array} navs - An array of navigation items to build the model from.
     * @param {Object} builder - The builder object containing the parser and serializer.
     *
     * @returns {Object} The built navigation model.
     *
     * @private
     */
    buildConfig(configs, navs, builder) {
        const navIdsAndPaths = Object.values(navs);

        // run em all through the parsers
        const navPartials = configs.map((config, idx) => {
            const { path } = navIdsAndPaths[idx];

            if (!path) {
                return config;
            }

            const partial = get(config, path);

            if (partial) {
                return partial;
            }

            // exception fallback: pretend this entry doesn't exist
            console.warn('[NavsService] The given path does not exist in the nav', config, path);
        });

        // run all the partials through the nav parsers
        const parser = this.getParser(builder?.parser);
        const parsedPartials = parser ? navPartials.map(parser) : navPartials;
        const config = {};
        for (const parsed of parsedPartials) {
            Object.assign(config, parsed);
        }

        return config;
    }


    /**
     * Saves the navigation configuration for a given event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {Object} eventNode - The event node object.
     * @param {Object} data - The data to be saved.
     * @param {Object} options - The options object.
     * @param {Array} options.navs - An array of navigation objects.
     * @param {string} options.navs[].navId - The ID of the navigation.
     * @param {string} options.navs[].path - The path of the navigation.
     * @param {Object} options.builder - The builder object.
     * @param {Object} options.$services - The services object.
     *
     * @returns {Promise<void>} - A promise that resolves when the navigation configuration is saved.
     */
    async saveNav(eventId, eventNode, data, { navs, builder, $services }) {
        const navIdsAndPaths = Object.values(navs);
        for (const { navId, path } of navIdsAndPaths) {
            const override = await this.getOverrideNavBit(eventId, navId, $services);
            this.mergePartialIntoNavBit(data, override, path, builder);

            console.info('[NavsService] Saving nav config', override);
            await this.updateOverrideNavBit(eventId, eventNode, override, navId, `nav-bit:${navId}`, $services);
            await this.compileNav(eventId, navId);
        }
    }

    /**
     * Updates the override navigation bit with the provided details and saves it to the database.
     *
     * @param {string} eventId - The ID of the event.
     * @param {Object} eventNode - The event node object.
     * @param {Object} navBit - The navigation bit object to be updated.
     * @param {string} navId - The ID of the navigation.
     * @param {string|null} navBitType - The type of the navigation bit. If null, a default type is used.
     * @param {Object} $services - The services object containing the documents service.
     *
     * @returns {Promise} - A promise that resolves when the navigation bit is successfully saved to the database.
     */
    updateOverrideNavBit(eventId, eventNode, navBit, navId, navBitType, $services) {
        // ensure the override bit contains required fields
        if (navBitType === null) {
            navBitType = OVERRIDE_NAV_BIT_TYPE;
        }
        navBit._id = this.getNavOverrideBitId(navId);
        navBit.fp_subtype = navId;
        navBit.fp_bit_priority = 'highest';
        // save the override bit to the db
        return $services.documents.createDocs(eventId, eventNode, navBitType, navBit, { keepId: true });
    }

    /**
     * Retrieves the override navigation bit for a given event and navigation ID.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string|null} navId - The ID of the navigation. If null, defaults to 'nav_spotman'.
     * @param {Object} $services - The services object containing the documents service.
     * @param {Object} $services.documents - The documents service.
     * @param {Function} $services.documents.getDocById - Function to get a document by its ID.
     *
     * @returns {Promise<Object>} The override navigation bit object. If not found, returns an empty object.
     *
     * @throws {Error} Throws an error if the request fails with a status other than 404.
     */
    async getOverrideNavBit(eventId, navId, $services) {
        try {
            if (navId === null) { navId = 'nav_spotman'; }
            return await $services.documents.getDocById(eventId, this.getNavOverrideBitId(navId));
        } catch (error) {
            if (error.response.status === 404) {
                console.log('[NavsService] No override nav bit found for navId:', navId);
                return {};
            }

            throw error;
        }
    }

    /**
     * Compiles navigation data for a given event and navigation ID.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} navId - The ID of the navigation.
     *
     * @returns {Promise<Object>} A promise that resolves to the compiled navigation data.
     */
    async compileNav(eventId, navId) {
        const { data } = await this.generateNavCompileFns(true)(eventId, navId);
        return data;
    }

    /**
     * Merges partial data into a navigation bit object.
     *
     * @param {Object} newData - The new data to merge into the navigation bit.
     * @param {Object} navBit - The navigation bit object to be updated.
     * @param {string[]} path - The path within the navigation bit where the new data should be merged.
     * @param {Object} builder - An optional builder object that may contain a serializer function.
     *
     * @throws {Error} Throws an error if merging the nav bits fails.
     *
     * @private
     */
    mergePartialIntoNavBit(newData, navBit, path, builder) {
        try {
            let setter = (ctx, obj) => Object.assign(navBit, obj);

            if (path && path.length) {
                setter = (ctx, obj) => set(ctx, path, obj);
            }

            const serializer = this.getSerializer(builder?.serializer);
            setter(navBit, serializer ? serializer(newData) : newData);
        } catch (error) {
            console.error('[NavEditor] An error occurred while merging the nav bits', error.message);
            throw error;
        }
    }
}
