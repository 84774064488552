"use strict";

var $ = require('jquery');
var newWindowEvents = function newWindowEvents(spec) {
  var $document = spec.$document;
  var drag = spec.drag;
  var drop = spec.drop;
  function attachWindowEvents() {
    $document.bind('mouseup', onWindowMouseUp).bind('mousemove', onWindowMouseMove);
  }
  function detachWindowEvents() {
    $document.unbind('mouseup', onWindowMouseUp).unbind('mousemove', onWindowMouseMove);
  }
  function onWindowMouseUp(event) {
    drop(event.pageX, event.pageY);
  }
  function onWindowMouseMove(event) {
    drag(event.pageX, event.pageY);
  }
  return {
    attachWindowEvents: attachWindowEvents,
    detachWindowEvents: detachWindowEvents
  };
};
angular.module('backstage.services').service('btDnd', /* @ngInject */["$window", function ($window) {
  var draggedElement = null;
  var $document = $($window.document);
  var isInDom = false;
  var windowEvents = newWindowEvents({
    $document: $(document),
    stopDrag: stopDrag,
    drag: drag,
    drop: drop
  });
  var dispatcher = $('<div/>');
  function drag(x, y) {
    draggedElement.$view.css({
      left: x - draggedElement.offset.x + 'px',
      top: y - draggedElement.offset.y + 'px'
    });
    dispatcher.trigger('drag', {
      draggedElement: draggedElement,
      x: x,
      y: y
    });
  }
  function startDrag(element) {
    draggedElement = element;
    var width = draggedElement.$view.width();
    var height = draggedElement.$view.height();
    isInDom = $.contains($document[0].documentElement, draggedElement.$view[0]);
    if (!isInDom) {
      $document.find('body').append(draggedElement.$view);
    }
    var $view = draggedElement.$view;
    $view.addClass('bt-dragged');
    if (draggedElement.fixedSize) {
      $view.css({
        width: width + 'px',
        height: height + 'px'
      });
    }
    windowEvents.attachWindowEvents();
  }
  function stopDrag() {
    if (!draggedElement) {
      return;
    } // if it's already stopped
    if (!isInDom) {
      draggedElement.$view.remove();
    }
    var el = draggedElement;
    var $view = draggedElement.$view;
    $view.removeClass('bt-dragged');
    if (draggedElement.fixedSize) {
      $view.css({
        width: '',
        height: ''
      });
    }
    draggedElement = null;
    windowEvents.detachWindowEvents();
    dispatcher.trigger('stopDrag', {
      draggedElement: el
    });
  }
  function drop(x, y) {
    var el = draggedElement;
    dispatcher.trigger('drop', {
      draggedElement: el,
      x: x,
      y: y
    });
    stopDrag();
  }
  return {
    on: dispatcher.on.bind(dispatcher),
    removeEventListener: dispatcher.unbind.bind(dispatcher),
    startDrag: startDrag,
    stopDrag: stopDrag,
    drop: drop,
    getDraggedElement: function getDraggedElement() {
      return draggedElement;
    }
  };
}]);