"use strict";

module.exports = /* @ngInject */["$scope", "userService", function ($scope, userService) {
  var userCanReadAllOrgs = userService.hasPermission('org_realms.read_all_orgs');
  $scope.userCanReadAllOrgs = userCanReadAllOrgs;
  $scope.hasOrgAccess = function (org) {
    return userService.hasPermission('org_realms.read_org_doc', {
      org_id: org._id
    });
  };
}];