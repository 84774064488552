"use strict";

// bsFocusWhenSet

// workaround for changeable login input autofocus
module.exports = /* @ngInject */["$timeout", function ($timeout) {
  return {
    link: function link(scope, element, attrs) {
      var focus = function focus() {
        return $timeout(function () {
          return element.focus();
        }, 300);
      };
      if (scope[attrs.whenWatch]) {
        focus();
      }
      return scope.$watch(function () {
        return scope[attrs.whenWatch];
      }, function (newValue) {
        if (newValue) {
          return focus();
        }
      });
    }
  };
}];