"use strict";

var marked = require('marked');
angular.module('backstage.controllers.help', []).controller('HelpContentViewerCtrl', /* @ngInject */["$window", "$sce", "$scope", "helpContent", function ($window, $sce, $scope, helpContent) {
  $scope.helpContent = helpContent || (helpContent = {});
  var allPageKeys = $scope.allPageKeys = _.keys(helpContent);
  // selectedKey might be set from helpService (or defaultHelpViewer)

  $scope.selectedKey = $scope.selectedKey || _.first(allPageKeys);
  $scope.topLevelPageKeys = _.chain(allPageKeys).map(function (key) {
    var slashIdx = key.indexOf('/');
    if (slashIdx === -1) {
      return;
    }
    return key.substr(0, slashIdx);
  }).compact().uniq().value();
  $scope.selectItem = function (pageKey) {
    $scope.selectedKey = pageKey;

    // a very grubby scroll-to-top hack
    angular.element($window).scrollTop(0);
  };
  $scope.getPageHtmlForKey = _.memoize(function (pageKey) {
    return $sce.trustAsHtml(marked(atob(helpContent[pageKey] || '')));
  });
}]);