"use strict";

module.exports = /* @ngInject */["$filter", function ($filter) {
  return {
    restrict: 'EAC',
    scope: {
      model: '=',
      label: '@'
    },
    link: function link(scope, element, attrs) {
      scope.name = $filter('stripStringToAlphaNumerics')(attrs.model);
    },
    template: "\n            <label class=\"control-label\">{{label}}</label>\n            <div class=\"controls\">\n                <div ng-model=\"model\" data-bs-colourpicker></div>\n                <input type=\"text\" name=\"{{name}}\" ng-model=\"model\" ng-model-options=\"{ updateOn: 'blur' }\" class=\"input-mini has-validations\" data-bs-hex-colour-validation/>\n            </div>\n            <p class=\"help error\" ng-if=\"$parent.themeForm[name].$error.colour\">\n                Invalid hex colour code\n            </p>\n            "
  };
}];