module.exports = [() => ({
    controller: 'ReorderableModuleListNavCtrl',
    link(scope, elem) {
        elem.sortable({
            tolerance: 'intersect'
        });
        elem.disableSelection();

        return scope.$watch('inModuleReorderMode', function(newValue, oldValue) {
            // mutex for controller above
            scope.lastModuleReorderModeHandled = newValue;
            if (newValue) {
                elem.sortable('enable');
                return elem.css({
                    minHeight: elem.outerHeight()
                }); // fix list height
            } else {
                elem.sortable('disable');
                elem.css({
                    minHeight: ''
                }); // unfix list height

                // save the new module ordering?
                if (oldValue !== true) {
                    return;
                }
                let reorderedModuleKeys =
                    _.compact(_.map(elem.children(), el => $(el).attr('data-module-key')));

                return scope.saveModuleOrdering(reorderedModuleKeys);
            }
        });
    }
})];
